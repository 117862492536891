import { useEffect, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { getMyFavorites } from 'api/user/favorites/getUserFavorites';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useMyFavoritesFetch = (isModalOpen) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const { authenticated } = useAppStateContext();
  const dispatchSetFavoriteOrders = useAppDispatchContext();
  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      if (authenticated) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const response = await getMyFavorites();
          const faves = response.faves ? response.faves.reverse() : [];
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: faves } });
            dispatchSetFavoriteOrders({ type: ACTION_TYPES.setFavoriteOrders, payload: { data: faves } });
          }
        } catch (e) {
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
          }
        }
      }
    };

    if (isModalOpen) {
      fetch();
    }

    return () => {
      ignore = true;
    };
  }, [isModalOpen, dispatchSetFavoriteOrders, authenticated]);

  return [state];
};

export default useMyFavoritesFetch;
