import React, { memo } from 'react';
import styles from './HandoffModeInfo.module.scss';
import Alert from 'components/Alert/Alert';
import { useQueryUrlParamsDispatch } from 'hooks';
import { getHandoffModeName } from '../../../HandoffModeModal/HandoffModeModal';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import HandoffModeLink from 'components/HandoffModeLink/HandoffModeLink';
import { TextButton } from 'components/Button/Button';

const HandoffModeInfo = ({ onClose }) => {
  const {
    basket: { data, error },
  } = useAppStateContext();
  const setQuery = useQueryUrlParamsDispatch();
  const errorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not load basket. Please try again later!')) || '';
  const handoffMode = getHandoffModeName(data && data.deliverymode);
  const onSelectHandoffClick = () => {
    onClose();
    setQuery({ selectLocationFirstModal: true });
  };
  return (
    <>
      {errorMessage && <Alert message={errorMessage} />}
      {data && (
        <div className={styles.handoff_mode_info}>
          <span>{handoffMode}</span>
          <HandoffModeLink className={styles.handoff_mode_link} label={'Edit'} />
        </div>
      )}
      {!data && (
        <div className={styles.select_handoff_mode}>
          <span className={styles.handoff_title}>Order Type:</span>
          <TextButton onClick={onSelectHandoffClick} className={styles.handoff_mode_link}>
            Select Order Type
          </TextButton>
        </div>
      )}
    </>
  );
};
const MemoizedHandoffModeInfo = memo(HandoffModeInfo);
export default MemoizedHandoffModeInfo;
