import React, { useState, useEffect } from 'react';
import Validator from 'services/ValidateForm';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { TextButton, PrimaryButton } from 'components/Button/Button';
import { InputField } from 'components/Input/Input';
import Link from 'components/Link/Link';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';

import useSendEmail from './hooks/useSendEmail';
import './ForgotPasswordForm.scss';

const errorMessages = {
  emailIsInvalid: 'Please enter a valid e-mail address',
};

const ForgotPasswordForm = ({ close, onSignIn, onSignUp }) => {
  const [sendEmail, setSendEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [validateEmail, setValidateEmail] = useState(true);
  const [{ loading, data, error: errorSendEmail }, { sendEmailToReset }] = useSendEmail();
  const [isErrorSendEmailVisible, setIsErrorSendEmailVisible] = useState(false);

  useEffect(() => {
    if (data && data.result === 'success') {
      setSendEmail(true);
      setValidateEmail(true);
    }

    if (errorSendEmail) {
      setValidateEmail(false);
      setIsErrorSendEmailVisible(true);
      setEmailErrorMessage(errorMessages.emailIsInvalid);
    }
  }, [data, sendEmail, setSendEmail, errorSendEmail]);

  const handleSubmit = () => {
    const isValid = Validator.validateEmail(email);
    if (!isValid) {
      setEmailErrorMessage(errorMessages.emailIsInvalid);
      setValidateEmail(false);
      return;
    }

    if (close) {
      close();
    } else {
      if (email) {
        sendEmailToReset(email);
      }
    }
  };

  const handleResendEmail = () => {
    sendEmailToReset(email);
  };

  const onChangeEmail = () => {
    if (email) {
      setEmail('');
    }
    if (!validateEmail) {
      setValidateEmail(true);
      setEmailErrorMessage('');
      setIsErrorSendEmailVisible(false);
    }
  };

  const handleEmail = (event) => {
    if (!isErrorSendEmailVisible) {
      const isValid = Validator.validateEmail(event.target.value);
      if (event.target.validity.valid && isValid) {
        setEmailErrorMessage('');
        setEmail(event.target.value);
        setValidateEmail(true);
        return;
      }
      setEmailErrorMessage(errorMessages.emailIsInvalid);
      setValidateEmail(false);
    }
  };

  const errorMessage =
    errorSendEmail && isErrorSendEmailVisible
      ? errorSendEmail.errorMessage || 'Something went wrong... Could not send email. Please try again later!'
      : '';

  return (
    <>
      {errorMessage && <Alert message={errorMessage} className={'form_error_container'} />}
      <form className={'form'}>
        {sendEmail ? (
          <>
            <label className={'form_success-content'}>
              <CheckCircleIcon className={'form_success_icon'} fontSize={'large'} />
              <p className="content_text">Check your email for a link to reset your password.</p>
            </label>
            <label className={'form_signin'}>
              <Link routing onClick={onSignIn}>
                <PrimaryButton>SIGN IN</PrimaryButton>
              </Link>
            </label>
            <label className={'form_resend-email'}>
              <TextButton size={'large'} onClick={handleResendEmail}>
                resend email
              </TextButton>
            </label>
          </>
        ) : (
          <>
            <label className={'form_input-email'}>
              <InputField
                required
                onChange={onChangeEmail}
                label={'Email'}
                error={!validateEmail}
                helperText={emailErrorMessage}
                onBlur={handleEmail}
                autoComplete={'username'}
                inputProps={{
                  minLength: '6',
                  maxLength: '60',
                }}
              />
            </label>
            <label className={'form_button-submit'}>
              <PrimaryButton onClick={handleSubmit} disabled={loading}>
                SUBMIT
                <Spin spinning={loading} className={'button_spinner'} />
              </PrimaryButton>
            </label>
            {onSignUp && (
              <div className={'form_signup'}>
                <span>DON&apos;T HAVE AN ACCOUNT?</span>
                <Link routing isGothic onClick={onSignUp}>
                  <TextButton>Sign up</TextButton>
                </Link>
              </div>
            )}
          </>
        )}
      </form>
    </>
  );
};

export default React.memo(ForgotPasswordForm);
