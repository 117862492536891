import { PASSWORD_VALIDATION_PATTERN } from './password';
import { EMAIL_VALIDATION_PATTERN } from './email';

const Validator = {
  validateEmail: (email) => {
    return email.match(EMAIL_VALIDATION_PATTERN) ? true : false;
  },

  validatePassword: (password) => {
    return password.match(PASSWORD_VALIDATION_PATTERN) ? true : false;
  },

  isFieldsEmpty: (...fields) => {
    const errors = [];
    fields.forEach((obj) => {
      if (!obj.value) errors.push(obj.field);
    });
    return errors.filter(Boolean);
  },
};

export default Validator;
