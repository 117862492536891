import React, { useCallback, memo } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes.json';

import { TextButton } from 'components/Button/Button';
import Link from 'components/Link/Link';

import { ForgotPasswordForm } from '../../components/Forms';
import styles from './ForgotPassword.module.scss';
import PrimaryHeader from '../../components/PrimaryHeader/PrimaryHeader';

const ForgotPassword = () => {
  const history = useHistory();

  const handleSubmit = useCallback(() => {
    history.push(routes.account);
  }, [history]);

  const handleSignUp = useCallback(() => {
    history.push(routes.signUp);
  }, [history]);

  const handleSignIn = useCallback(() => {
    history.push(routes.signIn);
  }, [history]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.form_header}>
          <PrimaryHeader title={'Forgot Password'} size={'extra'} />
        </div>
        <div className={styles.form_body}>
          <ForgotPasswordForm close={handleSubmit} onSignIn={handleSignIn} />
        </div>
        <div className={styles.form_footer}>
          <span>DON&apos;T HAVE AN ACCOUNT?</span>
          <Link routing isGothic onClick={handleSignUp}>
            <TextButton>Sign up</TextButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(ForgotPassword);
