import React from 'react';
import styles from './OrderDetails.module.scss';
import Section from 'components/Section/Section';
import moment from 'moment';

const OrderDetails = ({ order: { readytime, deliveryaddress }, restaurant, isDeliveryMode }) => {
  const date = moment(readytime, 'YYYYMMDD hh:mm').format('h:mm A[\n]dddd, MMMM D, YYYY');
  const { building = '', city = '', zipcode = '', streetaddress = '' } = deliveryaddress || {};
  const { state, streetaddress: restaurantAddress, zip: restaurantZipCode, city: restaurantCity } = restaurant;

  return (
    <Section className={styles.section} sectionHr title="Order Details">
      <div className={styles.wrapper}>
        <div className={styles.section}>
          <h4 className={styles.date}>{`${isDeliveryMode ? 'Delivery' : 'Pickup'} at ${date}`}</h4>
          {deliveryaddress && (
            <p>
              {`${building} ${streetaddress}`}
              <br />
              {`${city}, ${zipcode}`}
            </p>
          )}
        </div>
        <div className={styles.section}>
          <h4>Your Store:</h4>
          <p>
            {restaurantAddress}
            <br />
            {`${restaurantCity} ${state} ${restaurantZipCode}`}
          </p>
        </div>
      </div>
    </Section>
  );
};
export default React.memo(OrderDetails);
