import React from 'react';

import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

import HandoffInfo from 'components/HandoffInfo/HandoffInfo';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

import RestaurantDetails from '../RestaurantDetails/RestaurantDetails';

const CreateGroupOrderFormView = () => {
  const { groupOrder } = useAppStateContext();
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  const groupOrderExist = !(groupOrder && groupOrder.data === null && !groupOrder.error);
  return (
    <>
      <Grid container spacing={isMobileSize ? 0 : 5}>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <RestaurantDetails showEdit={!groupOrderExist} topped={!isMobileSize} />
        </Grid>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <HandoffInfo topped={!isMobileSize} />
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(CreateGroupOrderFormView);
