import React, { useState, useRef } from 'react';
import Validator from 'services/ValidateForm';
import { PrimaryButton } from 'components/Button/Button';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import EmailInput from 'components/EmailInput/EmailInput';
import { FirstNameInput, LastNameInput } from 'components/NameInputs/NameInputs';

import './AsGuestForm.scss';

const FIELDS = ['lastName', 'firstName', 'email', 'phoneNumber'];

const AsGuestForm = ({ close }) => {
  const sessionUser = JSON.parse(window.sessionStorage.getItem('guestData')) || {};
  const { firstName: sFirstName = '', email: sEmail = '', lastName: sLastName = '', mobilePhone = '' } = sessionUser;
  const [firstName, setFirstName] = useState(sFirstName);
  const [lastName, setLastName] = useState(sLastName);
  const [email, setEmail] = useState(sEmail);
  const [phoneValue, setPhoneValue] = useState(mobilePhone);
  const [validatePhoneValue, setValidatePhoneValue] = useState(true);
  const [validateFirstName, setValidateFirstName] = useState(true);
  const [validateLastName, setValidateLastName] = useState(true);
  const [validateEmail, setValidateEmail] = useState(true);
  const phoneNumberRef = useRef(null);

  const onChangeFirstName = (value) => {
    setValidateFirstName(true);
    setFirstName(value);
  };

  const onChangeLastName = (value) => {
    setValidateLastName(true);
    setLastName(value);
  };

  const onChangeEmail = (value) => {
    setValidateEmail(true);
    setEmail(value);
  };
  const onErrorEmail = (value) => {
    if (value) {
      setValidateEmail(!value);
      setEmail('');
    } else {
      setValidateEmail(!value);
    }
  };

  const onChangePhone = (value) => {
    setValidatePhoneValue(true);
    setPhoneValue(value);
  };

  const submitGuestUser = () => {
    const fields = Validator.isFieldsEmpty(
      { value: firstName, field: 'firstName' },
      { value: lastName, field: 'lastName' },
      { value: email, field: 'email' },
      { value: phoneValue, field: 'phoneNumber' }
    );
    const isNotErrors = setInvalidValue(fields);

    if (isNotErrors) {
      const data = {
        firstName,
        lastName,
        email,
        mobilePhone: phoneValue,
        username: email,
      };

      window.sessionStorage.setItem('guestData', JSON.stringify(data));
      close();
    }
  };

  const setInvalidValue = (fields) => {
    FIELDS.forEach((field) => {
      if (fields.includes(field)) {
        switch (field) {
          case 'firstName':
            setValidateFirstName(false);
            break;
          case 'lastName':
            setValidateLastName(false);
            break;
          case 'email':
            setValidateEmail(false);
            break;
          case 'phoneNumber':
            setValidatePhoneValue(false);
            break;
          default:
            return;
        }
      } else {
        switch (field) {
          case 'firstName':
            setValidateFirstName(true);
            break;
          case 'lastName':
            setValidateLastName(true);
            break;
          case 'email':
            setValidateEmail(true);
            break;
          case 'phoneNumber':
            setValidatePhoneValue(true);
            break;
          default:
            return;
        }
      }
    });

    return fields.length <= 0;
  };

  return (
    <div className={'as-guest_content'}>
      <form className={'content_form'}>
        <div className={'form_first-name'}>
          <FirstNameInput error={!validateFirstName} onChange={onChangeFirstName} />
        </div>
        <div className={'form_last-name'}>
          <LastNameInput error={!validateLastName} onChange={onChangeLastName} />
        </div>
        <div className={'form_email'}>
          <EmailInput defaultValue={sEmail} error={!validateEmail} onError={onErrorEmail} onChange={onChangeEmail} />
        </div>
        <div className={'form_phone'}>
          <PhoneInput
            onChange={onChangePhone}
            required
            value={phoneValue}
            ref={phoneNumberRef}
            error={!validatePhoneValue}
          />
        </div>
        <div className={'form_as-guest-button'}>
          <PrimaryButton size="large" onClick={submitGuestUser}>
            CONTINUE AS GUEST
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default React.memo(AsGuestForm);
