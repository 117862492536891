import React, { useMemo } from 'react';
import PlacesMap from 'modules/RestaurantsMap/PlacesMap';
import { useGeoPosition, useQueryUrlParamsDispatch } from 'hooks';
import Spin from 'components/Spin/Spin';
import { useEffect } from 'react';
import { useQueryUrlParams } from 'hooks';
import useNearByRestaurants from '../RestaurantsMap/hooks/useNearByRestaurants';
import { useHistory } from 'react-router';
import { useAppDispatchContext } from '../../stateProviders/useAppStateContext';
import { ACTION_TYPES } from '../../stateProviders/appStateProvider';
import styles from './RestaurantLocation.module.scss';

const RestaurantLocation = () => {
  const history = useHistory();
  const dispatch = useAppDispatchContext();
  const setQuery = useQueryUrlParamsDispatch();
  const { location, locationId } = useQueryUrlParams();
  const showPlacesView = location && JSON.parse(location);
  const [
    { loading: loadingRestaurants, data: restaurantsData, error: restaurantsError },
    getNearByRestaurants,
  ] = useNearByRestaurants();
  const { latitude, longitude, accuracy, timestamp, error } = useGeoPosition(locationId);
  const isGeolocationLoading = !latitude && !longitude && !accuracy && !timestamp && !error;
  const isDefinedGeolocation = !isGeolocationLoading && latitude && longitude && accuracy;
  const isDeniedGeolocation = !isGeolocationLoading && error && !latitude && !longitude && !accuracy;
  useEffect(() => {
    if (isDeniedGeolocation && !showPlacesView) {
      setQuery({ location: true });
    } else if (
      isDefinedGeolocation &&
      !showPlacesView &&
      !loadingRestaurants &&
      !restaurantsData &&
      !restaurantsError
    ) {
      getNearByRestaurants(latitude, longitude);
    }
  }, [
    isDeniedGeolocation,
    setQuery,
    showPlacesView,
    loadingRestaurants,
    latitude,
    longitude,
    isDefinedGeolocation,
    getNearByRestaurants,
    restaurantsError,
    restaurantsData,
  ]);

  const restaurant = useMemo(
    () => (restaurantsData && restaurantsData['restaurants'].length > 0 ? restaurantsData['restaurants'][0] : null),
    [restaurantsData]
  );

  useEffect(() => {
    if (!loadingRestaurants && !showPlacesView && ((restaurantsData && !restaurant) || restaurantsError)) {
      setQuery({
        location: true,
      });
    }
  }, [restaurant, showPlacesView, loadingRestaurants, restaurantsError, restaurantsData, setQuery]);

  const pageLoading = isGeolocationLoading || loadingRestaurants;

  const showPlaces = !pageLoading && showPlacesView;
  const showConfirmRestaurant = !showPlacesView && !pageLoading && !restaurantsError && restaurant;

  useEffect(() => {
    if (showConfirmRestaurant && history && dispatch && restaurant) {
      dispatch({ type: ACTION_TYPES.setAutodetectedRestaurant, payload: { data: restaurant } });
      history.push(`/autodetectlocation`);
    }
  }, [showConfirmRestaurant, history, dispatch, restaurant]);

  return (
    <>
      {pageLoading && (
        <div className={styles.restaurant_location_spinner_container}>
          <Spin spinning={true} color="primary" />
        </div>
      )}
      {showPlaces && (
        <PlacesMap defaultLocationSelect={locationId} coords={isDefinedGeolocation && { latitude, longitude }} />
      )}
    </>
  );
};
export default React.memo(RestaurantLocation);
