import React, { useCallback, useEffect, useState } from 'react';
import RestaurantCategoriesNav from './RestaurantCategoriesNav/RestaurantCategoriesNav';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import RestaurantCategoryList from './RestaurantCategoryList/RestaurantCategoryList';

function RestaurantCategoryListView({
  search,
  menuSearchTerm,
  imagepath,
  categoryDefault,
  categories,
  displayedCategories,
  onProductClick,
  onCategoryView,
  className,
}) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [navRect, setNavRect] = useState(null);
  const dispatch = useAppDispatchContext();
  const isSearchFinished = search === menuSearchTerm;

  useEffect(() => {
    if (categoryDefault) {
      setSelectedCategory({ ...categoryDefault });
    }
  }, [categoryDefault]);

  useEffect(() => {
    if (isSearchFinished && menuSearchTerm !== '' && selectedCategory && selectedCategory.index !== -1) {
      setSelectedCategory({ index: -1, scrollingTo: false, animation: false });
      window.scrollTo(0, 0);
    } else if (isSearchFinished && menuSearchTerm === '' && selectedCategory && selectedCategory.index === -1) {
      setSelectedCategory({ index: 0, scrollingTo: true, animation: false });
    }
  }, [isSearchFinished, menuSearchTerm, selectedCategory]);

  const handleCategoryClick = useCallback(
    (index) => {
      setSelectedCategory({ index: index, scrollingTo: true, animation: menuSearchTerm === '' });
      if (menuSearchTerm !== '') {
        dispatch({ type: ACTION_TYPES.cleanupMenuSearch });
      }
    },
    [dispatch, menuSearchTerm]
  );

  const handleCategoryChange = useCallback((category) => {
    setSelectedCategory(category);
  }, []);

  const handleNavDomUpdated = useCallback(
    (value) => {
      if (navRect === null || JSON.stringify(value) !== JSON.stringify(navRect)) {
        setNavRect(value);
      }
    },
    [navRect]
  );

  const value = selectedCategory ? selectedCategory.index : -1;
  const top = navRect ? navRect.top + navRect.height : 0;
  const navHeight = navRect ? navRect.height : 0;

  return (
    <div className={className} style={{ marginTop: navHeight }}>
      {categories.length > 0 && (
        <RestaurantCategoriesNav
          categories={categories}
          value={value}
          onDomUpdated={handleNavDomUpdated}
          onChange={handleCategoryClick}
        />
      )}
      <RestaurantCategoryList
        imagepath={imagepath}
        categories={displayedCategories}
        top={top}
        selectedCategory={selectedCategory}
        onCategoryChange={handleCategoryChange}
        menuSearchTerm={menuSearchTerm}
        onProductClick={onProductClick}
        onViewCategory={onCategoryView}
      />
    </div>
  );
}

export default RestaurantCategoryListView;
