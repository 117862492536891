import React from 'react';
import styles from './ConfirmTipsModal.module.scss';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import CheckIcon from '@material-ui/icons/Check';

const ConfirmTipsModal = ({ isOpen, handleOnClose, onSubmit }) => {
  return (
    <ModalWindow
      fullWidth={false}
      maxWidth="sm"
      className={styles.modal_wrapper}
      open={isOpen}
      onChange={handleOnClose}
      headerSize="extra"
      headerTitle="Confirm Tip"
      footerChildren={
        <div className={styles.modal_confirm_btn}>
          <PrimaryButton
            size="large"
            fullWidth
            onClick={onSubmit}
            endIcon={<CheckIcon />}
            className={styles.confirm_btn}
          >
            Confirm
          </PrimaryButton>
        </div>
      }
    >
      <div className={styles.modal_content}>
        <p>You’ve entered a generous tip. Please confirm this is correct before check out.</p>
      </div>
    </ModalWindow>
  );
};

export default React.memo(ConfirmTipsModal);
