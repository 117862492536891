import React, { memo } from 'react';
import styles from './Checkout.module.scss';
import CheckoutOrder from './CheckoutOrder/CheckoutOrder';

const Checkout = () => {
  return (
    <div className={styles.wrapper}>
      <CheckoutOrder />
    </div>
  );
};
const MemoizedCheckout = memo(Checkout);
export default MemoizedCheckout;
