import moment from 'moment';

const generateTimeIntervals = (from, to, interval) => {
  const intervals = [];
  while (from.isBefore(to)) {
    intervals.push(moment(from));
    from.set('minute', from.minutes() + interval);
  }
  return intervals;
};

const getDeliveryTimeIntervals = (openingTime, closingTime, offset, interval = 15) => {
  const now = moment().utcOffset(offset);
  const roundedMinutes = interval * Math.ceil(openingTime.minute() / interval);
  openingTime.set('minute', roundedMinutes);
  openingTime.set('second', 0);
  closingTime.set('second', 0);
  const intervals = generateTimeIntervals(openingTime, closingTime, interval);
  return intervals.filter((interval) => interval.isAfter(now));
};

export default getDeliveryTimeIntervals;
