import axios from './axiosInstances/axiosInstance';

export const updateBasketProduct = (
  basketGuid,
  basketProductId,
  productId,
  options,
  quantity,
  specialInstructions,
  recipient
) => {
  return axios.put(`baskets/${basketGuid}/products/${basketProductId}`, {
    productid: productId,
    options: options,
    quantity: quantity,
    specialinstructions: specialInstructions,
    recipient: recipient,
  });
};
