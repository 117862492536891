export const mapSavedAddressToViewModel = ({
  id,
  streetaddress,
  zipcode,
  building,
  city,
  specialinstructions,
  isdefault,
}) => ({
  id,
  street: streetaddress,
  zipCode: zipcode,
  building,
  city,
  isDefault: isdefault,
  specialInstructions: specialinstructions || '',
});

export const mapViewModelToSavedAddress = ({
  id,
  street,
  zipCode,
  building,
  city,
  specialInstructions,
  isDefault,
}) => ({
  id,
  streetaddress: street,
  zipcode: zipCode,
  building,
  city,
  isdefault: isDefault,
  specialinstructions: specialInstructions,
});
