import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './HandoffModeModal.module.scss';
import { isMacOs } from 'react-device-detect';

// MAPPERS
import { mapSavedAddressToViewModel, mapViewModelToSavedAddress } from './mappers/savedAddressMapping';

// COMPONETS IMPORTS
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ModalWindow from 'components/Modal/Modal';
import { TextButton, PrimaryButton } from 'components/Button/Button';
import { InputField } from 'components/Input/Input';
import { useRestaurantInfo } from 'components/OrderingFrom/OrderingFrom';
import { CheckboxWithLabel } from 'components/Checkbox/Checkbox';
import Spin from 'components/Spin/Spin';
import Card from 'components/Card/Card';
import Section from 'components/Section/Section';
import { Divider } from '@material-ui/core';

// HOOKS IMPORTS
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import useCurbsideFetch from './hooks/useCurbsideFetch';
import useDispatchAddressFetch from './hooks/useDispatchAddressFetch';
import useSetDeliveryMode from './hooks/useSetDeliveryMode';
import useUserSavedAddressList from './hooks/useUserSavedAddressList';

// ICONS

import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';

// LOCAL CONSTANTS
const CURBSIDE_INPUTES_MAX_LENGTH = 16;
const CONTACTLESS_DELIVERY_MESSAGE = 'I want contactless delivery';
export const HANDOFF_MODE_ENUM = {
  DELIVERY: 'delivery',
  DISPATCH: 'dispatch',
  PICKUP: 'pickup',
  CURBSIDE: 'curbside',
};
const INITIAL_ADDRESS = {
  id: '',
  street: '',
  building: '',
  city: '',
  zipCode: '',
  specialInstructions: '',
  isdefault: false,
};

export const getHandoffModeName = (basketDeliveryMode) => {
  if (!basketDeliveryMode) return null;
  return basketDeliveryMode === HANDOFF_MODE_ENUM.DISPATCH ? HANDOFF_MODE_ENUM.DELIVERY : basketDeliveryMode;
};

const getInitialAddress = (address) =>
  Object.assign({}, INITIAL_ADDRESS, address && mapSavedAddressToViewModel(address));
const getInitialCustomField = (basket, fieldKey) => {
  const field =
    basket &&
    basket.customfields &&
    basket.customfields.find((field) => field.label.toLowerCase().startsWith(fieldKey));
  return (field && field.value) || '';
};

/**
 * Figure out the default value of Handoof Mode for initializing page.
 * In case the office contains only one option (curbside (pickup) or delivery) then available option should be selected by default. In case the office contains both options the Curbside (pickup) option have to be selected by default.
 * @param handoffModes Information about restaurant handoff modes.
 * @param handoffModes.hasCurbside Information about the availability of curbside of the selected restaurant.
 * @param handoffModes.hasPickup Information about the availability of pickup of the selected restaurant.
 * @param handoffModes.hasDelivery Information about the availability of delivery of the selected restaurant.
 * @returns enum{HANDOFF_MODE_ENUM}
 */
const getDefaultHandoffMode = ({ hasCurbside, hasPickup, hasDelivery, isModifyMode, basket }) => {
  if (isModifyMode && basket) {
    return basket.deliverymode === HANDOFF_MODE_ENUM.DISPATCH ? HANDOFF_MODE_ENUM.DELIVERY : basket.deliverymode;
  }

  return (
    (hasCurbside && HANDOFF_MODE_ENUM.CURBSIDE) ||
    (hasPickup && HANDOFF_MODE_ENUM.PICKUP) ||
    (hasDelivery && HANDOFF_MODE_ENUM.DELIVERY)
  );
};

const HandoffModeNew = ({
  changeOrderMode,
  isOpen = false,
  restaurant: restaurantFromProps,
  basket: basketFromProps,
  isModifyMode = false,
  cannotBeClosed = false,
  submitBtnText = 'Start your order',
  onClose = () => {},
  onSubmit = () => {},
}) => {
  // Restaurant data
  const restaurantFromState = useRestaurantInfo([
    'id',
    'supportsdispatch',
    'supportscurbside',
    'supportedtimemodes',
    'utcoffset',
    'iscurrentlyopen',
    'customfields',
  ]);
  const {
    canpickup: hasPickup,
    supportsdispatch: hasDelivery,
    supportscurbside: hasCurbside,
    customfields: customFields,
  } = restaurantFromProps || restaurantFromState;
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  // Basket data
  const {
    basket: { data: basketFromState },
    authenticated,
  } = useAppStateContext();
  const basket = basketFromProps || basketFromState || {};

  // GENERAL STATES
  const [activeHandoffMode, setActiveHandoffMode] = useState(
    getDefaultHandoffMode({ hasCurbside, hasPickup, hasDelivery, isModifyMode, basket })
  );
  const [isTouched, setIsTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressesListExpanded, setIsAddressesListExpanded] = useState(false);

  // CURBSIDE STATES
  const [make, setMake] = useState(getInitialCustomField(basket, 'make'));
  const [model, setModel] = useState(getInitialCustomField(basket, 'model'));
  const [color, setColor] = useState(getInitialCustomField(basket, 'color'));

  // DELIVERY STATES
  const [address, setAddress] = useState(getInitialAddress(basket.deliveryaddress));
  const [shouldSaveAddress, setShouldSaveAddress] = useState(false);

  // HOOKS
  const { setCustomField } = useCurbsideFetch();
  const { setDispatchAddress } = useDispatchAddressFetch();
  const { setDeliveryMode } = useSetDeliveryMode();
  const { data: userSavedAddresses, removeAddress } = useUserSavedAddressList(authenticated);

  useEffect(() => {
    const isAddressFilled = Object.keys(INITIAL_ADDRESS).some((key) => !!address[key]);
    if (userSavedAddresses && userSavedAddresses.length && !isAddressFilled) {
      const defaultAddress = userSavedAddresses.find((item) => item.isdefault);
      if (defaultAddress) {
        setAddress(mapSavedAddressToViewModel(defaultAddress));
      }
    }
  }, [userSavedAddresses, setAddress, address]);

  useEffect(() => {
    if (!!restaurantFromState && !activeHandoffMode) {
      const {
        canpickup: hasPickup,
        supportsdispatch: hasDelivery,
        supportscurbside: hasCurbside,
      } = restaurantFromState;
      setActiveHandoffMode(getDefaultHandoffMode({ hasCurbside, hasPickup, hasDelivery, isModifyMode, basket }));
    }
  }, [restaurantFromState, restaurantFromProps, activeHandoffMode, setActiveHandoffMode, basket, isModifyMode]);

  const onHandoffModeBtnClick = (mode) => () => {
    setIsTouched(false);
    setActiveHandoffMode(mode);
    changeOrderMode(mode);
  };
  const onInputStateChange = (setState) => (e) => {
    setState(e.target.value);
  };
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevState) => ({ ...prevState, [name]: value, id: undefined }));
  };
  const handleZipCode = (e) => {
    const { value } = e.target;
    const pattern = /^[0-9]*$/;
    if (pattern.test(value)) {
      handleAddressChange(e);
    }
  };

  const handleAddressSelect = (address) => () => setAddress(mapSavedAddressToViewModel(address));
  const onSaveAddressCheck = () => () => setShouldSaveAddress(!shouldSaveAddress);
  const getErrorInputErrorMsg = (name) => `Please enter a ${name}`;
  const isFormValid = () => {
    const requiredCurbsideFields = [make, model, color];
    const { street, city, zipCode } = address;
    const requiredDeliveryFields = [street, city, zipCode];

    switch (activeHandoffMode) {
      case HANDOFF_MODE_ENUM.CURBSIDE:
        return !requiredCurbsideFields.some((field) => !field);
      case HANDOFF_MODE_ENUM.DELIVERY:
        return !requiredDeliveryFields.some((field) => !field);
      default:
        return true;
    }
  };

  const putCurbsideInfoToBasket = async () => {
    const getCurbsideFieldId = (fieldKey) =>
      customFields.find((field) => field.label.toLowerCase().startsWith(fieldKey)).id;

    const curbsideFields = [
      { id: getCurbsideFieldId('make'), value: make },
      { id: getCurbsideFieldId('model'), value: model },
      { id: getCurbsideFieldId('color'), value: color },
    ];
    await setDeliveryMode(basket.id, HANDOFF_MODE_ENUM.CURBSIDE);
    return Promise.all(curbsideFields.map((field) => setCustomField(basket.id, { id: field.id, value: field.value })));
  };

  const putDeliveryAddressToBasket = async () => {
    const newAddress = await setDispatchAddress(basket.id, mapViewModelToSavedAddress(address));
    if (authenticated && !shouldSaveAddress && !address.id) {
      removeAddress(newAddress.id);
    }
  };

  const handleContactlessDeliveryCheck = () => {
    const { specialInstructions = '' } = address;
    const hasContactlessDelivery = specialInstructions.includes(CONTACTLESS_DELIVERY_MESSAGE);
    const regExp = new RegExp(`\\s?${CONTACTLESS_DELIVERY_MESSAGE}`, 'gi');

    const getCombinedUserMsgWithContactlessDelivery = () =>
      specialInstructions ? `${specialInstructions} ${CONTACTLESS_DELIVERY_MESSAGE}` : CONTACTLESS_DELIVERY_MESSAGE;
    const instructions = hasContactlessDelivery
      ? specialInstructions.replace(regExp, '')
      : getCombinedUserMsgWithContactlessDelivery();
    setAddress((prevState) => ({ ...prevState, specialInstructions: instructions, id: undefined }));
  };

  const handleIsDefaultAddressCheck = () =>
    setAddress((prevState) => ({ ...prevState, isDefault: !address.isDefault }));

  const onStartOrderClick = async () => {
    setIsTouched(true);
    if (!isFormValid()) return;
    try {
      setIsLoading(true);
      if (activeHandoffMode === HANDOFF_MODE_ENUM.CURBSIDE) {
        await putCurbsideInfoToBasket();
      } else if (activeHandoffMode === HANDOFF_MODE_ENUM.DELIVERY) {
        await putDeliveryAddressToBasket();
      }
      setIsLoading(false);
      onSubmit();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const renderHandoffModeButtons = () => {
    const shouldShowCurbside = hasCurbside || !hasPickup;
    const shouldShowPickup = hasPickup && !hasCurbside;

    return (
      <div className={styles.buttonGroup}>
        {shouldShowCurbside && (
          <PrimaryButton
            className={classNames({
              [styles.active]: activeHandoffMode === HANDOFF_MODE_ENUM.CURBSIDE,
            })}
            disabled={!hasCurbside}
            onClick={onHandoffModeBtnClick(HANDOFF_MODE_ENUM.CURBSIDE)}
          >
            <div className={styles.button_content}>
              <span>{HANDOFF_MODE_ENUM.CURBSIDE}</span>
              <CarIcon
                className={classNames(styles.icon, {
                  [styles.icon__disabled]: !hasCurbside,
                  [styles.mac_platform]: isMacOs,
                })}
              />
            </div>
          </PrimaryButton>
        )}
        {shouldShowPickup && (
          <PrimaryButton
            className={classNames({
              [styles.active]: activeHandoffMode === HANDOFF_MODE_ENUM.PICKUP,
            })}
            onClick={onHandoffModeBtnClick(HANDOFF_MODE_ENUM.PICKUP)}
          >
            <div className={styles.button_content}>
              <span>{HANDOFF_MODE_ENUM.PICKUP}</span>
              <CarIcon
                className={classNames(styles.icon, {
                  [styles.mac_platform]: isMacOs,
                })}
              />
            </div>
          </PrimaryButton>
        )}
        <PrimaryButton
          className={classNames({
            [styles.active]: activeHandoffMode === HANDOFF_MODE_ENUM.DELIVERY,
          })}
          disabled={!hasDelivery}
          onClick={onHandoffModeBtnClick(HANDOFF_MODE_ENUM.DELIVERY)}
        >
          <div className={styles.button_content}>
            <span>{HANDOFF_MODE_ENUM.DELIVERY}</span>
            <BoxIcon
              className={classNames(styles.icon, {
                [styles.icon__disabled]: !hasDelivery,
                [styles.mac_platform]: isMacOs,
              })}
            />
          </div>
        </PrimaryButton>
      </div>
    );
  };

  const renderCurbsideForm = () => (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputField
            fullWidth
            error={isTouched && !make}
            helperText={isTouched && !make && getErrorInputErrorMsg('make')}
            inputProps={{ maxLength: CURBSIDE_INPUTES_MAX_LENGTH }}
            onChange={onInputStateChange(setMake)}
            name={'make'}
            label={'Make'}
            value={make}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            fullWidth
            error={isTouched && !model}
            helperText={isTouched && !model && getErrorInputErrorMsg('model')}
            inputProps={{ maxLength: CURBSIDE_INPUTES_MAX_LENGTH }}
            onChange={onInputStateChange(setModel)}
            name={'model'}
            label={'Model'}
            value={model}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            fullWidth
            error={isTouched && !color}
            helperText={isTouched && !color && getErrorInputErrorMsg('color')}
            inputProps={{ maxLength: CURBSIDE_INPUTES_MAX_LENGTH }}
            onChange={onInputStateChange(setColor)}
            name={'color'}
            label={'Color'}
            value={color}
          />
        </Grid>
      </Grid>
    </>
  );

  const onAddssesArrowIconClick = () => setIsAddressesListExpanded(!isAddressesListExpanded);

  const renderDeliveryForm = () => (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputField
            fullWidth
            error={isTouched && !address.street}
            helperText={isTouched && !address.street && getErrorInputErrorMsg('street addreess')}
            label={'Street Address'}
            name={'street'}
            value={address.street || ''}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            fullWidth
            label={'Apt, Floor, Suite, Building, Company'}
            value={address.building || ''}
            name={'building'}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid container item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <InputField
                fullWidth
                error={isTouched && !address.city}
                helperText={isTouched && !address.city && getErrorInputErrorMsg('city')}
                name={'city'}
                label={'City'}
                value={address.city || ''}
                onChange={handleAddressChange}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                fullWidth
                error={isTouched && !address.zipCode}
                helperText={isTouched && !address.zipCode && getErrorInputErrorMsg('zip')}
                name={'zipCode'}
                label={'Zip'}
                value={address.zipCode || ''}
                onChange={handleZipCode}
                inputProps={{
                  maxLength: '5',
                }}
              />
            </Grid>
          </Grid>
          {authenticated && (
            <Grid item xs={12} className={styles.default_address}>
              <Grid className={styles.checkbox} item>
                <CheckboxWithLabel
                  handleChange={handleIsDefaultAddressCheck}
                  label={'Default address'}
                  checked={address.isDefault}
                />
              </Grid>
            </Grid>
          )}
          {authenticated && (
            <Grid container item className={styles.use_saved_address_wrap}>
              <CheckboxWithLabel
                checked={shouldSaveAddress}
                handleChange={onSaveAddressCheck()}
                label={'Save this address'}
              />
              {userSavedAddresses && !!userSavedAddresses.length && (
                <div
                  className={classNames(styles.saved_addresses_button_wrap, {
                    [styles.saved_addresses_button_wrap__mobile]: isMobileSize,
                  })}
                >
                  <TextButton className={styles.use_saved_address_button} onClick={onAddssesArrowIconClick}>
                    <span>Use saved address</span>
                    {isAddressesListExpanded ? (
                      <KeyboardArrowUpIcon className={styles.arrow_icon} />
                    ) : (
                      <KeyboardArrowDownIcon className={styles.arrow_icon} />
                    )}
                  </TextButton>
                </div>
              )}
            </Grid>
          )}
          {userSavedAddresses && !!userSavedAddresses.length && isAddressesListExpanded && (
            <div className={styles.address_list_wrap}>
              <Divider className={styles.divider} />
              <div className={styles.address_list}>
                {userSavedAddresses.map((savedAddress) => (
                  <Card
                    key={savedAddress.id}
                    className={styles.card_wrapper}
                    onCardClick={handleAddressSelect(savedAddress)}
                    isActive={address && savedAddress.id === address.id}
                    variant="outlined"
                  >
                    <div
                      className={classNames(styles.card, {
                        [styles.isActive]: address && savedAddress.id === address.id,
                      })}
                    >
                      {savedAddress.streetaddress && (
                        <span className={styles.card_title}>{savedAddress.streetaddress}</span>
                      )}
                      {savedAddress.building && <span>{savedAddress.building}</span>}
                      {(savedAddress.city || savedAddress.zipcode) && (
                        <span>
                          {savedAddress.city}, {savedAddress.zipcode}
                        </span>
                      )}
                      {savedAddress.phonenumber && <span>Phone: {savedAddress.phonenumber}</span>}
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          )}
        </Grid>
        <Grid className={styles.special_instructions} item xs={12}>
          <Divider className={styles.divider} />
          <InputField
            fullWidth
            label={'Special Instructions'}
            value={address.specialInstructions || ''}
            name={'specialInstructions'}
            onChange={handleAddressChange}
            inputProps={{
              maxLength: '100',
            }}
          />
          <div className={styles.checkbox}>
            <CheckboxWithLabel
              checked={
                !!address.specialInstructions && address.specialInstructions.includes(CONTACTLESS_DELIVERY_MESSAGE)
              }
              handleChange={handleContactlessDeliveryCheck}
              label={'Contactless delivery'}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );

  const isStartOrderButtonDisabled = () => {
    const isCurbsideUnavailable = activeHandoffMode === HANDOFF_MODE_ENUM.CURBSIDE && !customFields;
    const hasAtLeastOneSupportedMode = hasCurbside || hasPickup || hasDelivery;
    return !hasAtLeastOneSupportedMode || isCurbsideUnavailable;
  };

  return (
    <Section
      maxWidth={isMobileSize ? 'lg' : 'sm'}
      headerSize={'lg'}
      open={isOpen}
      onChange={cannotBeClosed ? null : onClose}
      className={styles.modal}
      headerTitle={'Order Type'}
    >
      <div
        className={classNames(styles.handoff_mode_wrap, {
          [styles.handoff_mode_wrap__mobile]: isMobileSize,
          [styles.modal_content__loading]: isLoading,
        })}
      >
        <Spin className={styles.modal_spin} spinning={isLoading} />
        {renderHandoffModeButtons()}
        {activeHandoffMode === HANDOFF_MODE_ENUM.CURBSIDE && renderCurbsideForm()}
        {activeHandoffMode === HANDOFF_MODE_ENUM.DELIVERY && renderDeliveryForm()}
      </div>
      <div
        className={classNames(styles.modal_footer, {
          [styles.modal_footer__mobile]: isMobileSize,
          [styles.modal_footer__loading]: isLoading,
        })}
      >
        <PrimaryButton
          fullWidth
          onClick={onStartOrderClick}
          endIcon={<ArrowRight />}
          disabled={isStartOrderButtonDisabled()}
        >
          Save
        </PrimaryButton>
      </div>
    </Section>
  );
};

export default React.memo(HandoffModeNew);
