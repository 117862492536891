import React, { useCallback } from 'react';
import styles from './InviteHistoryModal.module.scss';
import useInviteHistoryFetch from './hooks/useInviteHistoryFetch';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';
import ModalWindow from 'components/Modal/Modal';
import InviteHistoryList from './InviteHistoryList/InviteHistoryList';
import Section from 'components/Section/Section';
import { TextButton } from 'components/Button/Button';

const InviteHistoryModal = ({ onClose, onOpenInviteModal, open }) => {
  const [{ loading, data, error }] = useInviteHistoryFetch(open);
  const onClickInvite = useCallback(() => {
    onOpenInviteModal({ inviteHistory: false, referFriend: true });
  }, [onOpenInviteModal]);

  const onCloseModal = useCallback(() => onClose(false), [onClose]);

  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not load invite history. Please try again later!')) ||
    '';
  const isLoadedData = !loading && !error && data;

  return (
    <ModalWindow maxWidth="md" headerSize="lg" open={open} onChange={onCloseModal} headerTitle={'INVITE HISTORY'}>
      <div className={styles.modal_content}>
        <Section sectionHr className={styles.invite_history_section} title={'INVITE HISTORY'} />
        {errorMessage && <Alert message={errorMessage} />}
        {loading && <Spin spinning={loading} />}
        {isLoadedData &&
          (data.length > 0 ? (
            <InviteHistoryList data={data} />
          ) : (
            <div className={styles.no_data}>
              You have not sent any invitations yet.&nbsp;
              <TextButton onClick={onClickInvite} className={styles.invite_link}>
                Invite your friends
              </TextButton>
              &nbsp;and receive a special treat when they join!
            </div>
          ))}
      </div>
    </ModalWindow>
  );
};

export default React.memo(InviteHistoryModal);
