import React, { useEffect, memo, useState } from 'react';
import styles from './CouponCode.module.scss';
import { InputField } from 'components/Input/Input';
import { PrimaryButton } from 'components/Button/Button';
import useApplyCouponFetch from './hooks/useApplyCouponFetch';
import CircularProgress from '@material-ui/core/CircularProgress';
import showToast from 'components/Toast/ShowToast';
import CheckIcon from '@material-ui/icons/Check';

const CouponCode = ({ basket }) => {
  const [couponValue, setCouponValue] = useState();
  const [submitCoupon, setSubmitCoupon] = useState(null);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const { loading, error, cleanUpState } = useApplyCouponFetch(submitCoupon);
  useEffect(() => {
    if (submitCoupon && !loading && error && !errorSubmit) {
      const errorMessage =
        error && (error.errorMessage || 'Something went wrong... Could not apply coupon. Please try again later!');
      setErrorSubmit(errorMessage);
      setSubmitCoupon(null);
    }
  }, [error, submitCoupon, errorSubmit, loading]);
  const onSubmitCoupon = () => {
    if (couponValue) {
      if (basket && basket.appliedrewards && basket.appliedrewards.length) {
        showToast('warning', 'Discount coupons cannot be used together with rewards');
        return;
      }

      setSubmitCoupon(couponValue);
    } else {
      setErrorSubmit('Please enter a coupon code.');
    }
  };
  const onFocusInput = () => {
    cleanUpState();
    setErrorSubmit(false);
    setSubmitCoupon(null);
  };
  const onChangeInput = (e) => {
    const value = e.target.value;
    setSubmitCoupon(null);
    setErrorSubmit(false);
    setCouponValue(value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.input}>
        <InputField
          error={!!errorSubmit}
          helperText={errorSubmit ? errorSubmit : 'Coupon cannot bring total to $0.00'}
          fullWidth
          onFocus={onFocusInput}
          onChange={onChangeInput}
          label={'Enter Coupon Code'}
          disabled={loading}
        />
      </div>
      <div className={styles.submit}>
        <PrimaryButton
          className={styles.apply_btn}
          disabled={loading}
          endIcon={<CheckIcon />}
          onClick={onSubmitCoupon}
          size={'large'}
        >
          Apply
        </PrimaryButton>
        {loading && <CircularProgress size={32} className={styles.buttonProgress} />}
      </div>
    </div>
  );
};
const MemoizedCouponCode = memo(CouponCode);
export default MemoizedCouponCode;
