import React, { useCallback, useEffect, useState } from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import useDebounce from 'hooks/useDebounce';
import styles from './RestaurantCategories.module.scss';
import RestaurantCategoryListView from './RestaurantCategoryListView/RestaurantCategoryListView';
import RestaurantCategoryDetailsView from './RestaurantCategoryDetailsView/RestaurantCategoryDetailsView';
import ProductDetailsModal from '../../ProductDetailsModal/ProductDetailsModal';
import { Fade } from '@material-ui/core';

const selectedCategoryDefault = {
  index: 0,
  scrollingTo: true,
  animation: false,
};

const RestaurantCategories = ({ imagepath, categories: restaurantCategories }) => {
  const [categories, setCategories] = useState([]);
  const [categoryDefault, setCategoryDefault] = useState(null);
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategoryDetails, setShowCategoryDetails] = useState(false);
  const search = useAppStateContext().menuSearchValue;
  const menuSearchTerm = useDebounce(search, 500);
  const [openProductDetails, setOpenProductDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    if (restaurantCategories) {
      const values = restaurantCategories.map((category) => {
        return { ...category, ref: React.createRef() };
      });
      setCategories(values);
    }
  }, [restaurantCategories]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      if (menuSearchTerm !== '') {
        const searchResults = [];
        categories.forEach((category) => {
          const filtered = category.products.filter((product) => {
            return (
              product.description.toLowerCase().includes(menuSearchTerm.toLowerCase()) ||
              product.name.toLowerCase().includes(menuSearchTerm.toLowerCase())
            );
          });
          if (filtered) {
            searchResults.push(...filtered);
          }
        });
        setDisplayedCategories(
          searchResults.length > 0
            ? [{ id: 0, name: 'Search results: ' + menuSearchTerm, products: searchResults }]
            : []
        );
      } else {
        setDisplayedCategories([...categories]);
      }
    }
  }, [categories, menuSearchTerm]);

  useEffect(() => {
    if (menuSearchTerm !== '') {
      setSelectedCategory(null);
      setShowCategoryDetails(false);
    }
  }, [menuSearchTerm]);

  useEffect(() => {
    if (categoryDefault === null) {
      setCategoryDefault({ ...selectedCategoryDefault });
    }
  }, [categoryDefault]);

  const handleCategoryView = useCallback((category, index) => {
    setCategoryDefault({ index: index, scrollingTo: false, animation: false });
    setSelectedCategory(category);
    setShowCategoryDetails(true);
  }, []);

  const handleMenuView = useCallback(() => {
    setSelectedCategory(null);
    setShowCategoryDetails(false);
  }, []);

  const handleProductClick = useCallback((product) => {
    setOpenProductDetails(true);
    setSelectedProduct(product);
  }, []);

  const handleProductDetailsClose = useCallback(() => {
    setOpenProductDetails(false);
    setSelectedProduct(null);
  }, []);

  const handleExitListView = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.container}>
      <Fade in={!showCategoryDetails} mountOnEnter unmountOnExit timeout={300} onExited={handleExitListView}>
        <div>
          <RestaurantCategoryListView
            imagepath={imagepath}
            menuSearchTerm={menuSearchTerm}
            search={search}
            categories={categories}
            categoryDefault={categoryDefault}
            displayedCategories={displayedCategories}
            onProductClick={handleProductClick}
            onCategoryView={handleCategoryView}
          />
        </div>
      </Fade>
      <Fade in={showCategoryDetails} mountOnEnter unmountOnExit timeout={300}>
        <div>
          <RestaurantCategoryDetailsView
            imagepath={imagepath}
            category={selectedCategory}
            onProductClick={handleProductClick}
            onMenuView={handleMenuView}
          />
        </div>
      </Fade>
      {openProductDetails && (
        <ProductDetailsModal
          imagepath={imagepath}
          emptyModifiers={selectedProduct.emptyModifiers}
          product={selectedProduct}
          onClose={handleProductDetailsClose}
        />
      )}
    </div>
  );
};

export default React.memo(RestaurantCategories);
