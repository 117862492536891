import React from 'react';
import Section from 'components/Section/Section';
import CopyLink from 'components/CopyLink/CopyLink';
import useReferralCode from './hooks/useReferralCode';
import Alert from 'components/Alert/Alert';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import styles from './ReferFriendCopyLink.module.scss';

const ReferFriendCopyLink = () => {
  const [{ data, loading, error }] = useReferralCode();
  const { code } = data || {};
  const link = code && `${process.env.REACT_APP_DOMAIN}/?referral_code=${code}`;
  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not generate copy link. Please try again later!')) ||
    '';

  return (
    <Section sectionHr title={'Copy link'}>
      {loading && (
        <div className={styles.skeleton}>
          <Skeleton variant="rect" width={480} height={64} />
          <Box ml={2}>
            <Skeleton variant="rect" width={100} height={64} />
          </Box>
        </div>
      )}
      {errorMessage && <Alert message={errorMessage} />}
      {link && <CopyLink link={link} />}
    </Section>
  );
};
export default React.memo(ReferFriendCopyLink);
