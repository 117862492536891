import axios from '../axiosInstances/axiosInstance';

const URI = '/users';

export const getAddresses = () => {
  return axios.get(`${URI}/deliveryAddresses`);
};

export const setDefaultAddress = (id) => {
  return axios.put(`${URI}/deliveryAddresses/${id}/default`);
};

export const deleteAddress = (id) => {
  return axios.delete(`${URI}/deliveryAddresses/${id}`);
};
