import { useEffect, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { getRestaurantByRef } from 'api/restaurantByRef';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useFavoriteLocationsFetch = (favoriteLocationId) => {
  const dispatchAppUserState = useAppDispatchContext();
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      dispatchAppUserState({ type: ACTION_TYPES.setLoadingFavoriteOloRestaurant, payload: { loading: true } });
      dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
      try {
        let response = [];
        let favoriteLocations = [];

        if (favoriteLocationId) {
          response = await getRestaurantByRef(favoriteLocationId);
          favoriteLocations =
            response.restaurants.map((item) => {
              return {
                name: item.name,
                description: `${item.city}, ${item.state}`,
              };
            }) || [];
        }

        if (!ignore) {
          dispatchAppUserState({
            type: ACTION_TYPES.setFavoriteOloRestaurant,
            payload: { data: response.restaurants && response.restaurants.length ? response.restaurants[0] : null },
          });
          dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: favoriteLocations } });
        }
      } catch (e) {
        if (!ignore) {
          dispatchAppUserState({ type: ACTION_TYPES.setErrorFavoriteOloRestaurant, payload: { error: e } });
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
        }
      }
    };

    fetch();

    return () => {
      ignore = true;
    };
  }, [favoriteLocationId, dispatchAppUserState]);

  return [state];
};

export default useFavoriteLocationsFetch;
