import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { getNearByRestaurants } from 'api/nearByRestaurants';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useLocationFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const searchByQuery = useCallback((searchQuery) => {
    const fetch = async () => {
      dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
      try {
        const response = await getNearByRestaurants({ searchQuery });
        const restaurants = response.restaurants || [];
        dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: restaurants } });
      } catch (e) {
        dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
        showToast('error', e.errorMessage || 'Something went wrong... Could not find any locations!');
      }
    };

    fetch();
  }, []);

  return [state, { searchByQuery }];
};

export default useLocationFetch;
