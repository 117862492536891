import React, { useCallback, useEffect, useState } from 'react';
import styles from './ImHereSection.module.scss';
import { PrimaryButton } from 'components/Button/Button';
import PanToolIcon from '@material-ui/icons/PanTool';
import useSubmitArrivalNotification from './hooks/useSubmitArrivalNotification';
import Spin from 'components/Spin/Spin';
import { InputField } from 'components/Input/Input';
import { useParams } from 'react-router';
import { useQueryUrlParams, useQueryUrlParamsDispatch } from 'hooks';
import { ORDER_ARRIVAL_STATUS_ENUM } from './enums/OrderArrivalStatusEnum';

const messageErrors = {
  empty: 'Please provide a parking spot number',
};

const ImHereSection = ({ order, parkingError = false }) => {
  const { orderId } = useParams();
  const { markArrived } = useQueryUrlParams();
  const { deliverymode, arrivalstatus } = order || {};
  const arrivedStatuses = [ORDER_ARRIVAL_STATUS_ENUM.ARRIVED, ORDER_ARRIVAL_STATUS_ENUM.PICKED_UP];
  const isArrived = arrivedStatuses.includes(arrivalstatus);
  const [isSend, setIsSend] = useState(isArrived);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(parkingError);
  const [errorMessage, setErrorMessage] = useState(messageErrors.empty);
  const setQuery = useQueryUrlParamsDispatch();
  const [{ loading, data, error }, { submitCustomerArrival }] = useSubmitArrivalNotification();
  const isNotCurbside = deliverymode !== 'curbside';
  const hasArrivalStatus = order && !!order.arrivalstatus;

  useEffect(() => {
    console.log('markArrived', markArrived);
    if (!loading && data && !error && !isArrived) {
      //setIsSend(true);
      setQuery({}, null, false, ['markArrived']);
    }
  }, [loading, error, data, isArrived, setQuery]);
  const onChangeMessage = (e) => {
    const value = e.target.value.trim();
    setMessageError(false);
    setErrorMessage('');
    setMessage(value);
  };
  const onClick = useCallback(() => {
    if (orderId && message) {
      submitCustomerArrival(orderId, message);
      setIsSend(true);
    } else {
      setMessageError(true);
      setMessageError(messageErrors.empty);
    }
  }, [submitCustomerArrival, orderId, message]);

  // useEffect(() => {
  //   if (orderId && markArrived && !isArrived) {
  //     submitCustomerArrival(orderId, message);
  //   }
  // }, [markArrived, orderId, submitCustomerArrival, isArrived, message]);

  if (isNotCurbside || !hasArrivalStatus) return;
  return (
    <div className={styles.wrapper}>
      {isSend ? (
        <p>
          The restaurant has been notified that you are here. Someone will be out with your order shortly. Thank you!
        </p>
      ) : (
        <>
          <p>
            Use the button below to notify the restaurant that you have arrived. We will bring your food out to your
            vehicle.
          </p>
          <div className={styles.confirmationSection}>
            <InputField
              label={'Parking Spot Number'}
              required
              value={message}
              name="message"
              //maxLength={ 1 }
              inputProps={{
                minLength: '1',
                maxLength: '1',
              }}
              //type={'number'}
              onChange={onChangeMessage}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className={styles.message}
              error={messageError}
              helperText={messageError && errorMessage}
            />
            <PrimaryButton disabled={loading} onClick={onClick} className={styles.button} endIcon={<PanToolIcon />}>
              I am here!
              <Spin spinning={loading} size={30} className={styles.spinner} />
            </PrimaryButton>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(ImHereSection);
