import React, { useCallback } from 'react';
import { useQueryUrlParamsDispatch } from 'hooks';
import { TextButton } from 'components/Button/Button';
import Link from 'components/Link/Link';
import DrawerLayout from 'components/DrawerLayout/DrawerLayout';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import { SignInForm } from '../Forms';
import styles from './SignInPanel.module.scss';
import PasswordImprovementNotification from '../PasswordImprovementNotification/PasswordImprovementNotification';

export const SignInContainer = React.memo(({ onClose, onForgotPassword, onSignUp }) => {
  return (
    <div className={styles.form_container}>
      <div>
        <PasswordImprovementNotification className={styles.notification} onReset={onForgotPassword} />
        <SignInForm close={onClose} />
      </div>
      <div className={styles.form_footer}>
        <div>
          <span>DON&apos;T HAVE AN ACCOUNT?</span>
          <Link routing isGothic onClick={onSignUp}>
            <TextButton>Sign up</TextButton>
          </Link>
        </div>
        <div>
          <Link routing isGothic onClick={onForgotPassword}>
            <TextButton>Forgot Password?</TextButton>
          </Link>
        </div>
      </div>
    </div>
  );
});

SignInContainer.displayName = 'SignInContainer';

const SignInPanel = ({ open, onClose }) => {
  const setQuery = useQueryUrlParamsDispatch();
  const handleForgotPassword = useCallback(() => {
    setQuery(
      {
        forgotPassword: true,
      },
      '',
      false,
      ['signin', 'signup']
    );
  }, [setQuery]);

  const handleSignUp = useCallback(() => {
    setQuery(
      {
        signup: true,
      },
      '',
      false,
      ['forgotPassword', 'signin']
    );
  }, [setQuery]);
  return (
    <DrawerLayout
      anchor={'right'}
      open={open}
      onChange={onClose}
      header={<PrimaryHeader title={'Sign In'} size={'lg'} brush={'after'} />}
      footerChildren={<TextButton onClick={onClose}>cancel</TextButton>}
      transparentFooter
    >
      <SignInContainer onForgotPassword={handleForgotPassword} onSignUp={handleSignUp} onClose={onClose} />
    </DrawerLayout>
  );
};

export default SignInPanel;
