import { useCallback, useReducer } from 'react';
import { getNearByRestaurants } from 'api/nearByRestaurants';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { US_STATES } from 'enums/states';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useNearByRestaurants = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const _getNearByRestaurants = useCallback(
    (latitude = '', longitude = '', radius = '', searchValue = '', clean, searchValueValid = false) => {
      const fetch = async () => {
        if (dispatch) {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            const lat = clean ? '' : latitude;
            const long = clean ? '' : longitude;
            const response = await getNearByRestaurants({ lat, long, radius, searchQuery: searchValue });
            const groupByState =
              response &&
              response.restaurants.reduce((groups, restaurant) => {
                (groups[restaurant.state] = groups[restaurant.state] || []).push(restaurant);
                return groups;
              }, {});
            const restaurantsByState = Object.keys(groupByState)
              .map((key) => {
                const state = groupByState[key][0].state && groupByState[key][0].state;
                const enumState = US_STATES[state];
                return {
                  stateKey: key,
                  stateName: enumState || state,
                  hideState: !enumState,
                  restaurants: groupByState[key].sort((a, b) =>
                    searchValueValid ? a.distance - b.distance : a.city.localeCompare(b.city)
                  ),
                };
              })
              .filter((state) => !state.hideState)
              .sort((a, b) => a.stateName.localeCompare(b.stateName));
            dispatch({
              type: FETCH_ACTION_TYPES.setData,
              payload: { data: { restaurantsByState, restaurants: response.restaurants } },
            });
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
          }
        }
      };
      fetch();
    },
    []
  );
  return [state, _getNearByRestaurants];
};

export default useNearByRestaurants;
