import React, { useCallback, memo, useState } from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import DrawerLayout from 'components/DrawerLayout/DrawerLayout';
import Section from 'components/Section/Section';
import BasketItems from '../OrderingPanel/ReviewOrder/BasketItems/BasketItems';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';
import MomentUtils from '@date-io/moment';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import { DEADLINE_FULL_DATE, ORDER_DEADLINE_OLO } from 'enums/orderDeadline';

import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import { Box } from '@material-ui/core';

import ProductDetailsModal from '../ProductDetailsModal/ProductDetailsModal';
import styles from './RecipientOrderingPanel.module.scss';

const moment = new MomentUtils();

const HeaderPanel = memo(({ hasOrderData, formattedDeadline, errorMessage, groupOrderLoading }) => {
  return (
    <div className={styles.header}>
      <Box mb={4}>
        <PrimaryHeader size="lg" brush="after" title="YOUR ORDER" />
      </Box>
      {errorMessage && <Alert message={errorMessage} />}
      <Spin spinning={groupOrderLoading} />
      {hasOrderData && (
        <>
          <span className={styles.title}>Deadline</span>
          <p className={styles.deadline}>Order Deadline: {formattedDeadline}</p>
        </>
      )}
    </div>
  );
});
HeaderPanel.displayName = 'HeaderPanel';

const RecipientOrderingPanel = () => {
  const { groupOrderPanel } = useQueryUrlParams();
  const parsedGroupOrderPanel = groupOrderPanel ? !!JSON.parse(groupOrderPanel) : false;
  const {
    basket: { loading, data, error },
  } = useAppStateContext();
  const {
    groupOrder: { data: groupOrderData, loading: groupOrderLoading, error: groupOrderError },
  } = useAppStateContext();
  const setQuery = useQueryUrlParamsDispatch();
  const { deadline, note } = groupOrderData || {};
  const formattedDeadline = deadline ? moment.moment(deadline, ORDER_DEADLINE_OLO).format(DEADLINE_FULL_DATE) : '';

  const [openProductDetails, setOpenProductDetails] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);

  const handleClose = useCallback(() => {
    setQuery({}, '', false, ['groupOrderPanel', 'openBasket']);
  }, [setQuery]);

  const hasOrderData = !groupOrderLoading && groupOrderData && !groupOrderError;

  const errorMessage =
    (groupOrderError &&
      (groupOrderError.errorMessage ||
        'Something went wrong... Could not load group order information. Please try again later!')) ||
    '';

  const handleNameClick = (product) => {
    setEditedProduct(product);
    setOpenProductDetails(true);
  };

  const handleProductDetailsClose = () => {
    setOpenProductDetails(false);
    setEditedProduct(null);
  };

  return (
    <>
      <DrawerLayout
        header={
          <HeaderPanel
            groupOrderLoading={groupOrderLoading}
            hasOrderData={hasOrderData}
            formattedDeadline={formattedDeadline}
            errorMessage={errorMessage}
          />
        }
        open={parsedGroupOrderPanel}
        onChange={handleClose}
        disabledFooter={true}
      >
        <div className={styles.container}>
          {hasOrderData && note && (
            <Section className={styles.header_section} sectionHr title={'Your host note'}>
              <h4>{note}</h4>
            </Section>
          )}
          <Section className={styles.header_section} sectionHr title={'SELECTED ITEMS'}>
            <BasketItems
              loading={loading}
              data={data}
              error={error}
              onClose={handleClose}
              handleNameClick={handleNameClick}
            />
          </Section>
          <div className={styles.instructions}>
            <h5>I&apos;m Done Ordering, now what?</h5>
            <p>
              Just close your browser &ndash; no need to do anything else. The organizer will see your order on their
              screen.
            </p>
            <h5>What if I want to add another item?</h5>
            <p>As long as the Order Deadline hasn&rsquo;t passed, you can come back and add to your order.</p>
          </div>
        </div>
      </DrawerLayout>
      {openProductDetails && (
        <ProductDetailsModal edit={true} product={editedProduct} onClose={handleProductDetailsClose} />
      )}
    </>
  );
};
export default RecipientOrderingPanel;
