import axios from '../axiosInstances/axiosInstance';
import styles from 'modules/Checkout/CheckoutOrder/PaymentMethod/NewCreditCard/NewCreditCard.module.scss';
import { CCSF_FIELDS } from './oloCcsfConstants';

const handlers = {
  getOnFocus: function () {
    const self = this;

    if (this.onFocus) {
      return this.onFocus;
    }

    this.onFocus = function (data) {
      self.setCssClass(data.field, 'add', styles.floatedLabel);
    };

    return this.onFocus;
  },
  getOnReady: function () {
    if (this.onReady) {
      return this.onReady;
    }

    this.onReady = function () {};

    return this.onReady;
  },
  onFocus: null,
  onReady: null,
  setCssClass: function (field, action, className) {
    const node = document.getElementById(CCSF_FIELDS[field]);
    node.classList[action](className);
  },
};

const getGiftCardsBalance = ({ giftCard }) => {
  let total = 0;
  for (var index in giftCard) {
    total += giftCard[index].balance;
  }
  return total;
};

const getGiftCardBillingAccount = ({
  useSavedGiftCard,
  amount,
  tip,
  billingSchemeId,
  savedGiftCard,
  cardNumber,
  pin,
  savedGiftCardID,
  currentTotal,
}) => {
  const giftCardFields = [{ name: 'number', value: cardNumber }];
  if (pin) {
    giftCardFields.push({ name: 'pin', value: pin });
  }
  return {
    billingMethod: savedGiftCardID ? 'billingaccount' : 'storedvalue',
    amount: currentTotal && currentTotal <= amount ? amount - (amount - currentTotal) : amount,
    tipPortion: tip,
    saveOnFile: String(savedGiftCard),
    ...(billingSchemeId && { billingSchemeId: billingSchemeId }),
    ...(savedGiftCardID && { billingAccountId: savedGiftCardID }),
    ...(!savedGiftCardID && { billingFields: giftCardFields }),
  };
};

const calculateAmountPortions = (total, giftCardBalance, tip) => {
  let amountCreditCard, amountGiftCard, tipCreditCard, tipGiftCard;

  if (giftCardBalance < total) {
    amountCreditCard = total - giftCardBalance;
    amountGiftCard = giftCardBalance;
    tipGiftCard = amountGiftCard >= tip ? tip : amountGiftCard;
    tipCreditCard = tipGiftCard === tip ? 0 : tip - tipGiftCard;
  } else {
    amountCreditCard = 0;
    amountGiftCard = total;
    tipCreditCard = 0;
    tipGiftCard = tip;
  }

  return {
    amountCreditCard: Number(amountCreditCard.toFixed(2)),
    amountGiftCard: Number(amountGiftCard.toFixed(2)),
    tipCreditCard: Number(tipCreditCard.toFixed(2)),
    tipGiftCard: Number(tipGiftCard.toFixed(2)),
  };
};

const calculateGiftCardAmountPortions = (total, giftCardBalance, tip) => {
  let amountGiftCard, tipGiftCard;

  if (giftCardBalance < total) {
    amountGiftCard = giftCardBalance;
    tipGiftCard = amountGiftCard >= tip ? tip : amountGiftCard;
  } else {
    amountGiftCard = total;
    tipGiftCard = tip;
  }

  return {
    amountGiftCard: Number(amountGiftCard.toFixed(2)),
    tipGiftCard: Number(tipGiftCard.toFixed(2)),
  };
};

const getSubmitWithCreditCardRequestBody = (params) => {
  const {
    basketId,
    cardData = {},
    contactPhoneNumber,
    isAuthenticated,
    accessToken,
    userData = {},
    savedPaymentsAccounts: { creditCard },
    total,
    tip,
  } = params;

  const requestBody = {
    id: basketId,
    accessToken,
    firstName: userData.firstName,
    lastName: userData.lastName,
    emailAddress: userData.email,
    userType: isAuthenticated ? 'user' : 'guest',
    contactNumber: contactPhoneNumber || userData.mobilePhone,
    billingAccounts: [
      {
        amount: total,
        billingMethod: 'creditcard',
        expiryMonth: cardData.expiryMonth,
        expiryYear: cardData.expiryYear,
        zip: cardData.zip,
        tipPortion: tip,
        saveOnFile: String(creditCard),
      },
    ],
  };

  return requestBody;
};

const getSubmitWithMultipleCardsRequestBody = ({
  basket,
  cardData,
  contactPhoneNumber,
  isAuthenticated,
  accessToken,
  userData: { email, firstName, lastName },
  giftCard,
  savedPaymentsAccounts: { creditCard, giftCard: savedGiftCard },
  useSavedGiftCard,
}) => {
  const { tip, total, id: basketId } = basket;
  //const { balance, cardNumber, billingSchemeId, pin, id: giftCardId } = giftCard; //Doesn't do anything since it's now an array
  const balance = getGiftCardsBalance({ giftCard: giftCard });
  const { amountGiftCard, amountCreditCard, tipGiftCard, tipCreditCard } = calculateAmountPortions(total, balance, tip);
  let arrayGifts = [];
  console.log('first');
  console.log(giftCard);
  let currentTotal = total;
  let giftTipRemaining = tipGiftCard;
  for (var index in giftCard) {
    let giftCardPayment = getGiftCardBillingAccount({
      useSavedGiftCard,
      amount: giftCard[index].balance,
      tip: giftCard[index].balance >= giftTipRemaining ? giftTipRemaining : giftCard[index].balance,
      billingSchemeId: giftCard[index].billingSchemeId,
      savedGiftCard,
      cardNumber: giftCard[index].cardNumber,
      pin: giftCard[index].pin,
      savedGiftCardID: giftCard[index].id,
      currentTotal,
    });
    giftTipRemaining = giftCard[index].balance >= giftTipRemaining ? 0 : giftTipRemaining - giftCard[index].balance;
    //cont = giftCard[index].balance >= giftTipRemaining ? cont + 1 : cont;
    currentTotal = Math.round((currentTotal -= giftCardPayment.amount) * 100) / 100;
    arrayGifts.push(giftCardPayment);
  }

  const billingAccounts = arrayGifts;

  if (cardData) {
    billingAccounts.push({
      amount: amountCreditCard,
      billingMethod: 'creditcard',
      expiryMonth: Number(cardData.expiryMonth),
      expiryYear: Number(cardData.expiryYear),
      zip: cardData.zip,
      tipPortion: tipCreditCard,
      saveOnFile: String(creditCard),
    });
  }

  const requestBody = {
    id: basketId,
    userType: isAuthenticated ? 'user' : 'guest',
    firstName: firstName,
    lastName: lastName,
    emailAddress: email,
    contactNumber: contactPhoneNumber,
    billingAccounts: billingAccounts,
    accessToken,
  };
  return requestBody;
};

const getSubmitWithMultipleGiftCardsRequestBody = ({
  basket,
  contactPhoneNumber,
  isAuthenticated,
  accessToken,
  userData: { email, firstName, lastName },
  giftCard,
  savedPaymentsAccounts: { creditCard, giftCard: savedGiftCard },
  useSavedGiftCard,
}) => {
  const { tip, total, id: basketId } = basket;
  const { balance, cardNumber, billingSchemeId, pin, id: giftCardId } = giftCard;
  const { amountGiftCard, amountCreditCard, tipGiftCard, tipCreditCard } = calculateGiftCardAmountPortions(
    total,
    balance,
    tip
  );
  let arrayGifts = [];
  let cont = 0;
  console.log('first');
  console.log(giftCard);
  let currentTotal = total;
  for (var index in giftCard) {
    let giftCardPayment = getGiftCardBillingAccount({
      useSavedGiftCard,
      amount: giftCard[index].balance,
      tip: cont == 0 && giftCard[index].balance >= tipGiftCard ? tipGiftCard : 0,
      billingSchemeId: giftCard[index].billingSchemeId,
      savedGiftCard,
      cardNumber: giftCard[index].cardNumber,
      pin: giftCard[index].pin,
      savedGiftCardID: giftCard[index].id,
      currentTotal,
    });
    currentTotal = Math.round((currentTotal -= giftCardPayment.amount) * 100) / 100;
    cont++;
    arrayGifts.push(giftCardPayment);
  }

  const billingAccounts = arrayGifts;

  const requestBody = {
    id: basketId,
    userType: isAuthenticated ? 'user' : 'guest',
    firstName: firstName,
    lastName: lastName,
    emailAddress: email,
    contactNumber: contactPhoneNumber,
    billingAccounts: billingAccounts,
    accessToken,
  };
  return requestBody;
};

const getSubmitWithSavedBillingAccountRequestBody = (params) => {
  const { billingAccountId, contactPhoneNumber, isAuthenticated, userData = {}, accessToken } = params;
  const { total, tip, id } = params.basket;
  const { amountCreditCard, tipCreditCard } = calculateAmountPortions(total, 0, tip);

  const billingAccounts = [
    {
      amount: amountCreditCard,
      billingAccountId,
      billingMethod: 'billingaccount',
      tipPortion: tipCreditCard,
      // saveOnFile: String(creditCard),
    },
  ];

  const requestBody = {
    id,
    billingAccounts,
    contactNumber: contactPhoneNumber || userData.mobilePhone,
    userType: isAuthenticated ? 'user' : 'guest',
    accessToken,
  };

  if (!isAuthenticated) {
    requestBody.emailAddress = userData.email;
    requestBody.firstName = userData.firstName;
    requestBody.lastName = userData.lastName;
  }

  return requestBody;
};

const getSubmitWithCashRequestBody = (params) => {
  const { basketId, contactPhoneNumber, isAuthenticated, accessToken, basket, userData = {} } = params;
  const { total } = basket;

  const requestBody = {
    id: basketId,
    contactNumber: contactPhoneNumber || userData.mobilePhone,
    userType: isAuthenticated ? 'user' : 'guest',
    accessToken,
    billingAccounts: [
      {
        billingMethod: 'cash',
        amount: total,
        tipPortion: 0,
      },
    ],
  };

  if (!isAuthenticated) {
    requestBody.emailAddress = userData.email;
    requestBody.firstName = userData.firstName;
    requestBody.lastName = userData.lastName;
  }

  return requestBody;
};

const getSubmitWithSavedBillingAccountMultipleRequestBody = ({
  basket,
  contactPhoneNumber,
  isAuthenticated,
  billingAccountId,
  userData: { email, firstName, lastName },
  giftCard,
  savedPaymentsAccounts: { giftCard: savedGiftCard },
  useSavedGiftCard,
  accessToken,
}) => {
  const { tip, total, id: basketId } = basket;
  //const { balance, cardNumber, billingSchemeId, pin, id } = giftCard;
  const balance = getGiftCardsBalance({ giftCard: giftCard });
  const { amountGiftCard, amountCreditCard, tipGiftCard, tipCreditCard } = calculateAmountPortions(total, balance, tip);

  let arrayGifts = [];
  console.log('first');
  console.log(giftCard);
  let currentTotal = total;
  let giftTipRemaining = tipGiftCard;
  for (var index in giftCard) {
    let giftCardPayment = getGiftCardBillingAccount({
      useSavedGiftCard,
      amount: giftCard[index].balance,
      tip: giftCard[index].balance >= giftTipRemaining ? giftTipRemaining : giftCard[index].balance,
      billingSchemeId: giftCard[index].billingSchemeId,
      savedGiftCard,
      cardNumber: giftCard[index].cardNumber,
      pin: giftCard[index].pin,
      savedGiftCardID: giftCard[index].id,
      currentTotal,
    });
    giftTipRemaining = giftCard[index].balance >= giftTipRemaining ? 0 : giftTipRemaining - giftCard[index].balance;
    currentTotal = Math.round((currentTotal -= giftCardPayment.amount) * 100) / 100;
    arrayGifts.push(giftCardPayment);
  }

  const billingAccounts = arrayGifts;

  if (billingAccountId) {
    billingAccounts.push({
      billingMethod: 'billingaccount',
      amount: amountCreditCard,
      tipPortion: tipCreditCard,
      billingAccountId,
    });
  }

  const requestBody = {
    id: basketId,
    userType: isAuthenticated ? 'user' : 'guest',
    firstName: firstName,
    lastName: lastName,
    emailaAdress: email,
    contactNumber: contactPhoneNumber,
    billingAccounts: billingAccounts,
    accessToken,
  };
  return requestBody;
};

const submitWithSinglePayment = (basketId, requestBody) => {
  return axios.post(`/baskets/${basketId}/submit`, requestBody);
};
const submitWithMultiplePayment = (basketId, requestBody) => {
  return axios.post(`/baskets/${basketId}/submit/multiplepayments`, requestBody);
};

const getBasketPaymentAccessToken = (basketId) => {
  return axios.post(`baskets/${basketId}/checkout`);
};

const deleteLastBasket = () => {
  return axios.delete(`baskets/lastBasket`);
};

export const oloCcsf = {
  getSubmitWithCashRequestBody,
  getSubmitWithCreditCardRequestBody,
  getSubmitWithMultipleCardsRequestBody,
  getSubmitWithMultipleGiftCardsRequestBody,
  getSubmitWithSavedBillingAccountRequestBody,
  getSubmitWithSavedBillingAccountMultipleRequestBody,
  onFocus: handlers.getOnFocus.bind(handlers),
  onReady: handlers.getOnReady.bind(handlers),
  getBasketPaymentAccessToken,
  deleteLastBasket,
  submitWithSinglePayment,
  submitWithMultiplePayment,
};
