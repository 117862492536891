import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import showToast from 'components/Toast/ShowToast';
import { setNewPassword } from 'api/resetPassword';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useResetPassword = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const resetPassword = useCallback(
    (resetCode, newPass) => {
      const fetch = async () => {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const response = await setNewPassword(resetCode, newPass);
          if (response.result === 'success') {
            showToast('success', `Success! Your password has been reset. Please login.`);
            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
          } else {
            showToast('error', response.errorMessage || 'Something went wrong... Could not change password!');
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: response } });
          }
        } catch (error) {
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: error } });
        }
      };
      fetch();
    },
    [dispatch]
  );

  return [state, { resetPassword }];
};
export default useResetPassword;
