import axios from './axiosInstances/axiosInstance';

export const addProductToBasket = (basketGuid, productId, options, quantity, specialInstructions, recipient) => {
  return axios.post(`baskets/${basketGuid}/products`, {
    productid: productId,
    options: options,
    quantity: quantity,
    specialinstructions: specialInstructions,
    recipient: recipient,
  });
};
