import { useReducer, useCallback } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { createUserFave } from 'api/createUserFave';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useSetOrderAsFavorite = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const request = useCallback(
    (basketId, orderName) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          if (basketId && orderName) {
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true, refetch: true } });
            try {
              await createUserFave(basketId, orderName);
              dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false, refetch: true } });
              resolve(true);
            } catch (e) {
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
              reject(false);
            }
          }
        };

        fetch();
      }),
    []
  );

  return [state, { request }];
};

export default useSetOrderAsFavorite;
