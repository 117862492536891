import React, { Fragment } from 'react';
import { useLocation } from 'react-router';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useQueryUrlParamsDispatch } from 'hooks';

import { Divider } from '@material-ui/core';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';

import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';
import ModalWindow from 'components/Modal/Modal';
import { TextButton, PrimaryButton } from 'components/Button/Button';
import showToast from 'components/Toast/ShowToast';

import useMyFavoritesFetch from './hooks/useMyFavoritesModalFetch';
import useAddFavoriteToBasket from './hooks/useAddFavoriteToBasket';
import styles from './FavoritesList.module.scss';

function FavoritesList({ state }) {
  const { pathname } = useLocation();
  const [{ loading, data, error }] = useMyFavoritesFetch(state);
  const [{ loading: adding }, { addFavoriteToBasket }] = useAddFavoriteToBasket();
  const {
    restaurant: { data: rData },
    basket: { data: basketData },
  } = useAppStateContext();
  const setQuery = useQueryUrlParamsDispatch();

  const handleAddToBag = (faveId, faveName) => {
    addFavoriteToBasket(faveId).then(() => {
      const SuccessMsg = () => (
        <span>
          Products from favorite order <u>{faveName}</u> added to basket
        </span>
      );

      showToast('success', <SuccessMsg />);

      if (rData && rData.id) {
        setQuery({ openBasket: true }, `/${rData.id}/menu`, false, ['myFavoritesModal']);
      } else {
        setQuery({ location: true }, '/', false, ['myFavoritesModal']);
      }
    });
  };

  const onClose = () => {
    setQuery({}, null, false, ['myFavoritesModal']);
  };

  const handleModifyClick = (id) => {
    const queriesToClear = ['myFavoritesModal', 'groupOrderId', 'owner', 'joinGroup', 'groupOrderModal'];

    const queriesToSet = { favoritesPanel: true, faveId: id };

    if (rData && rData.id) {
      setQuery({ ...queriesToSet }, `/${rData.id}/menu`, false, queriesToClear);
    } else {
      setQuery(
        { selectLocationFirstModal: true, returnUrl: `/:restaurantId/menu?favoritesPanel=true&faveId=${id}` },
        null,
        false,
        queriesToClear
      );
    }
  };

  const FavoritesList = ({ data }) => {
    return data.map((item, index) => {
      const divider = index === data.length - 1 ? '' : <Divider />;

      return (
        <Fragment key={item.id}>
          <div className={styles.favorites_data}>
            <h3 className={styles.product_name}>{item.name}</h3>

            <div className={styles.actions}>
              <PrimaryButton
                size="large"
                disabled={adding}
                endIcon={<LocalMallOutlinedIcon />}
                className={styles.add_to_bag_btn}
                onClick={() => {
                  handleAddToBag(item.id, item.name);
                }}
              >
                ADD TO BAG
              </PrimaryButton>

              <TextButton
                size="large"
                disabled={adding}
                className={styles.modify_btn}
                onClick={() => {
                  handleModifyClick(item.id);
                }}
              >
                MODIFY
              </TextButton>
            </div>
          </div>
          {divider}
        </Fragment>
      );
    });
  };

  const toMenu = () => {
    if (pathname.includes(`/checkout`)) {
      onClose();
      return;
    }

    if (rData && rData.id) {
      setQuery({}, `/${rData.id}/menu`, false, ['myFavoritesModal']);
    } else {
      setQuery({ location: true }, '/', false, ['myFavoritesModal']);
    }
  };

  const errorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not load favorites. Please try again later!')) ||
    '';

  return (
    <ModalWindow open={state} onChange={onClose} headerSize="lg" headerTitle={'MY FAVORITES'} maxWidth={'md'}>
      <div className={styles.modal_content}>
        {errorMessage && <Alert message={errorMessage} />}
        {(loading || adding) && <Spin spinning={loading || adding} />}
        {!loading &&
          !adding &&
          !error &&
          data &&
          (data.length > 0 ? (
            <>
              <Divider />
              <FavoritesList data={data} />
            </>
          ) : (
            <div className={styles.no_data}>
              You have no favorites.&nbsp;
              <TextButton onClick={toMenu} className={styles.place_order_link}>
                {basketData && basketData.products && basketData.products.length ? 'Continue your order' : 'Order Now'}
              </TextButton>
              &nbsp;and save it as a favorite to speed up the ordering process next time.
            </div>
          ))}
      </div>
    </ModalWindow>
  );
}

export default FavoritesList;
