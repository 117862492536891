import React, { useState } from 'react';
import classNames from 'classnames';
import './Image.scss';

const Image = ({ className, alt, url }) => {
  const [imageVisible, setImageVisibility] = useState(true);
  const handleError = () => {
    setImageVisibility(false);
  };
  return (
    url && (
      <span className={classNames('image_container', className)}>
        {imageVisible ? (
          <img src={url} alt={alt} onError={handleError} />
        ) : (
          <span className={'no_picture'} title={alt} tabIndex={-1} />
        )}
      </span>
    )
  );
};

export default React.memo(Image);
