import React, { useCallback, useState } from 'react';
import styles from './HandheldMarkerInfo.module.scss';
import { PrimaryButton, PrimaryIconButton } from 'components/Button/Button';
import CloseIcon from '@material-ui/icons/Close';
import OrderPlaceCard from '../../OrderPlaceCard/OrderPlaceCard';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow_right.svg';

const HandheldMarkerInfo = ({ restaurant = {}, onSelectOrder, onCleanSelectPlace }) => {
  const {
    city,
    state,
    zip,
    streetaddress,
    telephone,
    distance,
    isavailable,
    latitude,
    longitude,
    calendars,
    supportsdispatch,
    supportscurbside,
  } = restaurant;

  const [error, setError] = useState(false);
  const onClickOrder = useCallback(() => onSelectOrder(restaurant), [onSelectOrder, restaurant]);
  const onCloseMarkerInfo = useCallback(() => onCleanSelectPlace(true), [onCleanSelectPlace]);
  const isDisabled = !isavailable || error;
  return (
    <div className={styles.wrapper}>
      <PrimaryIconButton className={styles.close_btn} size="small" onClick={onCloseMarkerInfo}>
        <CloseIcon />
      </PrimaryIconButton>

      <OrderPlaceCard
        lat={latitude}
        long={longitude}
        phone={telephone}
        supportsdispatch={supportsdispatch}
        supportscurbside={supportscurbside}
        title={`${city}, ${state}`}
        calendars={calendars}
        viewMode={true}
        handledMode={true}
        setErrorOutput={setError}
        description={[
          { label: streetaddress },
          { label: `${city}, ${state} ${zip}` },
          { ...(distance !== 0 && { label: `${distance} miles away` }) },
        ]}
      />
      <div className={styles.order_btn}>
        <PrimaryButton
          onClick={onClickOrder}
          disabled={isDisabled}
          filled
          className={`${styles.btn} ${isDisabled ? styles.disabled_btn : ''}`}
          endIcon={<ArrowRight />}
        >
          {!isDisabled ? 'Select Location' : 'NOT ACCEPTING ONLINE ORDERS'}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default React.memo(HandheldMarkerInfo);
