import React, { useCallback } from 'react';
import { removeProductFromBasket as removeProduct } from 'api/removeProductFromBasket';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useQueryUrlParams } from 'hooks';
import showToast from 'components/Toast/ShowToast';
import { removeAppliedRewardFromBasket } from 'api/removeAppliedRewardFromBasket';

const useRemoveProductFromBasket = () => {
  const appDispatch = useAppDispatchContext();
  const { basketId } = useQueryUrlParams();
  const removeProductFromBasket = useCallback(
    (productId, name) => {
      const fetch = async () => {
        const SuccessMsg = () => (
          <span>
            Item <u>{name}</u> was removed
          </span>
        );
        if (productId && basketId) {
          appDispatch({ type: ACTION_TYPES.loadingBasket, payload: { loading: true } });
          try {
            const response = await removeProduct(basketId, productId);

            if (response && response.appliedrewards.length) {
              const rewardToRemove = response.appliedrewards[0];
              const rewardResponse = await removeAppliedRewardFromBasket(basketId, rewardToRemove.rewardid);
              window.localStorage.setItem('rewardId', rewardToRemove.reference);
              appDispatch({ type: ACTION_TYPES.setBasket, payload: { data: rewardResponse } });
            } else {
              appDispatch({ type: ACTION_TYPES.setBasket, payload: { data: response } });
            }

            showToast('success', <SuccessMsg />);
          } catch (e) {
            const msg = e.errorMessage || 'Something went wrong... Could not delete item from basket';
            showToast('error', msg);
            appDispatch({ type: ACTION_TYPES.errorBasket, payload: { error: e } });
          }
        }
      };
      fetch();
    },
    [basketId, appDispatch]
  );
  return removeProductFromBasket;
};

export default useRemoveProductFromBasket;
