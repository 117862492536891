import React, { useCallback, useState } from 'react';
import ActionTable from 'components/ActionTable/ActionTable';
import { TextButton } from 'components/Button/Button';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';
import useRemoveUserBillingAccount from '../../Checkout/CheckoutOrder/PaymentMethod/SavedCreditCardModal/hooks/useRemoveUserBillingAccount';
import RemoveCardModal from 'components/RemoveCardModal/RemoveCardModal';
import styles from '../CardsOnFile/CardsOnFile.module.scss';
import Price from 'components/Price/Price';

const GiftCardOnFile = ({ toMenu, billingAccounts = [], error, loading }) => {
  const [{ loading: isRemoving, error: errorBillingAccount }, removeBillingAccount] = useRemoveUserBillingAccount();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not load cards on file. Please try again later!')) ||
    '';
  const isDataLoaded = !loading && !error && billingAccounts;

  const onDeleteClick = (card) => () => {
    const cardToDelete = billingAccounts.find(({ id }) => id === card.id);
    setSelectedCard(cardToDelete);
    setIsConfirmModal(true);
  };
  const onCloseModal = () => {
    setIsConfirmModal(false);
    setSelectedCard(null);
  };
  const onDeleteCard = useCallback(() => {
    if (selectedCard) {
      removeBillingAccount(selectedCard.id).finally(onCloseModal);
    }
  }, [removeBillingAccount, selectedCard]);

  const RenderEmptyTable = (
    <div className={styles.no_data}>
      You have no gift cards on file.&nbsp;
      <TextButton onClick={toMenu} className={styles.place_order_link}>
        Order Now
      </TextButton>
      &nbsp;now to add a Gift Card.
    </div>
  );
  const giftCardsAccounts = (billingAccounts || [])
    .filter((account) => account.accounttype.toLowerCase().includes('gift card'))
    .map((card) => ({ ...card, tableName: `x-${card.cardNumber}` }));

  return (
    <div className={styles.container}>
      {errorMessage && <Alert message={errorMessage} />}
      {loading && <Spin spinning={loading} />}
      {isDataLoaded && (
        <ActionTable
          data={giftCardsAccounts}
          nameProperty="tableName"
          customDescription={(item) => <span>Balance: {<Price value={item.balance} />}</span>}
          idProperty="id"
          emptyTableRender={RenderEmptyTable}
          withBorder
        >
          {(row) => (
            <TextButton onClick={onDeleteClick(row)} className={styles.action_btn}>
              Delete
            </TextButton>
          )}
        </ActionTable>
      )}
      <RemoveCardModal
        open={isConfirmModal}
        disabled={isRemoving || !!errorBillingAccount}
        loading={isRemoving}
        onChange={onCloseModal}
        onSubmit={onDeleteCard}
        onCancel={onCloseModal}
      />
    </div>
  );
};

export default React.memo(GiftCardOnFile);
