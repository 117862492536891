import React, { memo } from 'react';
import styles from './HandoffInfo.module.scss';
import { HANDOFF_MODE_ENUM } from 'modules/HandoffModeModal/HandoffModeModal';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import Section from 'components/Section/Section';
import HandoffModeLink from 'components/HandoffModeLink/HandoffModeLink';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';

const HandoffInfo = ({ topped = false, isLoading }) => {
  const {
    basket: { data: basketData, loading, error },
  } = useAppStateContext();

  const { deliverymode, customfields, deliveryaddress } = basketData || {};
  const { streetaddress, building, zipcode, city } = deliveryaddress || {};

  const isEmptyCurbside = customfields && customfields.find((item) => !item.value);
  return (
    <Section
      title={'Order Type'}
      topped={topped}
      sectionHr
      description={<HandoffModeLink isDisabled={isLoading} label={'Edit'} className={styles.link} />}
      className={styles.container}
    >
      {loading && !basketData && <Spin spinning={loading} />}
      {error && <Alert message={error.errorMessage} />}
      {basketData && (
        <div className={styles.wrapper}>
          <h4>{deliverymode === HANDOFF_MODE_ENUM.DISPATCH ? HANDOFF_MODE_ENUM.DELIVERY : deliverymode}</h4>
          {deliverymode === HANDOFF_MODE_ENUM.DISPATCH && (
            <>
              <p>{streetaddress}</p>
              {building && <p>{building}</p>}
              <p>
                {city}, {zipcode}
              </p>
            </>
          )}
          {deliverymode === HANDOFF_MODE_ENUM.CURBSIDE && (
            <>
              {!isEmptyCurbside &&
                customfields.map((field, indx) => (
                  <p className={styles.curbside_field} key={`${field.value}-${indx}`}>
                    {field.value}
                  </p>
                ))}
              {isEmptyCurbside && (
                <Alert
                  className={styles.empty_curbside_warning}
                  severity="warning"
                  message={
                    <div>
                      <div>Please enter the make, model, and color of your vehicle for curbside.</div>
                      <HandoffModeLink isDisabled={isLoading} label={'Edit Order Type'} className={styles.link} />
                    </div>
                  }
                />
              )}
            </>
          )}
        </div>
      )}
    </Section>
  );
};
const MemoizedHandoffInfo = memo(HandoffInfo);
export default MemoizedHandoffInfo;
