import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { updateGroupOrder } from 'api/updateGroupOrder';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useUpdateGroupOrder = (groupOrderId) => {
  const { authenticated } = useAppStateContext();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppBasketState = useAppDispatchContext();
  const update = useCallback(
    (deadline = '', note = '') => {
      let ignore = false;
      const fetch = async () => {
        if (groupOrderId && authenticated) {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            const response = await updateGroupOrder(groupOrderId, deadline, note);
            if (!ignore) {
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
              dispatchAppBasketState({ type: ACTION_TYPES.setGroupOrder, payload: { data: response } });
              dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: response.basket } });
            }
          } catch (e) {
            if (!ignore) {
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            }
          }
        }
      };
      fetch();
      return () => {
        ignore = true;
      };
    },
    [groupOrderId, authenticated, dispatchAppBasketState, dispatch]
  );

  return [state, { updateGroupOrder: update }];
};

export default useUpdateGroupOrder;
