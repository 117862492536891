import { useCallback, useReducer } from 'react';
import TagManager from 'react-gtm-module';
import { oloCcsf } from 'api/oloCcsf';
import showToast from 'components/Toast/ShowToast';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { FETCH_ACTION_TYPES, fetchReducer } from 'reducers/fetchReducer';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';
import useValidateBasket from '../../hooks/useValidateBasket';

const initialState = {
  error: null,
  loading: false,
  data: null,
};

const setEcommerceAnalytics = (basketData, order) => {
  if (!order || !basketData) return;
  TagManager.dataLayer({
    dataLayer: {
      transactionTotal: null,
      transactionId: null,
      transactionRevenue: null,
      ecommerce: null,
    },
  });
  TagManager.dataLayer({
    dataLayer: {
      event: 'ga_event',
      transactionTotal: order.total,
      transactionId: order.id,
      transactionRevenue: order.total,
      ecommerce: {
        purchase: {
          actionField: {
            id: order.id,
            revenue: order.total,
            tax: order.salestax,
          },
          products: basketData.products.map(({ productId, name, totalcost, quantity }) => {
            return {
              id: productId.toString(),
              name,
              price: totalcost,
              quantity: quantity,
            };
          }),
        },
      },
    },
  });
};

const useSubmitOrder = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const appDispatch = useAppDispatchContext();
  const setHistoryPush = useQueryUrlParamsDispatch();
  const {
    authenticated,
    basket: { data: basketData },
    giftCard: { data: giftCardData },
    paymentData: { data: paymentData, isUseSavedCard, basketPaymentAccessToken, isCash },
    userBillingAccounts: { defaultBillingAccount, selectedBillingAccount },
    user: { data: authenticatedUserData },
    savedPaymentsAccounts,
    savedUserGiftCards: { selectedSavedGiftCard },
  } = useAppStateContext();
  const resetState = useCallback(() => {
    dispatch({
      type: FETCH_ACTION_TYPES.cleanState,
    });
  }, []);
  const validateBasket = useValidateBasket();

  const submit = useCallback(
    (contactPhoneNumber) => {
      const handlePaymentResponse = (response) => {
        appDispatch({ type: ACTION_TYPES.cleanupBasket });
        if (!authenticated) {
          /* store order id to identify that the user who submitted order was guest user.*/
          window.sessionStorage.setItem('guestOrderId', response.id);
        }
        setEcommerceAnalytics(basketData, response);
        setHistoryPush({}, `/${response.vendorid}/order/${response.id}`, true, ['basketId']);
        appDispatch({ type: ACTION_TYPES.cleanUpPaymentData });
        appDispatch({ type: ACTION_TYPES.cleanUpUserBillingAccountsData });
        appDispatch({ type: ACTION_TYPES.cleanUpGiftCard });
        appDispatch({ type: ACTION_TYPES.resetSavedPaymentsAccounts });
        appDispatch({ type: ACTION_TYPES.cleanUpSelectedSavedUserGiftCard });
        appDispatch({ type: ACTION_TYPES.resetItemsNotTransferred });

        dispatch({
          payload: { data: response },
          type: FETCH_ACTION_TYPES.setData,
        });
        window.localStorage.removeItem('rewardId');
      };

      const handleErrorResponse = (response) => {
        const error =
          (response && response.length && response[0].description) ||
          'Something went wrong... Could not submit your order!';

        if (error) {
          dispatch({
            type: FETCH_ACTION_TYPES.setError,
            payload: { error: error },
          });
          showToast('error', error);
        } else {
          showToast('error', response.errorMessage || 'Something went wrong... Could not submit your order!');
          dispatch({
            type: FETCH_ACTION_TYPES.setError,
            payload: { error: response },
          });
        }
      };

      const fetch = async () => {
        try {
          dispatch({
            payload: { loading: true },
            type: FETCH_ACTION_TYPES.loading,
          });
          await validateBasket();

          //Multiple payments credit card with gift card
          const newCreditPayment =
            paymentData.expiryMonth != '' ||
            paymentData.expiryYear != '' ||
            paymentData.zip != '' ||
            paymentData.name != '';
          const multiplePayments =
            (giftCardData != null && newCreditPayment != false) || (giftCardData != null && isUseSavedCard != false);
          const multipleGiftCards =
            giftCardData != null ? (giftCardData.id != null ? [giftCardData] : Object.values(giftCardData)) : [];
          const giftCardTotal = multipleGiftCards
            .map(function (item) {
              return item.balance;
            })
            .reduce(function (sum, next) {
              return (sum += next);
            }, 0);
          const giftCardPurchase = giftCardTotal >= basketData.total;
          const userData = authenticated
            ? authenticatedUserData
            : JSON.parse(window.sessionStorage.getItem('guestData'));

          let requestBody;

          if (window.checkoutFrame) {
            window.checkoutFrame.on('success', (response) => {
              if (authenticated) {
                oloCcsf.deleteLastBasket().then(() => {});
                handlePaymentResponse(response);
              } else {
                handlePaymentResponse(response);
              }
            });

            window.checkoutFrame.on('error', (response) => {
              handleErrorResponse(response);
            });
          }

          // GET REQUEST BODY
          if (isCash) {
            requestBody = oloCcsf.getSubmitWithCashRequestBody({
              basketId: basketData.id,
              contactPhoneNumber,
              basket: basketData,
              isAuthenticated: authenticated,
              accessToken: basketPaymentAccessToken,
              userData,
            });
          } else if (isUseSavedCard) {
            //For auth user with saved card in account
            if (multiplePayments) {
              //console.log("logged in user with saved CC + GC");
              requestBody = oloCcsf.getSubmitWithSavedBillingAccountMultipleRequestBody({
                billingAccountId: selectedBillingAccount.id || defaultBillingAccount.id,
                contactPhoneNumber,
                isAuthenticated: authenticated,
                userData,
                basket: basketData,
                giftCard: multipleGiftCards,
                savedPaymentsAccounts,
                accessToken: basketPaymentAccessToken,
                useSavedGiftCard: !!selectedSavedGiftCard,
              });
            } else {
              //console.log("logged in user with saved CC");
              requestBody = oloCcsf.getSubmitWithSavedBillingAccountRequestBody({
                basket: basketData,
                billingAccountId: selectedBillingAccount.id || defaultBillingAccount.id,
                contactPhoneNumber,
                accessToken: basketPaymentAccessToken,
                isAuthenticated: authenticated,
                userData,
                savedPaymentsAccounts,
              });
            }
            //For auth user or guest with new entered card
          } else if (basketPaymentAccessToken) {
            if (multiplePayments) {
              if (paymentData.expiryMonth || paymentData.expiryYear || paymentData.zip || paymentData.name) {
                //console.log("Multiple Payments with CC");
                requestBody = oloCcsf.getSubmitWithMultipleCardsRequestBody({
                  isAuthenticated: authenticated,
                  contactPhoneNumber,
                  cardData: paymentData,
                  accessToken: basketPaymentAccessToken,
                  userData,
                  basket: basketData,
                  giftCard: multipleGiftCards,
                  savedPaymentsAccounts,
                  useSavedGiftCard: !!selectedSavedGiftCard,
                });
                //No CC data
              } else {
                //console.log("Should not fire");
                requestBody = oloCcsf.getSubmitWithMultipleCardsRequestBody({
                  isAuthenticated: authenticated,
                  contactPhoneNumber,
                  accessToken: basketPaymentAccessToken,
                  userData,
                  basket: basketData,
                  giftCard: multipleGiftCards,
                  savedPaymentsAccounts,
                  useSavedGiftCard: !!selectedSavedGiftCard,
                });
              }
            } else {
              //Guest User or new CC - Single payment method - CC or GC
              if (giftCardPurchase) {
                //console.log("Gift card only purchase");
                requestBody = oloCcsf.getSubmitWithMultipleGiftCardsRequestBody({
                  isAuthenticated: authenticated,
                  contactPhoneNumber,
                  accessToken: basketPaymentAccessToken,
                  userData,
                  basket: basketData,
                  giftCard: multipleGiftCards,
                  savedPaymentsAccounts,
                  useSavedGiftCard: !!selectedSavedGiftCard,
                });
              } else {
                //console.log("Gift card + CC payment");
                requestBody = oloCcsf.getSubmitWithCreditCardRequestBody({
                  isAuthenticated: authenticated,
                  basketId: basketData.id,
                  contactPhoneNumber,
                  cardData: paymentData,
                  accessToken: basketPaymentAccessToken,
                  userData,
                  savedPaymentsAccounts,
                  total: basketData.total,
                  tip: basketData.tip,
                });
              }
            }
          }

          if (window.checkoutFrame) {
            window.checkoutFrame.submit(requestBody);
          }
        } catch (error) {
          handleErrorResponse(error);
        }
      };

      fetch();
    },
    [
      authenticated,
      appDispatch,
      basketData,
      defaultBillingAccount,
      dispatch,
      isUseSavedCard,
      isCash,
      paymentData,
      selectedBillingAccount,
      setHistoryPush,
      basketPaymentAccessToken,
      authenticatedUserData,
      giftCardData,
      validateBasket,
      savedPaymentsAccounts,
      selectedSavedGiftCard,
    ]
  );

  return [state, submit, resetState];
};

export default useSubmitOrder;
