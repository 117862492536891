import React, { useEffect, useRef, useState } from 'react';
import { CheckboxWithLabel } from 'components/Checkbox/Checkbox';
import { InputField } from 'components/Input/Input';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import styles from './NewCreditCard.module.scss';
import { CCSF_CARD_NUMBER_CONTAINER, CCSF_CVV_CONTAINER } from '../../../../../api/oloCcsf/oloCcsfConstants';

const ZIP_ERROR_MSG = 'Please enter billing zip';
const CARD_NAME_ERROR_MSG = 'Please enter name on card';
const EXP_DATE_ERROR_MSG = 'Please enter expiration date';

const checkIsValid = {
  expiry: (value) => value.length === 7,
  name: (value) => value.length > 0,
  zip: (value) => {
    const pattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (value.match(pattern) || (value.length >= 3 && value.length <= 5)) {
      return true;
    } else {
      return false;
    }
  },
};

const NewCreditCard = ({ isTouched, onError, errorsNewPaymentCard, cardInputContainerChanged }) => {
  const {
    authenticated,
    paymentData: { data },
    savedPaymentsAccounts: { creditCard },
    basket: { data: basketData },
  } = useAppStateContext();

  const [errorName, setErrorName] = useState(null);
  const [errorExpiry, setErrorExpiry] = useState(null);
  const [errorZip, setErrorZip] = useState(null);
  const [errors, setErrors] = useState([
    {
      key: 'errorName',
      msg: CARD_NAME_ERROR_MSG,
    },
    {
      key: 'errorExpiry',
      msg: EXP_DATE_ERROR_MSG,
    },
    {
      key: 'errorZip',
      msg: ZIP_ERROR_MSG,
    },
  ]);

  const appDispatch = useAppDispatchContext();

  const onClickSaveBillingMethod = () => {
    appDispatch({
      payload: { creditCard: !creditCard },
      type: ACTION_TYPES.setSavedPaymentsAccounts,
    });
  };

  const onChangeName = ({ target }) => {
    setErrorName(null);
    appDispatch({
      payload: { name: target.value },
      type: ACTION_TYPES.setPaymentData,
    });
  };
  const onBlurName = ({ target }) => {
    const isValidName = checkIsValid.name(target.value);
    const error = isValidName ? null : CARD_NAME_ERROR_MSG;

    setErrorName(error);
    appDispatch({
      payload: { isValidName },
      type: ACTION_TYPES.setCreditCardValidation,
    });
  };

  const onBlurExpiry = ({ target }) => {
    const isValidExpiry = checkIsValid.expiry(target.value);
    const error = isValidExpiry ? null : EXP_DATE_ERROR_MSG;

    setErrorExpiry(error);
    appDispatch({
      payload: { isValidExpiry },
      type: ACTION_TYPES.setCreditCardValidation,
    });
  };
  const onChangeExpiry = ({ target }) => {
    const { defaultValue, value } = target;
    setErrorExpiry(null);

    if (defaultValue.length > value.length && /\d$/.test(value)) {
      appDispatch({
        payload: { expiry: value },
        type: ACTION_TYPES.setPaymentData,
      });
      return value;
    }

    if (value.length > 7) {
      return;
    }

    const char1 = parseInt(value[0], 10);
    const char2 = parseInt(value[1], 10);

    const separator = ' / ';

    const result =
      /^\d$/.test(value) && '0' !== value && '1' !== value
        ? '0' + value + separator
        : /^\d\d$/.test(value)
        ? char2 > 2 && 0 !== char1
          ? '0' + char1 + separator + char2
          : '' + value + separator
        : value;

    const expiryMonth = result.slice(0, 2);
    const expiryYear = result.slice(5);

    appDispatch({
      payload: { expiry: result, expiryMonth, expiryYear: '20'.concat(expiryYear) },
      type: ACTION_TYPES.setPaymentData,
    });
  };

  const onChangeZip = ({ target }) => {
    setErrorZip(null);
    if (!isNaN(target.value) || target.value === '') {
      appDispatch({
        payload: { zip: target.value },
        type: ACTION_TYPES.setPaymentData,
      });
    }
  };
  const onBlurZip = ({ target }) => {
    const isValidZip = checkIsValid.zip(target.value);
    const error = isValidZip ? null : ZIP_ERROR_MSG;

    setErrorZip(error);
    appDispatch({
      payload: { isValidZip },
      type: ACTION_TYPES.setCreditCardValidation,
    });
  };

  const creditCardContainer = useRef(null);
  const cvvContainer = useRef(null);

  useEffect(() => {
    if (creditCardContainer && cvvContainer && basketData && cardInputContainerChanged) {
      cardInputContainerChanged(creditCardContainer, cvvContainer, basketData.id);
    }
  }, [creditCardContainer, cvvContainer, basketData, cardInputContainerChanged]);

  useEffect(() => {
    if (isTouched) {
      const { expiry, name, zip } = data;

      setErrorName(checkIsValid.name(name) ? null : CARD_NAME_ERROR_MSG);
      setErrorExpiry(checkIsValid.expiry(expiry) ? null : EXP_DATE_ERROR_MSG);
      setErrorZip(checkIsValid.zip(zip) ? null : ZIP_ERROR_MSG);
    }
  }, [data, errorExpiry, errorName, errorZip, isTouched]);

  useEffect(() => {
    const { expiry, name, zip } = data;
    const errorsState = [
      { key: 'errorExpiry', value: expiry },
      { key: 'errorName', value: name },
      { key: 'errorZip', value: zip },
    ]
      .map((input) => {
        if (!input.value) {
          const error = errors.find((error) => error.key === input.key);
          return error;
        }
        return null;
      })
      .filter(Boolean);
    if (errorsState.length > 0 && errorsState.length !== errors.length) {
      onError(errorsState);
      setErrors(errorsState);
    } else if (errorsState.length === 0 && errors.length > 0 && expiry && name && zip) {
      setErrors([]);
      onError([]);
    }
  }, [errorExpiry, errorName, errors, onError, errorZip, data, setErrors]);

  useEffect(() => {
    //Send to callback errors by did mount render component
    if (onError && !errorsNewPaymentCard && !isTouched && errors.length > 0) {
      onError(errors);
    }
  }, [onError, isTouched, errors, errorsNewPaymentCard]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <InputField
          error={Boolean(errorName)}
          fullWidth
          helperText={errorName}
          label={'Name on Card'}
          onBlur={onBlurName}
          onChange={onChangeName}
          value={data.name}
        />
      </div>

      <div
        className={`${styles.inputWrapper} ${styles['card-number-container']} ${CCSF_CARD_NUMBER_CONTAINER}`}
        id={CCSF_CARD_NUMBER_CONTAINER}
        ref={creditCardContainer}
      />

      <div className={styles.inputGroupWrapper}>
        <div className={styles.inlineInputItem}>
          <InputField
            error={Boolean(errorExpiry)}
            fullWidth
            helperText={errorExpiry}
            label={'Exp MM/YY'}
            onBlur={onBlurExpiry}
            onChange={onChangeExpiry}
            placeholder={'MM/YY'}
            type={'tel'}
            value={data.expiry}
          />
        </div>

        <div
          className={`${styles.inlineInputItem} ${styles['cvv-info']} ${CCSF_CVV_CONTAINER}`}
          id={CCSF_CVV_CONTAINER}
          ref={cvvContainer}
        />

        <div className={styles.inlineInputItem}>
          <InputField
            error={Boolean(errorZip)}
            fullWidth
            inputMode="numeric"
            helperText={errorZip}
            inputProps={{ maxLength: 5 }}
            label={'Billing Zip'}
            onBlur={onBlurZip}
            onChange={onChangeZip}
            value={data.zip}
          />
        </div>
      </div>

      {authenticated && (
        <div className={styles.checkboxWrapper}>
          <CheckboxWithLabel
            checked={creditCard}
            handleChange={onClickSaveBillingMethod}
            label={'Save card for future order'}
          />
        </div>
      )}
    </div>
  );
};

export default NewCreditCard;
