import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { updateAccount } from 'api/user/updateAccount';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useEditProfile = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppUserState = useAppDispatchContext();
  const {
    user: { data: currentUserDetails },
  } = useAppStateContext();

  const updateInfo = useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            await updateAccount(user);
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false } });
            dispatchAppUserState({
              type: ACTION_TYPES.setUserDetails,
              payload: { data: { ...currentUserDetails, ...user } },
            });
            resolve(true);
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            showToast('error', e.errorMessage || 'Something went wrong... Could not update user info!');
            reject(e);
          }
        };

        fetch();
      }),
    [dispatchAppUserState, currentUserDetails]
  );

  return [state, { updateInfo }];
};

export default useEditProfile;
