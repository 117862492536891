import { useEffect, useReducer, useRef } from 'react';
import { checkOrderDeliveryStatus } from 'api/checkOrderDeliveryStatus';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useParams } from 'react-router';
import { useState } from 'react';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useRecursiveTimeout = (callback, delay = 1000) => {
  const [count, setCount] = useState(0);
  const ref = useRef();

  useEffect(() => {
    if (count === 0) {
      callback();
      setCount(count + 1);
      ref.current = callback;
    }
  }, [count, callback]);

  useEffect(() => {
    const tick = () => {
      if (count > 0) {
        const ret = ref.current();
        if (ret.constructor === Promise) {
          setCount(count + 1);
          ret.then(setTimeout(tick, delay));
        }
      }
    };

    const timer = setTimeout(tick, delay);

    return () => clearTimeout(timer);
  }, [delay, setCount, count]);
};

const useOrderDeliveryStatusFetch = () => {
  const { orderId } = useParams();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const fetch = async () => {
    if (orderId) {
      dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true, refetch: true } });
      try {
        const response = await checkOrderDeliveryStatus(orderId);
        const deliveryOrder = response && response.deliveries[0];
        dispatch({
          type: FETCH_ACTION_TYPES.setData,
          payload: { data: deliveryOrder || null },
        });
      } catch (e) {
        dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
      }
    }
  };
  useRecursiveTimeout(fetch, 5 * 60 * 1000);
  return state;
};

export default useOrderDeliveryStatusFetch;
