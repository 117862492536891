import React, { useEffect, useState, memo, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './NewGiftCard.module.scss';
import Alert from 'components/Alert/Alert';
import { PrimaryButton } from 'components/Button/Button';
import { InputField } from 'components/Input/Input';
import Spin from 'components/Spin/Spin';
import showToast from 'components/Toast/ShowToast';
import useRetrieveGiftCardBalance from '../hooks/useRetrieveGiftCardBalance';
import { CheckboxWithLabel } from 'components/Checkbox/Checkbox';
import { useAppStateContext, useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong... Could not apply gift card. Please try again later!';
const PIN_REQUIREMENT_CONDITIONS = ['9840', '6000'];
let nextId = 1;
let lastCardNumbers = [];
let isPinRequiredBoolean = false;

const NewGiftCard = ({ errorSchemes, dataSchemes, loadingSchemes }) => {
  const {
    authenticated,
    savedPaymentsAccounts: { giftCard },
  } = useAppStateContext();
  const {
    giftCard: { data: giftCardData },
  } = useAppStateContext();

  const appDispatch = useAppDispatchContext();
  let {
    loading: loadingGiftBalance,
    error: errorGiftBalance,
    getGiftCardBalance,
    captchaToken,
  } = useRetrieveGiftCardBalance();
  const [giftCardNumber, setGiftCardNumber] = useState('');
  const [giftCardPin, setGiftCardPin] = useState('');
  const [giftCardPinError, setGiftCardPinError] = useState('');
  const [isPinRequired, setIsPinRequired] = useState(false);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE);

  const [giftCardList, setGiftCardList] = useState([
    { id: 0, giftCardNumberInner: '', giftCardPin: '', response: false, balance: 0 },
  ]);
  const giftCardScheme = dataSchemes && dataSchemes.find((scheme) => scheme.type === 'giftcard');
  let accountGifts = 0;
  const onChangeGiftNumber = (event) => {
    const value = event.target.value;
    setErrorMessage('');

    const pinRequired = PIN_REQUIREMENT_CONDITIONS.some((requirement) => value.startsWith(requirement));

    if (pinRequired) {
      setIsPinRequired(true);
    } else {
      setIsPinRequired(false);
    }
    setGiftCardNumber(value.trim());
  };
  const addGift = (event) => {
    if (giftCardList[nextId - 1]) {
      if (giftCardList[nextId - 1].giftCardNumberInner == '') {
        showToast('error', 'Please fill out the previous Gift Card field before create a new one.');
        return;
      }
    }
    nextId = nextId + 1;
    setGiftCardList([...giftCardList, { giftCardNumberInner: '', id: nextId }]);
  };
  const onChangeGiftPin = (event, item) => {
    setGiftCardPinError('');
    let temportalGifList = giftCardList.map((element) => {
      if (element.id === item.id) {
        return {
          id: item.id,
          giftCardNumberInner: element.giftCardNumberInner,
          giftCardPin: event.target.value,
          response: item.response,
          balance: item.balance,
        };
      } else {
        return element;
      }
    });
    setGiftCardList(temportalGifList);
  };

  const changeInputCard = (event, item) => {
    const value = event.target.value;
    setErrorMessage('');
    let pinRequired = PIN_REQUIREMENT_CONDITIONS.some((requirement) => value.startsWith(requirement));
    if (pinRequired) {
      isPinRequiredBoolean = true;
    } else {
      isPinRequiredBoolean = false;
    }

    let temportalGifList = giftCardList.map((element) => {
      if (element.id === item.id) {
        let relVal = event.target.value.replace(' ', '');
        return {
          id: item.id,
          giftCardNumberInner: relVal,
          giftCardPin: element.giftCardPin,
          response: item.response,
          balance: item.balance,
        };
      } else {
        return element;
      }
    });
    setGiftCardList(temportalGifList);
  };
  const onFocus = () => {
    setErrorMessage('');
  };
  const setItemAfterCallApi = (item) => {
    let temportalGifList = giftCardList.map((element) => {
      if (element.id === item.id) {
        return {
          id: item.id,
          giftCardNumberInner: item.giftCardNumberInner,
          giftCardPin: item.giftCardPin,
          response: item.response,
          balance: item.balance,
        };
      } else {
        return element;
      }
    });
    setGiftCardList(temportalGifList);
  };
  const onApplyGiftCard = async (item) => {
    let card2number = item.giftCardNumberInner.replace(/\s+/g, '');
    if (isPinRequiredBoolean && !item.giftCardPin) {
      showToast('error', 'Enter please Gift Card PIN!');
      setGiftCardPinError('PIN is required for this gift card');
      return;
    }
    if (item.giftCardNumberInner && !isPinRequiredBoolean) {
      setGiftCardPinError('');

      let dataBalance = 0;
      let copyGiftobj;
      if (giftCardData) {
        if (giftCardData[0]) {
          copyGiftobj = giftCardData;
        } else {
          copyGiftobj = { ...giftCardData };
        }
      }
      const response = await getGiftCardBalance(
        giftCardScheme.id,
        item.giftCardNumberInner,
        null,
        dataBalance,
        copyGiftobj
      );
      item.response = response.response;
      item.balance = response.itemBalance;
      if (response) {
        lastCardNumbers.push(card2number);
      }
      //setGiftCardList(giftCardList);
      setItemAfterCallApi(item);
      return;
    }
    if (item.giftCardNumberInner && item.giftCardPin && isPinRequiredBoolean) {
      setGiftCardPinError('');
      let dataBalance = 0;
      let copyGiftobj;
      if (giftCardData) {
        if (giftCardData[0]) {
          copyGiftobj = giftCardData;
        } else {
          copyGiftobj = { ...giftCardData };
        }
      }

      const response = await getGiftCardBalance(
        giftCardScheme.id,
        item.giftCardNumberInner,
        item.giftCardPin,
        dataBalance,
        copyGiftobj
      );
      item.response = response.response;
      item.balance = response.itemBalance;
      if (response.response) {
        lastCardNumbers.push(card2number);
      }
      setItemAfterCallApi(item);
      return;
    }
  };
  const onApplyResGiftCard = async (item) => {
    let arrayNew = [];
    for (var e in giftCardData) {
      arrayNew.push(giftCardData[e]);
    }
    if (arrayNew) {
      let index = arrayNew.findIndex((element) => (item.giftCardNumberInner = element.cardNumber));
      arrayNew.splice(index, 1);
    }

    appDispatch({
      type: ACTION_TYPES.setGiftCard,
      payload: { data: arrayNew },
    });
    item.response = false;
    item.giftCardNumberInner = '';
    item.giftCardPin = '';
    setItemAfterCallApi(item);
    showToast('success', 'Gift card successfully removed');
  };
  /*   useEffect(() => {
    if (errorGiftBalance) {
      setErrorMessage(errorGiftBalance.errorMessage);
    }
  }, [errorGiftBalance, setErrorMessage]); */

  // useEffect(() => {
  //   const pinRequired = PIN_REQUIREMENT_CONDITIONS.some((requirement) => giftCardNumber.startsWith(requirement));
  //   setIsPinRequired(pinRequired);
  // }, [giftCardNumber, isPinRequired, setIsPinRequired]);

  const onChangeCheckbox = (e) => {
    appDispatch({
      payload: { giftCard: e.target.checked },
      type: ACTION_TYPES.setSavedPaymentsAccounts,
    });
  };
  return (
    <div className={styles.wrapper}>
      {loadingSchemes && <Spin spinning={loadingSchemes} />}
      {errorSchemes && <Alert message={DEFAULT_ERROR_MESSAGE} />}
      {!loadingSchemes && dataSchemes && (
        <div className={styles.body}>
          <div className={styles.tooltip}>
            {'Enter your gift card number (excluding spaces) and pin/reg code if provided'}
          </div>
          {giftCardList.map((item, index) => {
            return (
              <div key={`${item.id}+${index}`} className={styles.row}>
                <div className={styles.gift_card_input}>
                  <InputField
                    disabled={loadingGiftBalance}
                    error={Boolean(errorGiftBalance)}
                    helperText={errorGiftBalance && errorMessage}
                    fullWidth
                    className={styles.input_container}
                    label={'Gift Card Number'}
                    onChange={(e) => {
                      changeInputCard(e, item);
                    }}
                    //onFocus={onFocus}
                    type={'tel'}
                    key={`${item.id}`}
                    value={item.giftCardNumberInner}
                  />
                </div>
                <div className={styles.inputs_group}>
                  <div className={styles.card_pin_input}>
                    <InputField
                      disabled={loadingGiftBalance}
                      error={Boolean(giftCardPinError)}
                      fullWidth
                      className={`${styles.input_container} absolute_error pin_input`}
                      helperText={Boolean(giftCardPinError) && giftCardPinError}
                      label={'Pin/Reg. Code'}
                      onChange={(e) => {
                        onChangeGiftPin(e, item);
                      }}
                      //onFocus={onFocus}
                      type={'tel'}
                      key={`${item.id + 'a'}`}
                      value={item.giftCardPin}
                    />
                  </div>
                  <div className={styles.button_container}>
                    {!item.response ? (
                      <PrimaryButton
                        disabled={!captchaToken || loadingGiftBalance}
                        className={styles.button}
                        fullWidth
                        onClick={(index) => {
                          onApplyGiftCard(item, index);
                        }}
                        endIcon={<CheckIcon />}
                        key={`${item.id + 'b'}`}
                        size={'large'}
                      >
                        {'Apply'}
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        className={styles.button}
                        fullWidth
                        onClick={(index) => {
                          onApplyResGiftCard(item, index);
                        }}
                        endIcon={<CloseIcon />}
                        key={`${item.id + 'r'}`}
                        size={'large'}
                      >
                        {'REMOVE'}
                      </PrimaryButton>
                    )}
                    {loadingGiftBalance && <CircularProgress size={32} className={styles.loading} />}
                  </div>
                </div>
              </div>
            );
          })}
          <div className={styles.container_btn_plus}>
            <div className={styles.plus_button} onClick={addGift}>
              Add Gift Card
            </div>
          </div>

          <div className={classNames([styles.captcha, 'g-recaptcha'])} id="v2-captcha" data-badge="inline" />
          {authenticated && (
            <div
              className={`${styles.checkbox_wrapper} ${
                Boolean(giftCardPinError) ? styles.checkbox_wrapper_offset : ''
              }`}
            >
              <CheckboxWithLabel
                checked={giftCard}
                handleChange={onChangeCheckbox}
                label={'Save card for future order'}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const MemoizedNewGiftCard = memo(NewGiftCard);
export default MemoizedNewGiftCard;
