import React, { useCallback, useEffect, useState } from 'react';
import Section from 'components/Section/Section';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from 'components/Select/Select';
import { DEADLINE_DATE, DEADLINE_DATE_OLO, ORDER_DEADLINE_OLO } from 'enums/orderDeadline';
import 'components/Input/Input.scss';

import styles from './GroupOrderDeadline.module.scss';

const moment = new MomentUtils();

const TIME_STEP = 15; //minutes

const getTimeValues = (startHour, endHour) => {
  const values = [];
  for (let hour = startHour; hour < endHour + 12; hour++) {
    for (let minutes = 0; minutes < 60; minutes += TIME_STEP) {
      const p = Math.floor(hour / 12) === 0 ? 'AM' : 'PM';
      const formattedHour = hour % 12 === 0 ? '12' : hour % 12;
      const formattedMinutes = minutes <= 9 ? '0' + minutes : minutes;
      values.push({ label: formattedHour + ':' + formattedMinutes + ' ' + p, value: hour + ':' + formattedMinutes });
    }
  }
  return values;
};

const timeValues = getTimeValues(0, 12);

const currentDate = moment.moment();

const GroupOrderDeadline = ({ defaultDeadline = '', deadline, error, isExist, onChange }) => {
  const [date, setDate] = useState(currentDate);
  const [timeslots, setTimeslots] = useState([]);
  const [time, setTime] = useState('');
  const [timeInvalid, setTimeInvalid] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const curDate = moment.moment();
    if (moment.moment(date).format(DEADLINE_DATE) === curDate.format(DEADLINE_DATE)) {
      const newTimeslots = timeValues.filter((timeValue) => {
        return curDate.isBefore(moment.moment(timeValue.value, 'HH:mm'));
      });
      if (JSON.stringify(newTimeslots) !== JSON.stringify(timeslots)) {
        setTimeslots(newTimeslots);
        const { value } = newTimeslots[0] || {};
        const newDeadline = moment.moment(date).format(DEADLINE_DATE_OLO) + ' ' + value;
        if (deadline === '' || moment.moment(deadline, ORDER_DEADLINE_OLO).isBefore(moment.moment())) {
          setTime(value);
          onChange(newDeadline);
        }
      }
    } else if (JSON.stringify(timeValues) !== JSON.stringify(timeslots)) {
      setTimeslots(timeValues);
    }
  }, [date, timeslots, deadline, onChange]);

  useEffect(() => {
    setTimeInvalid(error);
  }, [error]);

  useEffect(() => {
    if (deadline) {
      const splitted = deadline.split(' ');
      if (
        moment.moment(date).format(DEADLINE_DATE) !==
        moment.moment(splitted[0], DEADLINE_DATE_OLO).format(DEADLINE_DATE)
      ) {
        setDate(moment.moment(splitted[0], DEADLINE_DATE_OLO));
      }
      if (splitted[1] && time !== splitted[1]) {
        setTime(splitted[1]);
      }
      if (
        defaultDeadline === deadline &&
        moment.moment(deadline, ORDER_DEADLINE_OLO).isBefore(moment.moment()) &&
        isExist
      ) {
        setIsExpired(true);
      } else {
        setIsExpired(false);
      }
    }
  }, [defaultDeadline, deadline, isExist, date, time]);

  const handleTimeChange = useCallback(
    (value) => {
      setTime(value);
      const newDeadline = moment.moment(date).format(DEADLINE_DATE_OLO) + ' ' + value;
      if (newDeadline !== deadline) {
        onChange(newDeadline);
      }
    },
    [date, deadline, onChange]
  );

  const handleDateChange = useCallback(
    (value) => {
      setDate(value);
      const newDeadline = moment.moment(value).format(DEADLINE_DATE_OLO) + ' ' + time;
      if (newDeadline !== deadline) {
        onChange(newDeadline);
      }
    },
    [deadline, time, onChange]
  );
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Section title={'Set group order DEADLINE'} sectionHr className={styles.container}>
        <p>
          As the host you control when the group order is placed, but this time provides your group members with a
          deadline. Please note that the order ready times available may vary depending on the size of your
          group&lsquo;s order.
        </p>
        <div className={styles.deadline}>
          <div className={styles.date_container}>
            <DatePicker
              className={styles.date}
              value={date}
              minDate={currentDate}
              label={'Date'}
              disableToolbar={true}
              variant="inline"
              inputVariant="filled"
              format={DEADLINE_DATE}
              autoOk
              onChange={handleDateChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon className={styles.icon} />
                  </InputAdornment>
                ),
                classes: {
                  root: 'input_root',
                  disabled: 'input_root_disabled',
                  error: 'input_error',
                },
              }}
            />
          </div>
          <div className={styles.time_container}>
            <Select
              variant={'filled'}
              label={'Time'}
              className={styles.time}
              onChange={handleTimeChange}
              value={time}
              items={timeslots}
              error={timeInvalid}
              helperText={timeInvalid ? 'Please provide a valid timeslot' : ''}
            />
          </div>
          {isExpired && (
            <p className={styles.error}>Your order deadline has passed. If desired, select a new deadline above</p>
          )}
        </div>
      </Section>
    </MuiPickersUtilsProvider>
  );
};
export default GroupOrderDeadline;
