import React from 'react';
import classNames from 'classnames/bind';
import './ActionTable.scss';

const ActionTable = ({
  data = [],
  children,
  idProperty = 'id',
  nameProperty = 'name',
  descriptionProperty,
  emptyTableRender = 'There is no data',
  customDescription,
  withBorder = false,
}) => {
  const rows = data.map((dataItem, index) => {
    return (
      <div
        className={classNames('action-table-row', { 'with-border': withBorder })}
        key={dataItem[idProperty] || index}
      >
        <div className="left-column">
          <h4 title={dataItem[nameProperty]}>{dataItem[nameProperty]}</h4>
          {descriptionProperty && (
            <div className="description" title={dataItem[descriptionProperty]}>
              {dataItem[descriptionProperty]}
            </div>
          )}
          {customDescription && (
            <div className="description">
              {typeof customDescription === 'function' ? customDescription(dataItem) : customDescription}
            </div>
          )}
        </div>
        <div className="action-btn">{typeof children === 'function' ? children(dataItem) : children}</div>
      </div>
    );
  });

  return !data || data.length === 0 ? (
    emptyTableRender
  ) : (
    <div className="action_table">
      <div className="action-table-header">
        <div className="name-title">Name</div>
        <div className="action-title">Action</div>
      </div>
      {rows}
    </div>
  );
};

export default React.memo(ActionTable);
