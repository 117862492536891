import React from 'react';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import { useQueryBasketId } from 'hooks';
import styles from 'modules/App.module.scss';

const Layout = ({ children, ...props }) => {
  useQueryBasketId();
  return (
    <>
      <Header {...props} />
      <div className={styles.content_wrapper}>{children}</div>
      <Footer {...props} />
    </>
  );
};
export default React.memo(Layout);
