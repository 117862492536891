import React from 'react';

import Section from 'components/Section/Section';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

import MenuItemCard from '../../../MenuItemCard/MenuItemCard';
import styles from './CategoryProductList.module.scss';

const CategoryProductList = React.forwardRef(
  ({ className, imagepath, category, onProductClick, groupOrderId }, ref) => {
    return (
      <Section
        ref={ref}
        titleElem={<PrimaryHeader title={category.name} size={'extra'} brush={'after'} />}
        description={category.description && <p className={styles.section_description}>{category.description}</p>}
        className={className}
      >
        <ul className={styles.products}>
          {category.products.map((product, productIndex) => {
            return (
              <li key={productIndex} className={styles.product}>
                <MenuItemCard
                  imagepath={imagepath}
                  groupOrderId={groupOrderId}
                  {...product}
                  onClick={onProductClick}
                  product={product}
                />
              </li>
            );
          })}
        </ul>
      </Section>
    );
  }
);

CategoryProductList.displayName = 'CategoryProductList';

export default React.memo(CategoryProductList);
