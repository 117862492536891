import React from 'react';

import GroupOrderCopyLink from '../GroupOrderCopyLink/GroupOrderCopyLink';
import GroupOrderSendInvites from '../GroupOrderSendInvites/GroupOrderSendInvites';

import styles from './GroupOrderInvitationView.module.scss';

const GroupOrderInvitationView = (props) => {
  return (
    <>
      <h2 className={styles.title}>
        Your Group order has been created! Invite others to participate in your shared order.
      </h2>
      <GroupOrderCopyLink />
      <GroupOrderSendInvites {...props} />
    </>
  );
};

export default GroupOrderInvitationView;
