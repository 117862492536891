import { useEffect, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { getProductModifiers } from 'api/productModifiers';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useProductModifiersFetch = (productId, emptyModifiers) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      if (productId) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const response = await getProductModifiers(productId);
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response.optiongroups } });
          }
        } catch (e) {
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
          }
        }
      }
    };
    if (!emptyModifiers) {
      fetch();
    }
    return () => {
      ignore = true;
    };
  }, [productId, emptyModifiers]);
  return [state];
};

export default useProductModifiersFetch;
