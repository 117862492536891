import React from 'react';
import MaterialCard from '@material-ui/core/Card';
import classNames from 'classnames/bind';
import './Card.scss';

const Card = ({ children, onCardClick, btnLabel, onBtnClick, className, isActive = false, ...props }) => {
  return (
    <MaterialCard
      onClick={onCardClick}
      className={classNames('card', className, {
        isActive,
        hasHover: !!onCardClick,
      })}
      variant="outlined"
      {...props}
    >
      <div
        className={classNames('card_content', {
          hasLabel: !!btnLabel,
        })}
      >
        {children}
      </div>
      {btnLabel && (
        <div className="cardBtn" onClick={onBtnClick}>
          {btnLabel}
        </div>
      )}
    </MaterialCard>
  );
};
export default Card;
