import React, { useCallback, useState } from 'react';

import { TextButton } from 'components/Button/Button';
import Section from 'components/Section/Section';
import Slider from 'components/Slider/Slider';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

import MenuItemCard from '../../../MenuItemCard/MenuItemCard';
import styles from './CategoryProductsSlider.module.scss';

const CategoryProductsSlider = React.forwardRef(
  ({ className, imagepath, category, onProductClick, onSeeAll, groupOrderId }, ref) => {
    const [displayedProductCount, setDisplayedProductCount] = useState(0);

    const handleSliderChange = useCallback((index, slidesToShow) => {
      setDisplayedProductCount(index + slidesToShow);
    }, []);

    const handleSeeAll = useCallback(() => {
      if (onSeeAll) {
        onSeeAll(category);
      }
    }, [onSeeAll, category]);

    return (
      <Section
        ref={ref}
        titleElem={<PrimaryHeader title={category.name} size={'xl'} brush={'after'} />}
        description={
          <>
            {category.description && <p className={styles.slider_description}>{category.description}</p>}
            <p className={styles.slider_details_container}>
              <span className={styles.slider_details}>
                {displayedProductCount} of {category.products.length}
              </span>
              <TextButton size="small" onClick={handleSeeAll} className={styles.view_all}>
                See all
              </TextButton>
            </p>
          </>
        }
        displayHeaderType={'column'}
        className={className}
      >
        <Slider
          items={category.products}
          itemComponentProps={{
            imagepath: imagepath,
            groupOrderId: groupOrderId,
            className: styles.product_slider_item,
          }}
          onClickItem={onProductClick}
          itemComponent={MenuItemCard}
          onChange={handleSliderChange}
        />
      </Section>
    );
  }
);

CategoryProductsSlider.displayName = 'CategoryProductsSlider';

export default CategoryProductsSlider;
