import { useCallback, useReducer } from 'react';
import { fetchReducer } from 'reducers/fetchReducer';
import { FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { updateUserDefaultCreditCard } from 'api/user/billingAccounts/updateUserDefaultCreditCard';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';

const initialState = {
  error: null,
  loading: false,
};

const useUpdateUserDefaultCreditCard = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const appDispatch = useAppDispatchContext();
  const {
    userBillingAccounts: { billingAccounts },
  } = useAppStateContext();

  const updateBillingAccount = useCallback(
    (billingAccountID) => {
      const fetch = async () => {
        try {
          dispatch({
            payload: { loading: true },
            type: FETCH_ACTION_TYPES.loading,
          });

          await updateUserDefaultCreditCard(billingAccountID);
          let updatedBillingAccount;
          const filteredBillingAccounts = billingAccounts.map((account) => {
            if (account.isDefault && account.id !== billingAccountID) {
              return {
                ...account,
                isDefault: false,
              };
            }
            if (account.id === billingAccountID) {
              updatedBillingAccount = {
                ...account,
                isDefault: true,
              };
              return {
                ...account,
                isDefault: true,
              };
            }
            return account;
          });

          dispatch({
            payload: { data: updatedBillingAccount },
            type: FETCH_ACTION_TYPES.setData,
          });
          appDispatch({
            payload: { billingAccounts: filteredBillingAccounts },
            type: ACTION_TYPES.setUserBillingAccountsData,
          });
        } catch (error) {
          dispatch({
            type: FETCH_ACTION_TYPES.setError,
            payload: { error },
          });
        }
      };

      fetch();
    },
    [appDispatch, dispatch, billingAccounts]
  );

  return [state, updateBillingAccount];
};

export default useUpdateUserDefaultCreditCard;
