import React, { useState, useEffect, useCallback } from 'react';

import { PrimaryButton, TextButton } from 'components/Button/Button';
import { InputField } from 'components/Input/Input';
import Spin from 'components/Spin/Spin';

import styles from './FavoriteOrderInfo.module.scss';

const FavoriteOrderInfo = ({ data, loading, error, handleUpdateNameClick }) => {
  const [state, setState] = useState({
    value: data.name,
    isTouched: false,
    isEditName: false,
    isUpdating: false,
  });

  const handleEditNameClick = () => {
    handleState({ isEditName: true });
  };

  const onUpdateNameClick = () => {
    if (state.value.length === 0) {
      handleState({ isTouched: true });
      return;
    }

    handleUpdateNameClick(state.value);
    handleState({ isUpdating: true });
  };

  const handleState = useCallback(
    (newState) => {
      setState({ ...state, ...newState });
    },
    [state]
  );

  useEffect(() => {
    if (!loading && !error && state && handleState && state.isEditName && state.isUpdating) {
      handleState({ isEditName: false });
    }
  }, [state, error, loading, handleState]);

  const nameHeader = (
    <div className={styles.name_header_wrapper}>
      <div className={styles.name_wrapper}>
        <h4 className={styles.name_label}>Favorite Name</h4>
        <h3 className={styles.name}>{data.name}</h3>
      </div>
      <TextButton onClick={handleEditNameClick} className={styles.edit_name_btn}>
        Edit Name
      </TextButton>
    </div>
  );

  const editNameInput = (
    <>
      <InputField
        required
        disabled={loading}
        className={styles.input_container}
        defaultValue={state.value}
        onChange={(e) => {
          handleState({ value: e.target.value.substring(0, 24) });
        }}
        inputProps={{
          maxLength: 24,
        }}
        onBlur={(e) => {
          handleState({ isTouched: true, value: e.target.value.trim() });
        }}
        error={state.isTouched && state.value.length === 0}
        helperText={state.isTouched && state.value.length === 0 ? 'Please enter favorite nickname' : ''}
        label={'Nickname for this favorite'}
      />
      <PrimaryButton onClick={onUpdateNameClick} className={styles.update_name_btn} disabled={loading}>
        {loading && <Spin spinning={loading} className={styles.spinner} />}
        UPDATE NAME
      </PrimaryButton>
    </>
  );

  return <div className={styles.container}>{state.isEditName ? editNameInput : nameHeader}</div>;
};

export default FavoriteOrderInfo;
