import React, { useCallback, useEffect, memo } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes.json';
import Spin from 'components/Spin/Spin';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import Link from 'components/Link/Link';
import { TextButton } from 'components/Button/Button';
import { SignInForm } from 'components/Forms';
import useUserInfoFetch from './hooks/useUserInfoFetch';
import styles from './SignIn.module.scss';
import { getLastBasket } from 'api/getLastBasket';
import PasswordImprovementNotification from 'components/PasswordImprovementNotification/PasswordImprovementNotification';

const SignIn = () => {
  const history = useHistory();
  const { data, loading, error } = useUserInfoFetch();

  useEffect(() => {
    if (data && !loading && !error) {
      history.replace(routes.account);
    }
  }, [data, loading, error, history]);

  const handleSubmit = useCallback(async () => {
    const lastBasket = await getLastBasket();
    if (!lastBasket) {
      history.replace(routes.account);
    }
  }, [history]);

  const handleSignUp = useCallback(() => {
    history.push(routes.signUp);
  }, [history]);

  const handleForgotPassword = useCallback(() => {
    history.push(routes.forgotPassword);
  }, [history]);

  return loading ? (
    <Spin spinning={loading} />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.form_header}>
          <PrimaryHeader title={'Sign In'} size={'extra'} />
        </div>
        <div className={styles.notification_wrapper}>
          <PasswordImprovementNotification className={styles.notification} onReset={handleForgotPassword} />
        </div>
        <div className={styles.form_body}>
          <SignInForm close={handleSubmit} />
        </div>
        <div className={styles.form_footer}>
          <div>
            <Link routing isGothic onClick={handleForgotPassword}>
              <TextButton>Forgot Password?</TextButton>
            </Link>
          </div>
          <div>
            <span>DON&apos;T HAVE AN ACCOUNT?</span>
            <Link routing isGothic onClick={handleSignUp}>
              <TextButton>Sign up</TextButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SignIn);
