import React from 'react';
import styles from './OrderConfirmation.module.scss';
import useOrderStatusFetch from './hooks/useOrderStatusFetch';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import ThanksSection from './ThanksSection/ThanksSection';
import ImHereSection from './ImHereSection/ImHereSection';
import DeliveryTracking from './DeliveryTracking/DeliveryTracking';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderSummary from './OrderSummary/OrderSummary';
import SignUpSection from './SignUpSection/SignUpSection';
import { useParams } from 'react-router';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

const OrderConfirmation = () => {
  const { orderId } = useParams();
  const { data, loading, error } = useOrderStatusFetch(orderId);
  const order = !loading && data && data['order'];
  const restaurant = !loading && data && data['restaurant'];
  const isDeliveryMode = order && order.deliverymode === 'dispatch';
  const errorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not load order status. Please try again later!')) ||
    '';
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header_container}>
          <PrimaryHeader
            size="extra"
            className={styles.header}
            titleClassName={styles.title}
            title={
              <span>
                Your order will be <br /> ready soon
              </span>
            }
          />
        </div>
        {loading && <Spin spinning={loading} />}
        {errorMessage && <Alert message={errorMessage} />}
        {data && (
          <div className={styles.body}>
            {isDeliveryMode && <DeliveryTracking />}
            <SignUpSection />
            <ThanksSection />
            <ImHereSection order={order} />
            <div className={styles.order_row}>
              <OrderDetails isDeliveryMode={isDeliveryMode} order={order} restaurant={restaurant} />
              <OrderSummary order={order} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(OrderConfirmation);
