import React, { useState, useEffect, useCallback } from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerInfoWindow from './MarkerInfoWindow/MarkerInfoWindow';
import { useMediaQuery, useTheme } from '@material-ui/core';

const MAP_MARKER_HEIGHT = 64; // also change $markerHeight in scss

const stylingMap = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#6a6a6a',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#2f2f2f',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#787878',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#969696',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#505050',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#454545',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#a0a0a0',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#aaaaaa',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#959595',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#f6f6f6',
      },
    ],
  },
];

const HANDLED_SIZE_OFFSET = 100;
const MARKER_WITH_CARD_HEIGHT = 450;
const HOVER_DISTANCE = Math.sqrt(2 * Math.pow(MAP_MARKER_HEIGHT / 2, 2));
const K_MARGIN_TOP = 0;
const K_MARGIN_RIGHT = 0;
const K_MARGIN_BOTTOM = 0;
const K_MARGIN_LEFT = 0;
const DEFAULT_CENTER = {
  lat: 37.0902,
  lng: -95.7129,
};

const GoogleMapApp = ({
  defaultZoom = 5,
  places = [],
  selectedPlace = '',
  onCleanSelectPlace,
  onSelect,
  onSelectOrder,
  resetState = false,
}) => {
  const theme = useTheme();
  const [showInfo, setShowInfo] = useState(selectedPlace);
  const [center, setMapCenter] = useState(DEFAULT_CENTER);
  const [latPerPixel, setLatPerPixel] = useState(0);
  const [mapGoogleEntity, setMapGoogleEntity] = useState();
  const [mapsGoogleEntity, setMapsGoogleEntity] = useState();
  const isHandheldSize = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (showInfo && resetState && !selectedPlace) {
      setShowInfo('');
    }
  }, [resetState, showInfo, selectedPlace]);

  const getShiftedLat = useCallback(
    (centerLat) => {
      const shiftedLat = centerLat + latPerPixel * (MARKER_WITH_CARD_HEIGHT / 2);
      const shiftedMobileLat = centerLat - latPerPixel * HANDLED_SIZE_OFFSET;
      return isHandheldSize ? shiftedMobileLat : shiftedLat;
    },
    [isHandheldSize, latPerPixel]
  );

  useEffect(() => {
    if (selectedPlace && selectedPlace.length > 0 && selectedPlace !== showInfo && mapGoogleEntity && latPerPixel) {
      const currPlaceData = places.find((place) => place.id.toString() === selectedPlace);
      const calcLatitude = getShiftedLat(currPlaceData.latitude);
      setShowInfo(selectedPlace.toString());
      setMapCenter({
        lat: calcLatitude,
        lng: currPlaceData.longitude,
      });
    }
  }, [selectedPlace, showInfo, places, getShiftedLat, mapGoogleEntity, latPerPixel]);

  const onChildClick = useCallback(
    (key, childProps) => {
      setMapCenter({
        lat: getShiftedLat(childProps.lat),
        lng: childProps.lng,
      });
      setShowInfo(key);
      onSelect({ stateKey: childProps.stateKey, idPlace: childProps.id, restaurant: childProps.place });
    },
    [onSelect, getShiftedLat]
  );
  const onHideInfoWindow = useCallback(() => {
    setShowInfo(null);
    onCleanSelectPlace(true);
  }, [onCleanSelectPlace]);

  const distanceToMouse = useCallback(({ x, y }, { x: mouseX, y: mouseY }) => {
    const offsetY = MAP_MARKER_HEIGHT / 2;
    return Math.sqrt((x - mouseX) * (x - mouseX) + (y - mouseY - offsetY) * (y - mouseY - offsetY));
  }, []);

  const getMapBounds = useCallback((map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.latitude, place.longitude));
    });
    return bounds;
  }, []);
  const bindResizeListener = useCallback((map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  }, []);
  const apiIsLoaded = useCallback(
    (places) => ({ map, maps }) => {
      if (places && places.length > 0) {
        const bounds = getMapBounds(map, maps, places);
        map.fitBounds(bounds);
        bindResizeListener(map, maps, bounds);
      }
      setMapGoogleEntity(map);
      setMapsGoogleEntity(maps);
    },
    [getMapBounds, bindResizeListener]
  );
  const onChange = useCallback(
    (mapStatus) => {
      const latDiff = mapStatus.bounds.nw.lat - mapStatus.bounds.sw.lat;
      const currLatPerPixel = latDiff / mapStatus.size.height;
      if (mapGoogleEntity) {
        setLatPerPixel(currLatPerPixel);
      }
    },
    [mapGoogleEntity]
  );
  useEffect(() => {
    if (places && places.length && mapGoogleEntity && mapsGoogleEntity) {
      const bounds = getMapBounds(mapGoogleEntity, mapsGoogleEntity, places);
      mapGoogleEntity.fitBounds(bounds);
      bindResizeListener(mapGoogleEntity, mapsGoogleEntity, bounds);
    }
  }, [places, mapGoogleEntity, mapsGoogleEntity, getMapBounds, bindResizeListener]);

  return (
    <GoogleMapReact
      hoverDistance={HOVER_DISTANCE}
      defaultCenter={DEFAULT_CENTER}
      center={center}
      defaultZoom={defaultZoom}
      yesIWantToUseGoogleMapApiInternals
      onChildClick={onChildClick}
      onClick={onHideInfoWindow}
      distanceToMouse={distanceToMouse}
      onChange={onChange}
      margin={[K_MARGIN_TOP, K_MARGIN_RIGHT, K_MARGIN_BOTTOM, K_MARGIN_LEFT]}
      options={{
        styles: stylingMap,
        gestureHandling: 'greedy',
        fullscreenControl: false,
        maxZoom: 18,
        minZoom: 5,
        zoomControlOptions: { position: 7 },
      }}
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
      onGoogleApiLoaded={apiIsLoaded(places)}
    >
      {places &&
        places.length > 0 &&
        places.map((place) => {
          return (
            <MarkerInfoWindow
              showInfo={showInfo === place.id.toString()}
              id={place.id}
              key={place.id}
              zip={place.zip}
              lat={place.latitude}
              lng={place.longitude}
              place={place}
              stateKey={place.state}
              onSelectOrder={onSelectOrder}
            />
          );
        })}
    </GoogleMapReact>
  );
};
export default React.memo(GoogleMapApp);
