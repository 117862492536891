import React, { useCallback } from 'react';
import Link from 'components/Link/Link';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';

const GroupOrderLink = ({ className, children }) => {
  const { basketId, groupOrderId, owner, restaurantId } = useQueryUrlParams();
  const setQuery = useQueryUrlParamsDispatch();
  const handleClick = useCallback(() => {
    let params = {
      groupOrderModal: true,
    };
    if (groupOrderId) {
      params = { ...params, groupOrderId: groupOrderId, basketId: basketId };
    }
    if (owner) {
      params = { ...params, owner: owner };
    }
    if (restaurantId) {
      params = { ...params, restaurantId: restaurantId };
    }
    setQuery(params);
  }, [basketId, groupOrderId, owner, restaurantId, setQuery]);
  return (
    <Link className={className} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default React.memo(GroupOrderLink);
