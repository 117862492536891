import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryUrlParams } from 'hooks';
import Validator from 'services/ValidateForm';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { InputField } from 'components/Input/Input';
import { PrimaryButton } from 'components/Button/Button';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';

import useResetPassword from './hooks/useResetPassword';
import './ChangePasswordForm.scss';

const errorMessages = {
  passwordIsEmpty: 'Please provide a password',
  passwordIsInvalid: 'Password must contain at least 8 characters, 1 special character !@#$%^&?*, and 1 number',
  confirmPasswordIsEmpty: 'Please confirm your password',
  confirmPasswordIsInvalid: 'Passwords do not match',
};

const ChangePasswordForm = () => {
  const { resetcode } = useQueryUrlParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] = useState('');
  const [validatePassword, setValidatePassword] = useState(true);
  const [validateConfirmPassword, setValidateConfirmPassword] = useState(true);
  const [visibility, setVisibilityPassword] = useState(true);
  const [visibilityConfirm, setVisibilityConfirmPassword] = useState(true);
  const history = useHistory();
  const [resetCode, setResetCode] = useState('');
  const [{ loading, data, error }, { resetPassword }] = useResetPassword();

  useEffect(() => {
    if (resetcode) {
      const urlStr = window.location.href;
      const url = new URL(urlStr);
      const code = decodeURI(url.searchParams.get('resetcode')).trim();
      setResetCode(code);
    } else {
      setResetCode('');
      return history.push('');
    }
  }, [history, setResetCode, resetcode]);

  useEffect(() => {
    if (!loading && !error && data && data.result === 'success') {
      history.push('/signin');
    }
  }, [data, loading, history, error]);

  const onChangePassword = (e) => {
    setValidatePassword(true);
    setPasswordErrorMessage('');
    const value = e.target.value.trim();
    setPassword(value);
  };

  const onBlurPassword = () => {
    if (password.length >= 8) {
      const checkPassword = Validator.validatePassword(password);
      if (checkPassword) {
        if (confirmPassword) {
          if (confirmPassword === password) {
            setValidateConfirmPassword(true);
          } else {
            setValidateConfirmPassword(false);
            setPasswordConfirmErrorMessage(errorMessages.confirmPasswordIsInvalid);
          }
        }
        setValidatePassword(true);
        setPasswordErrorMessage('');
        return;
      }
      setValidatePassword(false);
      setPasswordErrorMessage(errorMessages.passwordIsInvalid);
      return;
    } else if (password.length === 0) {
      setPasswordErrorMessage(errorMessages.passwordIsEmpty);
    } else {
      setPasswordErrorMessage(errorMessages.passwordIsInvalid);
    }
    setValidatePassword(false);
  };

  const onChangeConfirmPassword = (e) => {
    setValidateConfirmPassword(true);
    setPasswordConfirmErrorMessage('');
    const value = e.target.value.trim();
    setConfirmPassword(value);
  };

  const onBlurConfirmPassword = () => {
    if (confirmPassword.length > 0) {
      if (confirmPassword === password) {
        setPasswordConfirmErrorMessage('');
        setValidateConfirmPassword(true);
      } else {
        setValidateConfirmPassword(false);
        setPasswordConfirmErrorMessage(errorMessages.confirmPasswordIsInvalid);
      }
    } else if (confirmPassword.length === 0) {
      setValidateConfirmPassword(false);
      setPasswordConfirmErrorMessage(errorMessages.confirmPasswordIsEmpty);
    }
  };

  const showPassword = () => {
    setVisibilityPassword(!visibility);
  };

  const showPasswordVisibility = () => {
    setVisibilityConfirmPassword(!visibilityConfirm);
  };

  const handleChangePassword = () => {
    if (validatePassword && validateConfirmPassword && password.length > 0 && confirmPassword.length > 0) {
      resetPassword(resetCode, password);
    } else if (!validatePassword) {
      return setPasswordConfirmErrorMessage(
        passwordConfirmErrorMessage === errorMessages.confirmPasswordIsEmpty
          ? errorMessages.confirmPasswordIsEmpty
          : errorMessages.confirmPasswordIsInvalid
      );
    } else if (!validateConfirmPassword) {
      return setPasswordErrorMessage(
        passwordErrorMessage === errorMessages.passwordIsEmpty
          ? errorMessages.passwordIsEmpty
          : errorMessages.passwordIsInvalid
      );
    } else {
      setPasswordConfirmErrorMessage(errorMessages.confirmPasswordIsEmpty);
      setPasswordErrorMessage(errorMessages.passwordIsEmpty);
      setValidateConfirmPassword(false);
      setValidatePassword(false);
    }
  };

  const errorMessage = error
    ? error.errorMessage || 'Something went wrong... Could not change password. Please try again later!'
    : '';

  return (
    <>
      <div className="form_body_wrapper">
        <div className="form_body">
          {errorMessage && <Alert message={errorMessage} className="form_error_container" />}
          <InputField
            required
            value={password}
            label="New Password"
            error={!validatePassword}
            type={visibility ? 'password' : 'text'}
            helperText={passwordErrorMessage}
            onChange={onChangePassword}
            onBlur={onBlurPassword}
            inputProps={{
              minLength: '8',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showPassword}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {!visibility ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <p className="help_message">
            Password must contain at least 8 characters, 1 special character !@#$%^&?*, and 1 number
          </p>
          <InputField
            required
            label="Confirm New Password"
            value={confirmPassword}
            error={!validateConfirmPassword}
            type={visibilityConfirm ? 'password' : 'text'}
            onBlur={onBlurConfirmPassword}
            onChange={onChangeConfirmPassword}
            helperText={passwordConfirmErrorMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showPasswordVisibility}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {!visibilityConfirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="form_footer">
        <PrimaryButton onClick={handleChangePassword} disabled={loading}>
          Change Password
          <Spin spinning={loading} className="button_spinner" />
        </PrimaryButton>
      </div>
    </>
  );
};

export default React.memo(ChangePasswordForm);
