import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Radio } from '@material-ui/core';
import classNames from 'classnames/bind';
import './OrderPlaceCard.scss';
import Link from 'components/Link/Link';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import buildingIcon from '../../assets/icons/building.svg';
import carIcon from '../../assets/icons/car.svg';
import boxIcon from '../../assets/icons/box.svg';
import phoneIcon from '../../assets/icons/phone.svg';
import { ACCORDION_TRANSITION_TIMEOUT } from '../../modules/RestaurantsMap/RestaurantsList/RestaurantsList';
import moment from 'moment';
import { isMacOs } from 'react-device-detect';

const CALENDARS_TYPES = {
  DELIVERY: 'delivery',
  DISPATCH: 'dispatch',
  BUSINESS: 'business',
  CURBSIDE: 'curbsidepickup',
};

const CALENDARS_ORDER_CONFIG = {
  [CALENDARS_TYPES.BUSINESS]: {
    label: 'Restaurant',
    icon: buildingIcon,
    isValid: () => true,
  },
  [CALENDARS_TYPES.DELIVERY]: {
    label: 'Delivery',
    icon: boxIcon,
    isValid: ({ areRangesSet, supportsdispatch }) => {
      // if ranges are set for particular delivery method then we should support it also
      // Case when we don't support dispatch but there are working hours for this method isn't valid.
      const hasConflict = !!(supportsdispatch ^ areRangesSet);

      return !hasConflict;
    },
  },
  [CALENDARS_TYPES.DISPATCH]: {
    label: 'Delivery',
    icon: boxIcon,
    isValid: ({ areRangesSet, supportsdispatch }) => {
      // if ranges are set for particular delivery method then we should support it also
      // Case when we don't support dispatch but there are working hours for this method isn't valid.
      const hasConflict = !!(supportsdispatch ^ areRangesSet);

      return !hasConflict;
    },
  },
  [CALENDARS_TYPES.CURBSIDE]: {
    label: 'Curbside',
    icon: carIcon,
    isValid: ({ areRangesSet, supportscurbside }) => {
      const hasConflict = !!(supportscurbside ^ areRangesSet);

      return !hasConflict;
    },
  },
};

const OrderPlaceCard = ({
  id = '',
  title = '',
  description = [
    {
      label: 'lorem upsent',
      accent: true,
    },
  ],
  phone = '',
  forMap = false,
  btnLabel = '',
  onPick,
  onSelect,
  offsetTopChange,
  defaultSelect = '',
  parentOffsetTop = null,
  disabled = false,
  disabledMsg = '',
  supportsdispatch = true,
  supportscurbside = true,
  calendars = [],
  viewMode = false,
  handledMode = false,
  lat = '',
  long = '',
  setErrorOutput = () => {},
}) => {
  const ref = useRef();
  const [selected, setSelected] = useState(defaultSelect || forMap);
  const [error, setError] = useState(false);

  const isCalendarItemValid = useCallback(
    (item = {}) => {
      const { ranges = [], type } = item;

      const areRangesSet = ranges.length > 0;
      const areAllRangesSetCorrectly = ranges.every((range) => range.start && range.end);
      const areCalendarRangesValid = areRangesSet && areAllRangesSetCorrectly;

      const calendarConfig = type && CALENDARS_ORDER_CONFIG[type];
      const isDeliveryMethodValid =
        calendarConfig && calendarConfig.isValid({ supportsdispatch, supportscurbside, areRangesSet });

      return areCalendarRangesValid && isDeliveryMethodValid;
    },
    [supportscurbside, supportsdispatch]
  );
  useEffect(() => {
    if (defaultSelect !== selected && !forMap) {
      setSelected(defaultSelect);
    }
  }, [defaultSelect, selected, forMap]);

  useEffect(() => {
    const isCalendarValid = calendars.every(isCalendarItemValid);
    if (isCalendarValid) {
      // setError(false);
      // setErrorOutput(false);
    } else {
      // setError(true);
      // setErrorOutput(true);
    }
  }, [calendars, isCalendarItemValid, setError, setErrorOutput]);

  useEffect(() => {
    if (ref && !forMap && selected && offsetTopChange && parentOffsetTop !== null) {
      setTimeout(() => {
        offsetTopChange(ref.current.offsetTop + parentOffsetTop);
      }, ACCORDION_TRANSITION_TIMEOUT + 100);
    }
  }, [ref, forMap, selected, offsetTopChange, parentOffsetTop]);

  const getCalendar = useCallback((item) => {
    const itemConfig = CALENDARS_ORDER_CONFIG[item.type];

    const startTime = moment(item.ranges[0].start, 'YYYYMMDD HH:mm').format('h:mma');
    const endTime = moment(item.ranges[0].end, 'YYYYMMDD HH:mm').format('h:mma');

    return (
      <>
        <img src={itemConfig.icon} className={'icon'} alt="-" />
        <h3 className={'working_hours'}>
          {itemConfig.label}: {startTime} - {endTime}
        </h3>
      </>
    );
  }, []);

  const handleSelect = () => {
    if (forMap) return;
    setSelected(!selected);
    onSelect && onSelect(id);
  };
  const validCalendars = calendars.filter(isCalendarItemValid);
  const isDisabled = disabled || error || validCalendars.length === 0;
  const handlerOrder = () => {
    !isDisabled && onPick && onPick(id);
  };
  return (
    <div
      ref={ref}
      className={classNames('order_place_card_container', {
        order_place_card_container_active: forMap ? forMap : selected,
        order_place_card_container_map: forMap,
        view_mode: viewMode,
        handled_mode: handledMode,
      })}
    >
      <div
        className={classNames('wrapper', {
          order_place_card_active: forMap ? forMap : selected,
          view_mode: viewMode,
          handled_mode: handledMode,
        })}
        onClick={handleSelect}
      >
        {!forMap && !viewMode && (
          <div className={'radio_wrapper'}>
            <Radio checked={forMap ? forMap : selected} color={'primary'} />
          </div>
        )}
        <div className={'information'}>
          <h1 className={'title'}>{title}</h1>
          <div className={'description'}>
            {(description || []).map((info, index) => (
              <div className={classNames({ text_accent: info['accent'] })} key={index}>
                {info['label']}
              </div>
            ))}

            <h2 className={'get_deriction_header'}>
              <Link
                className={'text_accent'}
                target="_blank"
                href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`}
              >
                Get directions
              </Link>
            </h2>

            {validCalendars.map((item, index) => (
              <div className={'restaurant_info'} key={index}>
                {getCalendar(item)}
              </div>
            ))}

            <Link className={'restaurant_info'} href={`tel:${phone}`}>
              <img src={phoneIcon} className={'icon'} alt="-" />
              <h3 className={'phone'}>{phone}</h3>
            </Link>
          </div>
        </div>
      </div>
      <div
        onClick={handlerOrder}
        role="button"
        className={classNames('button', {
          order_place_card_btn_active: forMap ? forMap : selected,
          order_place_card_btn_map: forMap,
          btn_disabled: isDisabled,
          mac_platform: isMacOs,
        })}
      >
        {isDisabled && disabledMsg ? (
          disabledMsg
        ) : (
          <>
            {btnLabel} <ArrowForwardIcon className={'icon'} />
          </>
        )}
      </div>
    </div>
  );
};
export default React.memo(OrderPlaceCard);
