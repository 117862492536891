import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { resetPasswordByEmail } from 'api/resetPassword';

import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useSendEmail = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const sendEmailToReset = useCallback(
    (email) => {
      const fetch = async () => {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          let response;
          response = await resetPasswordByEmail(email).then((res) => {
            return res;
          });
          if (response.result === 'success') {
            showToast('success', 'Email was sent successfully!');
            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
          } else {
            showToast('error', response.errorMessage || 'Something went wrong... Could not send email!');
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: response } });
          }
        } catch (error) {
          showToast('error', error.errorMessage || 'Something went wrong... Could not send email!');
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: error } });
        }
      };
      fetch();
    },
    [dispatch]
  );

  return [state, { sendEmailToReset }];
};

export default useSendEmail;
