import React, { useState } from 'react';
import { TextButton } from 'components/Button/Button';
import get from 'lodash/get';
import useFavoriteLocationsFetch from '../hooks/useFavoriteLocationsFetch';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import SelectFavoriteLocationModal from '../SelectFavoriteLocationModal/SelectFavoriteLocationModal';
import Spin from 'components/Spin/Spin';
import styles from './FavoriteLocationButton.module.scss';

const FavoriteLocationButton = () => {
  const {
    user: { data: userData },
  } = useAppStateContext();
  const favoriteLocationId = get(userData, 'accountInfo.fields.favoriteStore.code', null);
  const [{ loading, data, error }] = useFavoriteLocationsFetch(favoriteLocationId);
  const [openModal, setOpenModal] = useState(false);
  const onChangeLocation = () => setOpenModal((prevState) => !prevState);
  const dataIsLoaded = !loading && !error && data && !!data.length;
  return (
    <div className={styles.wrapper}>
      {loading && <Spin spinning={loading} size={20} className={styles.spinner} />}
      {!loading && (
        <TextButton className={styles.button} size="small" onClick={onChangeLocation}>
          {dataIsLoaded ? data[0].description : 'Choose'}
        </TextButton>
      )}
      <SelectFavoriteLocationModal open={openModal} onClose={onChangeLocation} />
    </div>
  );
};

export default React.memo(FavoriteLocationButton);
