import React from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { getHandoffModeName } from '../../../modules/HandoffModeModal/HandoffModeModal';
import HandoffModeLink from '../../HandoffModeLink/HandoffModeLink';
import { useRouteMatch, useParams } from 'react-router';
import useOrderStatusFetch from '../../../modules/OrderConfirmation/hooks/useOrderStatusFetch';
import routes from 'routes.json';
import './OrderTypeLink.scss';

const OrderTypeLink = () => {
  const {
    basket: { data: basketData },
  } = useAppStateContext();
  const isOrderConfirmActive = useRouteMatch({
    path: routes.orderConfirm,
    exact: true,
  });
  const isSaveAsFavoriteActive = useRouteMatch({
    path: routes.createFaveOrder,
    exact: true,
  });
  const { orderId } = useParams();
  const { data } = useOrderStatusFetch(orderId);

  const isOrderConfirmationPage = isOrderConfirmActive || isSaveAsFavoriteActive;
  const orderInfo = basketData || (isOrderConfirmationPage && data && data.order);
  const handoffMode = getHandoffModeName(orderInfo && orderInfo.deliverymode);
  return (
    <>
      {handoffMode && (
        <div className="order_type_link_wrap">
          Order Type:&nbsp;
          <HandoffModeLink isDisabled={!!isOrderConfirmationPage} className="order_type_link" label={handoffMode} />
        </div>
      )}
    </>
  );
};

export default React.memo(OrderTypeLink);
