import React, { useCallback, useEffect, useState } from 'react';
import { useQueryUrlParamsDispatch } from 'hooks';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { ORDER_DEADLINE_OLO } from 'enums/orderDeadline';

import MomentUtils from '@date-io/moment';

import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import NotFoundLogo from 'components/NotFoundLogo/NotFoundLogo';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';

import styles from './ExpiredGroupOrderModal.module.scss';

const moment = new MomentUtils();

const ExpiredGroupOrderModal = () => {
  const [groupOrderExpired, setGroupOrderExpired] = useState(false);
  const { owner, groupOrderId } = useQueryUrlParams();
  const parsedOwner = owner ? !!JSON.parse(owner) : false;
  const setQuery = useQueryUrlParamsDispatch();
  const {
    restaurant: { data },
    groupOrder: { data: groupOrderData },
  } = useAppStateContext();

  useEffect(() => {
    const expired =
      groupOrderData !== null && moment.moment(groupOrderData.deadline, ORDER_DEADLINE_OLO).isBefore(moment.moment());
    if (groupOrderData !== null && (!groupOrderData.isopen || expired) && !parsedOwner && groupOrderId) {
      setGroupOrderExpired(true);
    }
  }, [groupOrderData, parsedOwner, groupOrderId]);

  const handleCloseModal = useCallback(() => {
    if (data && data.id) {
      setGroupOrderExpired(false);
      setQuery(null, `/${data.id}/menu`, true);
    }
  }, [setQuery, data]);

  return (
    <ModalWindow
      fullscreenSize={1024}
      maxWidth="sm"
      open={groupOrderExpired}
      onChange={handleCloseModal}
      footerChildren={
        <div className={styles.footer}>
          <PrimaryButton className={styles.button} endIcon={<ArrowRight />} size="large" onClick={handleCloseModal}>
            Back to menu
          </PrimaryButton>
        </div>
      }
    >
      <div className={styles.content}>
        <NotFoundLogo />
        <p className={styles.description}>Your group order link has expired.</p>
      </div>
    </ModalWindow>
  );
};

export default ExpiredGroupOrderModal;
