import React, { createRef, memo, useCallback, useEffect, useState } from 'react';
import { AppBar, Toolbar, Collapse, useMediaQuery } from '@material-ui/core';
import DrawerPanel from 'modules/OrderingPanel/DrawerPanel/DrawerPanel';
import Link from 'components/Link/Link';
import LogoComponent from 'components/LogoComponent/LogoComponent';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames/bind';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useOutsideClick, useQueryUrlParamsDispatch } from 'hooks';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { useRouteMatch, useParams } from 'react-router';
import Search from './Search/Search';
import routes from 'routes.json';
import OrderingFrom from 'components/OrderingFrom/OrderingFrom';
import SelectLocationFirstModal from 'components/SelectLocationFirstModal/SelectLocationFirstModal';
import './Header.scss';
import { useLocation } from 'react-router-dom';
import MyFavoritesModal from './../../modules/MyFavoritesModal/MyFavoritesModal';
import FavoritesPanel from './../../modules/FavoritesPanel/FavoritesPanel';
import GroupOrderModal from '../../modules/GroupOrderModal/GroupOrderModal';
import HandoffMode from 'components/HandoffMode/HandoffMode';
import GroupOrderLink from './GroupOrderLink/GroupOrderLink';
import FavoritesLink from './FavoritesLink/FavoritesLink';
import OrderTypeLink from './OrderTypeLink/OrderTypeLink';
import RecipientOrderingPanel from '../../modules/RecipientOrderingPanel/RecipientOrderingPanel';
import WelcomeGroupOrderModal from '../../modules/WelcomeGroupOrderModal/WelcomeGroupOrderModal';
import ExpiredGroupOrderModal from '../../modules/ExpiredGroupOrderModal/ExpiredGroupOrderModal';
import { ACTION_TYPES } from '../../stateProviders/appStateProvider';
import PersonIcon from '@material-ui/icons/Person';
import { PrimaryButton } from 'components/Button/Button';

const UserWelcomeComponent = memo(({ user, authenticated }) => {
  if (!authenticated) return '';
  return (
    <div className={'header_user'}>
      {authenticated && user && user['firstName'] && `WELCOME, ${user['firstName']} `}
    </div>
  );
});

UserWelcomeComponent.displayName = 'UserWelcomeComponent';

const MenuComponent = memo(({ location, setQuery, authenticated, isHandheld }) => {
  const { restaurantId } = useParams();
  const {
    restaurant: { data, loading, error },
  } = useAppStateContext();
  const appDispatch = useAppDispatchContext();
  const isAccountPage = location.pathname.includes('/account');

  const onClickAccount = useCallback(() => {
    const query = {
      ...(restaurantId && { restaurantId: restaurantId }),
    };

    appDispatch({ type: ACTION_TYPES.cleanUpPaymentData });
    appDispatch({ type: ACTION_TYPES.cleanUpGiftCard });
    appDispatch({ type: ACTION_TYPES.cleanUpUserBillingAccountsData });
    setQuery(query, '/account', false, ['location']);
  }, [setQuery, restaurantId, appDispatch]);
  const onSingIn = useCallback(() => {
    setQuery(
      {
        signin: true,
      },
      '',
      false
    );
  }, [setQuery]);
  const onClickMenu = useCallback(() => {
    if (loading || error) return;
    if (data && data.id) {
      setQuery(null, `/${data.id}/menu`, false, ['restaurantId']);
    } else {
      setQuery({ selectLocationFirstModal: true });
    }
  }, [setQuery, loading, error, data]);
  return (
    <>
      <ul className={classNames('header_menu_items', { handheld_mode: isHandheld })}>
        {authenticated ? (
          <>
            {isAccountPage && (
              <li className={'header_menu_item'} onClick={onClickMenu}>
                <Link routing className={'header_menu_link'}>
                  <span>Menu</span>
                  {isHandheld && (
                    <span className={'header_menu_item_icon'}>
                      <NavigateNextIcon />
                    </span>
                  )}
                </Link>
              </li>
            )}
            <li className={'header_menu_item'}>
              <FavoritesLink className={'header_menu_link'}>
                <span>Favorites</span>
                {isHandheld && (
                  <span className={'header_menu_item_icon'}>
                    <NavigateNextIcon />
                  </span>
                )}
              </FavoritesLink>
            </li>
            <li className={'header_menu_item'}>
              <GroupOrderLink className={'header_menu_link'}>
                <span>Group order</span>
                {isHandheld && (
                  <span className={'header_menu_item_icon'}>
                    <NavigateNextIcon />
                  </span>
                )}
              </GroupOrderLink>
            </li>
            <li className={'header_menu_item'} onClick={onClickAccount}>
              <Link routing className={'header_menu_link'}>
                My Account
                {isHandheld && (
                  <span className={'header_menu_item_icon'}>
                    <NavigateNextIcon />
                  </span>
                )}
              </Link>
            </li>
          </>
        ) : (
          <li className={'header_menu_item'}>
            <Link routing onClick={onSingIn} className={'header_menu_link'}>
              <div className="header_menu_item_with_icon">
                <PersonIcon />
                Sign in
              </div>
              {isHandheld && (
                <span className={'header_menu_item_icon'}>
                  <NavigateNextIcon />
                </span>
              )}
            </Link>
          </li>
        )}
      </ul>
    </>
  );
});

MenuComponent.displayName = 'MenuComponent';

const HandheldMenu = memo(
  ({ locationComponent, userComponent, menuComponent, searchComponent, bagComponent, isCheckoutPage }) => {
    const menuWrapper = createRef();
    const [openMenu, setOpenMenu] = useState(false);
    const handlerMenu = useCallback(() => setOpenMenu(!openMenu), [openMenu]);
    const handlerOutsideClick = useCallback(() => {
      setOpenMenu(false);
    }, []);
    useOutsideClick(menuWrapper, handlerOutsideClick);
    const isRestaurantMenuActive = useRouteMatch({
      path: routes.menu,
      exact: true,
    });
    const isSignInMenuActive = useRouteMatch({
      path: routes.signIn,
      exact: true,
    });
    const isSignUpMenuActive = useRouteMatch({
      path: routes.signUp,
      exact: true,
    });
    const isForgotPasswordMenuActive = useRouteMatch({
      path: routes.forgotPassword,
      exact: true,
    });
    const isResetPasswordMenuActive = useRouteMatch({
      path: routes.resetPassword,
      exact: true,
    });

    const { menuSearchValue } = useAppStateContext();
    const dispatch = useAppDispatchContext();

    useEffect(() => {
      if (!isRestaurantMenuActive && menuSearchValue !== '') {
        dispatch({ type: ACTION_TYPES.cleanupMenuSearch });
      }
    }, [isRestaurantMenuActive, menuSearchValue, dispatch]);

    const isAuthPagesActive =
      isSignInMenuActive || isSignUpMenuActive || isForgotPasswordMenuActive || isResetPasswordMenuActive;
    return (
      <div className="handheld_menu_wrapper">
        <div className="header_logo">
          <LogoComponent />
        </div>
        {!isAuthPagesActive && (
          <div className="handheld_menu_panel">
            {isRestaurantMenuActive && <div className="header_search handheld_search">{searchComponent}</div>}
            <div ref={menuWrapper} className="handheld_menu">
              <PrimaryButton
                className="handheld_menu_btn"
                variant="outlined"
                aria-label="Page Menu"
                role="button"
                onClick={handlerMenu}
              >
                {openMenu ? <CloseIcon /> : <MenuIcon />}
              </PrimaryButton>
              <div className="handheld_menu_items">
                <Collapse in={openMenu}>
                  <ul>
                    {(userComponent || locationComponent) && (
                      <li className="handheld_menu_item">
                        <div>{userComponent}</div>
                        <div className="location_handheld_item">{locationComponent}</div>
                        <div className="order_type_handheld_item">
                          <OrderTypeLink />
                        </div>
                      </li>
                    )}
                    <li className="handheld_menu_item">{menuComponent}</li>
                  </ul>
                </Collapse>
              </div>
            </div>
            {bagComponent && (
              <div className={classNames('handheld_bag', { hide_block: isCheckoutPage })}>{bagComponent}</div>
            )}
          </div>
        )}
      </div>
    );
  }
);

HandheldMenu.displayName = 'HandheldMenu';

const DesktopMenu = memo(({ locationComponent, userComponent, menuComponent, searchComponent, bagComponent }) => {
  const { menuSearchValue } = useAppStateContext();
  const dispatch = useAppDispatchContext();

  const isRestaurantMenuActive = useRouteMatch({
    path: routes.menu,
    exact: true,
  });
  const isSignInMenuActive = useRouteMatch({
    path: routes.signIn,
    exact: true,
  });
  const isSignUpMenuActive = useRouteMatch({
    path: routes.signUp,
    exact: true,
  });
  const isForgotPasswordMenuActive = useRouteMatch({
    path: routes.forgotPassword,
    exact: true,
  });
  const isResetPasswordMenuActive = useRouteMatch({
    path: routes.resetPassword,
    exact: true,
  });

  const isAuthPagesActive =
    isSignInMenuActive || isSignUpMenuActive || isForgotPasswordMenuActive || isResetPasswordMenuActive;

  useEffect(() => {
    if (!isRestaurantMenuActive && menuSearchValue !== '') {
      dispatch({ type: ACTION_TYPES.cleanupMenuSearch });
    }
  }, [isRestaurantMenuActive, menuSearchValue, dispatch]);

  return (
    <>
      <div className={'header_left_panel'}>
        <div className={'header_logo'}>
          <LogoComponent />
        </div>
        <div>
          <div className={'header_location'}>{locationComponent && locationComponent}</div>
          <div className={'header_order_type_link'}>
            <OrderTypeLink />
          </div>
        </div>
      </div>
      {!isAuthPagesActive && (
        <div className={'header_right_panel'}>
          <div className={'header_user'}>{userComponent && userComponent}</div>
          <div className={'header_menu'}>{menuComponent && menuComponent}</div>
          {isRestaurantMenuActive && <div className={'header_search'}>{searchComponent && searchComponent}</div>}
          <div className={'header_bag'}>{bagComponent && bagComponent}</div>
        </div>
      )}
    </>
  );
});

DesktopMenu.displayName = 'DesktopMenu';

const HeaderMenuWrapper = memo(({ isHandheldSize, ...otherProps }) =>
  isHandheldSize ? <HandheldMenu {...otherProps} /> : <DesktopMenu {...otherProps} />
);
HeaderMenuWrapper.displayName = 'HeaderMenuWrapper';

const Header = () => {
  const {
    authenticated,
    user: { data: userData },
    globalNotificationIsShow,
  } = useAppStateContext();
  const theme = useTheme();
  const location = useLocation();
  const isHandheldSize = useMediaQuery(theme.breakpoints.down(1361));
  const setQuery = useQueryUrlParamsDispatch();
  const isIndexPage = location.pathname === '/' && !location.search;
  const isCheckoutPage = location.pathname.includes('/checkout');
  const isMenuPage = location.pathname.includes('/menu');

  return (
    <>
      <MyFavoritesModal />
      <FavoritesPanel />
      <GroupOrderModal />
      <RecipientOrderingPanel />
      <WelcomeGroupOrderModal />
      <ExpiredGroupOrderModal />
      <SelectLocationFirstModal />
      <HandoffMode />
      <AppBar
        classes={{ root: classNames('app_bar_root', { with_notification: globalNotificationIsShow && isMenuPage }) }}
        position="fixed"
      >
        <Toolbar
          classes={{
            root: isHandheldSize ? 'header_handheld_wrapper' : 'header_wrapper',
          }}
        >
          <HeaderMenuWrapper
            locationComponent={!isIndexPage ? <OrderingFrom /> : ''}
            userComponent={authenticated ? <UserWelcomeComponent authenticated={authenticated} user={userData} /> : ''}
            menuComponent={
              <MenuComponent
                location={location}
                setQuery={setQuery}
                isHandheld={isHandheldSize}
                authenticated={authenticated}
              />
            }
            searchComponent={<Search />}
            bagComponent={<DrawerPanel />}
            authenticated={authenticated}
            isHandheldSize={isHandheldSize}
            isCheckoutPage={isCheckoutPage}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

const MemoizedHeader = memo(Header);

export default MemoizedHeader;
