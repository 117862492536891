import React, { useCallback, useState, useEffect } from 'react';
import { useQueryUrlParamsDispatch, useQueryUrlParams } from 'hooks';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import NotFoundLogo from 'components/NotFoundLogo/NotFoundLogo';

import styles from './SelectLocationFirstModal.module.scss';

const SelectLocationFirstModal = () => {
  const setQuery = useQueryUrlParamsDispatch();
  const { selectLocationFirstModal } = useQueryUrlParams();
  const [openModal, setOpenModal] = useState(false);
  const parsedSelectLocationFirstModal = selectLocationFirstModal ? !!JSON.parse(selectLocationFirstModal) : false;

  const handleCloseModal = useCallback(() => {
    setQuery({}, null, false, ['selectLocationFirstModal', 'returnUrl']);
  }, [setQuery]);

  const handleChangeLocation = useCallback(() => {
    setQuery({ location: true }, `/`, false, ['selectLocationFirstModal', 'openBasket']);
  }, [setQuery]);

  useEffect(() => {
    if (!openModal && parsedSelectLocationFirstModal) {
      setOpenModal(true);
    }

    if (openModal && !parsedSelectLocationFirstModal) {
      setOpenModal(false);
    }
  }, [openModal, parsedSelectLocationFirstModal]);

  return (
    <ModalWindow fullscreenSize={1024} open={openModal} onChange={handleCloseModal}>
      <div className={styles.modal_content}>
        <div className={styles.content}>
          <NotFoundLogo />
          <p>Please select location first</p>
          <PrimaryButton className={styles.button} size={'large'} onClick={handleChangeLocation}>
            Select location
          </PrimaryButton>
        </div>
      </div>
    </ModalWindow>
  );
};

export default SelectLocationFirstModal;
