import React, { useCallback, useState } from 'react';
import MapMarker from 'components/GoogleMap/MapMarker/MapMarker';
import { PrimaryIconButton, TextButton } from '../../../components/Button/Button';
import styles from './NotFoundRestaurants.module.scss';
import classNames from 'classnames/bind';
import CloseIcon from '@material-ui/icons/Close';

const NotFoundRestaurants = ({ onClick, description = true, view = 'text' }) => {
  const [show, setShow] = useState(true);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  return show ? (
    <div className={classNames(styles.wrapper, { [styles.modal_view]: view !== 'text' })}>
      <div className={styles.inner_wrapper}>
        <div className={styles.icon}>
          <MapMarker className={styles.marker} />
        </div>
        <PrimaryIconButton className={styles.close_btn} size={'small'} onClick={handleClose}>
          <CloseIcon />
        </PrimaryIconButton>
        <div className={styles.description}>
          <h2>There are Not any 99 Restaurant locations in your area yet.</h2>
          {description && (
            <p>Try expanding your search radius, enter a different zip code, or click below to see all locations.</p>
          )}
        </div>
        <div className={styles.btn_wrapper}>
          <TextButton onClick={onClick} className={styles.button}>
            See all locations
          </TextButton>
        </div>
      </div>
    </div>
  ) : null;
};
export default React.memo(NotFoundRestaurants);
