import React, { Fragment, useState } from 'react';
import styles from './SavedAddresses.module.scss';
import { TextButton } from 'components/Button/Button';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';
import { Divider } from '@material-ui/core';
import classNames from 'classnames/bind';
import useUserSavedAddressList from 'modules/HandoffModeModal/hooks/useUserSavedAddressList';
import showToast from 'components/Toast/ShowToast';
import RemoveCardModal from 'components/RemoveCardModal/RemoveCardModal';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

const MODAL_DESCRIPTION = 'Are you sure you want to remove this address?';
const MODAL_TITLE = 'Remove address?';

const List = React.memo(({ data = [], handleState }) => {
  const onAddressDeleteClick = (address) => () => handleState({ addressModal: true, address });

  const list = data.map((address, index) => {
    const divider = index !== data.length - 1 && <Divider />;

    return (
      <Fragment key={address.id}>
        <div className={styles.row}>
          <div className={styles.cell}>
            <div className={styles.name}>
              {address.building} {address.streetaddress}
            </div>
            <div className={styles.date}>
              {address.city} {address.zipcode}
            </div>
          </div>
          <div className={styles.cell_actions}>
            <TextButton className={classNames(styles.btn, styles.delete)} onClick={onAddressDeleteClick(address)}>
              Delete
            </TextButton>
          </div>
        </div>
        {divider}
      </Fragment>
    );
  });

  return <>{list}</>;
});

List.displayName = 'List';

const SavedAddresses = ({ toMenu }) => {
  const { data, error, loading: loadingData, loadAddressList, removeAddress } = useUserSavedAddressList(true);
  const {
    basket: { data: basketData },
  } = useAppStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    address: {},
    addressModal: false,
  });
  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not load saved addresses. Please try again later!')) ||
    '';

  const handleState = (stateToChange) => {
    setState({ ...state, ...stateToChange });
  };
  const dateleAddress = async () => {
    const { address } = state;
    setIsLoading(true);
    handleState({ addressModal: false });
    await removeAddress(address.id);
    await loadAddressList();
    showToast('success', 'The address was deleted successfully');
    setIsLoading(false);
  };
  const loading = isLoading || loadingData;
  return (
    <div className={styles.container}>
      {errorMessage && <Alert message={errorMessage} />}
      {loading && <Spin spinning={loading} />}
      {data &&
        !loading &&
        (data.length > 0 ? (
          <List data={data} state={state} handleState={handleState} />
        ) : (
          <>
            <div className={styles.no_data}>
              You have no saved addresses.&nbsp;
              <TextButton onClick={toMenu} className={styles.place_order_link}>
                {basketData && basketData.products && basketData.products.length ? 'Continue your order' : 'Order Now'}
              </TextButton>
              &nbsp;and save your address during the checkout process for a faster checkout next time.
            </div>
          </>
        ))}
      <RemoveCardModal
        open={state.addressModal}
        onChange={() => handleState({ addressModal: false })}
        onCancel={() => handleState({ addressModal: false })}
        onSubmit={dateleAddress}
        title={MODAL_TITLE}
        description={MODAL_DESCRIPTION}
      />
    </div>
  );
};

export default React.memo(SavedAddresses);
