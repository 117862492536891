import { useEffect, useReducer, useCallback } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { getMyFavorites } from 'api/user/favorites/getUserFavorites';
import { deleteUserFave } from 'api/user/favorites/deleteUserFave';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useMyFavoritesFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
      try {
        const response = await getMyFavorites();
        const faves = response.faves ? response.faves.reverse() : [];
        if (!ignore) {
          dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: faves } });
        }
      } catch (e) {
        if (!ignore) {
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
        }
      }
    };

    fetch();

    return () => {
      ignore = true;
    };
  }, []);

  const deleteFave = useCallback(
    (faveId) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            await deleteUserFave(faveId);
            const filteredData = state.data.filter((item) => item.id !== faveId);

            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: filteredData } });
            resolve(true);
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            reject(false);
          }
        };

        fetch();
      }),
    [state]
  );

  return [state, { deleteFave }];
};

export default useMyFavoritesFetch;
