import React, { useState, useEffect, useRef } from 'react';
import { useQueryUrlParams } from 'hooks';
import Validator from 'services/ValidateForm';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Alert from 'components/Alert/Alert';
import { PrimaryButton } from 'components/Button/Button';
import { CheckboxWithLabel } from 'components/Checkbox/Checkbox';
import { InputField } from 'components/Input/Input';
import Spin from 'components/Spin/Spin';
import ChooseFavoriteLocation from 'components/ChooseFavoriteLocation/ChooseFavoriteLocation';
import Link from 'components/Link/Link';
import showToast from 'components/Toast/ShowToast';
import BirthdayInput from 'components/BirthdayInput/BirthdayInput';
import EmailInput from 'components/EmailInput/EmailInput';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import { FirstNameInput, LastNameInput } from 'components/NameInputs/NameInputs';
import { withReCaptchaRequest } from 'services/reCaptcha';

import useCreateNewUser from './hooks/useCreateNewUser';
import './SignUpForm.scss';

const CheckBoxLabel = (props) => {
  return (
    <div className={'form_label-checkbox'}>
      <span>Accept Terms and Conditions - </span>
      <Link color={'primary'} target="_blank" href={process.env.REACT_APP_PATH_TERMS} className={'form_label-btn'}>
        view&nbsp;here
      </Link>
      {props.helperText ? <p className={'helperText'}>{props.helperText}</p> : <></>}
    </div>
  );
};

const errorMessages = {
  passwordIsEmpty: 'Please provide a password',
  passwordIsInvalid: 'Password must contain at least 8 characters, 1 special character !@#$%^&?*, and 1 number',
  confirmPasswordIsEmpty: 'Please confirm your password',
  confirmPasswordIsInvalid: 'Passwords do not match',
};

const emailDuplicatedErrorMessage = 'Provided email address already associated with Ninety Nine account.';
const phoneDuplicatedErrorMessage = 'Provided phone number already associated with Ninety Nine account.';

const SignUpForm = ({ close, onSubmit, onSignIn, onForgotPassword }) => {
  const { email: _email, referral_code, referrer_email } = useQueryUrlParams();
  const [{ loading, error }, { createNewUser }] = useCreateNewUser();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(_email || '');
  const [phoneValue, setPhoneValue] = useState('');
  const [birthdayValue, setBirthdayValue] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [favoriteState, setFavoriteState] = useState({ searchValue: '', isTouched: false, locationCode: '' });
  const [checkState, setCheckState] = useState(true);

  const [visibility, setVisibilityPassword] = useState(true);
  const [visibilityConfirm, setVisibilityConfirmPassword] = useState(true);

  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] = useState('');

  const [validateFirstName, setValidateFirstName] = useState(true);
  const [validateLastName, setValidateLastName] = useState(true);
  const [validateEmail, setValidateEmail] = useState(true);
  const [validatePhoneValue, setValidatePhoneValue] = useState(true);
  const [validateBirthday, setValidateBirthday] = useState(true);
  const [validatePassword, setValidatePassword] = useState(true);
  const [validateConfirmPassword, setValidateConfirmPassword] = useState(true);
  const [checkTerms, setCheckTerms] = useState(true);

  const [isDuplicatedEmailAddress, setDuplicatedEmailAddress] = useState(false);
  const [isDuplicatedMobilePhone, setDuplicatedMobilePhone] = useState(false);

  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);

  const validStateFields = () =>
    validateFirstName &&
    validateLastName &&
    validateEmail &&
    validatePhoneValue &&
    validateBirthday &&
    validatePassword &&
    validateConfirmPassword;

  const setDuplicatedProperties = (property, focused = false) => {
    if (property === 'email') {
      setDuplicatedEmailAddress(true);

      if (!focused) {
        emailRef.current.focus();
      }
      return;
    }

    if (property === 'mobilePhone') {
      setDuplicatedMobilePhone(true);

      if (!focused) {
        phoneNumberRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (error && error.errorByFields) {
      if (Array.isArray(error.errorByFields)) {
        let focused = false;
        error.errorByFields.forEach((property) => {
          setDuplicatedProperties(property, focused);
          focused = true;
        });

        return;
      }

      setDuplicatedProperties(error.errorByFields);
    }
  }, [error]);

  const handleFavoriteLocationChanged = (state) => {
    setFavoriteState(state);
  };

  const handleChangeCheck = (event) => {
    if (event.target.checked) {
      setCheckTerms(true);
    }
    setCheckState(event.target.checked);
  };

  const onChangeFirstName = (value) => {
    setValidateFirstName(true);
    setFirstName(value);
  };

  const onChangeLastName = (value) => {
    setValidateLastName(true);
    setLastName(value);
  };

  const onChangePhoneNumber = (value) => {
    setPhoneValue(value);
    setDuplicatedMobilePhone(false);
  };

  const onChangeEmail = (value) => {
    setEmail(value);
    setValidateEmail(true);
    setDuplicatedEmailAddress(false);
  };

  const onErrorEmail = (value) => {
    if (value) {
      setValidateEmail(!value);
      setEmail('');
    } else {
      setValidateEmail(!value);
    }
  };

  const onErrorBirthday = (value) => {
    setValidateBirthday(!value);
  };

  const onErrorPhone = (value) => {
    setValidatePhoneValue(!value);
  };

  const onChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value.trim());
    setPasswordErrorMessage('');
    setValidatePassword(true);
  };

  const onBlurPassword = () => {
    if (!password) {
      setPasswordErrorMessage(errorMessages.passwordIsEmpty);
    } else {
      const checkPassword = Validator.validatePassword(password);
      if (checkPassword) {
        if (confirmPassword) {
          if (confirmPassword === password) {
            setValidateConfirmPassword(true);
          } else {
            setValidateConfirmPassword(false);
            setPasswordConfirmErrorMessage(errorMessages.confirmPasswordIsInvalid);
          }
        }
        setValidatePassword(true);
        setPasswordErrorMessage('');
        return;
      }
      setValidatePassword(false);
      setPasswordErrorMessage(errorMessages.passwordIsInvalid);
      return;
    }
    setValidatePassword(false);
  };

  const onChangeConfirmPassword = (e) => {
    const value = e.target.value;
    setConfirmPassword(value.trim());
    setPasswordConfirmErrorMessage('');
    setValidateConfirmPassword(true);
  };

  const onBlurConfirmPassword = () => {
    if (confirmPassword) {
      if (confirmPassword === password) {
        setPasswordConfirmErrorMessage('');
        setValidateConfirmPassword(true);
      } else {
        setValidateConfirmPassword(false);
        setPasswordConfirmErrorMessage(errorMessages.confirmPasswordIsInvalid);
      }
    } else {
      setValidateConfirmPassword(false);
      setPasswordConfirmErrorMessage(errorMessages.confirmPasswordIsEmpty);
    }
  };

  const handleSignUpClick = () => {
    if (!checkState) {
      setCheckTerms(false);
    }

    const emptyFields = Validator.isFieldsEmpty(
      { value: firstName, field: 'firstName' },
      { value: lastName, field: 'lastName' },
      { value: email, field: 'email' },
      { value: phoneValue, field: 'phoneNumber' },
      { value: password, field: 'password' },
      { value: birthdayValue, field: 'birthdayValue' }
    );

    const hasEmptyFields = setInvalidValue(emptyFields);

    const referralData =
      referrer_email || referral_code
        ? referrer_email
          ? { referrerEmail: referrer_email }
          : { referralCode: referral_code }
        : {};

    setFavoriteState({ ...favoriteState, isTouched: true });

    if (!hasEmptyFields && validStateFields() && checkState && !isDuplicatedEmailAddress && !isDuplicatedMobilePhone) {
      if (!favoriteState.locationCode) {
        showToast('warning', 'Please choose favorite location');
        return;
      }

      const data = {
        firstName,
        lastName,
        email,
        mobilePhone: phoneValue,
        password,
        dateOfBirth: birthdayValue,
        username: email,
        favoriteStore: { code: favoriteState.locationCode },
        ...referralData,
      };

      withReCaptchaRequest(createNewUser, [data]).then((response) => {
        if (response && response.result === 'success') {
          onSubmit && onSubmit();
          close && close();
        }
      });
    }
  };

  const setInvalidValue = (fields) => {
    fields.forEach((field) => {
      switch (field) {
        case 'firstName':
          setValidateFirstName(false);
          break;
        case 'lastName':
          setValidateLastName(false);
          break;
        case 'email':
          setValidateEmail(false);
          break;
        case 'phoneNumber':
          setValidatePhoneValue(false);
          break;
        case 'password':
          setValidatePassword(false);
          setValidateConfirmPassword(false);
          setPasswordErrorMessage(errorMessages.passwordIsEmpty);
          setPasswordConfirmErrorMessage(errorMessages.confirmPasswordIsEmpty);
          break;
        case 'birthdayValue':
          setValidateBirthday(false);
          break;
        default:
      }
    });

    return fields.length > 0;
  };

  const showPassword = () => {
    setVisibilityPassword(!visibility);
  };

  const showPasswordVisibility = () => {
    setVisibilityConfirmPassword(!visibilityConfirm);
  };

  const AlertDuplicationMessage = ({ error }) => {
    let conflictingFields = [];

    if (error && error.errorByFields) {
      if (!Array.isArray(error.errorByFields)) {
        conflictingFields.push(error.errorByFields);
      } else {
        conflictingFields = error.errorByFields.slice();
      }
    }

    let message = '';
    const conflictingEmail = conflictingFields.find((field) => field === 'email');
    const conflictingMobilePhone = conflictingFields.find((field) => field === 'mobilePhone');

    if (conflictingEmail && conflictingMobilePhone) {
      message = 'There is a Ninety Nine account already associated with this email address and phone number.';
    } else if (conflictingEmail) {
      message = 'There is a Ninety Nine account already associated with this email address.';
    } else if (conflictingMobilePhone) {
      message = 'There is a Ninety Nine account already associated with this phone number.';
    } else {
      message = 'There is a Ninety Nine account already associated with this information.';
    }

    const loginLink = onSignIn ? (
      <Link routing uppercase={false} onClick={onSignIn}>
        login
      </Link>
    ) : (
      <a href="?signin=true">login</a>
    );

    const forgotPasswordLink = onForgotPassword ? (
      <Link routing uppercase={false} onClick={onForgotPassword}>
        reset your password
      </Link>
    ) : (
      <a href="?forgotPassword=true">reset your password</a>
    );

    return (
      <div className={'alert_message'}>
        <span>
          {message} Please {loginLink} or {forgotPasswordLink} to get started.
        </span>
      </div>
    );
  };

  const errorMessage = error
    ? error.errorMessage || 'Something went wrong... Could not sign up. Please try again later!'
    : '';

  return (
    <form className={'content_form'}>
      {error && (
        <div className={'form_alert'}>
          {error.errorByFields ? (
            <Alert outlined={'filled'} message={<AlertDuplicationMessage error={error} />} />
          ) : (
            <Alert message={errorMessage} className={'form_error_container'} />
          )}
        </div>
      )}
      <div className={'form_first-name input_wrapper'}>
        <FirstNameInput error={!validateFirstName} onChange={onChangeFirstName} />
      </div>
      <div className={'form_last-name input_wrapper'}>
        <LastNameInput error={!validateLastName} onChange={onChangeLastName} />
      </div>
      <div className={'form_email input_wrapper'}>
        <EmailInput
          onChange={onChangeEmail}
          ref={emailRef}
          defaultValue={email}
          error={!validateEmail || isDuplicatedEmailAddress}
          errorMsg={isDuplicatedEmailAddress && emailDuplicatedErrorMessage}
          onError={onErrorEmail}
        />
      </div>
      <div className={'form_phone input_wrapper'}>
        <PhoneInput
          onChange={onChangePhoneNumber}
          required
          error={!validatePhoneValue || isDuplicatedMobilePhone}
          ref={phoneNumberRef}
          errorMsg={isDuplicatedMobilePhone && phoneDuplicatedErrorMessage}
          onError={onErrorPhone}
        />
      </div>
      <div className={'form_birthday'}>
        <BirthdayInput onChange={setBirthdayValue} error={!validateBirthday} onError={onErrorBirthday} />
      </div>
      <div className={'form_password input_wrapper'}>
        <InputField
          label={'Password'}
          required
          error={!validatePassword}
          type={visibility ? 'password' : 'text'}
          helperText={passwordErrorMessage}
          onChange={onChangePassword}
          onBlur={onBlurPassword}
          value={password}
          autoComplete={'new-password'}
          inputProps={{
            minLength: '8',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={'password_adornment_icon'}
                  aria-label="toggle password visibility"
                  onClick={showPassword}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  {!visibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <p className={'help_message'}>
        Password must contain at least 8 characters, 1 special character !@#$%^&?*, and 1 number
      </p>
      <div className={'form_confirm-password input_wrapper'}>
        <InputField
          label={'Confirm Password'}
          required
          error={!validateConfirmPassword}
          type={visibilityConfirm ? 'password' : 'text'}
          onChange={onChangeConfirmPassword}
          onBlur={onBlurConfirmPassword}
          helperText={passwordConfirmErrorMessage}
          autoComplete={'new-password'}
          value={confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={'password_adornment_icon'}
                  aria-label="toggle password visibility"
                  onClick={showPasswordVisibility}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  {!visibilityConfirm ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={'form_favorite_location'}>
        <h3 className={'label'}>Choose Your Favorite Location</h3>
        <ChooseFavoriteLocation state={favoriteState} stateChanged={handleFavoriteLocationChanged} />
      </div>
      <div className={'form_checkbox'}>
        <CheckboxWithLabel label={<CheckBoxLabel />} checked={checkState} onChange={handleChangeCheck} />
        <InputField
          error={!checkTerms}
          helperText={'You must accept the terms and conditions to create an account'}
          style={{ display: checkTerms ? 'none' : 'block' }}
        />
      </div>
      <div className="captcha g-recaptcha" id="v2-captcha" data-badge="inline" />
      <div className={'form_signup-button'}>
        <PrimaryButton size="large" onClick={handleSignUpClick} disabled={loading}>
          SIGN UP
          <Spin spinning={loading} className={'button_spinner'} />
        </PrimaryButton>
      </div>
    </form>
  );
};

export default SignUpForm;
