import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { sendGroupOrderInvites } from 'api/sendGroupOrderInvites';
import { updateGroupOrder } from 'api/updateGroupOrder';
import showToast from '../../../components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useSendInvitation = (groupOrderId) => {
  const { authenticated } = useAppStateContext();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppBasketState = useAppDispatchContext();
  const sendInvitation = useCallback(
    (deadline = '', note = '', emails = []) => {
      let ignore = false;
      const fetch = async () => {
        if (groupOrderId && authenticated && (note !== '' || emails.length !== 0)) {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            if (note !== '') {
              const response = await updateGroupOrder(groupOrderId, deadline, note);
              if (!ignore) {
                if (emails.length > 0) {
                  dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
                }
                dispatchAppBasketState({ type: ACTION_TYPES.setGroupOrder, payload: { data: response } });
                dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: response.basket } });
              }
            }
            if (emails.length > 0) {
              const invitesResponse = await sendGroupOrderInvites(groupOrderId, emails);
              if (!ignore) {
                showToast('success', 'Successfully Sent!');
                dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: invitesResponse } });
              }
            }
          } catch (e) {
            if (!ignore) {
              showToast('error', 'Something went wrong... Could not send invitations!');
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            }
          }
        }
      };
      fetch();
      return () => {
        ignore = true;
      };
    },
    [groupOrderId, authenticated, dispatchAppBasketState, dispatch]
  );

  return [state, { sendInvitation: sendInvitation }];
};

export default useSendInvitation;
