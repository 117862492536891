import React, { useState } from 'react';
import styles from './CreateAccountSection.module.scss';
import { PrimaryButton } from 'components/Button/Button';
import { SignUpForm as Form } from '../../../components/Forms';
import Section from 'components/Section/Section';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Collapse from '@material-ui/core/Collapse';

const SignUpForm = () => {
  const onSubmit = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Section sectionHr title={'CREATE ACCOUNT'}>
      <div className={styles.form}>
        <Form onSubmit={onSubmit} />
      </div>
    </Section>
  );
};

const CreateAccountSection = () => {
  const [isCreateAccount, setIsCreateAccount] = useState(false);

  const onClick = () => {
    setIsCreateAccount((prevState) => !prevState);
  };

  return (
    <div className={styles.wrapper}>
      <Collapse in={isCreateAccount}>
        <SignUpForm />
      </Collapse>
      {!isCreateAccount && (
        <PrimaryButton endIcon={<ArrowForwardIcon />} className={styles.button} size="large" onClick={onClick}>
          Join 99 Rewards
        </PrimaryButton>
      )}
    </div>
  );
};
export default React.memo(CreateAccountSection);
