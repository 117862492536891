import React, { useState, useEffect, memo } from 'react';
import { Stepper as StepperMUI } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import DoneIcon from '@material-ui/icons/Done';
import classNames from 'classnames';
import { useMediaQuery, useTheme } from '@material-ui/core';
import './Stepper.scss';

const StepIcon = ({ active, completed, ...otherProps }) => {
  return (
    <div className={classNames('step_icon', { active: active, completed: completed })}>
      <DoneIcon />
    </div>
  );
};
const StepConnector = ({ active, completed, orientation, ...otherProps }) => {
  return (
    <div
      className={classNames('step_connector', { active: active, completed: completed, [orientation]: orientation })}
    />
  );
};

const Stepper = ({ steps, currStep }) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    if (currStep && activeStep !== currStep) {
      setActiveStep(currStep);
    }
  }, [activeStep, currStep]);
  return (
    <StepperMUI
      orientation={isMobileSize ? 'vertical' : 'horizontal'}
      classes={{
        root: 'stepper_root',
        alternativeLabel: 'stepper_alt_label',
      }}
      alternativeLabel
      connector={<StepConnector />}
      activeStep={activeStep}
    >
      {steps.map(({ label, description, content }, index, arr) => {
        const stepProps = {};
        const labelProps = {};
        return (
          <Step style={{ zIndex: arr.length - index }} key={index} {...stepProps}>
            <StepLabel {...labelProps} StepIconComponent={StepIcon}>
              <h4 className={'stepper_header'}>{label}</h4>
              <p className={'stepper_desc'}>{description}</p>
            </StepLabel>
            {content && <StepContent>{content}</StepContent>}
          </Step>
        );
      })}
    </StepperMUI>
  );
};
const MemoizedStepper = memo(Stepper);
export default MemoizedStepper;
