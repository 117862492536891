import React, { useState, useEffect, memo, useCallback } from 'react';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { RadioBox } from 'components/Radiobox/Radiobox';
import classNames from 'classnames/bind';
import SimpleSelect from 'components/Select/Select';
import { ReactComponent as DefaultIcon } from '../../assets/upsell_default_icon.svg';
import Avatar from '@material-ui/core/Avatar';
import './ChooseButton.scss';

const ControlWithImage = React.memo(({ imagePath, IconForEmptyImage, ...props }) => (
  <div className="image_control_wrapper">
    {imagePath ? (
      <Avatar variant="rounded" className="image_control_img" src={imagePath} />
    ) : (
      <Avatar variant="rounded" className="image_control_img">
        <IconForEmptyImage />
      </Avatar>
    )}
    <Checkbox className="image_control_checkbox" {...props} />
  </div>
));

ControlWithImage.displayName = 'ControlWithImage';

const Control = React.memo(({ type, IconForEmptyImage, imagePath, ...props }) =>
  type === 'radio' ? (
    <RadioBox {...props} />
  ) : type === 'image' ? (
    <ControlWithImage IconForEmptyImage={IconForEmptyImage} imagePath={imagePath} {...props} />
  ) : (
    <Checkbox {...props} />
  )
);

Control.displayName = 'Control';

const ChooseButton = ({
  checked = false,
  handleChange,
  handleClick,
  name = '',
  label = '',
  description = '',
  price = '',
  disabled = false,
  value = '',
  type = 'radio' || 'checkbox' || 'image',
  selectOptions = null,
  className,
  imagePath = '',
  IconForEmptyImage = DefaultIcon,
  noRadioCircle = false,
  classes = {},
  ...otherProps
}) => {
  const [selected, setSelected] = useState(checked);

  const onChange = useCallback(
    (event) => {
      const value = type === 'radio' ? event.target.value : event.target.checked;
      handleChange && handleChange(value, event);
    },
    [handleChange, type]
  );

  const onClick = useCallback((event) => handleClick && handleClick(event), [handleClick]);

  useEffect(() => {
    if (checked !== selected) {
      setSelected(checked);
    }
  }, [checked, selected]);

  const Label = (
    <div className="choose_btn_label_wrapper">
      <div className="choose_btn_info_section">
        <div
          className={classNames('choose_btn_label', {
            choose_btn_label_selected: type === 'image' && selected,
            choose_btn_label_disabled: disabled,
          })}
        >
          {label}
        </div>
        {description && <p className="choose_btn_description_wrapper">{description}</p>}
      </div>
      <div className="choose_btn_price_section">
        {price && (
          <span
            className={classNames('choose_btn_price', {
              choose_btn_price_selected: selected,
              choose_btn_price_disabled: disabled,
            })}
          >
            {price}
          </span>
        )}
      </div>
      {selectOptions && <SimpleSelect {...selectOptions} />}
    </div>
  );

  return (
    <FormControlLabel
      label={Label}
      className={classNames(
        {
          choose_btn_selected: selected,
          choose_btn_disabled: disabled,
        },
        className
      )}
      value={value}
      onChange={onChange}
      onClick={onClick}
      classes={{
        root: classNames('form_choose_btn_root', { with_image: type === 'image', form_no_radio_circle: noRadioCircle }),
        label: 'form_choose_btn_label',
      }}
      control={
        <Control
          name={name}
          type={type}
          IconForEmptyImage={IconForEmptyImage}
          imagePath={imagePath}
          disabled={disabled}
          checked={selected}
          {...otherProps}
        />
      }
    />
  );
};

const MemoizedChooseButton = memo(ChooseButton);
export default memo(ChooseButton);
