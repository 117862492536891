import React from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

import Section from 'components/Section/Section';
import CopyLink from 'components/CopyLink/CopyLink';

import styles from './GroupOrderCopyLink.module.scss';

const GroupOrderCopyLink = () => {
  const {
    groupOrder: { data },
  } = useAppStateContext();
  const restaurantId = data && data.basket ? data.basket.vendorid : '';

  const link = data
    ? `${process.env.REACT_APP_DOMAIN}/${restaurantId}/menu?joinGroup=true&groupOrderId=${data.id}`
    : '';

  return (
    <Section title={"Your group's order link"} sectionHr className={styles.container}>
      <p className={styles.description}>Copy and share this unique link to invite others to your group</p>
      <CopyLink link={link} />
    </Section>
  );
};

export default GroupOrderCopyLink;
