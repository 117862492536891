import React, { useCallback, useEffect, useState } from 'react';
import { InputField } from 'components/Input/Input';
import { NAME_VALIDATION_PATTERN } from 'services/name';

const validationNamePattern = (value) => NAME_VALIDATION_PATTERN.test(value);
const isNotEmptyName = (value) => !!value;

const NameInput = React.forwardRef(
  (
    {
      label,
      defaultValue,
      required = true,
      error = false,
      onChange,
      onError,
      autoComplete,
      emptyErrorMsg = '',
      patternErrorMsg = '',
    },
    ref
  ) => {
    const [nameError, setNameError] = useState(error);
    const [nameErrorMessage, setNameErrorMessage] = useState(emptyErrorMsg);
    const [value, setValue] = useState(defaultValue);
    const [isTouched, setIsTouched] = useState(false);

    useEffect(() => {
      if (defaultValue && !value && !isTouched) {
        setValue(defaultValue);
      }
    }, [defaultValue, value, isTouched]);

    useEffect(() => {
      if (typeof error === 'boolean') {
        setNameError(error);
      }
    }, [error]);

    const onChangeName = useCallback((e) => {
      const value = e.target.value;
      setIsTouched(true);
      setNameError(false);
      setNameErrorMessage('');
      setValue(value);
    }, []);

    const onBlurName = useCallback(() => {
      const isValid = validationNamePattern(value);
      const isNotEmpty = isNotEmptyName(value);

      if (isValid) {
        setNameError(false);
        setValue(value);
        onChange && onChange(value);
        return;
      }

      if (!isNotEmpty) {
        setNameError(true);
        setNameErrorMessage(emptyErrorMsg);
        onError && onError(true);
        return;
      }

      if (!isValid) {
        setNameError(true);
        setNameErrorMessage(patternErrorMsg);
        onError && onError(true);
      }
    }, [value, onChange, onError, emptyErrorMsg, patternErrorMsg]);

    console.log(nameError, nameErrorMessage);

    return (
      <InputField
        required={required}
        label={label}
        inputRef={ref}
        error={nameError}
        value={value}
        helperText={nameError && nameErrorMessage}
        onChange={onChangeName}
        onBlur={onBlurName}
        autoComplete={autoComplete}
        inputProps={{
          minLength: '1',
          maxLength: '30',
        }}
      />
    );
  }
);

const _FirstNameInput = React.forwardRef(({ defaultValue, required, onChange, error, onError }, ref) => {
  return (
    <NameInput
      label={'First Name'}
      defaultValue={defaultValue}
      required={required}
      error={error}
      onChange={onChange}
      autoComplete={'given-name'}
      ref={ref}
      emptyErrorMsg={'Please provide a first name'}
      patternErrorMsg={'First name can include letters, parenthesis, numbers, hyphens, or spaces.'}
    />
  );
});

const _LastNameInput = React.forwardRef(({ defaultValue, required, onChange, error, onError }, ref) => {
  return (
    <NameInput
      label={'Last Name'}
      defaultValue={defaultValue}
      required={required}
      error={error}
      onChange={onChange}
      autoComplete={'family-name'}
      ref={ref}
      emptyErrorMsg={'Please provide a last name'}
      patternErrorMsg={'Last name can include letters, parenthesis, numbers, hyphens, or spaces.'}
    />
  );
});

NameInput.displayName = 'NameInput';
_FirstNameInput.displayName = 'FirstNameInput';
_LastNameInput.displayName = 'LaseNameInput';

const FirstNameInput = React.memo(_FirstNameInput);
const LastNameInput = React.memo(_LastNameInput);

export { FirstNameInput, LastNameInput };
