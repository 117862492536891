import React, { useEffect } from 'react';
import { useQueryUrlParams, useQueryUrlParamsDispatch } from 'hooks';
import CreateGroupOrderModal from './CreateGroupOrderModal/CreateGroupOrderModal';
import ManageGroupOrderModal from './ManageGroupOrderModal/ManageGroupOrderModal';
import NotSupportedGroupOrderModal from './NotSupportedGroupOrderModal/NotSupportedGroupOrderModal';
import { useAppStateContext } from '../../stateProviders/useAppStateContext';
import useGroupOrder from 'hooks/useGroupOrder';

const GroupOrderModal = () => {
  const { groupOrderModal, groupOrderId, invites, owner } = useQueryUrlParams();
  useGroupOrder(groupOrderId);
  const {
    restaurant: { data, loading, error },
    basket: { data: basketData },
    authenticated,
  } = useAppStateContext();
  const setQuery = useQueryUrlParamsDispatch();
  const parsedGroupOrderModal = groupOrderModal ? !!JSON.parse(groupOrderModal) : false;
  const parsedOwner = owner ? !!JSON.parse(owner) : false;
  const parsedInvites = invites ? !!JSON.parse(invites) : false;
  const recipient = !parsedOwner && groupOrderId;
  const available = data && data.supportsgrouporders && basketData && !error;

  useEffect(() => {
    if (!authenticated && parsedGroupOrderModal) {
      setQuery({ signin: true }, '', false, ['groupOrderModal']);
    }
  }, [authenticated, parsedGroupOrderModal, setQuery]);

  return !loading && parsedGroupOrderModal ? (
    <>
      {!available && <NotSupportedGroupOrderModal />}
      {(!groupOrderId || parsedInvites || recipient) && available && <CreateGroupOrderModal invites={parsedInvites} />}
      {groupOrderId && !parsedInvites && available && owner && <ManageGroupOrderModal />}
    </>
  ) : null;
};
export default GroupOrderModal;
