import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { createBasketFromPreviousOrder } from 'api/createBasketFromPreviousOrder';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useCreateBasketFromPreviousOrder = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const appDispatch = useAppDispatchContext();

  const createBasket = useCallback(
    (orderId) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          appDispatch({ type: ACTION_TYPES.loadingBasket, payload: { loading: true } });
          try {
            const basket = await createBasketFromPreviousOrder(orderId);
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false } });
            appDispatch({ type: ACTION_TYPES.cleanupRestaurant });
            appDispatch({ type: ACTION_TYPES.cleanupRestaurantMenu });
            appDispatch({ type: ACTION_TYPES.setBasket, payload: { data: basket } });
            resolve(basket);
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            reject(false);
          }
        };

        fetch();
      }),
    [appDispatch]
  );

  return [state, { createBasket }];
};

export default useCreateBasketFromPreviousOrder;
