import { setBasketCustomField, deleteBasketCustomField } from 'api/customFields';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import showToast from 'components/Toast/ShowToast';
import { useCallback, useReducer } from 'react';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useCurbsideFetch = () => {
  const appDispatch = useAppDispatchContext();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  return {
    ...state,
    setCustomField: useCallback(
      (basketId, customField) =>
        new Promise((resolve, reject) => {
          const fetch = async () => {
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
            try {
              const response = customField.value
                ? await setBasketCustomField(basketId, customField)
                : await deleteBasketCustomField(basketId, customField.id);
              appDispatch({ type: ACTION_TYPES.setBasket, payload: { data: response } });
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
              resolve();
            } catch (e) {
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
              customField.value &&
                showToast('error', e.errorMessage || 'Something went wrong... Could not set a value!');
              reject();
            }
          };
          if (basketId && customField) {
            fetch();
          }
        }),
      [appDispatch, dispatch]
    ),
  };
};

export default useCurbsideFetch;
