import React from 'react';

import Price from 'components/Price/Price';
import ProductImage from 'components/ProductImage/ProductImage';
import ProductCalories from 'components/ProductCalories/ProductCalories';

import styles from './ProductDescription.module.scss';

const ProductDescription = ({ imagepath, product }) => {
  const { images, name, description, cost, basecalories, caloriesseparator, maxcalories } = product;

  return (
    <div className={styles.container}>
      <ProductImage imagepath={imagepath} images={images} alt={name} className={styles.image} />
      <div className={styles.wrapper}>
        <h3 className={styles.name}>{name}</h3>
        <div className={styles.cost_calories_wrapper}>
          <Price value={cost} prefix={true} suffix={false} />
          <ProductCalories
            basecalories={basecalories}
            caloriesseparator={caloriesseparator}
            maxcalories={maxcalories}
          />
        </div>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default React.memo(ProductDescription);
