import React from 'react';
import { Redirect, Route } from 'react-router';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

const ProtectedRoute = ({ children, ...rest }) => {
  const { authenticated } = useAppStateContext();
  return (
    <Route
      {...rest}
      render={() => {
        return authenticated ? children : <Redirect to={'/'} />;
      }}
    />
  );
};

export default ProtectedRoute;
