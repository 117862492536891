import React, { memo } from 'react';
import styles from './OrderInformation.module.scss';
import Price from 'components/Price/Price';

const OrderInformation = ({ products = [] }) => {
  return (
    <div className={styles.wrapper}>
      {products.length > 0
        ? products.map(({ quantity, totalcost, id, name }) => (
            <div className={styles.row} key={id}>
              <div className={styles.grow_cell}>
                <div className={styles.product}>
                  <span className={styles.quantity}>{quantity}x</span>
                  <span>{name}</span>
                </div>
              </div>
              <div className={styles.cell}>
                <span>{totalcost === 0 ? 'FREE' : <Price value={totalcost} />}</span>
              </div>
            </div>
          ))
        : 'You Order is empty'}
    </div>
  );
};
const MemoizedOrderInformation = memo(OrderInformation);
export default MemoizedOrderInformation;
