import React from 'react';
import { PrimaryButton } from 'components/Button/Button';
import NotFoundLogo from 'components/NotFoundLogo/NotFoundLogo';
import './PageNotFound.scss';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className={'wrapper_page-not-found'}>
      <div className={'page-not-found_content'}>
        <div className={'page_not_found_logo'}>
          <NotFoundLogo />
        </div>
        <p>Sorry, we couldn&apos;t find anything here.</p>
        <Link to={'/'}>
          <PrimaryButton fullWidth size="large">
            START YOUR ORDER
          </PrimaryButton>
        </Link>
      </div>
    </div>
  );
};

export default React.memo(PageNotFound);
