import React, { useCallback, useState, useEffect } from 'react';
import { useQueryUrlParamsDispatch } from 'hooks';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { ORDER_DEADLINE_OLO } from 'enums/orderDeadline';
import { EMAIL_REGEXP } from 'services/email';

import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import MomentUtils from '@date-io/moment';

import { PrimaryButton } from 'components/Button/Button';
import { TextButton } from 'components/Button/Button';
import ModalWindow from 'components/Modal/Modal';
import showToast from 'components/Toast/ShowToast';
import Alert from 'components/Alert/Alert';
import Link from 'components/Link/Link';

import CreateGroupOrderFormView from '../CreateGroupOrderFormView/CreateGroupOrderFormView';
import GroupOrderInvitationView from '../GroupOrderInvitationView/GroupOrderInvitationView';
import GroupOrderDeadline from '../GroupOrderDeadline/GroupOrderDeadline';
import useCreatingGroupOrder from '../hooks/useCreatingGroupOrder';
import useSendInvitation from '../hooks/useSendInvitation';

import styles from './CreateGroupOrderModal.module.scss';

const moment = new MomentUtils();

const validateEmails = (values) => {
  const incorrect = values.find((value) => {
    return !EMAIL_REGEXP.test(value) || value === '';
  });
  return !incorrect;
};

const validateDeadline = (date) => {
  return moment.moment().isBefore(moment.moment(date, ORDER_DEADLINE_OLO));
};

const CreateGroupOrderModal = ({ invites = false }) => {
  const {
    groupOrder: { data: groupOrderInfo },
  } = useAppStateContext();
  const { deadline: storedDeadline } = groupOrderInfo || {};
  const [deadline, setDeadline] = useState('');
  const [deadlineError, showDeadlineError] = useState(false);
  const [showInvitation, setShowInvitation] = useState(invites);
  const setQuery = useQueryUrlParamsDispatch();
  const [
    { loading: createGroupOrderLoading, data: createGroupOrderData, error: createGroupOrderError },
    { createGroupOrder },
  ] = useCreatingGroupOrder();

  const [emails, setEmails] = useState('');
  const [note, setNote] = useState('');
  const [emailsInvalid, showEmailsInvalid] = useState(false);
  const groupOrderId = groupOrderInfo ? groupOrderInfo.id : '';
  const [{ loading, data, error }, { sendInvitation }] = useSendInvitation(groupOrderId);

  useEffect(() => {
    if (showInvitation) {
      setDeadline(storedDeadline);
    }
  }, [storedDeadline, showInvitation]);

  useEffect(() => {
    if (createGroupOrderData) {
      setShowInvitation(true);
    }
  }, [createGroupOrderData]);

  const handleCloseModal = useCallback(() => {
    showEmailsInvalid(false);
    setNote('');
    setEmails('');
    if (createGroupOrderData) {
      setQuery(
        {
          openBasket: true,
          groupOrderId: createGroupOrderData.id,
          basketId: createGroupOrderData.basket.id,
          owner: true,
        },
        `/${createGroupOrderData.basket.vendorid}/menu`,
        true
      );
    } else {
      setQuery({}, '', false, ['groupOrderModal', 'invites']);
    }
  }, [createGroupOrderData, setQuery]);

  useEffect(() => {
    if (data) {
      handleCloseModal();
    }
  }, [data, handleCloseModal]);

  const handleStartGroupOrderClick = useCallback(() => {
    const isValid = deadline && validateDeadline(deadline);
    if (deadline && validateDeadline(deadline)) {
      createGroupOrder(deadline);
    } else if (deadline && !isValid) {
      showDeadlineError(true);
    }
  }, [deadline, createGroupOrder]);

  const handleDeadlineChange = useCallback(
    (value) => {
      if (value !== deadline) {
        setDeadline(value);
        showDeadlineError(false);
      }
    },
    [deadline]
  );

  const onChangeEmails = (values) => {
    showEmailsInvalid(false);
    setEmails(values);
  };

  const onChangeNote = (value) => setNote(value);

  const handleSendEmailClick = useCallback(() => {
    const values = emails
      .split(',')
      .map((email) => email.trim())
      .filter((email) => {
        return email !== '';
      });
    const emailsIsValid = values.length > 0 && validateEmails(values);
    if (sendInvitation && emailsIsValid) {
      sendInvitation(deadline, note, values);
    }
    if (!emailsIsValid) {
      showEmailsInvalid(true);
    }
  }, [sendInvitation, note, emails, deadline]);

  const invitationErrorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not send invitations. Please try again later!')) ||
    '';

  const creationError =
    (createGroupOrderError &&
      (createGroupOrderError.errorMessage ||
        'Something went wrong... Could not create group order. Please try again later!')) ||
    '';

  useEffect(() => {
    if (creationError) {
      showToast('error', creationError);
    }
  }, [creationError]);

  return (
    <ModalWindow
      fullscreenSize={1024}
      open={true}
      maxWidth={'md'}
      headerSize="lg"
      onChange={handleCloseModal}
      headerTitle={'Group Order'}
      footerChildren={
        !showInvitation ? (
          <div className={styles.start_order_footer}>
            <Link routing isGothic onClick={handleCloseModal}>
              <TextButton>CANCEL</TextButton>
            </Link>
            <PrimaryButton
              size="large"
              className={styles.order_button}
              disabled={createGroupOrderLoading}
              onClick={handleStartGroupOrderClick}
              endIcon={<ArrowForwardIcon />}
            >
              Start Group Order
              {createGroupOrderLoading && <CircularProgress size={24} className={styles.button_progress} />}
            </PrimaryButton>
          </div>
        ) : (
          <div className={styles.footer}>
            <PrimaryButton
              className={styles.button}
              size="large"
              endIcon={<ArrowForwardIcon />}
              disabled={loading}
              onClick={handleSendEmailClick}
            >
              Send email
            </PrimaryButton>
          </div>
        )
      }
    >
      <div className={styles.modal_content}>
        {creationError && <Alert message={creationError} />}
        {!showInvitation && (
          <>
            <CreateGroupOrderFormView />
            <GroupOrderDeadline
              deadline={deadline}
              error={deadlineError}
              onChange={handleDeadlineChange}
              isExist={false}
            />
          </>
        )}
        {showInvitation && (
          <GroupOrderInvitationView
            onChangeEmails={onChangeEmails}
            onChangeNote={onChangeNote}
            error={emailsInvalid}
            onSend={handleSendEmailClick}
            errorMessage={invitationErrorMessage}
            loading={loading}
          />
        )}
      </div>
    </ModalWindow>
  );
};

export default CreateGroupOrderModal;
