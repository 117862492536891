import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';

// States
import { useAppStateContext, useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';

// Hooks
import useRestaurantMenuFetch from './hooks/useRestaurantMenuFetch';
import useSetDeliveryMode from '../HandoffModeModal/hooks/useSetDeliveryMode';
import { useQueryUrlParams } from 'hooks';

// Components
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import GlobalNotification from 'components/GlobalNotification/GlobalNotification';
import RestaurantCategories from './RestaurantCategories/RestaurantCategories';
import { useRestaurantInfo } from 'components/OrderingFrom/OrderingFrom';
import ContinueToBag from './ContinueToBag/ContinueToBag';
import HandoffModeModal from 'modules/HandoffModeModal/HandoffModeModal';
import { HANDOFF_MODES_ENUM_LIST, HANDOFF_MODE_ENUM } from 'modules/HandoffModeModal/HandoffModeModal';

// API
import { applyReferralToBasket } from '../../api/applyReferralToBasket';
import { createBasket } from '../../api/createBasket';

// Styles
import styles from './RestaurantMenu.module.scss';

function RestaurantMenu() {
  const [{ loading, data, error }] = useRestaurantMenuFetch();
  const {
    restaurant: { data: rData },
    basket: { data: basketFromState },
    globalNotificationIsShow,
  } = useAppStateContext();
  const basket = basketFromState || {};
  const restaurantFromState = useRestaurantInfo(['id']);
  const restaurant = rData || restaurantFromState;
  const appDispatch = useAppDispatchContext();
  const hasData = !loading && !error && data && data.categories.length > 0;
  const [isHandoffModalOpen, setIsHandoffModalOpen] = useState(false);
  const [basketId, setBasketId] = useState();
  const queryParam = useQueryUrlParams();
  const { setDeliveryMode } = useSetDeliveryMode();

  const getBasketId = useCallback(
    async (restaurantId) => {
      if (basket && basket.id) {
        return basket.id;
      }

      const createdBasket = await createBasket(restaurantId);
      appDispatch({ type: ACTION_TYPES.setBasket, payload: { data: createdBasket } });

      return createdBasket.id;
    },
    [basket, appDispatch]
  );

  useEffect(() => {
    async function fetchBasketId() {
      const basketId = await getBasketId(restaurant.id);
      setBasketId(basketId);
    }
    if (restaurant && restaurant.id) {
      fetchBasketId();
    }
  }, [restaurant, getBasketId]);

  // Referral logic
  useEffect(() => {
    if (queryParam.rwg_token && basketId) {
      const rwg_token = queryParam.rwg_token || '';
      if (rwg_token) {
        const payload = {
          source: 'rwg_token',
          token: rwg_token,
        };
        applyReferralToBasket(basketId, payload);
      }
    }
  }, [queryParam.rwg_token, basketId]);

  // Handoff logic
  useEffect(() => {
    async function setPickup() {
      const basketId = await getBasketId(restaurant.id);
      setBasketId(basketId);
      setDeliveryMode(basketId, HANDOFF_MODE_ENUM.PICKUP);
    }

    if (queryParam) {
      const { handoff = '' } = queryParam;
      if (handoff && HANDOFF_MODES_ENUM_LIST.includes(handoff)) {
        if (handoff === HANDOFF_MODE_ENUM.PICKUP) {
          setPickup();
        } else {
          setIsHandoffModalOpen(true);
        }
      }
    }
  }, [queryParam.handoff, setIsHandoffModalOpen]);

  const errorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not load menu. Please try again later!')) || '';

  return (
    <div className={classNames(styles.page_container, { [styles.with_notification]: globalNotificationIsShow })}>
      {globalNotificationIsShow && rData && <GlobalNotification notification={rData.customerfacingmessage} />}
      {error && <Alert message={errorMessage} />}
      {loading && <Spin spinning={loading} />}
      {hasData && <RestaurantCategories {...data} />}
      <HandoffModeModal
        isOpen={isHandoffModalOpen}
        submitBtnText="Confirm Order Mode"
        onClose={() => {
          setIsHandoffModalOpen(false);
        }}
        onSubmit={() => {
          setIsHandoffModalOpen(false);
        }}
      />
      <ContinueToBag />
    </div>
  );
}

export default React.memo(RestaurantMenu);
