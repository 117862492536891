import React, { memo, useCallback, useState, useEffect } from 'react';
import styles from './ReferFriendModal.module.scss';
import ModalWindow from 'components/Modal/Modal';
import ReferFriendCopyLink from './ReferFriendCopyLink/ReferFriendCopyLink';
import { PrimaryButton } from 'components/Button/Button';
import useReferrals from './hooks/useReferrals';
import Section from 'components/Section/Section';
import SendEmails from 'components/SendEmails/SendEmails';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const EMAIL_REGEXP = /^[a-zA-Z]+(?:[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*(?:\.[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,5}$/;

const validateEmails = (values) => {
  const incorrect = values.find((value) => {
    return !EMAIL_REGEXP.test(value) || value === '';
  });
  return !incorrect;
};

const ReferFriendModal = ({ onClose, open }) => {
  const [emails, setEmails] = useState('');
  const [emailsInvalid, showEmailsInvalid] = useState(false);
  const [{ loading, data, error }, { sendReferrals, resetState }] = useReferrals();

  const onCloseChanges = useCallback(() => {
    resetState();
    showEmailsInvalid(false);
    setEmails('');
    onClose(false);
  }, [onClose, resetState]);

  useEffect(() => {
    if (data) {
      onCloseChanges();
    }
  }, [data, onCloseChanges]);

  const onSubmitReferrals = useCallback(() => {
    const values = emails
      .split(',')
      .map((email) => email.trim())
      .filter((email) => {
        return email !== '';
      });
    const emailsIsValid = values.length > 0 && validateEmails(values);
    if (sendReferrals && emailsIsValid) {
      sendReferrals(values);
    }
    if (!emailsIsValid) {
      showEmailsInvalid(true);
    }
  }, [sendReferrals, emails]);

  const errorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not send emails. Please try again later!')) || '';

  const onChangeEmails = (values) => {
    showEmailsInvalid(false);
    setEmails(values);
  };

  return (
    <ModalWindow
      maxWidth="md"
      headerSize="lg"
      open={open}
      footerChildren={
        <div className={styles.footer}>
          <PrimaryButton
            className={styles.button}
            size="large"
            endIcon={<ArrowForwardIcon />}
            disabled={loading}
            onClick={onSubmitReferrals}
          >
            Send email
          </PrimaryButton>
        </div>
      }
      onChange={onCloseChanges}
      headerTitle="Refer a friend"
    >
      <div className={styles.modal_content}>
        <h3>Invite friends and family to join and get a special treat when they sign up.</h3>
        <ReferFriendCopyLink />
        <Section sectionHr title={'Email a friend'}>
          <SendEmails
            loading={loading}
            allowNotes={false}
            errorMessage={errorMessage}
            onChangeEmails={onChangeEmails}
            error={emailsInvalid}
          />
        </Section>
      </div>
    </ModalWindow>
  );
};

const ReferFriendModalMemo = memo(ReferFriendModal);

export default ReferFriendModalMemo;
