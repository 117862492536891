import React, { useState, memo } from 'react';
import styles from './GiftCard.module.scss';
import { TextButton } from 'components/Button/Button';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import NewGiftCard from './NewGiftCard/NewGiftCard';
import SavedGiftCard from './SavedGiftCard/SavedGiftCard';
import SavedGiftCardModal from './SavedGiftCardModal/SavedGiftCardModal';
import useBillingSchemesFetch from 'modules/Checkout/CheckoutOrder/hooks/useBillingSchemesFetch';
import { ACTION_TYPES } from '../../../../../stateProviders/appStateProvider';

const getUserBillingGiftCards = (card) => card.accounttype.toLowerCase().includes('gift card');

const GiftCard = () => {
  const {
    authenticated,
    userBillingAccounts: { billingAccounts },
    basket: { loading: basketLoading, error: basketError },
    savedUserGiftCards: { selectedSavedGiftCard },
  } = useAppStateContext();
  const { loading: loadingSchemes, data: dataSchemes, error: errorSchemes } = useBillingSchemesFetch();
  const [useSavedGiftCard, setUseSavedGiftCard] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const appDispatch = useAppDispatchContext();

  const giftCardData = (billingAccounts || []).filter(getUserBillingGiftCards);
  const hasBillingAccounts = authenticated && Array.isArray(giftCardData) && !!giftCardData.length;
  const onClickButton = () => {
    if (selectedSavedGiftCard && useSavedGiftCard) {
      setOpenModal(false);
      setUseSavedGiftCard(false);
      appDispatch({
        type: ACTION_TYPES.cleanUpSelectedSavedUserGiftCard,
      });
      appDispatch({
        type: ACTION_TYPES.cleanUpGiftCard,
      });
    } else if (!useSavedGiftCard && !selectedSavedGiftCard) {
      setOpenModal(true);
      setUseSavedGiftCard(true);
    } else if (!useSavedGiftCard && selectedSavedGiftCard) {
      setOpenModal(false);
      setUseSavedGiftCard(true);
    }
  };
  const onCloseModal = () => {
    setOpenModal(false);
    if (selectedSavedGiftCard) {
      setUseSavedGiftCard(true);
    } else {
      setUseSavedGiftCard(false);
    }
  };
  const onSubmitModal = () => {
    setOpenModal(false);
    setUseSavedGiftCard(true);
  };
  const onSelectDifferentCard = () => setOpenModal(true);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.header_title}>{'Gift Card'}</h3>
        <div className={styles.header_btn}>
          {hasBillingAccounts && (
            <TextButton className={styles.link} disabled={basketLoading || basketError} onClick={onClickButton}>
              {useSavedGiftCard ? 'Use New Card' : 'Use Saved Card'}
            </TextButton>
          )}
        </div>
      </div>
      {useSavedGiftCard ? (
        <>
          <SavedGiftCard selectedGiftCard={selectedSavedGiftCard} onClick={onSelectDifferentCard} />
          <NewGiftCard loadingSchemes={loadingSchemes} dataSchemes={dataSchemes} errorSchemes={errorSchemes} />
        </>
      ) : (
        <NewGiftCard loadingSchemes={loadingSchemes} dataSchemes={dataSchemes} errorSchemes={errorSchemes} />
      )}
      <SavedGiftCardModal onClose={onCloseModal} onSubmit={onSubmitModal} isOpen={openModal} />
    </div>
  );
};
const MemoizedGiftCard = memo(GiftCard);
export default MemoizedGiftCard;
