import React from 'react';
import NumberFormat from 'react-number-format';

const Price = ({ value, prefix = true, suffix = false, ...otherProps }) => {
  return (
    <NumberFormat
      {...otherProps}
      value={Number(value).toFixed(2)}
      displayType={'text'}
      thousandSeparator={' '}
      prefix={prefix ? '$' : ''}
      suffix={suffix ? ' USD' : ''}
      decimalSeparator={'.'}
    />
  );
};

export default React.memo(Price);
