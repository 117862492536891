import React, { useCallback, useState, useEffect } from 'react';
import { useQueryUrlParamsDispatch, useQueryUrlParams } from 'hooks';
import HandoffModeModal from '../../modules/HandoffModeModal/HandoffModeModal';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

const HandoffMode = () => {
  const setQuery = useQueryUrlParamsDispatch();
  const { handoffModeModal } = useQueryUrlParams();
  const [openModal, setOpenModal] = useState(false);
  const parsedHandoffModeModal = handoffModeModal ? !!JSON.parse(handoffModeModal) : false;

  const {
    basket: { data: basketData },
    restaurant: { data: restaurantData },
  } = useAppStateContext();

  const handleCloseModal = useCallback(() => {
    setQuery({}, null, false, ['handoffModeModal']);
  }, [setQuery]);

  useEffect(() => {
    if (!openModal && parsedHandoffModeModal) {
      setOpenModal(true);
    }

    if (openModal && !parsedHandoffModeModal) {
      setOpenModal(false);
    }
  }, [openModal, parsedHandoffModeModal]);

  return (
    <>
      {openModal && basketData && restaurantData && (
        <HandoffModeModal
          isModifyMode
          basket={basketData}
          restaurant={restaurantData}
          isOpen={true}
          onClose={handleCloseModal}
          onSubmit={handleCloseModal}
        />
      )}
    </>
  );
};

export default React.memo(HandoffMode);
