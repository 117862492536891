import React, { memo } from 'react';
import styles from './CheckoutOrderingFrom.module.scss';
import { useRestaurantInfo } from 'components/OrderingFrom/OrderingFrom';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';

const CheckoutOrderingFrom = () => {
  const { dataLoaded, loading, error, shortCityName, zip, streetaddress, telephone } = useRestaurantInfo([
    'streetaddress',
    'zip',
    'telephone',
  ]);
  const errorMessage =
    (error &&
      (error.errorMessage ||
        'Something went wrong... Could not load restaurant location order. Please try again later!')) ||
    '';
  return (
    <>
      {loading && <Spin spinning={loading} />}
      {errorMessage && <Alert message={errorMessage} />}
      {dataLoaded && (
        <div className={styles.wrapper}>
          <h4>{shortCityName}</h4>
          <p>{streetaddress}</p>
          <p>
            {shortCityName} {zip}
          </p>
          <p>Phone: {telephone}</p>
        </div>
      )}
    </>
  );
};
const MemoizedCheckoutOrderingFrom = memo(CheckoutOrderingFrom);
export default MemoizedCheckoutOrderingFrom;
