import React, { useEffect, useReducer } from 'react';
import { applyCouponToBasket } from 'api/applyCouponToBasket';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import showToast from 'components/Toast/ShowToast';
import { validateBasketService } from 'services/validateBasket';
import { useCallback } from 'react';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useApplyCouponFetch = (couponcode) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const { basketId } = useQueryUrlParams();
  const appDispatch = useAppDispatchContext();
  const cleanUpState = useCallback(() => {
    dispatch({ type: FETCH_ACTION_TYPES.cleanState });
  }, []);
  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      if (basketId && couponcode) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const response = await validateBasketService(applyCouponToBasket, basketId, couponcode);
          if (!ignore && response) {
            dispatch({
              type: FETCH_ACTION_TYPES.setData,
              payload: { data: response },
            });
            appDispatch({
              type: ACTION_TYPES.setBasket,
              payload: { data: response },
            });

            const SuccessMsg = () => (
              <span>
                Coupon code <u>{couponcode}</u> applied to the order successfully
              </span>
            );
            showToast('success', <SuccessMsg />);
          }
        } catch (e) {
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
          }
        }
      }
    };
    fetch();
    return () => {
      ignore = true;
    };
  }, [basketId, couponcode, appDispatch]);
  return { ...state, cleanUpState };
};

export default useApplyCouponFetch;
