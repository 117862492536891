import { useEffect, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { getRecentOrders } from 'api/user/orders/getRecentOrders';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useRecentOrdersFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
      try {
        const response = await getRecentOrders();
        const recentOrders = response.orders ? response.orders.slice(0, 3) : [];
        if (!ignore) {
          dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: recentOrders } });
        }
      } catch (e) {
        if (!ignore) {
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
        }
      }
    };

    fetch();

    return () => {
      ignore = true;
    };
  }, []);

  return [state];
};

export default useRecentOrdersFetch;
