import { useEffect } from 'react';
import { useState } from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';

const useQueryBasketId = () => {
  const [currBasketId, setCurrBasketId] = useState(null);
  const setQuery = useQueryUrlParamsDispatch();
  const {
    basket: { data },
  } = useAppStateContext();
  const basketId = data && data.id;
  useEffect(() => {
    if ((basketId && !currBasketId) || (basketId && currBasketId && basketId !== currBasketId)) {
      setCurrBasketId(basketId);
      setQuery({
        basketId: basketId,
      });
    }
  }, [currBasketId, basketId, setQuery]);
};
export default useQueryBasketId;
