import { useCallback, useReducer } from 'react';
import moment from 'moment';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { getRestaurantOperatingHours } from 'api/restaurantOperatingHours';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useRestaurantOperatingHoursFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const getOperatingHours = useCallback(
    (restaurantId, date) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            if (restaurantId) {
              const day = moment(date).toISOString();
              const response = await getRestaurantOperatingHours(restaurantId, {
                from: day,
                to: day,
              });
              const operatingHours = response.calendar
                .filter((item) => !!item.ranges.find((range) => range.start && range.end))
                .map((item) => {
                  const range = item.ranges.find((range) => range.start && range.end);
                  return {
                    type: item.type,
                    start: moment(range.start, 'YYYYMMDD hh:mm'),
                    end: moment(range.end, 'YYYYMMDD hh:mm'),
                  };
                });
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: operatingHours } });
              resolve(operatingHours);
            }
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            showToast('error', e.errorMessage || 'Something went wrong... Could not get restaurant operating hours!');
            reject(e);
          }
        };
        fetch();
      }),
    []
  );

  return { ...state, getOperatingHours };
};

export default useRestaurantOperatingHoursFetch;
