import React, { useReducer } from 'react';
import produce from 'immer';

const ACTION_TYPES = {
  setUserDetails: 'SET_USER_DETAILS',
  setLoadingUserDetails: 'SET_LOADING_USER_DETAILS',
  setReloadingUserDetails: 'SET_RELOADING_USER_DETAILS',
  setErrorUserDetails: 'SET_ERROR_USER_DETAILS',
  setBagsPanelState: 'SET_BAGS_PANEL_STATE',
  setGlobalNotificationState: 'SET_GLOBAL_NOTIFICATION_STATE',
  setLocation: 'SET_LOCATION',
  setMenuSearch: 'SET_MENU_SEARCH',
  setRestaurant: 'SET_RESTAURANT',
  setAutodetectedRestaurant: 'SET_AUTODETECTED_RESTAURANT',
  setRestaurantMenu: 'SET_RESTAURANT_MENU',
  setLoadingRestaurantMenu: 'SET_LOADING_RESTAURANT_MENU',
  setErrorRestaurantMenu: 'SET_ERROR_RESTAURANT_MENU',
  setErrorLocation: 'SET_LOCATION_ERROR',
  setFavoriteOrders: 'SET_FAVORITE_ORDERS',
  loadingFavoriteOrders: 'LOADING_FAVORITE_ORDERS',
  errorFavoriteOrders: 'ERROR_FAVORITE_ORDERS',
  setModifyFavoriteIsInProgress: 'SET_MODIFY_FAVORITE_IS_IN_PROGRESS',
  setBasket: 'SET_BASKET',
  loadingBasket: 'LOADING_BASKET',
  loadingTransferBasket: 'LOADING_TRANSFER_BASKET',
  errorBasket: 'ERROR_BASKET',
  setCurRestaurant: 'SET_CURRENT_RESTAURANT',
  loadingCurRestaurant: 'LOADING_CURRENT_RESTAURANT',
  errorCurRestaurant: 'ERROR_CURRENT_RESTAURANT',
  setCurrRestaurant: 'SET_CURRENT_LOCATION',
  cleanupBasket: 'CLEAN_UP_BASKET',
  cleanupUserDetails: 'CLEAN_UP_USER_DETAILS',
  cleanupMenuSearch: 'CLEAN_UP_MENU_SEARCH',
  cleanupRestaurantMenu: 'CLEAN_UP_MENU',
  cleanupRestaurant: 'CLEAN_UP_RESTAURANT',
  cleanupAutodetectedRestaurant: 'CLEAN_AUTODETECTED_RESTAURANT',
  cleanupFavoriteOrders: 'CLEAN_UP_FAVORITE_ORDERS',
  finishCleanUpBasket: 'FINISH_CLEAN_UP_BASKET',
  setItemsNotTransferred: 'SET_NOT_TRANSFERRED_ITEMS',
  resetItemsNotTransferred: 'RESET_NOT_TRANSFERRED_ITEMS',
  setGroupOrder: 'SET_GROUP_ORDER',
  setLoadingGroupOrder: 'SET_LOADING_GROUP_ORDER',
  setErrorGroupOrder: 'SET_ERROR_GROUP_ORDER',
  cleanupGroupOrder: 'CLEAN_UP_GROUP_ORDER',
  setPaymentData: 'SET_PAYMENT_DATA',
  clearPaymentData: 'CLEAR_PAYMENT_DATA',
  setCreditCardValidation: 'SET_CREDIT_CARD_VALIDATION',
  setIsFetchingTokens: 'SET_IS_FETCHING_TOKENS',
  setIsCash: 'SET_IS_CASH',
  setIsUseSavedCard: 'SET_IS_USE_SAVED_CARD',
  setIsUseCardTouched: 'SET_IS_USE_CARD_TOUCHED',
  setIsSaveBillingMethod: 'SET_IS_SAVE_BILLING_METHOD',
  setBasketPaymentAccessToken: 'SET_BASKET_PAYMENT_ACCESS_TOKEN',
  setIsGiftCardOnlyPayment: 'SET_IS_GIFT_CARD_ONLY_PAYMENT',
  cleanUpPaymentData: 'CLEAN_UP_PAYMENT_DATA',
  setUserBillingAccountsData: 'SET_USER_BILLING_ACCOUNTS_DATA',
  setLoadingUserBillingAccountsData: 'SET_LOADING_USER_BILLING_ACCOUNTS_DATA',
  setErrorUserBillingAccountsData: 'SET_ERROR_USER_BILLING_ACCOUNTS_DATA',
  cleanUpUserBillingAccountsData: 'CLEAN_UP_USER_BILLING_ACCOUNTS_DATA',
  setGiftCard: 'SET_GIFT_CARD',
  loadingGiftCard: 'LOADING_GIFT_CARD',
  errorGiftCard: 'ERROR_GIFT_CARD',
  cleanUpGiftCard: 'ClEAN_UP_GIFT_CARD',
  setFavoriteOloRestaurant: 'SET_FAVORITE_OLO_RESTAURANT',
  setLoadingFavoriteOloRestaurant: 'SET_LOADING_FAVORITE_OLO_RESTAURANT',
  setErrorFavoriteOloRestaurant: 'SET_ERROR_FAVORITE_OLO_RESTAURANT',
  cleanUpFavoriteOloRestaurant: 'CLEAN_UP_FAVORITE_OLO_RESTAURANT',
  setSavedPaymentsAccounts: 'SET_SAVED_PAYMENTS_ACCOUNTS',
  resetSavedPaymentsAccounts: 'RESET_SAVED_PAYMENTS_ACCOUNTS',
  setSelectedSavedUserGiftCard: 'SET_SELECTED_SAVED_USER_GIFT_CARDS',
  cleanUpSelectedSavedUserGiftCard: 'CLEAN_UP_SELECTED_SAVED_USER_GIFT_CARDS',
  cleanUpUserSelectedBillingAccounts: 'CLEAN_UP_SELECTED_BILLING_ACCOUNT',
};

const INITIAL_PAYMENT_DATA = {
  data: {
    expiry: '',
    expiryMonth: '',
    expiryYear: '',
    name: '',
    zip: '',
  },
  isFetchingTokens: false,
  isCash: false,
  isGiftCardOnlyPayment: false,
  isUseSavedCard: false,
  isUseCardTouched: false,
  basketPaymentAccessToken: null,
  validation: {
    isValidCardNumber: false,
    isValidCvv: false,
    isValidExpiry: false,
    isValidName: false,
    isValidZip: false,
  },
};

//location object is long and lat user coords
//restaurant object is selected place for order
export const defaultState = {
  user: {
    data: null,
    loading: false,
    reloading: false,
    error: null,
  },
  authenticated: false,
  basket: {
    data: null,
    loading: false,
    error: null,
    isBasketResetting: false,
  },
  groupOrder: {
    data: null,
    loading: false,
    error: null,
  },
  notTransferredBasketItems: null,
  favoriteOrders: null,
  modifyFavoriteIsInProgress: false,
  modifyGroupOrderIsInProgress: false,
  location: {
    coords: null,
    error: false,
  },
  globalNotificationIsShow: false,
  menuSearchValue: '',
  restaurant: {
    data: null,
    loading: false,
    error: null,
  },
  autodetectedRestaurant: {
    data: null,
  },
  restaurantMenu: {
    data: null,
    loading: false,
    error: null,
  },
  paymentData: INITIAL_PAYMENT_DATA,
  savedPaymentsAccounts: {
    creditCard: false,
    giftCard: false,
  },
  giftCard: {
    data: null,
    loading: false,
    error: null,
  },
  savedUserGiftCards: {
    selectedSavedGiftCard: null,
  },
  userBillingAccounts: {
    billingAccounts: null,
    error: null,
    loading: false,
    defaultBillingAccount: {
      cardNumber: null,
      cardType: null,
      expiryMonth: null,
      expiryYear: null,
      id: null,
      isDefault: null,
      isRemovable: null,
      name: null,
    },
    selectedBillingAccount: {
      cardNumber: null,
      cardType: null,
      expiryMonth: null,
      expiryYear: null,
      id: null,
      isDefault: null,
      isRemovable: null,
      name: null,
    },
  },
  favoriteOloRestaurant: {
    data: null,
    loading: false,
    error: null,
  },
};

const DispatchContext = React.createContext({});
const StateContext = React.createContext({});

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPES.setUserDetails: {
        const { data } = action.payload;
        draft.user = { data: { ...data }, error: null, loading: false, reloading: false };
        draft.authenticated = !!data;
        return draft;
      }
      case ACTION_TYPES.setLoadingUserDetails: {
        const { loading } = action.payload;
        draft.user = { ...draft.user, error: null, loading };

        return draft;
      }
      case ACTION_TYPES.setReloadingUserDetails: {
        const { reloading } = action.payload;
        draft.user = { ...draft.user, error: null, loading: reloading, reloading };

        return draft;
      }
      case ACTION_TYPES.setErrorUserDetails: {
        const { error } = action.payload;
        draft.user = { ...draft.user, error, loading: false, reloading: false };

        return draft;
      }
      case ACTION_TYPES.cleanupUserDetails: {
        draft.authenticated = false;
        draft.user = {
          data: null,
          loading: false,
          reloading: false,
          error: null,
        };

        return draft;
      }
      case ACTION_TYPES.cleanupRestaurant: {
        draft.restaurant = {
          data: null,
          loading: false,
          error: null,
        };

        return draft;
      }
      case ACTION_TYPES.cleanupAutodetectedRestaurant: {
        draft.autodetectedRestaurant = {
          data: null,
        };

        return draft;
      }
      case ACTION_TYPES.setRestaurant: {
        const restaurant = action.payload;
        draft.restaurant = restaurant ? { ...restaurant } : null;
        if (draft.restaurantMenu.data) {
          draft.restaurantMenu = { data: null, loading: false, error: false };
        }
        return draft;
      }
      case ACTION_TYPES.setAutodetectedRestaurant: {
        const restaurant = action.payload;
        draft.autodetectedRestaurant = restaurant ? { ...restaurant } : null;
        return draft;
      }
      case ACTION_TYPES.setLocation: {
        const location = action.payload;
        draft.location = location ? { coords: { ...location }, error: false } : null;

        return draft;
      }
      case ACTION_TYPES.setItemsNotTransferred: {
        const notTransferredItems = action.payload;
        draft.notTransferredBasketItems = notTransferredItems;

        return draft;
      }
      case ACTION_TYPES.resetItemsNotTransferred: {
        draft.notTransferredBasketItems = defaultState.notTransferredBasketItems;
        return draft;
      }
      case ACTION_TYPES.setErrorLocation: {
        const locationError = action.payload;
        draft.basketItemsNotTransferred = locationError ? { coords: null, error: locationError } : null;

        return draft;
      }
      case ACTION_TYPES.setBagsPanelState: {
        const { isOpen } = action.payload;
        draft.basketIsOpen = isOpen;

        return draft;
      }
      case ACTION_TYPES.setGlobalNotificationState: {
        const { isShow } = action.payload;
        draft.globalNotificationIsShow = isShow;

        return draft;
      }
      case ACTION_TYPES.setFavoriteOrders: {
        const { data } = action.payload;
        draft.favoriteOrders = { data: [...data], error: null, loading: false };

        return draft;
      }
      case ACTION_TYPES.loadingFavoriteOrders: {
        const { loading } = action.payload;
        draft.favoriteOrders = { ...draft.favoriteOrders, loading: loading, error: null };

        return draft;
      }
      case ACTION_TYPES.errorFavoriteOrders: {
        const { error } = action.payload;
        draft.favoriteOrders = { ...draft.favoriteOrders, error, loading: false };

        return draft;
      }
      case ACTION_TYPES.cleanupFavoriteOrders: {
        draft.favoriteOrders = null;

        return draft;
      }
      case ACTION_TYPES.setModifyFavoriteIsInProgress: {
        const { data } = action.payload;
        draft.modifyFavoriteIsInProgress = data;

        return draft;
      }
      case ACTION_TYPES.setBasket: {
        const { data } = action.payload;
        draft.basket = { ...draft.basket, data: { ...data }, error: null, loading: false };

        return draft;
      }
      case ACTION_TYPES.errorBasket: {
        const { error } = action.payload;
        draft.basket = { ...draft.basket, error, loading: false };

        return draft;
      }
      case ACTION_TYPES.loadingBasket: {
        const { loading } = action.payload;
        draft.basket = { ...draft.basket, loading: loading, error: null };

        return draft;
      }
      case ACTION_TYPES.cleanupBasket: {
        draft.basket = {
          data: null,
          loading: false,
          error: null,
          isBasketResetting: true,
        };

        return draft;
      }
      case ACTION_TYPES.finishCleanUpBasket: {
        draft.basket.isBasketResetting = false;

        return draft;
      }
      case ACTION_TYPES.loadingTransferBasket: {
        const { loading } = action.payload;
        draft.basket = { ...draft.basket, data: null, loading: loading, error: null };

        return draft;
      }
      case ACTION_TYPES.setCurRestaurant: {
        const { data } = action.payload;
        draft.restaurant = { data: { ...data }, error: null, loading: false };

        return draft;
      }
      case ACTION_TYPES.errorCurRestaurant: {
        const { error } = action.payload;
        draft.restaurant = { ...draft.restaurant, error, loading: false };

        return draft;
      }
      case ACTION_TYPES.loadingCurRestaurant: {
        const { loading } = action.payload;
        draft.restaurant = { ...draft.restaurant, loading: loading, error: null };

        return draft;
      }
      case ACTION_TYPES.setMenuSearch: {
        const { value } = action.payload;

        draft.menuSearchValue = value;

        return draft;
      }
      case ACTION_TYPES.cleanupMenuSearch: {
        draft.menuSearchValue = '';
        return draft;
      }
      case ACTION_TYPES.setRestaurantMenu: {
        const { data } = action.payload;
        draft.restaurantMenu = { data: data, loading: false, error: false };

        return draft;
      }
      case ACTION_TYPES.setLoadingRestaurantMenu: {
        const { loading } = action.payload;
        draft.restaurantMenu = { ...draft.restaurantMenu, loading: loading, error: false };

        return draft;
      }
      case ACTION_TYPES.setErrorRestaurantMenu: {
        const { error } = action.payload;

        draft.restaurantMenu = { ...draft.restaurantMenu, loading: false, error: error };

        return draft;
      }
      case ACTION_TYPES.cleanupRestaurantMenu: {
        draft.restaurantMenu = {
          data: null,
          loading: false,
          error: null,
        };

        return draft;
      }
      case ACTION_TYPES.setLoadingGroupOrder: {
        const { loading } = action.payload;
        draft.groupOrder = { ...draft.groupOrder, loading: loading, error: null };
        return draft;
      }
      case ACTION_TYPES.setErrorGroupOrder: {
        const { error } = action.payload;
        draft.groupOrder = { ...draft.groupOrder, loading: false, error: error };
        return draft;
      }
      case ACTION_TYPES.setGroupOrder: {
        const { data } = action.payload;
        draft.groupOrder = { data: data, loading: false, error: null };
        return draft;
      }
      case ACTION_TYPES.cleanupGroupOrder: {
        draft.groupOrder = { data: null, loading: false, error: null };
        draft.basket = { ...draft.basket, data: null, error: null, loading: false };
        return draft;
      }
      case ACTION_TYPES.setPaymentData:
        draft.paymentData = {
          ...state.paymentData,
          data: { ...state.paymentData.data, ...action.payload },
        };

        return draft;
      case ACTION_TYPES.clearPaymentData:
        draft.paymentData = {
          ...state.paymentData,
          data: INITIAL_PAYMENT_DATA.data,
        };

        return draft;
      case ACTION_TYPES.setCreditCardValidation:
        draft.paymentData = {
          ...state.paymentData,
          validation: {
            ...draft.paymentData.validation,
            ...action.payload,
          },
        };

        return draft;
      case ACTION_TYPES.setIsFetchingTokens:
        draft.paymentData = { ...state.paymentData, isFetchingTokens: action.payload };

        return draft;
      case ACTION_TYPES.setIsCash:
        draft.paymentData = { ...state.paymentData, isCash: action.payload };

        return draft;
      case ACTION_TYPES.setIsGiftCardOnlyPayment:
        draft.paymentData = { ...state.paymentData, isGiftCardOnlyPayment: action.payload };

        return draft;
      case ACTION_TYPES.setIsUseSavedCard:
        draft.paymentData = { ...state.paymentData, isUseSavedCard: action.payload };

        return draft;
      case ACTION_TYPES.setIsUseCardTouched:
        draft.paymentData = { ...state.paymentData, isUseCardTouched: action.payload };

        return draft;
      case ACTION_TYPES.setBasketPaymentAccessToken:
        draft.paymentData = { ...state.paymentData, basketPaymentAccessToken: action.payload };

        return draft;
      case ACTION_TYPES.cleanUpPaymentData:
        draft.paymentData = { ...defaultState.paymentData };

        return draft;
      case ACTION_TYPES.setUserBillingAccountsData: {
        draft.userBillingAccounts = {
          ...state.userBillingAccounts,
          loading: false,
          error: false,
          ...action.payload,
        };

        return draft;
      }
      case ACTION_TYPES.cleanUpUserSelectedBillingAccounts: {
        draft.userBillingAccounts = {
          ...state.userBillingAccounts,
          loading: false,
          error: false,
          selectedBillingAccount: {
            cardNumber: null,
            cardType: null,
            expiryMonth: null,
            expiryYear: null,
            id: null,
            isDefault: null,
            isRemovable: null,
            name: null,
          },
        };

        return draft;
      }
      case ACTION_TYPES.setLoadingUserBillingAccountsData: {
        const { loading } = action.payload;
        draft.userBillingAccounts = {
          ...state.userBillingAccounts,
          error: false,
          loading: loading,
        };

        return draft;
      }
      case ACTION_TYPES.setErrorUserBillingAccountsData: {
        const { error } = action.payload;
        draft.userBillingAccounts = {
          ...state.userBillingAccounts,
          error: error,
          loading: false,
        };

        return draft;
      }
      case ACTION_TYPES.cleanUpUserBillingAccountsData: {
        draft.userBillingAccounts = { ...defaultState.userBillingAccounts };
        return draft;
      }

      case ACTION_TYPES.setGiftCard: {
        const { data } = action.payload;
        draft.giftCard = { data: { ...data }, error: null, loading: false };

        return draft;
      }
      case ACTION_TYPES.errorGiftCard: {
        const { error } = action.payload;
        draft.giftCard = { ...draft.giftCard, error, loading: false };

        return draft;
      }
      case ACTION_TYPES.loadingGiftCard: {
        const { loading } = action.payload;
        draft.giftCard = { ...draft.giftCard, loading: loading, error: null };

        return draft;
      }
      case ACTION_TYPES.cleanUpGiftCard: {
        draft.giftCard = { ...defaultState.giftCard };

        return draft;
      }
      case ACTION_TYPES.setLoadingFavoriteOloRestaurant: {
        const { loading } = action.payload;
        draft.favoriteOloRestaurant = { ...draft.favoriteOloRestaurant, loading: loading, error: null };
        return draft;
      }
      case ACTION_TYPES.setErrorFavoriteOloRestaurant: {
        const { error } = action.payload;
        draft.favoriteOloRestaurant = { ...draft.favoriteOloRestaurant, loading: false, error: error };
        return draft;
      }
      case ACTION_TYPES.setFavoriteOloRestaurant: {
        const { data } = action.payload;
        draft.favoriteOloRestaurant = { data: data, loading: false, error: null };
        return draft;
      }
      case ACTION_TYPES.cleanUpFavoriteOloRestaurant: {
        draft.favoriteOloRestaurant = { data: null, loading: false, error: null };
        return draft;
      }
      case ACTION_TYPES.setSavedPaymentsAccounts: {
        const value = action.payload;
        draft.savedPaymentsAccounts = { ...draft.savedPaymentsAccounts, ...value };
        return draft;
      }
      case ACTION_TYPES.resetSavedPaymentsAccounts: {
        draft.savedPaymentsAccounts = { ...defaultState.savedPaymentsAccounts };
        return draft;
      }
      case ACTION_TYPES.setSelectedSavedUserGiftCard: {
        const giftCard = action.payload;
        draft.savedUserGiftCards = { ...draft.savedUserGiftCards, selectedSavedGiftCard: giftCard };
        return draft;
      }
      case ACTION_TYPES.cleanUpSelectedSavedUserGiftCard: {
        draft.savedUserGiftCards = {
          ...draft.savedUserGiftCards,
          selectedSavedGiftCard: null,
        };
        return draft;
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  });

export const DispatchProvider = DispatchContext.Provider;
export const StateProvider = StateContext.Provider;

const AppStateProvider = React.memo(({ children }) => {
  const initState = {
    ...defaultState,
  };

  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <DispatchProvider value={dispatch}>
      <StateProvider value={state}>{children} </StateProvider>
    </DispatchProvider>
  );
});

AppStateProvider.displayName = 'AppStateProvider';

export { AppStateProvider, DispatchContext, StateContext, ACTION_TYPES };
