import { useEffect } from 'react';
import { getBasketInfo } from 'api/getBasketInfo';
import { useAppStateContext, useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useQueryUrlParams } from 'hooks';

const useBasketInfo = () => {
  const { basketId } = useQueryUrlParams();
  const appDispatch = useAppDispatchContext();
  const { basket } = useAppStateContext();
  const data = basket && basket.data;
  const loading = basket && basket.loading;
  const error = basket && basket.error;
  const isBasketResetting = basket && basket.isBasketResetting;
  useEffect(() => {
    const fetch = async () => {
      if (basketId && !isBasketResetting && !loading && !error && !data) {
        appDispatch({ type: ACTION_TYPES.loadingBasket, payload: { loading: true } });
        try {
          const response = await getBasketInfo(basketId);
          appDispatch({ type: ACTION_TYPES.setBasket, payload: { data: response } });
        } catch (e) {
          appDispatch({ type: ACTION_TYPES.errorBasket, payload: { error: e } });
        }
      }
    };
    fetch();
  }, [basketId, appDispatch, data, error, loading, isBasketResetting]);
  return basket;
};

export default useBasketInfo;
