import React, { useMemo } from 'react';
import flatMap from 'lodash/flatMap';

import styles from './AccountVisits.module.scss';
import logo from 'assets/x-small-logo.svg';

const MAX_VISITS = 3;

function AccountVisits({ visitsBalance = {} }) {
  const { balance: totalBalance = 0 } = visitsBalance;

  const balance = useMemo(() => totalBalance % MAX_VISITS, [totalBalance]);

  const remainingVisits = useMemo(() => MAX_VISITS - balance, [balance]);

  const markedSteps = useMemo(
    () =>
      new Array(MAX_VISITS).fill(null).map((_, index) => (
        <div key={index} className={index + 1 > balance ? styles.step_wrapper : styles.step_wrapper_active}>
          <img src={logo} alt={`Visit ${index + 1}`} />
        </div>
      )),
    [balance]
  );

  const Steps = useMemo(() => {
    const separator = <div className={styles.separator}></div>;

    return flatMap(markedSteps, (value, index, array) => (array.length - 1 !== index ? [value, separator] : value));
  }, [markedSteps]);

  return (
    <div className={styles.visits_container}>
      <h3 className={styles.visits_title}>CHECK IN AND EARN REWARDS</h3>
      <p className={styles.visits_subtitle}>Almost there, {remainingVisits} visits until your next Reward</p>
      <div className={styles.visits_stepper}>{Steps}</div>
    </div>
  );
}

export default React.memo(AccountVisits);
