import axios from './axiosInstances/axiosInstance';

export const resetPasswordByEmail = (email) => {
  return axios.post('/users/sendResetPasswordCode', { username: email });
};

export const setNewPassword = (resetCode, newPassword) => {
  const data = {
    resetPasswordCode: resetCode,
    newPassword: newPassword,
  };
  return axios.post('users/resetPassword', data);
};
