import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';
import ProductImage from 'components/ProductImage/ProductImage';
import Price from 'components/Price/Price';
import ProductCalories from 'components/ProductCalories/ProductCalories';
import styles from './MenuItemCard.module.scss';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import useAddingProductToBasket from 'modules/ProductDetailsModal/hooks/useAddingProductToBasket';
import showToast from 'components/Toast/ShowToast';

const MenuItemCard = ({
  imagepath,
  images,
  name,
  description,
  cost,
  basecalories,
  caloriesseparator,
  maxcalories,
  quickaddavailable,
  id,
  isHorizontalView = false,
  className,
  onClick,
  product,
}) => {
  const { groupOrderId } = useQueryUrlParams();
  const [{ loading: adding, data: basketData, error: addingError }, { addProduct }] = useAddingProductToBasket();

  const onClickItem = useCallback(() => onClick(product), [onClick, product]);
  const onClickQuickAdd = useCallback(
    (e) => {
      e.stopPropagation();
      !adding && addProduct(id, '', 1);
    },
    [addProduct, id, adding]
  );

  useEffect(() => {
    if (addingError) {
      showToast('error', 'Something went wrong... Could not add product to basket. Please try again later!');
    }
  }, [addingError]);

  useEffect(() => {
    if (basketData) {
      showToast('success', 'Item is added to your bag!');
    }
  }, [basketData]);

  return (
    <div className={classNames(styles.product_card_wrapper, { [styles.horizontal_view]: isHorizontalView })}>
      <div className={classNames(styles.product_card, className)} onClick={onClickItem}>
        {!groupOrderId && quickaddavailable && (
          <div className={classNames(styles.plus_button, { [styles.disabled]: adding })} onClick={onClickQuickAdd}>
            +
          </div>
        )}
        <ProductImage imagepath={imagepath} images={images} alt={name} className={styles.product_image} />
        <div className={styles.product_details}>
          <h3 className={styles.name}>{name}</h3>
          <div className={styles.cost_calories_wrapper}>
            <Price value={cost} />
            <ProductCalories
              basecalories={basecalories}
              caloriesseparator={caloriesseparator}
              maxcalories={maxcalories}
            />
          </div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MenuItemCard);
