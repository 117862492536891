import React, { Fragment } from 'react';
import { RadioGroup } from '@material-ui/core';

import ChooseButton from 'components/ChooseButton/ChooseButton';
import './AvailableRewardsList.scss';

const AvailableRewardsList = ({ data, handleAddReward, handleRemoveReward }) => {
  const handleActionClick = (reward) => {
    if (reward.applied) {
      return handleRemoveReward(reward);
    }

    return handleAddReward(reward);
  };

  return (
    <RadioGroup className="available_rewards_list_conatiner">
      {data.map((rewardItem, index) => (
        <Fragment key={index}>
          <ChooseButton
            classes={{
              label: !!rewardItem.applied ? 'choose_btn_label_applied' : '',
            }}
            handleClick={() => handleActionClick(rewardItem)}
            checked={!!rewardItem.applied}
            label={rewardItem.label}
            description={rewardItem.description}
          />
        </Fragment>
      ))}
    </RadioGroup>
  );
};

export default AvailableRewardsList;
