import React, { useCallback } from 'react';
import styles from './ThanksSection.module.scss';
import Link from 'components/Link/Link';
import { PrimaryButton } from 'components/Button/Button';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useParams } from 'react-router';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useQueryUrlParams, useQueryUrlParamsDispatch } from 'hooks';

const ThanksSection = () => {
  const { orderId, restaurantId } = useParams();
  const { isFavorite } = useQueryUrlParams();
  const queryDispatch = useQueryUrlParamsDispatch();
  const parsedIsFavorite = isFavorite && JSON.parse(isFavorite);
  const { authenticated } = useAppStateContext();
  const guestOrderId = window.sessionStorage.getItem('guestOrderId');

  const handleSaveFavoriteClick = useCallback(() => {
    queryDispatch({}, `/${restaurantId}/createfave/${orderId}`);
  }, [queryDispatch, restaurantId, orderId]);

  const icon = parsedIsFavorite ? <StarIcon /> : <StarBorderIcon />;
  const SaveAsFavoriteBtn = () => (
    <PrimaryButton size="large" disabled={parsedIsFavorite} endIcon={icon} onClick={handleSaveFavoriteClick}>
      {parsedIsFavorite ? 'FAVORITE SAVED!' : 'SAVE AS FAVORITE'}
    </PrimaryButton>
  );
  // only user who submitted order can save it as favorite
  const canSaveAsFavorite = !guestOrderId || guestOrderId !== orderId;

  return (
    <div className={styles.wrapper}>
      <h2>Thank you for your order!</h2>
      <p>
        Have feedback about your order experience? We’d love to hear from you.{' '}
        <Link target="_blank" href={process.env.REACT_APP_PATH_FEEDBACK} className={styles.link} color={'primary'}>
          Submit your feedback
        </Link>
      </p>
      {authenticated && canSaveAsFavorite && (
        <div className={styles.button}>
          <SaveAsFavoriteBtn />
        </div>
      )}
    </div>
  );
};
export default React.memo(ThanksSection);
