import React, { memo } from 'react';
import classNames from 'classnames';
import './NotFoundLogo.scss';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

const NotFoundLogo = ({ className }) => {
  return <PrimaryHeader className={classNames('not_found_title', className)} size="extra" title="OH, NO!" />;
};
const MemoizedNotFoundLogo = memo(NotFoundLogo);
export default MemoizedNotFoundLogo;
