import { useEffect } from 'react';
import { getRestaurantById } from 'api/restaurantById';
import { useParams } from 'react-router';
import { useAppStateContext, useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useQueryUrlParams } from 'hooks';

const useRestaurantByIdFetch = () => {
  const { restaurantId } = useParams();
  const { restaurantId: queryResId } = useQueryUrlParams();
  const {
    restaurant: { data, loading, error },
  } = useAppStateContext();
  const appDispatch = useAppDispatchContext();
  useEffect(() => {
    const fetch = async () => {
      if ((restaurantId || queryResId) && !loading && !error && !data) {
        appDispatch({ type: ACTION_TYPES.loadingCurRestaurant, payload: { loading: true } });
        try {
          const response = await getRestaurantById(restaurantId || queryResId);
          appDispatch({ type: ACTION_TYPES.setCurRestaurant, payload: { data: response } });
        } catch (e) {
          appDispatch({ type: ACTION_TYPES.errorCurRestaurant, payload: { error: e } });
        }
      }
    };
    fetch();
  }, [restaurantId, appDispatch, loading, data, error, queryResId]);
  return data;
};

export default useRestaurantByIdFetch;
