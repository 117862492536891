import React, { memo } from 'react';
import classNames from 'classnames/bind';
import MuiLink from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import './Link.scss';

const Link = ({
  children,
  uppercase = true,
  underline = false,
  routing = false,
  isGothic = false,
  to,
  className,
  color = 'secondary',
  variant,
  ...props
}) =>
  routing ? (
    to ? (
      <RouterLink
        className={classNames(
          'router_link',
          {
            primary: color === 'primary',
            underline: underline,
            uppercase: uppercase,
            isGothic: isGothic,
          },
          className
        )}
        to={to}
        {...props}
      >
        {children}
      </RouterLink>
    ) : (
      <MuiLink
        className={classNames(
          'router_link',
          { primary: color === 'primary', underline: underline, isGothic: isGothic },
          className
        )}
        variant={variant}
        color={'inherit'}
        {...props}
      >
        {children}
      </MuiLink>
    )
  ) : (
    <MuiLink
      className={classNames(
        'extend_link',
        { primary: color === 'primary', underline: underline, isGothic: isGothic },
        className
      )}
      {...props}
      color={color ? color : 'inherit'}
      variant={variant}
    >
      {children}
    </MuiLink>
  );

const MemoizedLink = memo(Link);
export default MemoizedLink;
