import React, { useCallback, useEffect } from 'react';
import { useQueryUrlParamsDispatch } from 'hooks';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { DEADLINE_DATE, DEADLINE_DATE_OLO, ORDER_DEADLINE_OLO } from 'enums/orderDeadline';

import MomentUtils from '@date-io/moment';

import ModalWindow from 'components/Modal/Modal';
import showToast from 'components/Toast/ShowToast';

import styles from './WelcomeGroupOrderModal.module.scss';

const moment = new MomentUtils();

const WelcomeGroupOrderModal = () => {
  const { joinGroup, owner } = useQueryUrlParams();
  const parsedJoinGroup = joinGroup ? !!JSON.parse(joinGroup) : false;
  const parsedOwner = owner ? !!JSON.parse(owner) : false;
  const setQuery = useQueryUrlParamsDispatch();
  const {
    groupOrder: { data, error },
  } = useAppStateContext();
  const { deadline, ownername, isopen } = data || {};
  const expired = data !== null && moment.moment(deadline, ORDER_DEADLINE_OLO).isBefore(moment.moment());
  const showModal = parsedJoinGroup && !!isopen && !expired;

  const splitted = deadline ? deadline.split(' ') : [];
  const date = splitted ? moment.moment(splitted[0], DEADLINE_DATE_OLO).format(DEADLINE_DATE) : '';
  const time = splitted && splitted[1] ? moment.moment(splitted[1], 'HH:mm').format('hh:mm A') : '';

  useEffect(() => {
    if (((isopen !== undefined && !isopen) || error) && !parsedOwner && parsedJoinGroup) {
      setQuery({}, '', false, ['joinGroup', 'groupOrderId', 'basketId']);
    }
  }, [isopen, setQuery, error, parsedOwner, parsedJoinGroup]);

  const handleCloseModal = useCallback(() => {
    setQuery({}, '', false, ['joinGroup']);
  }, [setQuery]);

  const groupOrderError =
    (error &&
      (error.errorMessage ||
        'Something went wrong... Could not get group order information. Please try again later!')) ||
    '';

  useEffect(() => {
    if (groupOrderError) {
      showToast('error', groupOrderError);
    }
  }, [groupOrderError]);

  return (
    <ModalWindow
      fullscreenSize={1024}
      open={showModal}
      maxWidth="md"
      onChange={handleCloseModal}
      headerTitle={'Group Order'}
      headerSize={'lg'}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>Welcome to this group order, hosted by {ownername}.</h2>
        <figure className={styles.instructions_wrapper}>
          <figcaption>Your instructions:</figcaption>
          <ul className={styles.list}>
            <li className={styles.list_item}>Add your items</li>
            <li className={styles.list_item}>Be sure to include your unique name on each item</li>
            <li className={styles.list_item}>
              Submit your order before <b>{time}</b> on <b>{date}</b>
            </li>
          </ul>
        </figure>
        <p className={styles.description}>
          That&lsquo;s it! By adding your items your name is reported as ready to the host, so when you are done your
          can close your browser window.
        </p>
      </div>
    </ModalWindow>
  );
};

export default React.memo(WelcomeGroupOrderModal);
