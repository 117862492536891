import React, { useCallback } from 'react';
import { useQueryUrlParamsDispatch } from 'hooks';

import DrawerLayout from 'components/DrawerLayout/DrawerLayout';
import { TextButton } from 'components/Button/Button';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

import { ForgotPasswordForm } from '../Forms';
import styles from './ForgotPasswordPanel.module.scss';

const ForgotPasswordPanel = ({ open, onClose }) => {
  const setQuery = useQueryUrlParamsDispatch();

  const handleSignIn = useCallback(() => {
    setQuery(
      {
        signin: true,
      },
      '',
      false,
      ['forgotPassword', 'signup']
    );
  }, [setQuery]);

  const handleSignUp = useCallback(() => {
    setQuery(
      {
        signup: true,
      },
      '',
      false,
      ['forgotPassword', 'signin']
    );
  }, [setQuery]);

  return (
    <DrawerLayout
      anchor={'right'}
      open={open}
      onChange={onClose}
      header={<PrimaryHeader title={'Forgot Password'} size={'lg'} brush={'after'} />}
      footerChildren={<TextButton onClick={onClose}>Cancel</TextButton>}
      transparentFooter
    >
      <div className={styles.form_container}>
        <ForgotPasswordForm onSignIn={handleSignIn} onSignUp={handleSignUp} />
      </div>
    </DrawerLayout>
  );
};

export default ForgotPasswordPanel;
