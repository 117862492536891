import React, { useCallback } from 'react';
import { useQueryUrlParamsDispatch } from 'hooks';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

import CheckIcon from '@material-ui/icons/Check';

import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import NotFoundLogo from 'components/NotFoundLogo/NotFoundLogo';

import styles from './NotSupportedGroupOrderModal.module.scss';

const NotSupportedGroupOrderModal = () => {
  const setQuery = useQueryUrlParamsDispatch();
  const {
    restaurant: { data },
    basket: { data: basketData },
  } = useAppStateContext();
  const { id } = data || {};

  const handleCloseModal = useCallback(() => {
    setQuery({}, '', false, ['groupOrderModal']);
  }, [setQuery]);

  const handleChangeLocation = useCallback(() => {
    setQuery({ location: true }, `/`, false, ['groupOrderModal']);
  }, [setQuery]);

  const message =
    id && basketData
      ? 'This location does not support group ordering. Please select a different location'
      : 'Please select location first';

  const buttonText = id ? 'Change location' : 'Select location';

  return (
    <ModalWindow
      fullscreenSize={1024}
      maxWidth="sm"
      open={true}
      onChange={handleCloseModal}
      footerChildren={
        <div className={styles.footer}>
          <PrimaryButton
            className={styles.button}
            endIcon={<CheckIcon />}
            size={'large'}
            onClick={handleChangeLocation}
          >
            {buttonText}
          </PrimaryButton>
        </div>
      }
    >
      <div className={styles.content}>
        <NotFoundLogo />
        <p className={styles.description}>{message}</p>
      </div>
    </ModalWindow>
  );
};

export default NotSupportedGroupOrderModal;
