import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useQueryUrlParamsDispatch } from 'hooks';

import CheckIcon from '@material-ui/icons/Check';

import Section from 'components/Section/Section';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';
import { InputField } from 'components/Input/Input';
import { PrimaryButton } from 'components/Button/Button';
import showToast from 'components/Toast/ShowToast';
import OrderSummaryList from 'components/OrderSummaryList/OrderSummaryList';

import useCreateBasketFromFavoriteFetch from './hooks/useCreateBasketFromFavoriteFetch';
import useSetOrderAsFavorite from './hooks/useSetOrderAsFavorite';

import styles from './CreateFaveOrder.module.scss';

const CreateFaveOrder = () => {
  const { orderId, restaurantId } = useParams();
  const setQuery = useQueryUrlParamsDispatch();
  const { authenticated } = useAppStateContext();
  const { data: basket, loading: basketLoading, error: basketError } = useCreateBasketFromFavoriteFetch(
    orderId,
    authenticated
  );
  const [{ loading: favesLoading, error: favesError }, { request }] = useSetOrderAsFavorite();
  const [isTouched, setIsTouched] = useState(false);
  const [name, setName] = useState();

  const saveFavorite = useCallback(() => {
    if (name.length === 0) {
      setIsTouched(true);
      return;
    }

    request(basket.id, name).then(() => {
      const SuccessMsg = () => (
        <span>
          Favorite order <u>{name}</u> created successfully
        </span>
      );

      showToast('success', <SuccessMsg />);
      setQuery({ isFavorite: true }, `/${restaurantId}/order/${orderId}`);
    });
  }, [setQuery, request, restaurantId, orderId, basket, name]);

  const onChange = (e) => {
    const value = e.target.value.trim();
    setName(value);
  };
  const onBlur = (e) => {
    const value = e.target.value.trim();
    setName(value);
    setIsTouched(true);
  };

  const hasData = !basketLoading && !basketError && basket;

  const basketErrorMessage =
    (basketError &&
      (basketError.errorMessage || 'Something went wrong... Could not load order summary. Please try again later!')) ||
    '';
  const favesErrorMessage =
    (favesError &&
      (favesError.errorMessage ||
        'Something went wrong... Could not save order as favorite. Please try again later!')) ||
    '';

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.body}>
          {basketErrorMessage && <Alert message={basketErrorMessage} />}
          {favesErrorMessage && <Alert message={favesErrorMessage} />}
          {basketLoading && <Spin spinning={basketLoading} />}
          {hasData && (
            <>
              <Section sectionHr title="NAME YOUR FAVORITE">
                <InputField
                  disabled={favesLoading}
                  className={styles.input_container}
                  defaultValue={name}
                  onChange={onChange}
                  inputProps={{
                    maxLength: 24,
                  }}
                  onBlur={onBlur}
                  error={isTouched && name.length === 0}
                  helperText={isTouched && name.length === 0 ? 'Please enter favorite nickname' : ''}
                  label={'Nickname for this favorite *'}
                />
              </Section>
              <Section sectionHr title="ORDER SUMMARY">
                <OrderSummaryList skipTotal data={basket} />
              </Section>
            </>
          )}
        </div>
        {hasData && (
          <div className={styles.footer}>
            <PrimaryButton
              size="large"
              className={styles.save_btn}
              disabled={basketLoading || favesLoading || !name}
              onClick={saveFavorite}
              endIcon={<CheckIcon />}
            >
              {favesLoading && <Spin spinning={favesLoading} className={styles.spinner} />}
              SAVE FAVORITE
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CreateFaveOrder);
