import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import classNames from 'classnames';
import './Spin.scss';

const Spin = ({ spinning = false, color = 'primary', className, size }) => {
  return spinning ? (
    <span className={classNames('spin_container', className)}>
      <CircularProgress size={size} color={color} />
    </span>
  ) : null;
};

export default React.memo(Spin);
