import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import { isMacOs } from 'react-device-detect';
import classNames from 'classnames';
import './Button.scss';

const TextButton = memo(({ children, ...otherProps }) => {
  return (
    <Button
      disableRipple
      variant={'text'}
      color={'primary'}
      role={'button'}
      classes={{
        root: 'btn_primary_text',
        label: 'btn_primary_text_label',
        sizeSmall: 'btn_primary_text_sizeSmall',
        sizeLarge: 'btn_primary_text_sizeLarge',
        disabled: 'btn_primary_text_disabled',
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
});
TextButton.displayName = 'TextButton';

const PrimaryIconButton = memo(({ children, color = 'primary', size = 'medium', ...otherProps }) => {
  return (
    <IconButton
      disableRipple
      variant={'text'}
      color={color}
      size={size}
      classes={{
        root: `btn_icon ${color}`,
        label: 'btn_icon_label',
        sizeSmall: 'btn_icon_sizeSmall',
        disabled: 'btn_icon_disabled',
      }}
      {...otherProps}
    >
      {children}
    </IconButton>
  );
});

PrimaryIconButton.displayName = 'PrimaryIconButton';

const PrimaryButton = memo(
  ({
    children,
    variant = 'contained',
    endIcon = null,
    startIcon = null,
    facebook = false,
    filled = false,
    ...otherProps
  }) => {
    return (
      <Button
        disableRipple
        variant={variant}
        endIcon={endIcon}
        startIcon={startIcon}
        color={'primary'}
        data-type={facebook ? 'facebook' : ''}
        role={'button'}
        classes={{
          root: `btn_primary ${filled && 'btn_primary_filled'}`,
          label: 'btn_primary_label',
          endIcon: 'btn_primary_endIcon',
          contained: 'btn_primary_contained',
          outlined: 'btn_primary_outlined',
          sizeSmall: 'btn_primary_sizeSmall',
          sizeLarge: 'btn_primary_sizeLarge',
          disabled: 'btn_primary_disabled',
        }}
        {...otherProps}
      >
        <span className={classNames('btn_primary_content', { mac_platform: isMacOs, with_icon: !!endIcon })}>
          {children}
        </span>
      </Button>
    );
  }
);
PrimaryButton.displayName = 'PrimaryButton';
export { PrimaryButton, TextButton, PrimaryIconButton };
