import React, { useEffect, useState, memo } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import styles from './SavedCreditCardModal.module.scss';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton, TextButton } from 'components/Button/Button';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { InputField } from 'components/Input/Input';
import CardWrapper from '../../CardWrapper/CardWrapper';
import Section from 'components/Section/Section';
import Price from 'components/Price/Price';
import useRemoveUserBillingAccount from '../../SavedCreditCardModal/hooks/useRemoveUserBillingAccount';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import { ReactComponent as ArrowRight } from '../../../../../../assets/icons/arrow_right.svg';

const getUserBillingGiftCards = (card) => card.accounttype.toLowerCase().includes('gift card');

const SavedCreditCardModal = ({ onClose, onSubmit, isOpen }) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    userBillingAccounts: { billingAccounts, loading: loadingBillingAccounts, error: errorBillingAccounts },
  } = useAppStateContext();
  const giftCards = (billingAccounts || []).filter(getUserBillingGiftCards);
  const appDispatch = useAppDispatchContext();
  const [{ loading: isRemoving }, removeBillingAccount] = useRemoveUserBillingAccount();

  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardID, setSelectedCardID] = useState(null);
  // const [giftCardList, setGiftCardList] = useState([
  //   { id: 0, giftCardNumberInner: '', giftCardPin: '', response: false, balance: 0 },
  // ]);

  useEffect(() => {
    //console.log(giftCardList);
    if (!loadingBillingAccounts && !errorBillingAccounts && giftCards && !selectedCard) {
      const card = giftCards[0];
      if (card) {
        setSelectedCard(card);
        setSelectedCardID(card.id);
      }
    }
  }, [loadingBillingAccounts, errorBillingAccounts, giftCards, selectedCard]);

  const onClickCard = (card) => () => {
    setSelectedCard(card);
    setSelectedCardID(card.id);
  };
  const onRemoveCard = () => {
    removeBillingAccount(selectedCardID).then(() => {
      setSelectedCard(null);
      setSelectedCardID(null);
      appDispatch({
        type: ACTION_TYPES.cleanUpSelectedSavedUserGiftCard,
      });
    });
  };
  const onSubmitCard = () => {
    appDispatch({
      type: ACTION_TYPES.setSelectedSavedUserGiftCard,
      payload: selectedCard,
    });
    appDispatch({
      type: ACTION_TYPES.setGiftCard,
      payload: { data: selectedCard },
    });
    onSubmit();
  };

  return (
    <ModalWindow
      footerChildren={
        <div className={styles.footer}>
          <PrimaryButton fullWidth={isMobileSize} endIcon={<ArrowRight />} onClick={onSubmitCard}>
            Use This Card
          </PrimaryButton>
        </div>
      }
      onChange={onClose}
      open={isOpen}
    >
      <div className={styles.wrapper}>
        <div className={styles.panel}>
          <Section title="YOUR GIFT CARDS" sectionHr={true}>
            {loadingBillingAccounts && <Spin spinning={loadingBillingAccounts} />}
            {errorBillingAccounts && <Alert message={errorBillingAccounts.errorMessage} />}
            {!loadingBillingAccounts && !errorBillingAccounts && (
              <div className={styles.cards_wrapper}>
                {giftCards &&
                  giftCards.map((card) => (
                    <CardWrapper isSelected={selectedCardID === card.id} onClick={onClickCard(card)} key={card.id}>
                      <div className={styles.header_card}>{`x-${card.cardNumber}`}</div>
                      <div className={styles.description}>
                        {card.balance || card.balance === 0 ? `Balance: ${card.balance.toFixed(2)}` : card.balance}
                      </div>
                    </CardWrapper>
                  ))}
              </div>
            )}
          </Section>
        </div>
        <div className={styles.body}>
          <Section title="GIFT CARD INFORMATION" sectionHr={true}>
            {selectedCard && (
              <>
                <div className={styles.input_wrapper}>
                  <InputField
                    fullWidth
                    label={'Gift Card Number'}
                    value={`************ ${selectedCard.cardNumber}`}
                    disabled={isRemoving}
                    readOnly
                  />
                </div>
                <div className={styles.button_wrapper}>
                  <div className={styles.balance}>Gift Card Balance: {<Price value={selectedCard.balance} />}</div>
                  <TextButton
                    disabled={!selectedCard.isRemovable || isRemoving}
                    color={'secondary'}
                    onClick={onRemoveCard}
                  >
                    Delete
                  </TextButton>
                </div>
              </>
            )}
          </Section>
        </div>
      </div>
    </ModalWindow>
  );
};

const MemoizedSavedCreditCardModal = memo(SavedCreditCardModal);

export default MemoizedSavedCreditCardModal;
