import React from 'react';
import styles from './OrderSummary.module.scss';
import Section from 'components/Section/Section';
import Price from 'components/Price/Price';

const OrderSummary = ({ order }) => {
  return (
    <Section sectionHr className={styles.section} title="Order Summary">
      <div className={styles.wrapper}>
        <h4>{`Order #: ${order.oloid}`}</h4>
        <h4>
          {'Order Total: '}
          <Price value={order.total} />
        </h4>
      </div>
    </Section>
  );
};
export default React.memo(OrderSummary);
