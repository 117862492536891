import { useCallback, useReducer } from 'react';
import auth from 'services/auth';
import { useQueryUrlParams } from 'hooks';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { createUser } from 'api/createUserFromSignUp';
import { getUser } from 'api/login';

import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useCreateNewUser = () => {
  const { basketId } = useQueryUrlParams();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppUserState = useAppDispatchContext();

  const createNewUser = useCallback(
    (userData, config) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          dispatchAppUserState({
            type: ACTION_TYPES.setLoadingUserDetails,
            payload: { loading: true },
          });
          try {
            let response;

            response = await createUser(userData, basketId, config).then((res) => {
              auth.setLogged(true);
              return res;
            });

            if (response.result === 'cardCreatedSuccess') {
              response = await getUser();

              dispatchAppUserState({
                type: ACTION_TYPES.setUserDetails,
                payload: {
                  data: {
                    ...response.fields,
                    primaryCardNumbers: response.primaryCardNumbers,
                    accountInfo: response.accountInformation,
                  },
                },
              });
              window.sessionStorage.removeItem('guestData');
              showToast('success', `Welcome ${response.fields.firstName} ${response.fields.lastName}`);
            }

            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
            resolve(response);
          } catch (error) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: error } });
            dispatchAppUserState({
              type: ACTION_TYPES.setErrorUserDetails,
              payload: { error },
            });
            showToast('error', error.errorMessage);
            reject(error);
          }
        };
        fetch();
      }),
    [dispatchAppUserState, dispatch, basketId]
  );

  return [state, { createNewUser }];
};
export default useCreateNewUser;
