import React, { memo } from 'react';
import styles from './CreditCardDetails.module.scss';
import { InputField } from 'components/Input/Input';
import moment from 'moment';

const CreditCardDetails = ({ cardNumber, expiryMonth, expiryYear, name, zip }) => (
  <div className={styles.wrapper}>
    <div className={styles.inputWrapper}>
      <InputField className={styles.input} fullWidth label={'Name on Card'} readOnly value={name} />
    </div>

    <div className={styles.inputWrapper}>
      <InputField
        className={styles.input}
        fullWidth
        label={'Credit Card Number'}
        value={`XXXX-XXXX-XXXX-${cardNumber}`}
        readOnly
      />
    </div>

    <div className={styles.inputGroupWrapper}>
      <div className={styles.inputWrapper}>
        <InputField
          fullWidth
          label={'Exp MM/YY'}
          placeholder={'MM/YY'}
          className={styles.input}
          value={`${expiryMonth}/${moment(expiryYear, 'YYYY').format('YY')}`}
          readOnly
        />
      </div>

      <div className={styles.inputWrapper}>
        <InputField className={styles.input} fullWidth label={'CVV'} value={'XXX'} readOnly />
      </div>

      <div className={styles.inputWrapper}>
        <InputField className={styles.input} fullWidth label={'Billing Zip'} value={zip ? zip : 'n/a'} readOnly />
      </div>
    </div>
  </div>
);

const MemoizedCreditCardDetails = memo(CreditCardDetails);

export default MemoizedCreditCardDetails;
