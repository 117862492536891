import axios from './axiosInstances/axiosInstance';

const URI = '/baskets';

export const setBasketTimeWanted = (basketId, timeWanted) => {
  return axios.put(`${URI}/${basketId}/timewanted`, timeWanted);
};

export const setBasketTimeModeToASAP = (basketId) => {
  return axios.delete(`${URI}/${basketId}/timewanted`);
};
