import { useCallback, useReducer } from 'react';
import { setBasketTimeWanted, setBasketTimeModeToASAP as setTimeToASAP } from 'api/timeWanted';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import showToast from 'components/Toast/ShowToast';
import { TIME_MODES } from 'enums/timeModes';
import moment from 'moment';
import { validateBasketService } from 'services/validateBasket';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useTimeWantedFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const appDispatch = useAppDispatchContext();

  const setOrderTime = useCallback(
    (basketId, timeMode, timeWanted, isHideErrorMsg) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            let basketData;
            if (timeMode === TIME_MODES.ASAP) {
              basketData = await validateBasketService(setTimeToASAP, basketId);
            } else {
              const date = moment(timeWanted, 'YYYY-MM-DD HH:mm');
              basketData = await validateBasketService(setBasketTimeWanted, basketId, {
                day: date.date(),
                month: date.month() + 1,
                year: date.year(),
                hour: date.hour(),
                minute: date.minute(),
                ismanualfire: false,
              });
            }
            appDispatch({
              type: ACTION_TYPES.setBasket,
              payload: { data: basketData },
            });
            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: basketData } });
            resolve();
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            !isHideErrorMsg &&
              showToast('error', e.errorMessage || 'Something went wrong... Could not set wanted time!');
            reject(e);
          }
        };
        fetch();
      }),
    [appDispatch]
  );

  return { ...state, setOrderTime };
};

export default useTimeWantedFetch;
