import React, { useState, useCallback, useEffect, useMemo, memo } from 'react';
import { generateTimeIntervals } from 'utils';
import styles from './OrderTime.module.scss';
import classNames from 'classnames/bind';
import Spin from 'components/Spin/Spin';
import Select from 'components/Select/Select';
import showToast from 'components/Toast/ShowToast';
import { Grid, RadioGroup } from '@material-ui/core';
import ChooseButton from 'components/ChooseButton/ChooseButton';
import { TIME_MODES } from 'enums/timeModes';
import useTimeWantedFetch from './hooks/useTimeWantedFetch';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import useRestaurantOperatingHoursFetch from './hooks/useRestaurantOperatingHoursFetch';
import InfoIcon from '@material-ui/icons/Info';
import { ORDER_DATETIME_FORMAT } from './enums/orderTimeFormats';

const moment = new MomentUtils();

const OrderTime = ({
  restaurant = {},
  basket = {},
  isMobileSize,
  isTouched,
  setIsOrderTimeSectionValid,
  setIsDefaultOrderTimeSetting,
  isDelivery,
}) => {
  const {
    id: restaurantId,
    supportedtimemodes: timeModes,
    iscurrentlyopen: isRestaurantOpen,
    utcoffset: restaurantUtcOffset,
  } = restaurant;
  const { id: basketId, earliestreadytime, deliverymode, leadtimeestimateminutes, timewanted: initTimeWanted } = basket;
  const { loading: isTimeSettingProccess, setOrderTime } = useTimeWantedFetch();
  const { getOperatingHours } = useRestaurantOperatingHoursFetch();
  const [timeMode, setTimeMode] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState('');
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [todayOperatingHours, setTodayOperatingHours] = useState(null);
  const hasTimeIntervals = timeIntervals && timeIntervals.length > 0;
  const warningMessageStatus = {
    openedRestaurantAndHasSlots: isRestaurantOpen && hasTimeIntervals,
    openedRestaurantAndNoSlots: isRestaurantOpen && !hasTimeIntervals,
    closedRestaurantAndNoSlots: !isRestaurantOpen && !hasTimeIntervals,
    closedRestaurantAndHasSlots: !isRestaurantOpen && hasTimeIntervals,
  };
  const hasShowTimeError = isTouched && timeMode === TIME_MODES.ADVANCE && !time;
  const hasShowDateError = isTouched && timeMode === TIME_MODES.ADVANCE && !date;
  const isAsapAvailable = isRestaurantOpen && timeModes && timeModes.includes(TIME_MODES.ASAP);
  const noAvailableTimeSlots = !timeIntervals || timeIntervals.length === 0;

  const isDateDisabled = useCallback(
    (date) => {
      // TODO: disable delivery for the 9th of may 2021
      return isDelivery && date && date.date() === 9 && date.month() === 4 && date.year() === 2021;
    },
    [isDelivery]
  );

  const currentDate = useMemo(() => {
    return moment.moment().utcOffset(restaurantUtcOffset);
  }, [restaurantUtcOffset]);
  const lastAvailableDate = moment.moment(currentDate).add(7, 'days');
  const isSelectedTodayDate = !!date && date.isSame(currentDate, 'days');

  const getOrderTimeIntervals = useCallback(
    (restaurantUtcOffset, orderEarliestTime, restaurantOperatingHours, orderType) => {
      const now = moment.moment().utcOffset(restaurantUtcOffset);
      const operatingHoursForSpecificOrderType = restaurantOperatingHours.find((item) => item.type.includes(orderType));
      if (operatingHoursForSpecificOrderType) {
        const openingTime = moment.moment(now).set({
          date: operatingHoursForSpecificOrderType.start.date(),
          month: operatingHoursForSpecificOrderType.start.month(),
          hour: operatingHoursForSpecificOrderType.start.hour(),
          minute: 0,
          second: 0,
        });
        const closingTime = moment.moment(now).set({
          date: operatingHoursForSpecificOrderType.end.date(),
          month: operatingHoursForSpecificOrderType.end.month(),
          hour: operatingHoursForSpecificOrderType.end.hour(),
          minute: 0,
          second: 0,
        });
        // parsed time without offset
        const readyTime = moment.moment(orderEarliestTime, ORDER_DATETIME_FORMAT);
        const earliestReadyTime = now.set({ hour: readyTime.hours(), minute: readyTime.minutes() });
        const startTime = moment.moment.max([openingTime, earliestReadyTime]);
        const intervals = generateTimeIntervals(startTime, closingTime, restaurantUtcOffset).map((time) => ({
          label: time.format('hh:mm A'),
          value: time.format(),
        }));
        return intervals;
      }
    },
    []
  );

  const tryTosetTimeIntervals = useCallback(
    async (date) => {
      try {
        setLoading(true);
        const operatingHours = await getOperatingHours(restaurantId, date || currentDate);
        const orderTimeIntervals = getOrderTimeIntervals(
          restaurantUtcOffset,
          earliestreadytime,
          operatingHours,
          deliverymode
        );
        if (!orderTimeIntervals || orderTimeIntervals.length === 0) {
          showToast('error', 'No available time slots. Please call the restaurant to place your order!');
          setTimeIntervals([]);
          return;
        }
        setTimeIntervals(orderTimeIntervals);
      } finally {
        setLoading(false);
      }
    },
    [
      getOperatingHours,
      restaurantId,
      restaurantUtcOffset,
      currentDate,
      getOrderTimeIntervals,
      deliverymode,
      earliestreadytime,
    ]
  );

  const handleTimeChange = async (time) => {
    await setOrderTime(basketId, TIME_MODES.ADVANCE, time);
    setTime(time);
  };
  const handleDateChange = async (date) => {
    setTime('');
    setDate(date);
    tryTosetTimeIntervals(date);
  };

  useEffect(() => {
    // TODO: disable delivery for the 9th of may 2021
    if (isDateDisabled(date)) {
      setTime('');
      setDate(date.add(1, 'days'));
      tryTosetTimeIntervals(date);
    }
  }, [isDateDisabled, date, tryTosetTimeIntervals]);

  useEffect(() => {
    if (timeMode === TIME_MODES.ASAP || time) {
      setIsOrderTimeSectionValid(true);
    } else {
      setIsOrderTimeSectionValid(false);
    }
  }, [time, timeMode, setIsOrderTimeSectionValid]);

  useEffect(() => {
    const setTodayOperationHours = async () => {
      const operatingHours = await getOperatingHours(restaurantId, currentDate);
      const businessOperatingHours = operatingHours.find((item) => item.type.includes('business'));
      setTodayOperatingHours({
        start: businessOperatingHours.start.format('hh:mma'),
        end: businessOperatingHours.end.format('hh:mma'),
      });
    };

    if (warningMessageStatus.openedRestaurantAndNoSlots && !todayOperatingHours) {
      setTodayOperationHours();
    }
  }, [
    restaurantId,
    restaurantUtcOffset,
    currentDate,
    isRestaurantOpen,
    todayOperatingHours,
    warningMessageStatus,
    getOperatingHours,
  ]);

  useEffect(() => {
    const isLoading = !timeMode || !date;
    setIsDefaultOrderTimeSetting(isLoading);
  }, [timeMode, date, setIsDefaultOrderTimeSetting]);

  // Set default time mode (first try ASAP and then ADVANCE)
  useEffect(() => {
    const setDateTimeFromBasket = async () => {
      if (initTimeWanted) {
        const now = moment.moment().utcOffset(restaurantUtcOffset);
        const initTime = moment.moment(initTimeWanted, ORDER_DATETIME_FORMAT);
        const initTimeWithTimeZone = moment.moment(now).set({
          date: initTime.date(),
          hour: initTime.hour(),
          minute: initTime.minute(),
          second: 0,
        });
        setDate(initTimeWithTimeZone);
        await tryTosetTimeIntervals(initTimeWithTimeZone);
        setTime(initTimeWithTimeZone.format() || '');
      } else {
        setDate(currentDate);
        await tryTosetTimeIntervals(currentDate);
      }
    };
    const trySetOrderTypeToScheduled = () => {
      if (timeModes.includes(TIME_MODES.ADVANCE)) {
        setTimeMode(TIME_MODES.ADVANCE);
      }
    };
    const setDefaultOrderType = async () => {
      try {
        setLoading(true);
        await setDateTimeFromBasket();
        // trying to set ASAP as default time mode if this mode is available by the restaurant
        if (isAsapAvailable) {
          try {
            await setOrderTime(basketId, TIME_MODES.ASAP, null, true);
            setTimeMode(TIME_MODES.ASAP);
            setIsOrderTimeSectionValid(true);
          } catch (e) {
            // after an unsuccessful setting of the ASAP mode as default, set an ADVANCE mode
            trySetOrderTypeToScheduled();
          }
        } else {
          trySetOrderTypeToScheduled();
        }
      } finally {
        setLoading(false);
      }
    };
    if (basketId && timeModes && !timeMode && !date) {
      setDefaultOrderType();
    }
  }, [
    setOrderTime,
    setTimeMode,
    basketId,
    timeMode,
    timeModes,
    setIsOrderTimeSectionValid,
    isAsapAvailable,
    currentDate,
    tryTosetTimeIntervals,
    restaurantUtcOffset,
    initTimeWanted,
    date,
  ]);

  const switchOrderTypeToSchedule = async () => {
    try {
      date || setDate(currentDate);
      if (time) {
        await setOrderTime(basketId, TIME_MODES.ADVANCE, time);
      }
      tryTosetTimeIntervals(date);
    } catch (e) {
      setTime('');
      setIsOrderTimeSectionValid(false);
    }
  };
  const handleOrderTypeChange = async (e) => {
    const { value } = e.target;
    if (value === TIME_MODES.ASAP) {
      await setOrderTime(basketId, value);
    } else if (value === TIME_MODES.ADVANCE) {
      switchOrderTypeToSchedule();
    }
    setTimeMode(value);
  };
  return (
    <div className={classNames(styles.order_time_wrap, { [styles.order_time_wrap__mobile]: isMobileSize })}>
      <Spin className={styles.spinner} spinning={isTimeSettingProccess || loading} />
      {!isTimeSettingProccess && !loading && date && timeMode === TIME_MODES.ADVANCE && !time && (
        <>
          {warningMessageStatus.closedRestaurantAndNoSlots && isSelectedTodayDate && (
            <div className={styles.info_wrapper}>
              <InfoIcon />
              <p className={styles.info}>The restaurant is closed now. Please select another date</p>
            </div>
          )}
          {warningMessageStatus.closedRestaurantAndNoSlots && !isSelectedTodayDate && (
            <div className={styles.info_wrapper}>
              <InfoIcon />
              <p className={styles.info}>No available time slots. Please select another date</p>
            </div>
          )}
          {warningMessageStatus.openedRestaurantAndNoSlots && (
            <div className={styles.info_wrapper}>
              <InfoIcon />
              <p className={styles.info}>
                <span>No available time slots. Please call the restaurant to place your order</span>
                {todayOperatingHours && (
                  <span>
                    . Restaurant hours: {todayOperatingHours.start} – {todayOperatingHours.end}
                  </span>
                )}
              </p>
            </div>
          )}
          {warningMessageStatus.closedRestaurantAndHasSlots && (
            <div className={styles.info_wrapper}>
              <InfoIcon />
              <p className={styles.info}>The restaurant is closed now. You can place your scheduled order</p>
            </div>
          )}
        </>
      )}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid
          container
          className={classNames(styles.order_time, {
            [styles.order_time__loading]: isTimeSettingProccess || loading,
          })}
          spacing={isMobileSize ? 2 : 10}
        >
          <Grid container item md={6} lg={6} xs={12} sm={12}>
            <RadioGroup className={styles.time_buttons_wrap} value={timeMode} onChange={handleOrderTypeChange}>
              <ChooseButton
                className={styles.asap_button}
                value={TIME_MODES.ASAP}
                disabled={isTimeSettingProccess || !isAsapAvailable || loading}
                classes={{
                  label: styles.order_type_button_label,
                }}
                checked={timeMode === TIME_MODES.ASAP}
                label={'ASAP'}
              />
              <ChooseButton
                className={styles.scheduled_button}
                value={TIME_MODES.ADVANCE}
                classes={{
                  label: styles.order_type_button_label,
                }}
                disabled={isTimeSettingProccess || !timeModes.includes(TIME_MODES.ADVANCE) || loading}
                checked={timeMode === TIME_MODES.ADVANCE}
                label={'Scheduled'}
              />
            </RadioGroup>
          </Grid>
          <Grid className={styles.date_time_wrap} item md={12} lg={12} xs={12} sm={12}>
            {timeMode === TIME_MODES.ASAP && (
              <span className={styles.time_title}>Ready in: {leadtimeestimateminutes} minutes</span>
            )}
            {timeMode === TIME_MODES.ADVANCE && (
              <>
                <span className={styles.time_title}>When</span>
                <DatePicker
                  className={styles.date}
                  value={date}
                  minDate={currentDate}
                  maxDate={lastAvailableDate}
                  disableToolbar={true}
                  variant="inline"
                  label={date ? '' : 'Date'}
                  inputVariant="filled"
                  format={'MM/DD'}
                  disabled={isTimeSettingProccess}
                  onChange={handleDateChange}
                  autoOk
                  error={hasShowDateError}
                  shouldDisableDate={(date) => {
                    // TODO: disable delivery for the 9th of may 2021
                    return isDateDisabled(date);
                  }}
                  helperText={hasShowDateError ? 'Please select date' : ''}
                  FormHelperTextProps={{ classes: { root: styles.helperText } }}
                  InputLabelProps={{
                    className: styles.date_label,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: styles.date_input,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon className={styles.icon} />
                      </InputAdornment>
                    ),
                    classes: {
                      root: 'input_root',
                      disabled: 'input_root_disabled',
                      error: 'input_error',
                    },
                  }}
                />
                <Select
                  variant={'filled'}
                  label={time ? '' : 'Time'}
                  classes={{
                    label: styles.time_label,
                    root: styles.time_select,
                  }}
                  className={styles.time}
                  disabled={noAvailableTimeSlots || isTimeSettingProccess || !date}
                  onChange={handleTimeChange}
                  value={time}
                  items={timeIntervals}
                  error={hasShowTimeError}
                  helperText={
                    hasShowTimeError && (warningMessageStatus.openedRestaurantAndHasSlots || !date)
                      ? 'Please select time'
                      : ''
                  }
                  formHelperTextProps={{ classes: { root: styles.helperText } }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};
const MemoizedCheckoutOrderTime = memo(OrderTime);
export default MemoizedCheckoutOrderTime;
