import React, { Fragment } from 'react';
import styles from './QualifiedVisitHistory.module.scss';
import { Divider } from '@material-ui/core';
import useTransactionHistoryFetch from './hooks/useTransactionHistoryFetch';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import moment from 'moment';
import { TextButton } from 'components/Button/Button';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

const List = React.memo(({ data = [] }) => {
  const list = data.map((order, index) => {
    const divider = index !== data.length - 1 && <Divider />;
    const formatedDate = moment(order.datetime, 'YYYYMMDD HH:mm').format('ddd, MMMM D h:mm A');

    return (
      <Fragment key={order.id}>
        <div className={styles.row}>
          <div className={styles.cell}>
            <div className={styles.order_name}>
              <div className={styles.name}>Order at {order.storeName}</div>
            </div>
            <div className={styles.date}>{formatedDate}</div>
          </div>
        </div>
        {divider}
      </Fragment>
    );
  });

  return list;
});

List.displayName = 'QualifiedVisitHistoryList';

const QualifiedVisitHistory = ({ toMenu }) => {
  const [{ loading, data, error }] = useTransactionHistoryFetch();
  const {
    basket: { data: basketData },
  } = useAppStateContext();
  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not load order history. Please try again later!')) ||
    '';
  const dataIsLoaded = !loading && !error && data;
  return (
    <div className={styles.container}>
      {errorMessage && <Alert message={errorMessage} />}
      {loading && <Spin spinning={loading} />}
      {dataIsLoaded &&
        (data.length > 0 ? (
          <List data={data} toMenu={toMenu} />
        ) : (
          <div className={styles.no_data}>
            You have no orders,&nbsp;
            <TextButton onClick={toMenu} className={styles.place_order_link}>
              {basketData && basketData.products && basketData.products.length ? 'continue your order!' : 'Order Now!'}
            </TextButton>
          </div>
        ))}
    </div>
  );
};

export default React.memo(QualifiedVisitHistory);
