import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SelectMui from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Select.scss';
import FormHelperText from '@material-ui/core/FormHelperText';

const Select = ({
  label = undefined,
  onChange,
  value = '',
  items = [],
  disabled = false,
  defaultSelect = '',
  className,
  classesMenu = {},
  classes = {},
  none = false,
  variant = 'outlined',
  helperText,
  formHelperTextProps = {},
  ...otherProps
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  const disabledUnderline = variant === 'filled' ? { disableUnderline: true } : {};
  return (
    <FormControl
      variant={variant}
      classes={{
        root: 'form_select_root',
      }}
      {...otherProps}
    >
      <InputLabel
        id="select"
        disabled={disabled}
        classes={{ disabled: 'form_select_label_disabled', root: classes['label'] }}
      >
        {label}
      </InputLabel>
      <SelectMui
        labelId="select"
        value={value !== '' ? value : defaultSelect}
        label={label}
        onChange={handleChange}
        IconComponent={ExpandMoreIcon}
        disabled={disabled ? disabled : items.length === 0}
        className={className}
        classes={{
          root: `select_form ${classes['root']}`,
          disabled: 'select_form_disabled',
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          disablePortal: true,
          classes: {
            paper: `select_menu ${classesMenu['root']}`,
          },
        }}
        {...disabledUnderline}
        {...otherProps}
      >
        {none && <MenuItem value="">None</MenuItem>}
        {items.map((item, index) => (
          <MenuItem classes={{ root: 'select_item' }} key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </SelectMui>
      {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(Select);
