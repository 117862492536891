import { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router';
import { fetchReducer, FETCH_ACTION_TYPES } from '../reducers/fetchReducer';
import { useAppDispatchContext } from '../stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { getUser, authUserByMobile } from '../api/login';
import { getLastBasket } from '../api/getLastBasket';
import { getRestaurantById } from '../api/restaurantById';
import { getGroupOrder } from '../api/getGroupOrder';
import auth from '../services/auth';
import showToast from 'components/Toast/ShowToast';
import useQueryUrlParamsDispatch from './useQueryUrlParamsDispatch';
import { useQueryUrlParams } from './index';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useLogin = () => {
  const history = useHistory();
  const setQuery = useQueryUrlParamsDispatch();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppUserState = useAppDispatchContext();
  const { basketId } = useQueryUrlParams();

  const login = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatchAppUserState({
            type: ACTION_TYPES.setLoadingUserDetails,
            payload: { loading: true },
          });

          try {
            let response;
            response = await getUser().then((user) => {
              auth.setLogged(true);
              return user;
            });
            dispatchAppUserState({
              type: ACTION_TYPES.setUserDetails,
              payload: {
                data: {
                  ...response.fields,
                  primaryCardNumbers: response.primaryCardNumbers,
                  accountInfo: response.accountInformation,
                },
              },
            });
            dispatch({
              type: FETCH_ACTION_TYPES.setData,
              payload: {
                data: {
                  ...response.fields,
                  primaryCardNumbers: response.primaryCardNumbers,
                  accountInfo: response.accountInformation,
                },
              },
            });
            resolve(true);
          } catch (error) {
            dispatchAppUserState({
              type: ACTION_TYPES.setErrorUserDetails,
              payload: { error },
            });
            reject(false);
          }
        };

        fetch();
      }),
    [dispatchAppUserState, dispatch]
  );

  const relogin = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatchAppUserState({
            type: ACTION_TYPES.setReloadingUserDetails,
            payload: { reloading: true },
          });
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });

          try {
            let response;
            response = await getUser().then((user) => {
              auth.setLogged(true);
              return user;
            });

            dispatchAppUserState({
              type: ACTION_TYPES.setUserDetails,
              payload: {
                data: {
                  ...response.fields,
                  primaryCardNumbers: response.primaryCardNumbers,
                  accountInfo: response.accountInformation,
                },
              },
            });

            resolve(true);
          } catch (error) {
            dispatchAppUserState({
              type: ACTION_TYPES.setErrorUserDetails,
              payload: { error },
            });
            reject(false);
          }
        };

        fetch();
      }),
    [dispatchAppUserState, dispatch]
  );

  const loginByCode = useCallback(
    (code) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatchAppUserState({
            type: ACTION_TYPES.setLoadingUserDetails,
            payload: { loading: true },
          });

          try {
            let response;
            await authUserByMobile(code);
            response = await getUser().then((user) => {
              auth.setLogged(true);
              return user;
            });
            setQuery({}, null, false, ['code', 'expires_in']);

            if (!basketId) {
              const lastBasket = await getLastBasket();

              if (lastBasket) {
                let restaurantId = null;

                if (lastBasket.vendorid) {
                  const restaurant = await getRestaurantById(lastBasket.vendorid);

                  if (restaurant) {
                    dispatchAppUserState({
                      type: ACTION_TYPES.setRestaurant,
                      payload: {
                        data: restaurant,
                      },
                    });

                    restaurantId = restaurant.id;
                  }
                }

                if (lastBasket.groupOrderId) {
                  const groupOrder = await getGroupOrder(lastBasket.groupOrderId);

                  if (groupOrder) {
                    dispatchAppUserState({ type: ACTION_TYPES.setGroupOrder, payload: { data: groupOrder } });
                    dispatchAppUserState({ type: ACTION_TYPES.setBasket, payload: { data: groupOrder.basket } });
                    setQuery(
                      {
                        groupOrderId: lastBasket.groupOrderId,
                        basketId: lastBasket.id,
                        owner: lastBasket.isGroupOrderHost,
                      },
                      restaurantId ? `/${restaurantId}/menu` : '',
                      false,
                      ['signin']
                    );
                  }
                } else {
                  dispatchAppUserState({
                    type: ACTION_TYPES.setBasket,
                    payload: {
                      data: lastBasket,
                    },
                  });
                  history.push(`/${restaurantId}/menu`);
                }
              }
            }
            dispatchAppUserState({
              type: ACTION_TYPES.setUserDetails,
              payload: {
                data: {
                  ...response.fields,
                  primaryCardNumbers: response.primaryCardNumbers,
                  accountInfo: response.accountInformation,
                },
              },
            });
            dispatch({
              type: FETCH_ACTION_TYPES.setData,
              payload: {
                data: {
                  ...response.fields,
                  primaryCardNumbers: response.primaryCardNumbers,
                  accountInfo: response.accountInformation,
                },
              },
            });
            resolve(true);
          } catch (error) {
            setQuery({}, null, false, ['code', 'expires_in']);
            dispatchAppUserState({
              type: ACTION_TYPES.setErrorUserDetails,
              payload: { error },
            });
            showToast(
              'error',
              error.errorMessage || 'Something went wrong... Authentification faild. Please try again!'
            );
            reject(false);
          }
        };

        fetch();
      }),
    [dispatchAppUserState, dispatch, setQuery, basketId, history]
  );

  return [state, { login, relogin, loginByCode }];
};

export default useLogin;
