import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { changePassword } from 'api/user/changePassword';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useChangePassword = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const updatePassword = useCallback(
    (passwords) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            await changePassword(passwords);
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false } });
            resolve(true);
          } catch (e) {
            showToast('error', e.errorMessage || 'Something went wrong... Could not update password!');
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            reject(e);
          }
        };

        fetch();
      }),
    []
  );

  const resetState = () => dispatch({ type: FETCH_ACTION_TYPES.cleanState });

  return [state, { updatePassword, resetState }];
};

export default useChangePassword;
