import React, { useCallback } from 'react';
import ReviewOrder from './ReviewOrder/ReviewOrder';
import { useState } from 'react';
import { useEffect } from 'react';
import { useQueryUrlParams } from 'hooks';
import useQueryUrlParamsDispatch, { useQueryUrlCleanParamsDispatch } from 'hooks/useQueryUrlParamsDispatch';
import OrderUpsell from './OrderUpsell/OrderUpsell';
import { SignInPanel } from '../../components/SignInPanel';
import { SignUpPanel } from '../../components/SignUpPanel';
import { ForgotPasswordPanel } from '../../components/ForgotPasswordPanel';
import CheckoutPanel from '../../modules/CheckoutPanel/CheckoutPanel';
import { useLocation, useParams } from 'react-router';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import ProductDetailsModal from '../ProductDetailsModal/ProductDetailsModal';

const OrderingPanel = ({ defaultStep, handleClose, emptyBasket }) => {
  const [step, setStep] = useState(defaultStep);
  const { pathname } = useLocation();
  const { restaurantId } = useParams();
  const {
    signin,
    signup,
    forgotPassword,
    referral_code,
    referrer_email,
    restaurantId: queryRestaurantId,
  } = useQueryUrlParams();
  const setQueryParams = useQueryUrlParamsDispatch();
  const parseSignin = signin && JSON.parse(signin);
  const parseSignup = signup && JSON.parse(signup);
  const parseForgotPassword = forgotPassword && JSON.parse(forgotPassword);
  const cleanQuery = useQueryUrlCleanParamsDispatch();
  const {
    restaurant: { data },
  } = useAppStateContext();
  const getRestaurantId = restaurantId || queryRestaurantId || (data && data.id);

  const [openProductDetails, setOpenProductDetails] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);

  const handleNameClick = (product) => {
    setEditedProduct(product);
    setOpenProductDetails(true);
  };

  const handleProductDetailsClose = () => {
    setOpenProductDetails(false);
    setEditedProduct(null);
  };

  const onChangeStep = useCallback((step) => {
    setStep(step);
  }, []);
  const onSubmit = useCallback(
    (close) => {
      setStep(null);
      handleClose(false);
      if (!close && step === 'checkoutAuth') {
        setQueryParams('', `/${getRestaurantId}/checkout`, true, ['openBasket, checkoutAs']);
      }
      if (step === 'signin') cleanQuery(['signin']);
      if (step === 'signup') cleanQuery(['signup', 'referral_code', 'referrer_email', 'cardTemplateCode', 'email']);
      if (step === 'forgotPassword') cleanQuery(['forgotPassword', 'openBasket']);
    },
    [handleClose, step, setQueryParams, getRestaurantId, cleanQuery]
  );
  useEffect(() => {
    if (defaultStep && !step) {
      setStep(defaultStep);
    }
  }, [defaultStep, step]);

  useEffect(() => {
    if (parseSignin) {
      setStep('signin');
    }
    if (parseSignup) {
      setStep('signup');
    }
    if (parseForgotPassword) {
      setStep('forgotPassword');
    }
  }, [parseSignin, parseSignup, parseForgotPassword]);

  const onClose = useCallback(() => {
    setStep(null);
    handleClose(false);
  }, [handleClose]);

  const onEmptyClose = useCallback(() => {
    setStep(null);
    handleClose(false);
    if (step === 'order' && pathname === '/account') setQueryParams('', `/`, true, ['openBasket']);
  }, [pathname, step, setQueryParams, handleClose]);

  useEffect(() => {
    if ((referral_code || referrer_email) && setQueryParams && !parseSignup) {
      setQueryParams({ signup: true });
    }
  }, [referral_code, referrer_email, setQueryParams, parseSignup]);

  return (
    <>
      <ReviewOrder
        emptyBasket={emptyBasket}
        open={step === 'order'}
        onClose={onSubmit}
        onEmptyClose={onEmptyClose}
        onSetStep={onChangeStep}
        handleNameClick={handleNameClick}
      />
      <OrderUpsell open={step === 'upsell'} onClose={onSubmit} onSetStep={onChangeStep} />
      <SignInPanel open={step === 'signin'} onClose={onSubmit} />
      <SignUpPanel open={step === 'signup'} onClose={onSubmit} />
      <ForgotPasswordPanel open={step === 'forgotPassword'} onClose={onSubmit} />
      <CheckoutPanel open={step === 'checkoutAuth'} onSubmit={onSubmit} onClose={onClose} />
      {openProductDetails && (
        <ProductDetailsModal edit={true} product={editedProduct} onClose={handleProductDetailsClose} />
      )}
    </>
  );
};
export default React.memo(OrderingPanel);
