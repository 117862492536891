import React, { Fragment } from 'react';
import './OrderSummaryList.scss';
import { Divider } from '@material-ui/core';
import Price from 'components/Price/Price';

const OrderSummaryList = ({ data = {}, skipTotal = false }) => {
  const list = data.products.map((row, index) => {
    return (
      <Fragment key={index}>
        <div className="row">
          <div className="name">
            {row.quantity}x {row.name}
            {row.choices.length > 0 && (
              <div className={'choices'}>
                {row.choices.map((choice, index) => (
                  <div className={'choice'} key={index}>
                    {choice.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="cost">
            <Price value={row.totalcost} />
          </div>
        </div>
        <Divider />
      </Fragment>
    );
  });

  const subtotalAsTotal = (
    <div className="row total">
      <h2 className="estimated_subtotal">Subtotal</h2>
      <h2 className="estimated_subtotal">
        <Price value={data.subtotal} />
      </h2>
    </div>
  );

  const totals = (
    <Fragment>
      <div className="row">
        <div className="subtotal_label">Subtotal</div>
        <div className="cost">
          <Price value={data.subtotal} />
        </div>
      </div>
      <Divider />
      {data.deliverymode !== 'curbside' && (
        <>
          <div className="row">
            <div className="name">Delivery</div>
            <div className="cost">
              {data.customerhandoffcharge ? <Price value={data.customerhandoffcharge} /> : 'FREE'}
            </div>
          </div>
          <Divider />
        </>
      )}
      {data.fees.map(({ description, amount }, index) => (
        <Fragment key={index}>
          <div className="row">
            <div className="name">{description}</div>
            <div className="cost">
              <Price value={amount} />
            </div>
          </div>
          <Divider />
        </Fragment>
      ))}
      {data.discounts
        .filter(({ amount }) => amount > 0)
        .map(({ description, amount }, index) => (
          <Fragment key={index}>
            <div className="row">
              <div className="name">
                {data.coupon && data.coupon.description === description ? 'Coupon Code' : description}
              </div>
              <div className="cost">
                -<Price value={amount} />
              </div>
            </div>
            <Divider />
          </Fragment>
        ))}
      {!!data.tip && (
        <>
          <div className="row">
            <div className="name accent">Tip</div>
            <div className="cost">
              <Price value={data.tip} />
            </div>
          </div>
          <Divider />
        </>
      )}
      <div className="row">
        <div className="name accent">Estimated Tax</div>
        <div className="cost">
          <Price value={data.salestax} />
        </div>
      </div>
      <div className="row total">
        <h2 className="estimated_total">Total:</h2>
        <h2 className="estimated_total">
          <Price value={data.total} />
        </h2>
      </div>
    </Fragment>
  );

  return (
    <div className="order_summary_list">
      {list}
      {skipTotal ? subtotalAsTotal : totals}
    </div>
  );
};

export default React.memo(OrderSummaryList);
