import { useEffect, useReducer } from 'react';
import { getBillingSchemesAccounts } from 'api/getBillingSchemesAccounts';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useBillingSchemesFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const { basketId } = useQueryUrlParams();
  useEffect(() => {
    const fetch = async () => {
      if (basketId) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const response = await getBillingSchemesAccounts(basketId);
          if (response) {
            dispatch({
              type: FETCH_ACTION_TYPES.setData,
              payload: { data: response.billingschemes },
            });
          }
        } catch (e) {
          const errorMessage = e.errorMessage || 'Something went wrong... Could not accept gift card to your basket!';
          showToast('error', errorMessage);
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
        }
      }
    };
    fetch();
  }, [basketId]);
  return state;
};

export default useBillingSchemesFetch;
