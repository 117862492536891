export const TOKEN_KEY = '_TOKEN';

const auth = () => {
  return {
    getToken: () => {
      return window.localStorage.getItem(TOKEN_KEY);
    },
    setToken: (token) => {
      window.localStorage.setItem(TOKEN_KEY, token);
    },
    cleanup: () => {
      window.localStorage.removeItem(TOKEN_KEY);
    },
    logout: (callback) => {
      window.localStorage.removeItem(TOKEN_KEY);
      window.localStorage.removeItem('isLogged');
      window.localStorage.removeItem('rewardId');
      window.localStorage.removeItem('isScroll');
      //redirect to homepage
      if (callback) {
        callback();
      }
    },
    getLogged: () => {
      return window.localStorage.getItem('isLogged');
    },
    setLogged: (logged) => {
      window.localStorage.setItem('isLogged', logged);
    },
    removeLogged: () => {
      window.localStorage.removeItem('isLogged');
    },
  };
};

export default auth();
