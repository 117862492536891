import React, { memo } from 'react';
import classNames from 'classnames/bind';
import Notification from 'components/Notification/Notification';
import './Alert.scss';

const Alert = (props) => (
  <span className={'alert_container'}>
    <Notification type="error" className={classNames('alert', props.className)} {...props} />
  </span>
);

const MemoizedAlert = memo(Alert);

export default MemoizedAlert;
