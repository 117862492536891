import React, { useState, memo } from 'react';
import ActionTable from 'components/ActionTable/ActionTable';
import styles from './CardsOnFile.module.scss';
import { TextButton } from 'components/Button/Button';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';
import useRemoveUserBillingAccount from '../../Checkout/CheckoutOrder/PaymentMethod/SavedCreditCardModal/hooks/useRemoveUserBillingAccount';
import RemoveCardModal from 'components/RemoveCardModal/RemoveCardModal';

const CardsOnFile = ({ toMenu, billingAccounts = [], error, loading }) => {
  const [{ loading: isRemoving, error: errorBillingAccount }, removeBillingAccount] = useRemoveUserBillingAccount();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const disabledBtn = isRemoving || !!errorBillingAccount;

  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not load cards on file. Please try again later!')) ||
    '';
  const isDataLoaded = !loading && !error && billingAccounts;

  const handleDeleteClick = (card) => () => {
    const cardToDelete = billingAccounts.find(({ id }) => id === card.id);
    setSelectedCard(cardToDelete);
    setIsConfirmModal(true);
  };
  const onCloseModal = () => {
    setIsConfirmModal(false);
    setSelectedCard(null);
  };
  const onDeleteCard = () => {
    if (selectedCard) {
      removeBillingAccount(selectedCard.id).finally(onCloseModal);
    }
    return;
  };

  const RenderEmptyTable = (
    <div className={styles.no_data}>
      You have no cards on file.&nbsp;
      <TextButton onClick={toMenu} className={styles.place_order_link}>
        Order Now
      </TextButton>
      &nbsp;to add a Debit/Credit Card.
    </div>
  );
  const creditCardsAccounts = (billingAccounts || []).filter(
    (account) => !account.accounttype.toLowerCase().includes('gift card')
  );
  return (
    <div className={styles.container}>
      {errorMessage && <Alert message={errorMessage} />}
      {loading && <Spin spinning={loading} />}
      {isDataLoaded && (
        <ActionTable
          data={creditCardsAccounts}
          nameProperty={'name'}
          idProperty={'id'}
          emptyTableRender={RenderEmptyTable}
          withBorder
        >
          {(row) => (
            <TextButton onClick={handleDeleteClick(row)} className={styles.action_btn}>
              Delete
            </TextButton>
          )}
        </ActionTable>
      )}
      <RemoveCardModal
        open={isConfirmModal}
        disabled={disabledBtn}
        loading={isRemoving}
        onChange={onCloseModal}
        onSubmit={onDeleteCard}
        onCancel={onCloseModal}
      />
    </div>
  );
};

const MemoizedCardsOnFile = memo(CardsOnFile);

export default MemoizedCardsOnFile;
