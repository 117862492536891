import React, { Fragment, useCallback } from 'react';
import styles from './PrintRewardsCardModal.module.scss';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import smallLogo from 'assets/small-logo.png';
import rewards_card_bg from 'assets/rewards_card_bg.png';
import rewards_card_bg_pic from 'assets/rewards_card_bg_pic.png';
// import NumberFormat from 'react-number-format';
// import moment from 'moment';
import PrintIcon from '@material-ui/icons/Print';

const PrintRewardsCardModal = ({ data, isOpen, onClose }) => {
  const { firstName, lastName, rewardsId } = data;
  const handlePrintClick = () => {
    window.print();
  };
  const onChange = useCallback(() => onClose({ printRewards: false }), [onClose]);
  const rewardsCard = (
    <div className={styles.rewards_wrapper}>
      <div id="section-to-print" className={styles.rewards_card}>
        <div className={styles.header}>
          {smallLogo && <img className={styles.logo} src={smallLogo} alt="99-restaurant" />}
          <h2>REWARDS</h2>
        </div>
        {rewards_card_bg && (
          <img className={styles.rewards_card_bg} src={rewards_card_bg} alt="99-restaurant-background" />
        )}
        {rewards_card_bg_pic && (
          <img className={styles.rewards_card_bg_pic} src={rewards_card_bg_pic} alt="99-restaurant-background" />
        )}
        <div className={styles.card_holder}>
          <p>{firstName}</p>
          <p>{lastName}</p>
        </div>
        <div className={styles.rewards_id}>ID: {rewardsId}</div>
        <div className={styles.contacts}>
          {/* <p>
            Phone: <NumberFormat value={phoneNumber} displayType={'text'} format="###-###-####" />
          </p>
          <p>
            Birthday:{' '}
            {moment(birthday, 'YYYY-MM-DD').isValid() ? moment(birthday, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}
          </p>
          <p>{data.email}</p> */}
          <p>www.99restaurants.com</p>
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <ModalWindow
        open={isOpen}
        onChange={onChange}
        contrastBody={false}
        maxWidth="lg"
        footerChildren={
          <div className={styles.modal_footer}>
            <PrimaryButton className={styles.print_btn} endIcon={<PrintIcon />} size="large" onClick={handlePrintClick}>
              PRINT
            </PrimaryButton>
          </div>
        }
      >
        {rewardsCard}
      </ModalWindow>
    </Fragment>
  );
};

export default React.memo(PrintRewardsCardModal);
