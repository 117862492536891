import React, { useState, useEffect, useCallback } from 'react';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import useRetrieveBasketQualifyingRewardsFetch from './hooks/useRetrieveBasketQualifyingRewardsFetch';
import AvailableRewardsList from './AvailableRewardsList/AvailableRewardsList';
import AddRewardToOrderModal from './AddRewardToOrderModal/AddRewardToOrderModal';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import styles from './AvailableRewards.module.scss';
import showToast from '../../../../components/Toast/ShowToast';
import { useQueryUrlParamsDispatch } from 'hooks';
import { ACTION_TYPES } from '../../../../stateProviders/appStateProvider';

const AvailableRewards = () => {
  const {
    basket: { data: basketData },
    restaurant: { data: restaurantData },
    user: { data: userData },
  } = useAppStateContext();
  const appDispatch = useAppDispatchContext();
  const coupon = basketData && basketData.coupon;
  const basketId = basketData && basketData.id;
  const appliedrewardsIds =
    basketData && basketData.appliedrewards ? basketData.appliedrewards.map((reward) => reward.rewardid).join() : '';
  const setRedirect = useQueryUrlParamsDispatch();
  const restaurantId = restaurantData ? restaurantData.id : null;
  const [
    { data, loading, error },
    { addRewardToBasket, removeRewardFromBasket },
  ] = useRetrieveBasketQualifyingRewardsFetch(
    basketId,
    userData.primaryCardNumbers[0],
    appliedrewardsIds,
    restaurantData
  );
  const [modalState, setModalState] = useState(false);
  const [rewardToAdd, setRewardToAdd] = useState(null);
  const [backgroundRewardApplied, setBackgroundRewardApplied] = useState(false);

  const goToMenu = () => {
    setModalState(false);
    setRedirect(null, `/${restaurantId}/menu`, false, []);
  };

  const openAddRewardModal = (reward) => {
    setRewardToAdd(reward);
    setModalState(true);
  };

  const continueAddingRewardToBasket = useCallback(
    (reward, showRedirectionModal) => {
      addRewardToBasket(basketId, reward.reference).then((productInBasket) => {
        if (productInBasket) {
          const SuccessMsg = () => (
            <span>
              Reward <u>{reward.label}</u> applied to the order successfully
            </span>
          );

          showToast('success', <SuccessMsg />);
          return;
        }

        if (!showRedirectionModal) {
          return;
        }

        openAddRewardModal(reward);
      });
    },
    [addRewardToBasket, basketId]
  );

  const addReward = useCallback(
    (rewardToAdd, showRedirectionModal = true) => {
      const appliedReward = data.find((reward) => reward.applied);

      if (coupon) {
        showToast('warning', 'Rewards cannot be used together with discount coupons');
        return;
      }

      if (appliedReward) {
        removeRewardFromBasket(basketId, appliedReward.rewardid).then(() => {
          continueAddingRewardToBasket(rewardToAdd, showRedirectionModal);
        });

        return;
      }

      continueAddingRewardToBasket(rewardToAdd, showRedirectionModal);
    },
    [basketId, coupon, continueAddingRewardToBasket, data, removeRewardFromBasket]
  );

  const removeReward = (reward) => {
    removeRewardFromBasket(basketId, reward.rewardid).then(() => {
      const SuccessMsg = () => (
        <span>
          Reward <u>{reward.label}</u> removed from the basket successfully
        </span>
      );

      showToast('success', <SuccessMsg />);
    });
  };

  const handleModalSubmit = () => {
    appDispatch({ type: ACTION_TYPES.cleanUpPaymentData });
    appDispatch({ type: ACTION_TYPES.cleanUpGiftCard });
    window.localStorage.setItem('rewardId', rewardToAdd.reference);
    goToMenu();
  };

  useEffect(() => {
    if (!backgroundRewardApplied && data && basketId && restaurantData && restaurantData.supportsloyalty) {
      setBackgroundRewardApplied(true);

      if (data.some((reward) => reward.applied)) {
        window.localStorage.removeItem('rewardId');
        return;
      }

      const rewardId = window.localStorage.getItem('rewardId');

      if (rewardId) {
        const reward = data.find((reward) => reward.reference === rewardId);
        addReward(reward, false);
      }
    }
  }, [data, addReward, backgroundRewardApplied, basketId, restaurantData]);

  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not load available rewards. Please try again later!')) ||
    '';

  return (
    <div className={styles.available_rewards_container}>
      {errorMessage && <Alert message={errorMessage} />}
      {loading && <Spin spinning={loading} />}
      {!loading &&
        !error &&
        data &&
        (data.length > 0 ? (
          <AvailableRewardsList data={data} handleAddReward={addReward} handleRemoveReward={removeReward} />
        ) : (
          <>
            <div className={styles.no_data}>No available rewards</div>
          </>
        ))}

      <AddRewardToOrderModal
        reward={rewardToAdd}
        isModalOpen={modalState}
        handleIsModalOpen={setModalState}
        handleModalSubmit={handleModalSubmit}
      />
    </div>
  );
};

export default AvailableRewards;
