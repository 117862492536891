import React, { useCallback, useEffect, useState } from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useQueryUrlParamsDispatch } from 'hooks';
import { ORDER_DEADLINE_OLO } from 'enums/orderDeadline';
import { EMAIL_REGEXP } from 'services/email';

import MomentUtils from '@date-io/moment';

import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { PrimaryButton } from 'components/Button/Button';
import { TextButton } from 'components/Button/Button';
import showToast from 'components/Toast/ShowToast';
import ModalWindow from 'components/Modal/Modal';
import Alert from 'components/Alert/Alert';
import Link from 'components/Link/Link';

import CreateGroupOrderFormView from '../CreateGroupOrderFormView/CreateGroupOrderFormView';
import GroupOrderSendInvites from '../GroupOrderSendInvites/GroupOrderSendInvites';
import GroupOrderCopyLink from '../GroupOrderCopyLink/GroupOrderCopyLink';
import GroupOrderDeadline from '../GroupOrderDeadline/GroupOrderDeadline';
import useUpdateGroupOrder from '../hooks/useUpdateGroupOrder';
import useSendInvitation from '../hooks/useSendInvitation';

import styles from './ManageGroupOrderModal.module.scss';

const moment = new MomentUtils();

const validateDeadline = (date) => {
  return moment.moment().isBefore(moment.moment(date, ORDER_DEADLINE_OLO));
};

const validateEmails = (values) => {
  const incorrect = values.find((value) => {
    return !EMAIL_REGEXP.test(value) || value === '';
  });
  return !incorrect;
};

const ManageGroupOrderModal = () => {
  const {
    groupOrder: { data: groupOrderInfo },
  } = useAppStateContext();
  const { deadline: storedDeadline, note, id } = groupOrderInfo || {};
  const [deadline, setDeadline] = useState(storedDeadline);
  const [deadlineError, showDeadlineError] = useState(false);
  const setQuery = useQueryUrlParamsDispatch();
  const [{ loading, data, error }, { updateGroupOrder }] = useUpdateGroupOrder(id);

  const [emails, setEmails] = useState('');
  const [invitationNote, setInvitationNote] = useState('');
  const [emailsInvalid, showEmailsInvalid] = useState(false);
  const [{ loading: invitationLoading, error: invitationError }, { sendInvitation }] = useSendInvitation(id);

  const savingError =
    (error && (error.errorMessage || 'Something went wrong... Could not save group order. Please try again later!')) ||
    '';

  useEffect(() => {
    if (storedDeadline) {
      setDeadline(storedDeadline);
    }
  }, [storedDeadline]);

  const handleCloseModal = useCallback(() => {
    showEmailsInvalid(false);
    setInvitationNote('');
    setEmails('');
    setQuery({}, '', false, ['groupOrderModal']);
  }, [setQuery]);

  useEffect(() => {
    if (data && JSON.stringify(data) !== JSON.stringify(groupOrderInfo)) {
      setQuery({}, '', false, ['groupOrderModal']);
      showToast('success', 'Group order was updated successfully');
    }
  }, [data, groupOrderInfo, setQuery]);

  useEffect(() => {
    if (savingError) {
      showToast('error', savingError);
    }
  }, [savingError]);

  const handleSaveGroupOrderClick = useCallback(() => {
    const isValid = deadline && validateDeadline(deadline);
    if (deadline !== storedDeadline) {
      if (isValid) {
        updateGroupOrder(deadline, note);
      } else {
        showDeadlineError(true);
      }
    } else {
      handleCloseModal();
    }
  }, [deadline, storedDeadline, note, updateGroupOrder, handleCloseModal]);

  const handleDeadlineChange = useCallback(
    (value) => {
      if (value !== deadline) {
        setDeadline(value);
        showDeadlineError(false);
      }
    },
    [deadline]
  );

  const onChangeEmails = (values) => {
    showEmailsInvalid(false);
    setEmails(values);
  };

  const onChangeInvitationNote = (value) => setInvitationNote(value);

  const handleSendEmailClick = useCallback(() => {
    const values = emails
      .split(',')
      .map((email) => email.trim())
      .filter((email) => {
        return email !== '';
      });
    const emailsIsValid = values.length > 0 && validateEmails(values);
    if (sendInvitation && emailsIsValid) {
      sendInvitation(deadline, invitationNote, values);
    }
    if (!emailsIsValid) {
      showEmailsInvalid(true);
    }
  }, [sendInvitation, invitationNote, emails, deadline]);

  const invitationErrorMessage =
    (invitationError &&
      (invitationError.errorMessage ||
        'Something went wrong... Could not send invitations. Please try again later!')) ||
    '';

  return (
    <ModalWindow
      fullscreenSize={1024}
      open={true}
      onChange={handleCloseModal}
      maxWidth={'md'}
      headerSize="lg"
      headerTitle={'Group Order'}
      footerChildren={
        <div className={styles.modal_footer}>
          <Link routing isGothic onClick={handleCloseModal}>
            <TextButton>CANCEL</TextButton>
          </Link>
          <PrimaryButton
            size="large"
            className={styles.order_button}
            disabled={loading}
            onClick={handleSaveGroupOrderClick}
            endIcon={<CheckIcon />}
          >
            Save
            {loading && <CircularProgress size={24} className={styles.button_progress} />}
          </PrimaryButton>
        </div>
      }
    >
      <div className={styles.modal_content}>
        {savingError && <Alert message={savingError} />}
        <CreateGroupOrderFormView />
        <GroupOrderCopyLink />
        <GroupOrderDeadline
          defaultDeadline={storedDeadline}
          deadline={deadline}
          error={deadlineError}
          isExist={true}
          onChange={handleDeadlineChange}
        />
        <GroupOrderSendInvites
          onChangeEmails={onChangeEmails}
          onChangeNote={onChangeInvitationNote}
          error={emailsInvalid}
          onSend={handleSendEmailClick}
          errorMessage={invitationErrorMessage}
          loading={invitationLoading}
        />
        <PrimaryButton
          className={styles.send_button}
          size="medium"
          endIcon={<ArrowForwardIcon />}
          disabled={invitationLoading}
          onClick={handleSendEmailClick}
        >
          Send email
        </PrimaryButton>
      </div>
    </ModalWindow>
  );
};

export default ManageGroupOrderModal;
