import React, { useCallback, useEffect, useState } from 'react';

import Section from 'components/Section/Section';
import ChooseButton from 'components/ChooseButton/ChooseButton';
import Price from 'components/Price/Price';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

import useProductModifiersFetch from '../hooks/useProductModifiersFetch';
import styles from './ProductModifiersView.module.scss';

const getGroups = (groups, selectedOptions) => {
  let values = [];
  groups.forEach((group) => {
    let selected = [];
    const options = group.options.map((option) => {
      const isSelected = selectedOptions.some((selectedOption) => {
        return option.id === selectedOption;
      });
      if (isSelected && option.modifiers) {
        selected.push(option);
      }
      return {
        ...option,
        selected: isSelected,
      };
    });
    const selectedOptsCount = options.filter((option) => {
      return option.selected;
    }).length;
    let isValid = true;
    let validationMessage = '';
    if (group.mandatory) {
      isValid = selectedOptsCount === 1;
      validationMessage = !isValid ? 'Choose 1 side item' : '';
    } else if (!group.mandatory && group.minselects !== null && group.maxselects === null) {
      isValid = selectedOptsCount >= Number(group.minselects);
      validationMessage = !isValid ? `Choose ${group.minselects} side items` : '';
    } else if (!group.mandatory && group.minselects !== null && group.maxselects !== null) {
      isValid = Number(group.minselects) <= selectedOptsCount && selectedOptsCount <= Number(group.maxselects);
      validationMessage = !isValid
        ? group.minselects !== group.maxselects
          ? `Choose between ${group.minselects} and ${group.maxselects} side items`
          : `Choose ${group.minselects} side items`
        : '';
    }
    values.push({
      id: group.id,
      title: group.description,
      description: group.mandatory
        ? 'Required'
        : (group.minselects !== null && group.maxselects === null) ||
          (group.minselects === group.maxselects && group.minselects !== null)
        ? `Choose ${group.minselects} options`
        : !group.mandatory && group.minselects !== null && group.maxselects !== null
        ? `Choose between ${group.minselects} and ${group.maxselects}`
        : '',
      type: group.mandatory ? 'radio' : 'checkbox',
      isvalid: isValid,
      validationMessage: validationMessage,
      mandatory: group.mandatory,
      minselects: group.minselects,
      maxselets: group.maxselects,
      options: options,
      ref: React.createRef(),
    });
    selected.forEach((selectedOption) => {
      values = [...values, ...getGroups(selectedOption.modifiers, selectedOptions)];
    });
  });
  return values;
};

const getSelectedByDefault = (groups) => {
  let values = [];
  groups.forEach((group) => {
    group.options.forEach((option) => {
      if (option.isdefault) {
        values.push(option.id);
        if (option.modifiers) {
          values = [...values, ...getSelectedByDefault(option.modifiers)];
        }
      }
    });
  });
  return values;
};

const cleanupSelected = (groups, selectedOptions) => {
  let selected = [...selectedOptions];
  groups.forEach((group) => {
    group.options.forEach((option) => {
      selected = selected.filter((selectedOption) => {
        return selectedOption !== option.id;
      });
      if (option.modifiers) {
        selected = [...cleanupSelected(option.modifiers, selected)];
      }
    });
  });
  return selected;
};

const getExtraCostBySelected = (groups, selectedOptions) => {
  let cost = 0;
  groups.forEach((group) => {
    group.options.forEach((option) => {
      const isSelected = selectedOptions.some((selectedOption) => {
        return selectedOption === option.id;
      });
      if (isSelected) {
        cost += Number(option.cost);
      }
    });
  });
  return cost;
};

const ProductModifiersView = ({
  id,
  defaultSelected = [],
  showError,
  scrollingToError,
  contentRef,
  onChange,
  additionalOffset,
  emptyModifiers,
}) => {
  const [groups, setGroups] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(defaultSelected);

  const [{ loading, data, error }] = useProductModifiersFetch(id, emptyModifiers);
  const hasData = !loading && !error && data && data.length > 0;

  useEffect(() => {
    const selectedByDefault = data ? getSelectedByDefault(data) : [];
    if (selectedByDefault.length > 0 && defaultSelected.length === 0) {
      setSelectedOptions(selectedByDefault);
    }
  }, [data, defaultSelected]);

  useEffect(() => {
    if (data && selectedOptions) {
      const values = getGroups(data, selectedOptions);
      setGroups(values);
      if (onChange) {
        const cost = getExtraCostBySelected(values, selectedOptions);
        const isValid = !values.some((value) => {
          return !value.isvalid;
        });
        onChange(selectedOptions, cost, isValid);
      }
    }
  }, [data, selectedOptions, onChange]);

  useEffect(() => {
    if (groups && showError && scrollingToError) {
      const isInvalid = groups.find((group) => {
        return !group.isvalid;
      });
      if (isInvalid && isInvalid.ref && isInvalid.ref.current && contentRef && contentRef.current) {
        contentRef.current.scrollTo(0, isInvalid.ref.current.offsetTop - additionalOffset);
      }
    }
  }, [contentRef, groups, showError, scrollingToError, additionalOffset]);

  const handleOptionSelection = useCallback(
    (group, option, value) => {
      let newSelected = [...selectedOptions];
      if (group.type === 'radio') {
        newSelected = selectedOptions.filter((selectedOption) => {
          return !group.options.some((option) => {
            return option.id === selectedOption;
          });
        });
        newSelected.push(option.id);
        if (option.modifiers) {
          newSelected = [...cleanupSelected(option.modifiers, newSelected), ...getSelectedByDefault(option.modifiers)];
        }
      } else {
        if (value) {
          newSelected.push(option.id);
          if (option.modifiers) {
            newSelected = [...newSelected, ...getSelectedByDefault(option.modifiers)];
          }
        } else {
          newSelected = selectedOptions.filter((selectedOption) => {
            return option.id !== selectedOption;
          });
          if (option.modifiers) {
            newSelected = cleanupSelected(option.modifiers, newSelected);
          }
        }
      }
      setSelectedOptions(newSelected);
    },
    [selectedOptions]
  );

  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not load product options. Please try again later!')) ||
    '';

  return (
    <>
      {errorMessage && <Alert message={errorMessage} />}
      {loading && <Spin className={styles.spinner} spinning={loading} />}
      {hasData && (
        <div className={styles.modifiers_wrapper}>
          {groups.map((group) => {
            return (
              <div key={group.id} ref={group.ref}>
                <Section
                  className={styles.modifiers_section}
                  titleElem={<PrimaryHeader title={group.title} brush={'after'} />}
                  description={group.description}
                  headerHr
                >
                  {!group.isvalid && showError && (
                    <Alert message={group.validationMessage || 'Please choose options before continuing'} />
                  )}
                  <div className={styles.options_container}>
                    {group.options.map((option) => {
                      const cost = option.cost ? (
                        <span className={styles.cost}>
                          +
                          <Price value={option.cost} />
                        </span>
                      ) : (
                        ''
                      );

                      return (
                        <span className={styles.option_wrapper} key={option.id}>
                          <ChooseButton
                            type={group.type}
                            checked={option.selected}
                            description={cost}
                            handleChange={(value) => {
                              handleOptionSelection(group, option, value);
                            }}
                            label={option.name}
                          />
                        </span>
                      );
                    })}
                  </div>
                </Section>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default React.memo(ProductModifiersView);
