import React, { Fragment } from 'react';
import styles from './InviteHistoryList.module.scss';
import { Divider } from '@material-ui/core';
import moment from 'moment';
import { REFERRAL_VALUES } from 'enums/referralValues';

function InviteHistoryList({ data }) {
  return (
    <div className={styles.invite_list_container}>
      <div className={styles.header}>
        <div className={styles.left_columns}>
          <div className={styles.column_date}>DATE</div>
          <div className={styles.column_mail}>MAIL</div>
        </div>

        <div className={styles.column_status}>STATUS</div>
      </div>

      {data.map((item, index) => (
        <Fragment key={index}>
          <Divider></Divider>

          <div className={styles.row}>
            <div className={styles.left_columns}>
              <div className={styles.column_date}>
                {moment(item.lastSentDate, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')}
              </div>
              <div className={styles.column_mail} title={item.email}>
                {item.email}
              </div>
            </div>

            <div className={styles.column_status}>{REFERRAL_VALUES[item.status]}</div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}

export default InviteHistoryList;
