import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { submitCustomerArrivalNotification } from 'api/submitCustomerArrivalNotification';

import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useSubmitArrivalNotification = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const submitCustomerArrival = useCallback(
    (orderId, message) => {
      const fetch = async () => {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const response = await submitCustomerArrivalNotification(orderId, 'Parking Spot Number: ' + message);
          showToast('success', 'Arrival notification was sent successfully!');
          dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
        } catch (error) {
          showToast('error', error.errorMessage || 'Something went wrong... Could not send that you arrived!');
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: error } });
        }
      };
      fetch();
    },
    [dispatch]
  );

  return [state, { submitCustomerArrival }];
};

export default useSubmitArrivalNotification;
