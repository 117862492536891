import React, { Fragment, useEffect, useState, memo, useCallback, useMemo } from 'react';
import styles from './SavedCreditCardModal.module.scss';
import ModalWindow from 'components/Modal/Modal';
import CardWrapper from '../CardWrapper/CardWrapper';
import CreditCardDetails from '../CreditCardDetails/CreditCardDetails';
import { PrimaryButton, TextButton } from 'components/Button/Button';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import useRemoveUserBillingAccount from './hooks/useRemoveUserBillingAccount';
import useUpdateUserDefaultCreditCard from './hooks/useUpdateUserDefaultCreditCard';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Section from 'components/Section/Section';
import moment from 'moment';
import { CheckboxWithLabel } from '../../../../../components/Checkbox/Checkbox';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrow_right.svg';
import RemoveCardModal from '../../../../../components/RemoveCardModal/RemoveCardModal';

const SavedCreditCardModal = ({ handleClose, isOpen, isShowModalFooter = true, defaultSelectedCard }) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    userBillingAccounts: { billingAccounts },
  } = useAppStateContext();
  const creditCardAccounts = useMemo(
    () =>
      billingAccounts &&
      billingAccounts.length &&
      billingAccounts.filter((account) => !account.accounttype.toLowerCase().includes('gift card')),
    [billingAccounts]
  );

  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedCardDetails, setSelectedCardDetails] = useState(null);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [{ loading: isRemoving, error: errorBillingAccount }, removeBillingAccount] = useRemoveUserBillingAccount();
  const [
    { loading: isUpdating, error: isUpdatingError, data: updateData },
    updateBillingAccount,
  ] = useUpdateUserDefaultCreditCard();
  const appDispatch = useAppDispatchContext();

  const openConfirmationModal = () => {
    setIsConfirmModal(true);
  };

  const saveDefaultCard = () => {
    updateBillingAccount(selectedCardId);
  };

  const onCloseModal = () => {
    setIsConfirmModal(false);
  };

  const onDeleteCard = useCallback(() => {
    if (selectedCardId) {
      removeBillingAccount(selectedCardId)
        .then(() => {
          setSelectedCardId(null);
          setSelectedCardDetails(null);
          appDispatch({
            type: ACTION_TYPES.cleanUpUserSelectedBillingAccounts,
          });
        })
        .finally(onCloseModal);
    }
  }, [removeBillingAccount, appDispatch, selectedCardId]);

  useEffect(() => {
    if (
      !isUpdating &&
      !isUpdatingError &&
      updateData &&
      selectedCardDetails &&
      updateData.isDefault !== selectedCardDetails.isDefault &&
      updateData.id === selectedCardId
    ) {
      setSelectedCardDetails(updateData);
    }
  }, [isUpdating, isUpdatingError, updateData, selectedCardId, selectedCardDetails]);

  const onUseCreditCard = () => {
    appDispatch({
      payload: { selectedBillingAccount: selectedCardDetails },
      type: ACTION_TYPES.setUserBillingAccountsData,
    });
    handleClose(true);
  };

  useEffect(() => {
    if (defaultSelectedCard && !selectedCardId && creditCardAccounts) {
      const card = creditCardAccounts.find((card) => card.id === defaultSelectedCard);
      setSelectedCardDetails(card);
      setSelectedCardId(defaultSelectedCard);
    }
  }, [defaultSelectedCard, selectedCardId, creditCardAccounts]);

  useEffect(() => {
    if (!creditCardAccounts || (Array.isArray(creditCardAccounts) && !creditCardAccounts.length)) {
      handleClose();
    }
  }, [creditCardAccounts, handleClose]);

  const onClickCard = (card) => () => {
    setSelectedCardId(card.id);
    setSelectedCardDetails(card);
  };

  return (
    <>
      <ModalWindow
        footerChildren={
          isShowModalFooter ? (
            <div className={styles.actionsWrapper}>
              <PrimaryButton
                disabled={!selectedCardDetails || isRemoving || isUpdating}
                fullWidth={isMobileSize}
                endIcon={<ArrowRight />}
                onClick={onUseCreditCard}
              >
                Use This Card
              </PrimaryButton>
            </div>
          ) : null
        }
        contrast={false}
        secondaryContrast={true}
        onChange={handleClose}
        open={isOpen}
      >
        <div className={styles.wrapper}>
          <div className={styles.cardList}>
            <Section title="Your Cards" sectionHr={true} className={styles.section}>
              <div className={styles.cards_wrapper}>
                {Array.isArray(creditCardAccounts) &&
                  creditCardAccounts
                    .sort((a, b) => b.isDefault - a.isDefault)
                    .map((account) => (
                      <CardWrapper
                        isSelected={selectedCardId === account.id}
                        key={account.id}
                        onClick={onClickCard(account)}
                      >
                        {account.isDefault && <div className={styles.header_card}>{'Default Card'}</div>}
                        <div className={styles.card_type}>{`${account.cardType.toUpperCase()}: ******${
                          account.cardNumber
                        }`}</div>
                        <div className={styles.card_name}>{`${account.name} ${account.expiryMonth}/${moment(
                          account.expiryYear,
                          'YYYY'
                        ).format('YYYY')}`}</div>
                      </CardWrapper>
                    ))}
              </div>
            </Section>
          </div>
          <div className={styles.cardDetails}>
            <Section title="Card Information" sectionHr={true} className={styles.section}>
              {selectedCardDetails && (
                <Fragment>
                  <CreditCardDetails {...selectedCardDetails} />
                  <div className={styles.buttonsWrapper}>
                    <TextButton
                      disabled={selectedCardDetails.isDefault || isRemoving || isUpdating}
                      color={'secondary'}
                      onClick={openConfirmationModal}
                    >
                      {'Delete'}
                    </TextButton>

                    <CheckboxWithLabel
                      handleChange={saveDefaultCard}
                      label={'Set as default card'}
                      disabled={selectedCardDetails.isDefault || isRemoving || isUpdating}
                      checked={!!selectedCardDetails.isDefault}
                    />
                  </div>
                </Fragment>
              )}
            </Section>
          </div>
        </div>
      </ModalWindow>

      <RemoveCardModal
        open={isConfirmModal}
        disabled={isRemoving || !!errorBillingAccount}
        loading={isRemoving}
        onChange={onCloseModal}
        onSubmit={onDeleteCard}
        onCancel={onCloseModal}
      />
    </>
  );
};

const MemoizedSavedCreditCardModal = memo(SavedCreditCardModal);

export default MemoizedSavedCreditCardModal;
