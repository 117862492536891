import produce from 'immer';

export const FETCH_ACTION_TYPES = {
  loading: 'LOADING',
  setData: 'SET_DATA',
  setError: 'SET_ERROR',
};

export const fetchReducer = (state, action) =>
  produce(state, (draft) => {
    if ('type' in action) {
      switch (action.type) {
        case FETCH_ACTION_TYPES.loading: {
          const { loading, refetch = false } = action.payload;
          draft.loading = loading;
          if (refetch) {
            draft.error = false;
          } else {
            draft.error = false;
            draft.data = null;
          }
          return draft;
        }
        case FETCH_ACTION_TYPES.cleanState: {
          draft.loading = false;
          draft.error = false;
          draft.data = null;
          return draft;
        }
        case FETCH_ACTION_TYPES.setData: {
          const { data } = action.payload;
          draft.loading = false;
          draft.error = false;
          draft.data = data;
          return draft;
        }
        case FETCH_ACTION_TYPES.setError: {
          const { error } = action.payload;
          draft.loading = false;
          draft.error = error;
          draft.data = null;
          return draft;
        }
        default: {
          return draft;
        }
      }
    } else {
      return draft;
    }
  });
