import { useEffect, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { createBasketFromPreviousOrder } from '../../../api/createBasketFromPreviousOrder';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useCreateBasketFromFavoriteFetch = (orderId, authenticated) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      if (orderId && authenticated) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const basket = await createBasketFromPreviousOrder(orderId);

          if (!ignore) {
            dispatch({
              type: FETCH_ACTION_TYPES.setData,
              payload: { data: basket },
            });
          }
        } catch (e) {
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
          }
        }
      }
    };

    fetch();

    return () => {
      ignore = true;
    };
  }, [orderId, authenticated]);
  return state;
};

export default useCreateBasketFromFavoriteFetch;
