import { useCallback } from 'react';
import { addUpsellItems as addUpsellItemsFetch } from 'api/addUpsellItems';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useQueryUrlParams } from 'hooks';
import showToast from 'components/Toast/ShowToast';

const useAddUpsellItems = () => {
  const appDispatch = useAppDispatchContext();
  const { basketId } = useQueryUrlParams();
  const addUpsellItems = useCallback(
    (upsell) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          if (upsell.length > 0 && basketId) {
            appDispatch({ type: ACTION_TYPES.loadingBasket, payload: { loading: true } });
            try {
              const response = await addUpsellItemsFetch(basketId, upsell);
              appDispatch({ type: ACTION_TYPES.setBasket, payload: { data: response } });
              resolve(true);
            } catch (e) {
              const msg = e.errorMessage || 'Error add upsell item to basket';
              showToast('error', msg);
              appDispatch({ type: ACTION_TYPES.errorBasket, payload: { error: e } });
              reject(false);
            }
          }
        };
        fetch();
      }),
    [basketId, appDispatch]
  );
  return addUpsellItems;
};

export default useAddUpsellItems;
