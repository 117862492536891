import React, { memo } from 'react';
import styles from './RestaurantInfo.module.scss';
import { useRestaurantInfo } from 'components/OrderingFrom/OrderingFrom';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import { TextButton } from 'components/Button/Button';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import ChangeLocationLink from 'components/ChangeLocationLink/ChangeLocationLink';
import { useLocation } from 'react-router';

const RestaurantInfo = ({ showEdit = true, onClose }) => {
  const {
    dataLoaded,
    isDefinedAddress,
    loading,
    error,
    shortCityName,
    streetaddress,
    zip,
    distance,
  } = useRestaurantInfo(['zip', 'distance', 'streetaddress']);
  const { pathname } = useLocation();
  const {
    groupOrder: { data: groupOrderData },
  } = useAppStateContext();
  const canChangeLocation = !(groupOrderData && groupOrderData.id);
  const showChangeLocation = dataLoaded && !loading && canChangeLocation && showEdit;

  const errorMessage =
    (error &&
      (error.errorMessage ||
        'Something went wrong... Could not load restaurant information. Please try again later!')) ||
    '';
  return (
    <>
      {loading && <Spin spinning={loading} />}
      {errorMessage && <Alert message={errorMessage} />}
      {dataLoaded && !isDefinedAddress && pathname === '/' && (
        <div className={styles.empty_location}>
          <TextButton onClick={onClose}>Please select a location to start an order.</TextButton>
        </div>
      )}
      {dataLoaded && isDefinedAddress && (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h4>{shortCityName}</h4>
            {showChangeLocation && <ChangeLocationLink label={'Edit'} className={styles.link} />}
          </div>
          <div className={styles.description}>
            <p>{streetaddress}</p>
            <p>
              {shortCityName} {zip}
            </p>
            {distance !== 0 && <p className={styles.primary}>{`${distance} miles away`}</p>}
          </div>
        </div>
      )}
    </>
  );
};
const MemoizedRestaurantInfo = memo(RestaurantInfo);
export default MemoizedRestaurantInfo;
