import axios from './axiosInstances/axiosInstance';

const URI = '/baskets';

export const setBasketCustomField = (basketId, customField) => {
  return axios.put(`${URI}/${basketId}/customfields`, customField);
};

export const deleteBasketCustomField = (basketId, id) => {
  return axios.delete(`${URI}/${basketId}/customfields/${id}`);
};
