import React, { useState } from 'react';
import styles from './UIComponents.module.scss';
import { PrimaryButton, TextButton, PrimaryIconButton } from 'components/Button/Button';
import SearchIcon from '@material-ui/icons/Search';
import SimpleSelect from 'components/Select/Select';
import { InputField, MultilineInputField } from 'components/Input/Input';
import Section from 'components/Section/Section';
import AddIcon from '@material-ui/icons/Add';
import { CheckboxWithLabel } from 'components/Checkbox/Checkbox';
import { RadioboxWithLabel } from 'components/Radiobox/Radiobox';
import ChooseButton from 'components/ChooseButton/ChooseButton';
import Notification from 'components/Notification/Notification';
import OrderPlaceCard from 'components/OrderPlaceCard/OrderPlaceCard';
import ModalWindow from 'components/Modal/Modal';
import DrawerLayout from 'components/DrawerLayout/DrawerLayout';
import { Container, RadioGroup } from '@material-ui/core';
import Accordion from 'components/Accordion/Accordion';
import MapMarker from 'components/GoogleMap/MapMarker/MapMarker';
import Alert from 'components/Alert/Alert';
import ActionTable from 'components/ActionTable/ActionTable';
import showToast from 'components/Toast/ShowToast';
import PulseButton from 'components/PulseButton/PulseButton';
import ButtonGroup from 'components/ButtonGroup/ButtonGroup';

const UIComponents = () => {
  const [state, setState] = useState({
    modals: {
      default: false,
    },
    drawerOpenFirst: false,
    drawerOpenSecond: false,
  });
  const [radioValue, setValue] = React.useState('female');
  const [checkValue, setCheckValue] = React.useState({
    'checkbox-1': '',
    'checkbox-2': '',
    'checkbox-3': '',
  });

  const handleChangeGroup = (event) => {
    setValue(event.target.value);
  };
  const onChangeCheckbox = (name) => (value) => {
    setCheckValue({ ...checkValue, [name]: value });
  };
  const handlerOpenModal = (type) => () => {
    setState({ ...state, modals: { ...state.modals, [type]: true } });
  };
  const handlerCloseModal = (type) => (value) => {
    setState({ ...state, modals: { ...state.modals, [type]: value } });
  };
  const handlerDrawer = (type) => (value) => {
    setState({ ...state, [type]: value });
  };
  const handlerNotification = (type) => () => {
    showToast(type, 'Lorem lorem lore lorem lorem lorem');
  };
  return (
    <div className={styles.UIComponents}>
      <Container>
        <h2>Style guide</h2>
        <hr />
        <h1>HEADER 1</h1>
        <h2>HEADER 2</h2>
        <h3>HEADER 3</h3>
        <h4>HEADER 4</h4>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos dolores
          harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam
          nesciunt.
        </p>

        <Section title={'Notifications'}>
          <p>
            <TextButton onClick={handlerNotification('success')}>Show success notification</TextButton>
            <TextButton onClick={handlerNotification('info')}>Show info notification</TextButton>
            <TextButton onClick={handlerNotification('warning')}>Show warning notification</TextButton>
            <TextButton onClick={handlerNotification('error')}>Show error notification</TextButton>
          </p>
        </Section>
        <Section title={'Category lines'}>
          <Section
            title={'Category line with row description'}
            description={'Get them while you can! Available for a limited time.'}
            vector={'before'}
          />
          <Section
            title={'Category line with column description'}
            description={'Get them while you can! Available for a limited time.'}
            displayHeaderType={'column'}
            vector={'after'}
          />
          <Section
            title={'Category line with column description and both vector'}
            description={'Get them while you can! Available for a limited time.'}
            displayHeaderType={'column'}
            vector={'both'}
          />
        </Section>
        <Section title={'Pulse button'}>
          <PulseButton>CONTINUE TO BAG</PulseButton>
        </Section>
        <Section title={'Controlled Choose Radio Button'}>
          <RadioGroup name="gender1" value={radioValue} onChange={handleChangeGroup}>
            <ChooseButton
              value={'1'}
              checked={radioValue === '1'}
              description={'Add 190 calories'}
              price={'+ $0.99'}
              label={'default Choose Button 1'}
            />
            <ChooseButton
              value={'2'}
              checked={radioValue === '2'}
              description={'Add 190 calories'}
              price={'+ $0.99'}
              label={'default Choose Button 2'}
            />
            <ChooseButton
              value={'3'}
              description={'Add 190 calories'}
              price={'+ $0.99'}
              checked={radioValue === '3'}
              label={'default Choose Button 3'}
            />
            <ChooseButton
              value={'4'}
              description={'Add 190 calories'}
              price={'+ $0.99'}
              checked={radioValue === '4'}
              label={'default Choose Button 4'}
            />
            <ChooseButton
              value={'5'}
              description={'Add 190 calories'}
              price={'+ $0.99'}
              checked={radioValue === '5'}
              label={'default Choose Button 5'}
            />
          </RadioGroup>
        </Section>
        <Section title={'Controlled Choose Checkbox Button'}>
          <ChooseButton
            type="checkbox"
            name={'checkbox-1'}
            description={'Add 190 calories'}
            price={'+ $0.99'}
            handleChange={onChangeCheckbox('checkbox-1')}
            label={'default Choose Button'}
            checked={checkValue['checkbox-1']}
          />
          <ChooseButton
            type="checkbox"
            name={'checkbox-2'}
            description={'Add 190 calories'}
            price={'+ $0.99'}
            handleChange={onChangeCheckbox('checkbox-2')}
            label={'default Choose Button checked'}
            checked={checkValue['checkbox-2']}
          />
          <ChooseButton
            type="checkbox"
            name={'checkbox-3'}
            description={'Add 190 calories'}
            price={'+ $0.99'}
            handleChange={onChangeCheckbox('checkbox-3')}
            label={'default Choose Button disabled'}
            checked={checkValue['checkbox-3']}
          />
        </Section>
        <Section title={'Map components'}>
          <MapMarker />
        </Section>
        <Section title={'Right Layout Drawer'}>
          <p>Drawer layout with header and transparent footer</p>
          <PrimaryButton onClick={handlerDrawer('drawerOpenFirst')}>Drawer with header</PrimaryButton>
          <p>Drawer layout without header and fill footer</p>
          <PrimaryButton onClick={handlerDrawer('drawerOpenSecond')}>Default Drawer</PrimaryButton>
          <DrawerLayout
            open={state['drawerOpenFirst'] || false}
            headerTitle={'ADD TO ORDER'}
            transparentFooter
            headerDescription={
              'Make your meal perfect with a shareable starter, a slice of award-winning “old fashioned” pie, or a glass of Southern sweet tea. Choose from the options below.'
            }
            footerChildren={<TextButton>CHECKOUT: SUBTOTAL $168.96</TextButton>}
            onChange={handlerDrawer('drawerOpenFirst')}
          >
            <Section title={'ORDERING FROM'}>
              <h4>City name</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
            </Section>
            <Section title={'SELECTED ITEMS'}>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
            </Section>
          </DrawerLayout>
          <DrawerLayout
            open={state['drawerOpenSecond'] || false}
            footerChildren={<PrimaryButton>CHECKOUT: SUBTOTAL $168.96</PrimaryButton>}
            onChange={handlerDrawer('drawerOpenSecond')}
          >
            <Section title={'ORDERING FROM'}>
              <h4>City name</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
            </Section>
            <Section title={'SELECTED ITEMS'}>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
              <h4>SELECTED ITEMS</h4>
              <p className="p3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos
                dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur
                deserunt magnam nesciunt.
              </p>
            </Section>
          </DrawerLayout>
        </Section>
        <Section title={'Modal window'}>
          <TextButton onClick={handlerOpenModal('default')}>Open default modal</TextButton>
          <TextButton onClick={handlerOpenModal('withFooter')}>Open modal with footer</TextButton>
          <TextButton onClick={handlerOpenModal('bigContent')}>Open modal with big content</TextButton>
          <TextButton onClick={handlerOpenModal('customHeader')}>Open modal with custom header</TextButton>
          {/* DEFAULT MODAL TYPE */}
          <ModalWindow
            open={state.modals['default']}
            onChange={handlerCloseModal('default')}
            headerTitle={'ORDER HISTORY'}
          >
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos dolores
              harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam
              nesciunt.
            </p>
            <p className="p1">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos dolores
              harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam
              nesciunt.
            </p>
            <p className="p2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos dolores
              harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam
              nesciunt.
            </p>
            <p className="p3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos dolores
              harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam
              nesciunt.
            </p>
          </ModalWindow>
          {/* WITH BIG CONTENT */}
          <ModalWindow
            open={state.modals['bigContent']}
            onChange={handlerCloseModal('bigContent')}
            headerTitle={'ORDER HISTORY'}
            headerActions={<TextButton>Back</TextButton>}
            footerChildren={
              <div className={styles.modal_footer_custom}>
                <TextButton>Cancel</TextButton>
                <PrimaryButton size="large">Add to order</PrimaryButton>
              </div>
            }
          >
            <Section title={'Choose Your Temperature (Required)'}></Section>
          </ModalWindow>
          {/* WITH BIG CONTENT AND CUSTOM HEADER*/}
          <ModalWindow
            open={state.modals['customHeader']}
            onChange={handlerCloseModal('customHeader')}
            headerChildren={
              <div
                className={styles.test_modal_custom_header}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <img src="https://cdn.pixabay.com/photo/2017/04/04/00/36/japanese-food-2199962_960_720.jpg" alt="" />
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum accusantium ipsa quidem inventore
                  libero maxime ducimus doloribus, repellat temporibus cupiditate veritatis incidunt laudantium omnis
                  nisi velit quas vero in nobis?Dignissimos velit repudiandae ut ducimus omnis sequi maxime cumque iste
                  fugiat similique, possimus quibusdam aspernatur dolore quisquam itaque magni blanditiis explicabo
                  saepe distinctio atque voluptates deleniti incidunt doloribus quos! Eum!
                </div>
              </div>
            }
            footerChildren={
              <div className={styles.modal_footer_custom}>
                <TextButton>Cancel</TextButton>
                <PrimaryButton size="large">Add to order</PrimaryButton>
              </div>
            }
          >
            <Section title={'Complete Your Meal'}>
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
              <ChooseButton
                type="checkbox"
                description={'+ $0.99'}
                handleChange={() => {}}
                label={'default Choose Button'}
              />
            </Section>
          </ModalWindow>
          {/* WITH FOOTER */}
          <ModalWindow
            open={state.modals['withFooter']}
            onChange={handlerCloseModal('withFooter')}
            headerTitle={'ORDER HISTORY'}
            footerChildren={
              <div className={styles.modal_footer_custom}>
                <TextButton>Cancel</TextButton>
                <PrimaryButton size="large">Add to order</PrimaryButton>
              </div>
            }
          >
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos dolores
              harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam
              nesciunt.
            </p>
            <p className="p1">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos dolores
              harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam
              nesciunt.
            </p>
            <p className="p2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos dolores
              harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam
              nesciunt.
            </p>
            <p className="p3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem sunt vero rem? Excepturi quos dolores
              harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam
              nesciunt.
            </p>
          </ModalWindow>
        </Section>
        <Section title={'Order Place Card'}>
          <OrderPlaceCard
            title={'Shake Shack Green Hills'}
            btnLabel={'Order pickup'}
            description={[
              { label: '4031 Hillsboro Pike Suite' },
              { label: 'Nashville, TN 37215' },
              { label: '(615) 619-6326' },
              { label: 'Open until 8:00pm' },
              { label: '4.2 miles' },
            ]}
          />
          <OrderPlaceCard
            title={'Shake Shack Green Hills'}
            btnLabel={'Order pickup'}
            forMap={true}
            description={[
              { label: '4031 Hillsboro Pike Suite' },
              { label: 'Nashville, TN 37215' },
              { label: '(615) 619-6326' },
              { label: 'Open until 8:00pm' },
              { label: '4.2 miles away', accent: true },
            ]}
          />
        </Section>
        <Section title={'Accordion'}>
          <Accordion header={'Accordion title'}>
            <div style={{ margin: '1rem 0' }}>
              <OrderPlaceCard
                title={'Shake Shack Green Hills'}
                btnLabel={'Order pickup'}
                description={[
                  { label: '4031 Hillsboro Pike Suite' },
                  { label: 'Nashville, TN 37215' },
                  { label: '(615) 619-6326' },
                  { label: 'Open until 8:00pm' },
                  { label: '4.2 miles' },
                ]}
              />
            </div>
            <div style={{ margin: '1rem 0' }}>
              <OrderPlaceCard
                title={'Shake Shack Green Hills'}
                btnLabel={'Order pickup'}
                description={[
                  { label: '4031 Hillsboro Pike Suite' },
                  { label: 'Nashville, TN 37215' },
                  { label: '(615) 619-6326' },
                  { label: 'Open until 8:00pm' },
                  { label: '4.2 miles' },
                ]}
              />
            </div>
          </Accordion>
        </Section>
        <Section title={'Notifications default'}>
          <Notification message={'THIS IS SUCCESS MESSAGE!'} type={'success'} />
          <Notification message={'THIS IS INFORMATION MESSAGE!'} type={'info'} />
          <Notification message={'THIS IS WARNING MESSAGE!'} type={'warning'} />
          <Notification message={'THIS IS ERROR MESSAGE!'} type={'error'} />
        </Section>
        <Section title={'Form/page alert'}>
          <Alert message={'Something went wrong... Could not load menu. Please try again later!'} />
        </Section>
        <Section title="Checkbox">
          <CheckboxWithLabel handleChange={() => {}} label={'default checkbox'} />
          <CheckboxWithLabel handleChange={() => {}} label={'default checkbox checked'} checked />
          <CheckboxWithLabel handleChange={() => {}} label={'default checkbox checked disabled'} checked disabled />
          <CheckboxWithLabel handleChange={() => {}} label={'default checkbox disabled'} disabled />
        </Section>
        <Section title="Radiobox">
          <RadioboxWithLabel handleChange={() => {}} label={'default Radiobox'} />
          <RadioboxWithLabel handleChange={() => {}} label={'default Radiobox checked'} checked />
          <RadioboxWithLabel handleChange={() => {}} label={'default Radiobox checked disabled'} checked disabled />
          <RadioboxWithLabel handleChange={() => {}} label={'default Radiobox disabled'} disabled />
        </Section>
        <Section title="Choose Radio Button">
          <ChooseButton
            description={'Add 190 calories'}
            price={'+ $0.99'}
            handleChange={() => {}}
            label={'default Choose Button'}
          />
          <ChooseButton
            description={'Add 190 calories'}
            price={'+ $0.99'}
            handleChange={() => {}}
            label={'default Choose Button disabled'}
            disabled
          />
          <ChooseButton
            description={'Add 190 calories'}
            price={'+ $0.99'}
            handleChange={() => {}}
            label={'default Choose Button checked'}
            checked
          />
          <ChooseButton
            description={'Add 190 calories'}
            price={'+ $0.99'}
            handleChange={() => {}}
            label={'default Choose Button checked disabled'}
            checked
            disabled
          />
        </Section>
        <Section title="Choose Checkbox Button">
          <ChooseButton
            type="checkbox"
            name={'checkbox-1'}
            description={'Add 190 calories'}
            price={'+ $0.99'}
            handleChange={() => {}}
            label={'default Choose Button'}
          />
          <ChooseButton
            type="checkbox"
            name={'checkbox-2'}
            description={'Add 190 calories'}
            price={'+ $0.99'}
            disabled
            label={'default Choose Button checked'}
          />
          <ChooseButton
            type="checkbox"
            name={'checkbox-3'}
            description={'Add 190 calories'}
            price={'+ $0.99'}
            label={'default Choose Button disabled'}
            checked
          />
          <ChooseButton
            type="checkbox"
            name={'checkbox-2'}
            description={'Add 190 calories'}
            price={'+ $0.99'}
            disabled
            label={'default Choose Button checked disabled'}
            checked
          />
        </Section>
        <Section title={'Select'} description={'Styling selector'}>
          <h4>Default</h4>
          <div style={{ margin: '1rem' }}>
            <SimpleSelect
              onChange={(value) => setState({ ...state, simpleSelect: value })}
              value={state['simpleSelect']}
              label={'Default label'}
              items={[
                { label: 'label 1', value: '1' },
                { label: 'label 2', value: '2' },
                { label: 'label 3', value: '3' },
                { label: 'label 4', value: '4' },
              ]}
            />
          </div>
          <h4>Disabled</h4>
          <div style={{ margin: '1rem' }}>
            <SimpleSelect
              onChange={(value) => setState({ ...state, simpleSelect: value })}
              value={state['simpleSelect']}
              label={'Default label'}
              disabled
              items={[
                { label: 'label 1', value: '1' },
                { label: 'label 2', value: '2' },
              ]}
            />
          </div>
        </Section>
        <Section
          title={'Input Field'}
          description={
            'Excepturi quos dolores harum soluta adipisci laboriosam. Eaque error quis provident cumque laborum nihil pariatur deserunt magnam nesciunt.'
          }
        >
          <h4>Default</h4>
          <div style={{ margin: '1rem' }}>
            <InputField />
          </div>
          <h4>Disabled</h4>
          <div style={{ margin: '1rem' }}>
            <InputField disabled />
          </div>
          <h4>Error</h4>
          <div style={{ margin: '1rem' }}>
            <InputField error helperText="Incorrect entry." />
          </div>
          <h4>Default large size</h4>
          <div style={{ margin: '1rem' }}>
            <MultilineInputField />
          </div>
          <h4>Default disabled large size</h4>
          <div style={{ margin: '1rem' }}>
            <MultilineInputField disabled />
          </div>
          <h4>Default Error large size</h4>
          <div style={{ margin: '1rem' }}>
            <MultilineInputField error />
          </div>
        </Section>
        <Section title={'Primary Button'}>
          <div className={styles.button_wrapper}>
            <h4>Primary Button Type</h4>
            <PrimaryButton>START YOUR ORDER</PrimaryButton>
            <PrimaryButton disabled={true}>Disabled</PrimaryButton>
            <PrimaryButton variant="outlined">OUTLINED</PrimaryButton>
          </div>
          <div className={styles.button_wrapper}>
            <h4>Primary Button Size</h4>
            <PrimaryButton size={'medium'}>MEDIUM START YOUR ORDER</PrimaryButton>
            <PrimaryButton size="large">LARGE START YOUR ORDER</PrimaryButton>
          </div>
          <div className={styles.button_wrapper}>
            <h4>Primary Button with Icon</h4>
            <PrimaryButton endIcon={<SearchIcon />}>MEDIUM BAG</PrimaryButton>
            <PrimaryButton disabled endIcon={<SearchIcon fontSize="large" />}>
              LARGE BAG DISABLED
            </PrimaryButton>
            <PrimaryButton endIcon={<SearchIcon fontSize="large" />} size="large">
              LARGE BAG
            </PrimaryButton>
            <PrimaryButton disabled variant="outlined" endIcon={<SearchIcon fontSize="inherit" />} size="large">
              LARGE OUTLINED BAG
            </PrimaryButton>
          </div>
        </Section>
        <Section title={'Text BUTTON'}>
          <div className={styles.button_wrapper}>
            <h4>Text Button Type</h4>
            <TextButton>TEXT BUTTON</TextButton>
            <TextButton disabled>TEXT BUTTON DISABLED</TextButton>
          </div>
          <div className={styles.button_wrapper}>
            <h4>Text Button Size</h4>
            <TextButton>MEDIUM START YOUR ORDER</TextButton>
            <TextButton size="large">LARGE START YOUR ORDER</TextButton>
          </div>
          <div className={styles.button_wrapper}>
            <h4>Text Button Size Disabled</h4>
            <TextButton disabled>MEDIUM START YOUR ORDER</TextButton>
            <TextButton disabled size="large">
              LARGE START YOUR ORDER
            </TextButton>
          </div>
        </Section>
        <Section title={'Primary icon BUTTON'}>
          <div className={styles.button_wrapper}>
            <h4>Icon Button Type</h4>
            <PrimaryIconButton>
              <AddIcon />
            </PrimaryIconButton>
            <PrimaryIconButton disabled>
              <AddIcon />
            </PrimaryIconButton>
          </div>
          <div className={styles.button_wrapper}>
            <h4>Icon Button Size</h4>
            <PrimaryIconButton>
              <AddIcon />
            </PrimaryIconButton>
            <PrimaryIconButton size="small">
              <AddIcon />
            </PrimaryIconButton>
          </div>
        </Section>

        <Section>
          <div className={styles.action_table_container}>
            <ActionTable
              data={[
                { id: 1, name: 'Wednesday Burger Night' },
                { id: 2, name: 'Kid’s Dinner' },
                { id: 3, name: 'Date NighFt' },
              ]}
            >
              <TextButton className={styles.action_table_btn}>Modify</TextButton>
              <TextButton className={`${styles.action_table_btn} ${styles.delete}`}>Delete</TextButton>
            </ActionTable>
          </div>
        </Section>
        <Section>
          <ButtonGroup>
            <PrimaryButton className={'active'} variant="contained">
              DELIVERY
            </PrimaryButton>
            <PrimaryButton>CURBSIDE</PrimaryButton>
          </ButtonGroup>
        </Section>
      </Container>
    </div>
  );
};

export default UIComponents;
