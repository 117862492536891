import React, { memo, useCallback } from 'react';
import { useQueryUrlCleanParamsDispatch } from 'hooks/useQueryUrlParamsDispatch';

import CloseIcon from '@material-ui/icons/Close';

import { PrimaryIconButton } from 'components/Button/Button';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import { PrimaryButton } from 'components/Button/Button';
import Price from 'components/Price/Price';
import Quantity from 'components/Quantity/Quantity';

import useAddingProductToBasket from 'modules/ProductDetailsModal/hooks/useAddingProductToBasket';
import useRemoveProductFromBasket from '../hooks/removeProductFromBasket';
import styles from './BasketItems.module.scss';

const EmptyBag = memo(({ onClose }) => {
  return (
    <div className={styles.empty_wrapper}>
      <h2>Your bag is empty</h2>
      <p>Add delicious items to get started.</p>
      <div className={styles.empty_btn}>
        <PrimaryButton fullWidth onClick={onClose}>
          BACK
        </PrimaryButton>
      </div>
    </div>
  );
});

EmptyBag.displayName = 'EmptyBag';

const BasketItems = ({ onClose, loading, data, error, handleNameClick }) => {
  const cleanQuery = useQueryUrlCleanParamsDispatch();
  const products = (data && data['products']) || [];
  const [{ loading: updateProductLoading, error: updateProductError }, { updateProduct }] = useAddingProductToBasket();
  const removeItem = useRemoveProductFromBasket();

  const onRemoveProduct = (productId, name) => () => {
    removeItem(productId, name);
  };

  const onNameClick = (product) => () => {
    handleNameClick(product);
  };

  const handleBack = useCallback(() => {
    cleanQuery(['openBasket']);
    onClose();
  }, [onClose, cleanQuery]);

  const onChangeQuantity = useCallback(
    (product) => (quantity) => {
      if (product && product.quantity && quantity && !updateProductLoading) {
        const { recipient, specialinstructions, id, productId, choices } = product;
        const options = choices.length > 0 ? choices.map((choice) => choice.optionid).join(',') : '';
        updateProduct(id, productId, options, quantity, specialinstructions, recipient);
      }
    },
    [updateProductLoading, updateProduct]
  );

  const errorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not load basket. Please try again later!')) ||
    (updateProductError && updateProductError.errorMessage) ||
    '';

  const changeBagCondition = (data && loading) || updateProductLoading;
  const emptyBagCondition = !loading && !error && products.length === 0;
  const errorCondition = (errorMessage && !data) || updateProductError;
  const loadingCondition = loading && !data;

  return (
    <div className={styles.wrapper}>
      {loadingCondition && <Spin className={styles.spin} spinning={loading} />}
      {errorCondition && <Alert className={styles.alert} message={errorMessage} />}
      {emptyBagCondition && <EmptyBag onClose={handleBack} />}
      {changeBagCondition && <div className={styles.backdrop} />}
      {data &&
        products.length > 0 &&
        products.map((product) => {
          const { id, name, quantity, totalcost, choices, recipient } = product;
          return (
            <div className={styles.menu_item} key={id}>
              <div className={styles.item_header}>
                <div className={styles.item_title}>
                  <h4 onClick={onNameClick(product)}>{name}</h4>
                  <span className={styles.price}>
                    <Price value={totalcost} />
                  </span>
                </div>
                <div className={styles.left_side}>
                  <PrimaryIconButton className={styles.delete_btn} onClick={onRemoveProduct(id, name)} size="small">
                    <CloseIcon />
                  </PrimaryIconButton>
                </div>
              </div>
              {recipient && <span className={styles.recipient}>{recipient}</span>}
              {choices && choices.length > 0 && (
                <div className={styles.description}>
                  {choices.map(({ id, name }) => (
                    <span key={id}>{name}</span>
                  ))}
                </div>
              )}
              <div className={styles.quantity}>
                <div className={styles.quantity_title}>QTY.</div>
                <Quantity
                  classNameValue={'quantity_value'}
                  className={'quantity_container'}
                  size={'small'}
                  onChange={onChangeQuantity(product)}
                  defaultValue={quantity}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default React.memo(BasketItems);
