import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useQueryUrlParams } from 'hooks';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { PrimaryButton } from 'components/Button/Button';
import NotFoundLogo from 'components/NotFoundLogo/NotFoundLogo';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';

import CategoryProductsSlider from './CategoryProductsSlider/CategoryProductsSlider';
import CategoryProductList from './CategoryProductList/CategoryProductList';
import styles from './RestaurantCategoryList.module.scss';

const RestaurantCategoryList = ({
  selectedCategory,
  imagepath,
  menuSearchTerm,
  categories,
  top,
  onCategoryChange,
  onProductClick,
  onViewCategory,
}) => {
  const { groupOrderId = null } = useQueryUrlParams();
  const dispatch = useAppDispatchContext();
  const [scrollingInfo, setScrollingInfo] = useState(null);
  const categoryRefs = useRef([]);
  const { index } = selectedCategory || {};

  const categoryRef = (ind) => {
    return (node) => {
      if (node) {
        if (index !== undefined && ind === index) {
          if (scrollingInfo === null || (scrollingInfo && node.offsetTop !== scrollingInfo.top)) {
            setScrollingInfo({
              ...selectedCategory,
              top: node.offsetTop,
              scrollingTo:
                (scrollingInfo && scrollingInfo.index === index) ||
                scrollingInfo === null ||
                selectedCategory.scrollingTo,
            });
          } else if (scrollingInfo.scrollingTo !== selectedCategory.scrollingTo) {
            setScrollingInfo({
              ...scrollingInfo,
              scrollingTo: selectedCategory.scrollingTo,
              animation: selectedCategory.animation,
            });
          }
        }
        if (index === -1) {
          setScrollingInfo(null);
          categoryRefs.current = [];
        } else {
          categoryRefs.current[ind] = node;
        }
      }
    };
  };

  useEffect(() => {
    const handleWindowScroll = () => {
      if (
        selectedCategory === null ||
        selectedCategory.scrollingTo ||
        selectedCategory.index === -1 ||
        menuSearchTerm !== '' ||
        !categoryRefs
      ) {
        return false;
      }

      const categoryIndex = categoryRefs.current.findIndex((ref, index) => {
        if (!ref) {
          return false;
        }
        const offsetTop = ref.offsetTop;
        const prevOffset = categoryRefs.current[index - 1] ? categoryRefs.current[index - 1].clientHeight / 4 : 0;
        const nextOffset = ref.clientHeight / 4;
        return (
          (offsetTop - top > window.pageYOffset && index === 0) ||
          (offsetTop - top - prevOffset <= window.pageYOffset &&
            (index === categories.length - 1 ||
              (index < categories.length - 1 &&
                window.pageYOffset < categoryRefs.current[index + 1].offsetTop - top - nextOffset)))
        );
      });
      if (selectedCategory.index !== categoryIndex) {
        onCategoryChange({ index: categoryIndex, scrollingTo: false, animation: false });
      }
    };
    if (menuSearchTerm === '') {
      window.addEventListener('scroll', handleWindowScroll);
    } else {
      window.removeEventListener('scroll', handleWindowScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, [categories, categoryRefs, menuSearchTerm, top, selectedCategory, onCategoryChange]);

  useEffect(() => {
    const { top: selectedTop, index, scrollingTo, animation } = scrollingInfo || {};
    if (menuSearchTerm === '' && selectedTop && scrollingTo) {
      window.scrollTo({
        left: 0,
        top: index > 0 ? selectedTop - top : 0,
        behavior: animation ? 'smooth' : 'auto',
      });
      let timer = 0;
      const scrollHandler = () => {
        window.clearTimeout(timer);
        // Set a timeout to run after scrolling ends
        timer = setTimeout(function () {
          window.removeEventListener('scroll', scrollHandler);
          onCategoryChange({ index: index, scrollingTo: false, animation: false });
        }, 66);
      };
      window.removeEventListener('scroll', scrollHandler);
      window.addEventListener('scroll', scrollHandler, false);

      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [scrollingInfo, top, menuSearchTerm, onCategoryChange]);

  const handleMenuBackClick = useCallback(() => {
    dispatch({ type: ACTION_TYPES.cleanupMenuSearch });
  }, [dispatch]);

  const theme = useTheme();
  const isHandheldSize = useMediaQuery(theme.breakpoints.up(1025));

  return (
    <>
      {categories.length > 0
        ? categories.map((category, index) => {
            return isHandheldSize || (!isHandheldSize && menuSearchTerm !== '') ? (
              <CategoryProductList
                key={index}
                ref={categoryRef(index)}
                className={styles.section}
                category={category}
                imagepath={imagepath}
                onProductClick={onProductClick}
                groupOrderId={groupOrderId}
              />
            ) : (
              <CategoryProductsSlider
                key={index}
                ref={categoryRef(index)}
                className={styles.section}
                category={category}
                imagepath={imagepath}
                onProductClick={onProductClick}
                onSeeAll={(category) => {
                  onViewCategory(category, index);
                }}
                groupOrderId={groupOrderId}
              />
            );
          })
        : menuSearchTerm !== '' && (
            <div className={styles.no_search_results}>
              <NotFoundLogo />
              <p>{"Couldn't find anything that matched your search results"}</p>
              <PrimaryButton size="large" endIcon={<ArrowRight />} onClick={handleMenuBackClick}>
                Back to menu
              </PrimaryButton>
            </div>
          )}
    </>
  );
};

export default React.memo(RestaurantCategoryList);
