import React from 'react';
import MapMarker from '../MapMarker/MapMarker';
import OrderPlaceCard from 'components/OrderPlaceCard/OrderPlaceCard';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import classNames from 'classnames';
import styles from './MarkerInfoWindow.module.scss';

const MarkerInfoWindow = ({ showInfo, place = {}, onSelectOrder, $hover }) => {
  const {
    city,
    state,
    streetaddress,
    telephone,
    distance,
    isavailable,
    iscurrentlyopen,
    zip,
    latitude,
    longitude,
    calendars,
    supportsdispatch,
    supportscurbside,
  } = place;
  const theme = useTheme();
  const isHandheldSize = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classNames(styles.wrapper, { handheld_mode: isHandheldSize })}>
      <MapMarker hover={$hover} />
      {showInfo && !isHandheldSize && !isMobileSize && (
        <Zoom timeout={100} in={showInfo}>
          <div className={styles.info_window} onClick={(event) => event.stopPropagation()}>
            <OrderPlaceCard
              supportsdispatch={supportsdispatch}
              supportscurbside={supportscurbside}
              title={`${city}, ${state}`}
              lat={latitude}
              long={longitude}
              phone={telephone}
              calendars={calendars}
              btnLabel={'Start Your Order'}
              defaultSelected={showInfo}
              disabled={!isavailable}
              iscurrentlyopen={iscurrentlyopen}
              disabledMsg={'Not Accepting Online Orders'}
              onPick={() => onSelectOrder(place)}
              description={[
                { label: streetaddress },
                { label: `${city}, ${state} ${zip}` },
                { ...(distance !== 0 && { label: `${distance} miles away` }) },
              ]}
              forMap
            />
          </div>
        </Zoom>
      )}
    </div>
  );
};
export default React.memo(MarkerInfoWindow);
