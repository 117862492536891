import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import { createBasketFromFave } from 'api/createBasketFromFave';
import { addProductsFromFaveToBasket } from 'api/addProductsFromFaveToBasket';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useAddFavoriteToBasket = () => {
  let { basketId } = useQueryUrlParams();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppBasketState = useAppDispatchContext();

  const addFavoriteToBasket = useCallback(
    (faveId = '') =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            let response, basket, errors;
            const currentBasketId = basketId || '';

            if (currentBasketId) {
              response = await addProductsFromFaveToBasket(currentBasketId, faveId);
              basket = response.basket || [];
              errors = response.errors || [];

              errors.map((error) => showToast('warning', error.message));
            } else {
              basket = await createBasketFromFave(faveId);
              // TODO: handle unavailible products
              // showToast('warning', 'Please be informed, not available products removed from the basket');
            }

            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: { basket } } });
            dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: basket } });
            resolve(true);
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            showToast('error', e.errorMessage || 'Something went wrong... Could not add favorite order to basket!');
            reject(false);
          }
        };

        fetch();
      }),
    [basketId, dispatchAppBasketState, dispatch]
  );
  return [state, { addFavoriteToBasket }];
};

export default useAddFavoriteToBasket;
