import { useEffect } from 'react';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { getGroupOrder } from 'api/getGroupOrder';
import { useQueryUrlParams, useQueryUrlParamsDispatch } from './index';

const useGroupOrder = (groupOrderId) => {
  const { basketId: queryBasketId } = useQueryUrlParams();
  const { groupOrder } = useAppStateContext();
  const dispatchAppState = useAppDispatchContext();
  const setQuery = useQueryUrlParamsDispatch();
  const { data, error, loading } = groupOrder || {};

  const storedGroupOrderId = data ? data.id : '';

  useEffect(() => {
    if (data && !groupOrderId) {
      dispatchAppState({ type: ACTION_TYPES.cleanupGroupOrder });
    }
  }, [data, groupOrderId, dispatchAppState]);

  useEffect(() => {
    const fetch = async () => {
      if (groupOrderId && !storedGroupOrderId && !loading && !error) {
        dispatchAppState({ type: ACTION_TYPES.setLoadingGroupOrder, payload: { loading: true } });
        try {
          const response = await getGroupOrder(groupOrderId);
          dispatchAppState({ type: ACTION_TYPES.setGroupOrder, payload: { data: response } });
          if (!queryBasketId || queryBasketId !== response.basket.id) {
            setQuery({ basketId: response.basket.id }, '');
          }
          dispatchAppState({ type: ACTION_TYPES.setBasket, payload: { data: response.basket } });
        } catch (e) {
          dispatchAppState({ type: ACTION_TYPES.setErrorGroupOrder, payload: { error: e } });
        }
      }
    };
    fetch();
  }, [groupOrderId, storedGroupOrderId, dispatchAppState, loading, error, setQuery, queryBasketId]);

  return [groupOrder];
};

export default useGroupOrder;
