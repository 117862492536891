import React, { useEffect, memo } from 'react';
import Section from 'components/Section/Section';
import { useState } from 'react';
import styles from './ContactInformation.module.scss';
import PhoneInput from 'components/PhoneInput/PhoneInput';

const ContactInformation = ({ onSubmit, isTouched }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [error, setError] = useState(false);
  useEffect(() => {
    if (isTouched && !phoneNumber) {
      setError(true);
    }
  }, [isTouched, phoneNumber]);
  const onChange = (value) => {
    setError(false);
    setPhoneNumber(value);
    onSubmit && onSubmit(phoneNumber);
  };
  return (
    <Section title={'CONTACT INFORMATION'}>
      <div className={styles.input_wrapper}>
        <PhoneInput onChange={onChange} error={error} required value={phoneNumber} />
      </div>
    </Section>
  );
};
const MemoizedContactInformation = memo(ContactInformation);
export default MemoizedContactInformation;
