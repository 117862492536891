import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { deleteAccount } from 'api/user/deleteAccount';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useDeleteAccount = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const deleteUserAccount = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            await deleteAccount();
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false } });
            resolve(true);
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            showToast('error', e.errorMessage || 'Something went wrong... Could not delete account!');
            reject(false);
          }
        };

        fetch();
      }),
    []
  );

  return [state, { deleteUserAccount }];
};

export default useDeleteAccount;
