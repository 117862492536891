import { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router';
import auth from 'services/auth';
import { useQueryUrlParams, useQueryUrlParamsDispatch } from 'hooks';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { authUser, getUser } from 'api/login';
import { getLastBasket } from 'api/getLastBasket';
import { getRestaurantById } from 'api/restaurantById';
import { getGroupOrder } from 'api/getGroupOrder';
import showToast from 'components/Toast/ShowToast';
import { withReCaptchaRequest } from 'services/reCaptcha';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useAddUserInformation = () => {
  const history = useHistory();
  const { basketId } = useQueryUrlParams();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppUserState = useAppDispatchContext();
  const setQuery = useQueryUrlParamsDispatch();

  const userInformation = useCallback(
    (email, password) => {
      const fetch = async () => {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        dispatchAppUserState({
          type: ACTION_TYPES.setLoadingUserDetails,
          payload: { loading: true },
        });
        try {
          let response;

          response = await withReCaptchaRequest(authUser, [email, password, basketId]).then(async (res) => {
            auth.setLogged(true);
            const response = await getUser().then((res) => res);
            return response;
          });
          window.sessionStorage.removeItem('guestData');
          showToast('success', 'Successful Sign in');
          dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
          dispatchAppUserState({
            type: ACTION_TYPES.setUserDetails,
            payload: {
              data: {
                ...response.fields,
                primaryCardNumbers: response.primaryCardNumbers,
                accountInfo: response.accountInformation,
              },
            },
          });

          if (!basketId) {
            const lastBasket = await getLastBasket();

            if (lastBasket) {
              let restaurantId = null;

              if (lastBasket.vendorid) {
                const restaurant = await getRestaurantById(lastBasket.vendorid);

                if (restaurant) {
                  dispatchAppUserState({
                    type: ACTION_TYPES.setRestaurant,
                    payload: {
                      data: restaurant,
                    },
                  });

                  restaurantId = restaurant.id;
                }
              }

              if (lastBasket.groupOrderId) {
                const groupOrder = await getGroupOrder(lastBasket.groupOrderId);

                if (groupOrder) {
                  dispatchAppUserState({ type: ACTION_TYPES.setGroupOrder, payload: { data: groupOrder } });
                  dispatchAppUserState({ type: ACTION_TYPES.setBasket, payload: { data: groupOrder.basket } });
                  setQuery(
                    {
                      groupOrderId: lastBasket.groupOrderId,
                      basketId: lastBasket.id,
                      owner: lastBasket.isGroupOrderHost,
                    },
                    restaurantId ? `/${restaurantId}/menu` : '',
                    false,
                    ['signin']
                  );
                }
              } else {
                dispatchAppUserState({
                  type: ACTION_TYPES.setBasket,
                  payload: {
                    data: lastBasket,
                  },
                });
                const timer = setTimeout(() => {
                  history.push(`/${restaurantId}/menu`);
                  clearInterval(timer);
                }, 10);
              }
            }
          }
        } catch (error) {
          showToast('error', error.errorMessage || 'Something went wrong... Please try again!');
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: error } });
          dispatchAppUserState({
            type: ACTION_TYPES.setErrorUserDetails,
            payload: { error },
          });
        }
      };
      fetch();
    },
    [dispatchAppUserState, dispatch, basketId, history, setQuery]
  );

  return [state, { userInformation }];
};

export default useAddUserInformation;
