import React from 'react';
import styles from './AccountRewards.module.scss';
import AvailableRewards from './AvailableRewards/AvailableRewards';

function AccountRewards() {
  return (
    <div className={styles.rewards_container}>
      <h3 className={styles.rewards_title}>YOUR AVAILABLE REWARDS</h3>
      <div className={styles.divider} />
      <AvailableRewards />
    </div>
  );
}

export default React.memo(AccountRewards);
