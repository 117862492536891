import React, { useCallback, useRef } from 'react';
import { InputField } from 'components/Input/Input';
import 'components/Input/Input.scss';
import { TextButton } from 'components/Button/Button';
import showToast from 'components/Toast/ShowToast';

import './CopyLink.scss';

const CopyLink = ({ link }) => {
  const inputRef = useRef();
  const handleCopyLink = useCallback(
    (e) => {
      /* Select the text field */
      if (inputRef.current) {
        inputRef.current.select();
        inputRef.current.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand('copy');
        inputRef.current.blur();
        showToast('success', 'Copied');
      }
    },
    [inputRef]
  );

  return (
    <div className={'copy_link_container'}>
      <InputField className={'copy_link_input'} inputRef={inputRef} value={link} readOnly variant={'outlined'} />
      <TextButton className={'copy_link_button'} onClick={handleCopyLink} size={'large'}>
        Copy Link
      </TextButton>
    </div>
  );
};
export default React.memo(CopyLink);
