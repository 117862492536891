import React, { useCallback } from 'react';
import { TextButton } from 'components/Button/Button';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';

const HandoffModeLink = ({ className, label, isDisabled = false }) => {
  const setQuery = useQueryUrlParamsDispatch();

  const handleClick = useCallback(() => {
    const params = {
      handoffModeModal: true,
    };
    setQuery(params, '', false);
  }, [setQuery]);

  return (
    <TextButton disabled={isDisabled} className={className} onClick={handleClick}>
      {label}
    </TextButton>
  );
};

export default React.memo(HandoffModeLink);
