import React, { useState, useEffect, useCallback, memo } from 'react';
import { useQueryUrlParamsDispatch, useQueryUrlParams } from 'hooks';
import smallLogo from 'assets/small-logo.svg';
import { TextButton, PrimaryButton } from 'components/Button/Button';
import DrawerLayout from 'components/DrawerLayout/DrawerLayout';
import { SignUpForm, AsGuestForm } from 'components/Forms';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import { SignInContainer } from 'components/SignInPanel/SignInPanel';
import styles from './CheckoutPanel.module.scss';

const CheckoutPanel = ({ open, onClose, onSubmit }) => {
  const setQuery = useQueryUrlParamsDispatch();
  const { checkoutAs } = useQueryUrlParams();
  const [title, setTitle] = useState('Checkout as');
  const [showComponent, setShowComponent] = useState('');

  useEffect(() => {
    if (open && !checkoutAs) {
      setQuery(
        {
          checkoutAs: true,
        },
        '',
        false
      );
    }
  }, [open, setQuery, checkoutAs]);

  const handleClose = useCallback(() => {
    setShowComponent('');
    setTitle('Checkout as');
    onClose();
  }, [onClose]);

  const onForgotPassword = useCallback(() => {
    setQuery(
      {
        forgotPassword: true,
      },
      '',
      false,
      ['signin', 'signup']
    );
  }, [setQuery]);

  const onSignUpClick = () => setShowComponent('signUp');

  return (
    <DrawerLayout
      anchor="right"
      open={open}
      className={styles.layout}
      onChange={handleClose}
      header={<PrimaryHeader title={title} size="lg" brush="after" />}
      footerChildren={
        <TextButton className={styles.text_button} size="large" onClick={handleClose}>
          BACK TO MENU
        </TextButton>
      }
      transparentFooter
    >
      <div className={styles.checkout_container}>
        {showComponent === 'signIn' ? (
          <SignInContainer onForgotPassword={onForgotPassword} onSignUp={onSignUpClick} onClose={onSubmit} />
        ) : showComponent === 'signUp' ? (
          <SignUpForm close={onSubmit} />
        ) : showComponent === 'asGuest' ? (
          <AsGuestForm close={onSubmit} />
        ) : (
          <FirstCheckoutForm onChangeTitle={setTitle} onChangeComponent={setShowComponent} />
        )}
      </div>
    </DrawerLayout>
  );
};

export default memo(CheckoutPanel);

const FirstCheckoutForm = memo(({ onChangeTitle, onChangeComponent }) => {
  const onClickButton = (type, typeTitle) => () => {
    onChangeComponent(type);
    onChangeTitle(typeTitle);
  };

  return (
    <div className={styles.checkout_form}>
      <div>
        <h4 className={styles.notification_title}>
          Join&nbsp;
          <span>
            <img src={smallLogo} className={styles.logo} alt="99 restaurants logo" />
            REWARDS!
          </span>
        </h4>
        <p className={styles.notification_description}>
          Sign up now for faster checkout and exclusive rewards all year long. Download our mobile app for easy access.
        </p>
      </div>
      <PrimaryButton fullWidth onClick={onClickButton('signIn', 'Sign In')}>
        Sign In
      </PrimaryButton>
      <PrimaryButton fullWidth onClick={onClickButton('signUp', 'Sign Up')}>
        Sign Up
      </PrimaryButton>
      <TextButton className={styles.text_button} size="large" onClick={onClickButton('asGuest', 'Checkout As Guest')}>
        Checkout As Guest
      </TextButton>
    </div>
  );
});

FirstCheckoutForm.displayName = 'FirstCheckoutForm';
