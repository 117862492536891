import React, { memo, useState } from 'react';
import styles from './InviteFriends.module.scss';
import { PrimaryButton, TextButton } from 'components/Button/Button';
import InviteHistoryModal from './InviteHistoryModal/InviteHistoryModal';
import ReferFriendModal from './ReferFriendModal/ReferFriendModal';
import smallLogo from 'assets/small-logo.svg';

const InviteFriends = () => {
  const [inviteHistoryIsOpen, setInviteHistory] = useState(false);
  const [referFriendIsOpen, setReferFriend] = useState(false);

  const onViewReferModal = () => {
    setReferFriend((prevState) => !prevState);
  };

  const onViewReferHistoryModal = () => {
    setInviteHistory((prevState) => !prevState);
  };

  const onOpenInviteModal = () => {
    setInviteHistory(false);
    setReferFriend(true);
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        <span>Refer friends to join &nbsp;</span>
        <span>
          <img src={smallLogo} className={styles.logo} alt="99 restaurants logo" />
          REWARDS!
        </span>
      </h3>
      <p className={styles.description}>Invite friends and family to join and get a special treat when they sign up.</p>
      <div className={styles.btn_container}>
        <PrimaryButton className={styles.send_refer_btn} onClick={onViewReferModal}>
          Send Referrals
        </PrimaryButton>
        <TextButton onClick={onViewReferHistoryModal} className={styles.view_refer_history_btn}>
          View Referral History
        </TextButton>
      </div>
      <ReferFriendModal open={referFriendIsOpen} onClose={onViewReferModal} />
      <InviteHistoryModal
        open={inviteHistoryIsOpen}
        onOpenInviteModal={onOpenInviteModal}
        onClose={onViewReferHistoryModal}
      />
    </div>
  );
};

const InviteFriendsMemo = memo(InviteFriends);

export default InviteFriendsMemo;
