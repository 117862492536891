import React, { useCallback } from 'react';
import { InputField } from 'components/Input/Input';
import { PrimaryButton } from 'components/Button/Button';
import useLocationFetch from './hooks/useLocationFetch';
import './ChooseFavoriteLocation.scss';
import { RadioboxWithLabel } from 'components/Radiobox/Radiobox';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import { RadioGroup } from '@material-ui/core';

const ChooseFavoriteLocation = ({ state, stateChanged }) => {
  const [{ loading, data, error }, { searchByQuery }] = useLocationFetch();

  const handleState = useCallback(
    (newState) => {
      if (newState.searchValue && newState.searchValue.length) {
        newState.searchValue = newState.searchValue.substring(0, 24);
      }

      stateChanged({ ...state, ...newState });
    },
    [stateChanged, state]
  );

  const searchLocation = useCallback(() => {
    if (!state.searchValue) {
      handleState({ isTouched: true });
      return;
    }

    handleState({ locationCode: '' });
    searchByQuery(state.searchValue);
  }, [searchByQuery, state, handleState]);

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchLocation();
    }
  };

  const handleRadioItem = (e) => {
    handleState({ locationCode: e.target.value });
  };
  const onChangeInput = (e) => handleState({ searchValue: e.target.value });
  const onBlurInput = useCallback(
    (e) => {
      handleState({ isTouched: true, searchValue: e.target.value.trim() });

      if (!data) {
        searchLocation();
      }
    },
    [searchLocation, data, handleState]
  );
  return (
    <div className="choose_favorite_location_container">
      <div className="form">
        <InputField
          required
          disabled={loading}
          className="input_container"
          defaultValue={state.searchValue}
          onKeyDown={handleInputKeyDown}
          onChange={onChangeInput}
          inputProps={{
            maxLength: 24,
          }}
          onBlur={onBlurInput}
          error={state.isTouched && state.searchValue.length === 0}
          helperText={state.isTouched && state.searchValue.length === 0 ? 'Please enter city, state or zip' : ''}
          label={'City, State or Zip'}
        />

        <PrimaryButton size="large" className="search_btn" disabled={loading} onClick={searchLocation}>
          GO
        </PrimaryButton>
      </div>

      <div className="locations_list">
        {error && <Alert message={error.errorMessage} />}
        {loading && <Spin spinning={loading} />}
        {!loading && !error && data && (
          <>
            {data.length > 0 ? (
              <h4 className="locations_result_label">{data.length} Locations found. Please select the restaurant</h4>
            ) : (
              <h4 className="locations_result_label">No Locations found. Please try another search query</h4>
            )}

            <RadioGroup>
              {data.map((location) => {
                const address = `${location.name}, ${location.streetaddress}, ${location.city}, ${location.state}. `;
                const addressWithMiles = (
                  <>
                    {address} <span className="distance">{location.distance} mi.</span>
                  </>
                );

                return (
                  <RadioboxWithLabel
                    onChange={handleRadioItem}
                    className={state.locationCode === location.extref ? 'choosed_location' : ''}
                    label={addressWithMiles}
                    key={location.id}
                    value={location.extref}
                    checked={state.locationCode === location.extref}
                  />
                );
              })}
            </RadioGroup>
          </>
        )}
      </div>
    </div>
  );
};
export default React.memo(ChooseFavoriteLocation);
