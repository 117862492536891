import React, { useCallback } from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import Section from 'components/Section/Section';
import MomentUtils from '@date-io/moment';
import { TextButton } from 'components/Button/Button';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';

import styles from './GroupOrderInfo.module.scss';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';

const moment = new MomentUtils();

const GroupOrderInfo = ({ onClose }) => {
  const {
    groupOrder: { data, loading, error },
    basket: { data: basketData },
  } = useAppStateContext();
  const setQuery = useQueryUrlParamsDispatch();
  const { deadline, ownername } = data || {};
  const formattedDeadline = deadline ? moment.moment(deadline, 'YYYYMMDD HH:mm').format('MM/DD/YYYY LT') : '';

  const handleManageClick = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setQuery({ groupOrderModal: true }, '', false, ['openBasket']);
  }, [setQuery, onClose]);
  const hasData = data && !loading && !error;
  const showSection = loading || error || data;
  const errorMessage =
    (error &&
      (error.errorMessage ||
        'Something went wrong... Could not load group order information. Please try again later!')) ||
    '';
  const description = hasData ? (
    <TextButton className={styles.manage_button} onClick={handleManageClick}>
      Manage
    </TextButton>
  ) : null;

  return showSection ? (
    <Section title={'Your group order'} sectionHr className={styles.container} description={description}>
      {errorMessage && <Alert message={errorMessage} />}
      <Spin spinning={loading} />
      {hasData && (
        <>
          <span className={styles.title}>Deadline</span>
          <p className={styles.deadline}>Order Deadline: {formattedDeadline}</p>
          <span className={styles.title}>Group Added To Basket So Far:</span>
          <p className={styles.recipient}>{ownername || ''}</p>
          {basketData &&
            basketData.products &&
            basketData.products.map((item) => (
              <p key={item.id} className={styles.recipient}>
                {item.recipient}
              </p>
            ))}
        </>
      )}
    </Section>
  ) : null;
};
export default GroupOrderInfo;
