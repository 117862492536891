import { useEffect } from 'react';
import { retrieveUserBillingAccounts } from 'api/user/billingAccounts/retrieveUserBillingAccounts';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { getUserGiftCardBalances } from 'api/getUserGiftCardBalances';

const buildAccountsData = (data) => {
  let accounts = [];
  if (data && data.length > 0) {
    accounts = data.map(
      ({ cardsuffix, cardtype, expiration, accountid, isdefault, removable, description, ...otherProps }) => ({
        cardNumber: cardsuffix,
        cardType: cardtype,
        expiryMonth: expiration ? expiration.slice(-2) : '',
        expiryYear: expiration ? expiration.slice(0, 4) : '',
        id: accountid || '',
        isDefault: isdefault || '',
        isRemovable: removable || '',
        name: description || '',
        ...otherProps,
      })
    );
  }
  return accounts;
};

const giftCardType = (item) => item.accounttype && item.accounttype.toLowerCase().includes('gift card');

const useRetrieveBillingUserAccounts = () => {
  const { authenticated, userBillingAccounts } = useAppStateContext();
  const appDispatch = useAppDispatchContext();

  useEffect(() => {
    const fetch = async () => {
      if (authenticated) {
        appDispatch({
          payload: { loading: true },
          type: ACTION_TYPES.setLoadingUserBillingAccountsData,
        });
        try {
          const data = await retrieveUserBillingAccounts();
          let billingAccounts = buildAccountsData(data.billingaccounts);
          if (billingAccounts.some(giftCardType)) {
            const dataGiftCards = await getUserGiftCardBalances();
            let indx = 0;
            const balances = [...dataGiftCards.cardbalances];
            billingAccounts = billingAccounts.reduce((total, account) => {
              let length = balances.length - 1;
              if (giftCardType(account)) {
                if (indx <= length) {
                  total.push({
                    ...account,
                    ...balances[indx],
                  });
                  indx++;
                  return total;
                }
              }
              total.push(account);
              return total;
            }, []);
          }
          appDispatch({
            payload: {
              billingAccounts: billingAccounts,
            },
            type: ACTION_TYPES.setUserBillingAccountsData,
          });
        } catch (error) {
          appDispatch({
            payload: { error },
            type: ACTION_TYPES.setErrorUserBillingAccountsData,
          });
        }
      }
    };
    fetch();
  }, [appDispatch, authenticated]);
  return userBillingAccounts;
};

export default useRetrieveBillingUserAccounts;
