import React from 'react';
import styles from './MapMarker.module.scss';
import markerLogo from 'assets/marker_icon.png';
import classNames from 'classnames/bind';

const MapMarker = ({ hover, className }) => {
  return (
    <div className={classNames(styles.map_marker, className, { [styles.map_marker_hover]: hover })}>
      <div className={styles.img_wrapper}>
        <img src={markerLogo} alt="marker" />
      </div>
    </div>
  );
};
export default React.memo(MapMarker);
