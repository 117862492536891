import React, { memo } from 'react';
import Link from 'components/Link/Link';
import LogoComponent from 'components/LogoComponent/LogoComponent';
import routerConfig from 'routerConfig';
import { useLocation } from 'react-router';
import useRestaurantDisclaimers from './hooks/useRestaurantDisclaimers';
import logo from 'assets/99_pub_logo.svg';
import './Footer.scss';

const Footer = () => {
  const { pathname } = useLocation();
  const withoutFooter = routerConfig.some((path) => path['withoutFooter'] && pathname === path.path);
  const { data, error, loading } = useRestaurantDisclaimers(withoutFooter);
  const dataIsExist = !loading && !error && data && !!data.disclaimers.length;
  if (withoutFooter) return '';
  return (
    <div className="footer_wrapper">
      {dataIsExist && (
        <div className="footer_info_text">
          {data.disclaimers.map(({ disclaimer }, sectionIndex) => {
            const disclaimerArr = disclaimer ? disclaimer.split(/\r?\n/) : [];

            return (
              <div key={sectionIndex} className="footer_paragraph_offset">
                {disclaimerArr.map((disclaimerItem, index) => (
                  <p key={`${sectionIndex}${index}`}>{disclaimerItem}</p>
                ))}
              </div>
            );
          })}
        </div>
      )}
      <div className="footer_menu">
        <div className="footer_logo">
          <LogoComponent image={logo} />
        </div>
        <div className="footer_links">
          <Link target="_blank" href={process.env.REACT_APP_PATH_CONTACT_US}>
            Contact Us
          </Link>
          <Link target="_blank" href={process.env.REACT_APP_PATH_USER_AGREEMENT}>
            User Agreement
          </Link>
          <Link target="_blank" href={process.env.REACT_APP_PATH_PRIVACY_POLICY}>
            Privacy Policy
          </Link>
          <Link target="_blank" href={process.env.REACT_APP_PATH_ONLINE_TRACKING}>
            Online Tracking Opt-Out Guide
          </Link>
          <Link target="_blank" href={process.env.REACT_APP_PATH_MARKETING_SITE}>
            99restaurants.com
          </Link>
        </div>
      </div>
    </div>
  );
};
const MemoizedFooter = memo(Footer);
export default MemoizedFooter;
