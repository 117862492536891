import { useEffect } from 'react';
import { oloCcsf } from 'api/oloCcsf';
import { CCSF_CARD_NUMBER_CONTAINER, CCSF_CVV_CONTAINER } from 'api/oloCcsf/oloCcsfConstants';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from '../../../../../stateProviders/appStateProvider';

const onScriptError = (error) => {
  console.error('Script injection error:', error);
};

const onScriptLoad = (onReady, onFocus, basketId, appDispatch) => () => {
  if (window.Olo && window.Olo.CheckoutFrame) {
    const checkoutFrame = new window.Olo.CheckoutFrame({
      cardElement: CCSF_CARD_NUMBER_CONTAINER,
      cvvElement: CCSF_CVV_CONTAINER,
    });

    window.checkoutFrame = checkoutFrame;

    checkoutFrame.initialize({
      brandAccessId: process.env['REACT_APP_CCSF_API_BRAND_ACCESS_ID'],
      styles: {
        cardNumber: `
        border-top-width: 0;
        border-right-width: 0;
        border-bottom-width: 0;
        border-left-width: 0;
        height: 26px;
        padding: 27px 12px 10px;
        fontFamily: Poppins, Helvetica Neue, sans-serif;
        font-size: 16px;
        background-color: transparent;
        width: 100%`,
        cvv: `
        border-top-width: 0;
        border-right-width: 0;
        border-bottom-width: 0;
        border-left-width: 0;
        height: 26px;
        padding: 27px 12px 10px;
        fontFamily: Poppins, Helvetica Neue, sans-serif;
        font-size: 16px;
        background-color: transparent;
        width: 100%`,
      },
    });

    oloCcsf.getBasketPaymentAccessToken(basketId).then((response) => {
      appDispatch({
        payload: false,
        type: ACTION_TYPES.setIsFetchingTokens,
      });
      appDispatch({
        payload: response.accesstoken,
        type: ACTION_TYPES.setBasketPaymentAccessToken,
      });
    });

    checkoutFrame.on('ready', onReady);
    checkoutFrame.on('focus', onFocus);
  }
};

const useSecureInputs = (refs) => {
  const { creditCardContainer, cvvContainer, basketId } = refs;
  const appDispatch = useAppDispatchContext();

  useEffect(() => {
    if (creditCardContainer.current && cvvContainer.current) {
      const handleScriptOnLoad = onScriptLoad(oloCcsf.onReady(appDispatch), oloCcsf.onFocus(), basketId, appDispatch);

      const script = document.createElement('script');

      script.src = process.env.REACT_APP_CCSF_API_BASE_URL + process.env.REACT_APP_CCSF_API_SECURE_FIELDS_SOURCE;
      script.async = true;
      script.addEventListener('error', onScriptError);
      script.addEventListener('load', handleScriptOnLoad);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('error', onScriptError);
        script.removeEventListener('load', handleScriptOnLoad);

        document.body.removeChild(script);

        window.Olo = null;
        window.checkoutFrame = null;
      };
    }
  }, [appDispatch, creditCardContainer, cvvContainer, basketId]);
};

export default useSecureInputs;
