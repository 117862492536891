import React from 'react';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import styles from './RemoveCardModal.module.scss';
import Spin from 'components/Spin/Spin';

const DEFAULT_DESCRIPTION = 'Are you sure you want to remove this payment method?';
const DEFAULT_TITLE = 'Remove card?';

const RemoveCardModal = ({
  open = false,
  onChange,
  onSubmit,
  onCancel,
  disabled = false,
  loading = false,
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
}) => {
  return (
    <ModalWindow
      open={open}
      headerSize="lg"
      onChange={onChange}
      footerChildren={
        <div className={styles.footer}>
          <PrimaryButton disabled={disabled} onClick={onSubmit}>
            {loading && <Spin size={30} spinning={loading} className={styles.spinner} />}
            Remove
          </PrimaryButton>
          <PrimaryButton disabled={disabled} onClick={onCancel}>
            Cancel
          </PrimaryButton>
        </div>
      }
      headerTitle={title}
      maxWidth="xs"
    >
      <div className={styles.modal_wrapper}>
        <p className={styles.modal_msg}>{description}</p>
      </div>
    </ModalWindow>
  );
};

export default React.memo(RemoveCardModal);
