import React, { useState, useEffect, useCallback, memo } from 'react';
import styles from './EditProfileModal.module.scss';
import useEditProfile from './hooks/useEditProfile';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton, TextButton } from 'components/Button/Button';
import { InputField } from 'components/Input/Input';
import { EMAIL_VALIDATION_PATTERN } from 'services/email';
import Spin from 'components/Spin/Spin';
import showToast from 'components/Toast/ShowToast';
import BirthdayInput from 'components/BirthdayInput/BirthdayInput';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import CheckIcon from '@material-ui/icons/Check';
import { NAME_VALIDATION_PATTERN } from 'services/name';

const inputsConfig = {
  firstName: {
    label: 'First Name',
    isValidFn: (str) => NAME_VALIDATION_PATTERN.test(str),
    errorText: 'First name can include letters, parenthesis, numbers, hyphens, or spaces.',
    emptyErrorText: 'Please provide a first name',
  },
  lastName: {
    label: 'Last Name',
    isValidFn: (str) => NAME_VALIDATION_PATTERN.test(str),
    errorText: 'Last name can include letters, parenthesis, numbers, hyphens, or spaces.',
    emptyErrorText: 'Please provide a last name',
  },
  phoneNumber: {
    label: 'Phone Number',
    isValidFn: (str) => str && str.length === 10 && str.charAt(0) !== '0' && str.charAt(0) !== '1',
    errorText: 'Please enter a valid US phone number so that we can call you if there are any issues with your order',
    ref: React.createRef(),
  },
  birthday: {
    labels: {
      day: 'DD',
      month: 'MM',
      year: 'YYYY',
    },
    isValidFn: (str) => str && str.length > 0,
  },
  email: {
    label: 'E-mail',
    errorText: 'Please enter a valid email address',
    isValidFn: (str) => str.match(EMAIL_VALIDATION_PATTERN),
    ref: React.createRef(),
  },
};

const parseEvent = (type, e) => {
  let value;
  switch (type) {
    case 'firstName':
      value = e.target.value.substring(0, 30);
      break;
    case 'lastName':
      value = e.target.value.substring(0, 30);
      break;
    case 'phoneNumber':
      value = e;
      break;
    default:
      value = e.target.value;
  }
  return value;
};

const EditProfileForm = memo(
  ({ data, isDuplicatedEmailAddress, isDuplicatedMobilePhone, isFormSubmitted, handleState, loading }) => {
    const { firstName, lastName, phoneNumber, birthday, email } = data;

    const onChangeInput = (type) => (e) => {
      handleState(type, { value: parseEvent(type, e) });
    };
    const onBlurInput = (type) => () => {
      handleState(type, { isTouched: true });
    };

    const onChangeBirthday = (value) => {
      handleState('birthday', { value, isTouched: true, error: false });
    };
    const onErrorBirthday = (value) => {
      handleState('birthday', { error: value, isTouched: true });
    };

    return (
      <form className={styles.edit_profile_form} noValidate autoComplete="off">
        <InputField
          disabled={loading}
          className={styles.input_container}
          defaultValue={firstName.value}
          onChange={onChangeInput('firstName')}
          inputProps={{
            maxLength: 30,
          }}
          onBlur={onBlurInput('firstName')}
          error={(isFormSubmitted || firstName.isTouched) && !inputsConfig.firstName.isValidFn(firstName.value)}
          helperText={
            (isFormSubmitted || firstName.isTouched) && !inputsConfig.firstName.isValidFn(firstName.value)
              ? firstName.value.length
                ? inputsConfig.firstName.errorText
                : inputsConfig.firstName.emptyErrorText
              : ''
          }
          label={inputsConfig.firstName.label}
        />
        <InputField
          disabled={loading}
          className={styles.input_container}
          defaultValue={lastName.value}
          onChange={onChangeInput('lastName')}
          inputProps={{
            maxLength: 30,
          }}
          onBlur={onBlurInput('lastName')}
          error={(isFormSubmitted || lastName.isTouched) && !inputsConfig.lastName.isValidFn(lastName.value)}
          helperText={
            (isFormSubmitted || lastName.isTouched) && !inputsConfig.lastName.isValidFn(lastName.value)
              ? lastName.value.length
                ? inputsConfig.lastName.errorText
                : inputsConfig.lastName.emptyErrorText
              : ''
          }
          label={inputsConfig.lastName.label}
        />
        <PhoneInput
          className={styles.input_container}
          onChange={onChangeInput('phoneNumber')}
          defaultValue={phoneNumber.value}
          error={
            ((isFormSubmitted || phoneNumber.isTouched) && !inputsConfig.phoneNumber.isValidFn(phoneNumber.value)) ||
            isDuplicatedMobilePhone
          }
          disabled={loading}
          ref={inputsConfig.phoneNumber.ref}
          errorMsg={isDuplicatedMobilePhone && 'Provided phone number already associated with Ninety Nine account'}
        />
        <div className={styles.birthday_container}>
          <BirthdayInput
            // onChange={onChangeBirthday}
            disabled={true}
            required={false}
            // error={!!inputsConfig.birthday.isValidFn(birthday.value)}
            onError={onErrorBirthday}
            defaultValue={birthday.value}
          />
        </div>
        <InputField
          disabled={loading}
          className={styles.input_container}
          defaultValue={email.value}
          onChange={onChangeInput('email')}
          inputProps={{
            maxLength: 60,
          }}
          ref={inputsConfig.email.ref}
          onBlur={onBlurInput('email')}
          error={
            ((isFormSubmitted || email.isTouched) && !inputsConfig.email.isValidFn(email.value)) ||
            isDuplicatedEmailAddress
          }
          helperText={
            (isFormSubmitted || email.isTouched) && !inputsConfig.email.isValidFn(email.value)
              ? email.value.length
                ? inputsConfig.email.errorText
                : 'Please provide an email address'
              : isDuplicatedEmailAddress
              ? 'Provided email address already associated with Ninety Nine account'
              : ''
          }
          label={inputsConfig.email.label}
        />
      </form>
    );
  }
);

EditProfileForm.displayName = 'EditProfileForm';

const EditProfileModal = ({ onClose, isOpen, accountData }) => {
  const [{ loading }, { updateInfo }] = useEditProfile();
  const [initialized, setInitialized] = useState(false);

  const getDefaultState = useCallback((accountData) => {
    return {
      model: {
        firstName: {
          value: accountData.firstName,
          isTouched: false,
        },
        lastName: {
          value: accountData.lastName,
          isTouched: false,
        },
        phoneNumber: {
          value: accountData.phoneNumber,
          isTouched: false,
        },
        birthday: {
          value: accountData.birthday,
          isTouched: false,
          error: false,
        },
        email: {
          value: accountData.email,
          isTouched: false,
        },
      },
      isDuplicatedEmailAddress: false,
      isDuplicatedMobilePhone: false,
      isFormSubmitted: false,
    };
  }, []);

  const [localState, setLocalState] = useState(getDefaultState(accountData));

  const handleModelState = (property, propertyState) => {
    const newProperty = { ...localState.model[property], ...propertyState };
    const newModel = { ...localState.model, [property]: newProperty };

    const newState = { ...localState, model: newModel };

    if (property === 'email') {
      newState.isDuplicatedEmailAddress = false;
    }

    if (property === 'phoneNumber') {
      newState.isDuplicatedMobilePhone = false;
    }

    setLocalState(newState);
  };

  const handleIsDuplicated = (duplicatedState) => {
    const newState = { ...localState, ...duplicatedState };
    setLocalState(newState);
  };

  const handleIsFormSubmittedState = (isSubmitted) => {
    const newState = { ...localState, isFormSubmitted: [isSubmitted] };
    setLocalState(newState);
  };

  const handleOnSubmit = () => {
    handleIsFormSubmittedState(true);

    const patternMatched = Object.keys(inputsConfig).every((configKey) => {
      return inputsConfig[configKey].isValidFn
        ? inputsConfig[configKey].isValidFn(localState.model[configKey].value)
        : true;
    });

    if (
      !patternMatched ||
      localState.isDuplicatedEmailAddress ||
      localState.isDuplicatedMobilePhone ||
      localState.model.birthday.error
    ) {
      return;
    }

    const account = {
      firstName: localState.model.firstName.value,
      lastName: localState.model.lastName.value,
      mobilePhone: localState.model.phoneNumber.value,
      email: localState.model.email.value,
      dateOfBirth: localState.model.birthday.value,
    };

    updateInfo(account).then(
      () => {
        showToast('success', 'Profile updated successfully');
        setInitialized(false);
        onClose({ editProfile: false });
      },
      (error) => {
        if (error && error.errorByFields && Array.isArray(error.errorByFields)) {
          const duplicatedState = {};
          let alreadyFocused = false;
          error.errorByFields.forEach((property) => {
            if (property === 'mobilePhone') {
              duplicatedState.isDuplicatedMobilePhone = true;

              if (!alreadyFocused) {
                inputsConfig.phoneNumber.ref.current.focus();
                alreadyFocused = true;
              }
            }

            if (property === 'email') {
              duplicatedState.isDuplicatedEmailAddress = true;

              if (!alreadyFocused) {
                inputsConfig.email.ref.current.focus();
                alreadyFocused = true;
              }
            }

            handleIsDuplicated(duplicatedState);
          });
        }
      }
    );
  };

  const handleModalStateChange = (state) => () => {
    onClose(state);
    setInitialized(false);
  };

  useEffect(() => {
    if (!initialized && isOpen && getDefaultState) {
      setInitialized(true);
      setLocalState(getDefaultState(accountData));
    }
  }, [initialized, isOpen, getDefaultState, accountData]);

  return (
    <ModalWindow
      open={isOpen}
      onChange={handleModalStateChange({ editProfile: false })}
      headerTitle="EDIT PROFILE"
      headerSize="lg"
      maxWidth="md"
      footerChildren={
        <div className={styles.modal_footer}>
          <div className={styles.text_btns}>
            <TextButton
              size="large"
              disabled={loading}
              className={styles.delete_account_btn}
              onClick={handleModalStateChange({ deleteAccount: true, editProfile: false })}
            >
              DELETE ACCOUNT
            </TextButton>
          </div>

          <PrimaryButton
            className={styles.save_changes_btn}
            disabled={loading}
            endIcon={<CheckIcon />}
            size="large"
            onClick={handleOnSubmit}
          >
            <Spin className={styles.spinner} spinning={loading} />
            SAVE CHANGES
          </PrimaryButton>
        </div>
      }
    >
      <div className={styles.modal_content}>
        <EditProfileForm
          data={localState.model}
          isDuplicatedEmailAddress={localState.isDuplicatedEmailAddress}
          isDuplicatedMobilePhone={localState.isDuplicatedMobilePhone}
          isFormSubmitted={localState.isFormSubmitted}
          handleState={handleModelState}
          loading={loading}
        />
      </div>
    </ModalWindow>
  );
};

export default React.memo(EditProfileModal);
