import { useEffect, useReducer } from 'react';
import { getBasketUpsell } from 'api/getBasketUpsell';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useQueryUrlParams } from 'hooks';
import { useCallback } from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

const initialState = {
  loading: false,
  error: false,
  data: null,
};
export const useUpdateUpsell = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const { basketId } = useQueryUrlParams();
  const getUpsell = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          if (basketId) {
            try {
              const response = await getBasketUpsell(basketId);
              dispatch({
                type: FETCH_ACTION_TYPES.setData,
                payload: { data: response['groups'] },
              });
              resolve(response['groups']);
            } catch (e) {
              reject(true);
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            }
          }
        };
        fetch();
      }),
    [basketId]
  );
  return { ...state, getUpsell };
};
const useBasketUpsell = (open) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const { basketId } = useQueryUrlParams();
  const { restaurantMenu } = useAppStateContext();
  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      if (dispatch && basketId && open) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const response = await getBasketUpsell(basketId);
          if (response) {
            let data = response.groups;
            //Add images for upsell products
            if (restaurantMenu.data) {
              const categories = restaurantMenu.data.categories;
              const imagePath = restaurantMenu.data.imagepath;
              const products = categories.reduce((prevGroup, currGroup) => {
                const products = currGroup.products;
                prevGroup = prevGroup.concat(products);
                return prevGroup;
              }, []);
              data = data.reduce((prevItem, currItem) => {
                const newCurrItem = currItem.items.map((item) => {
                  let newItem = { ...item, image: null, imagePath: imagePath };
                  const findInCategory = products.find((product) => product.id.toString() === item.id.toString());
                  if (findInCategory && findInCategory.images) {
                    const image = findInCategory.images.find(
                      (image) => image.groupname === 'mobile-webapp-menu' && image.filename
                    );
                    newItem = {
                      ...newItem,
                      image: image,
                    };
                  }
                  return newItem;
                });
                const currObj = {
                  ...currItem,
                  items: newCurrItem,
                };
                prevItem.push(currObj);
                return prevItem;
              }, []);
            }
            dispatch({
              type: FETCH_ACTION_TYPES.setData,
              payload: { data },
            });
          }
        } catch (e) {
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
          }
        }
      }
    };
    fetch();
    return () => {
      ignore = true;
    };
  }, [basketId, open, restaurantMenu]);
  return state;
};

export default useBasketUpsell;
