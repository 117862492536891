import React, { useCallback, useEffect, useRef, useState } from 'react';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { PrimaryButton } from 'components/Button/Button';
import ModalWindow from 'components/Modal/Modal';
import Alert from 'components/Alert/Alert';
import showToast from 'components/Toast/ShowToast';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import ProductDescription from './ProductDescription/ProductDescription';
import ProductModifiersView from './ProductModifiersView/ProductModifiersView';
import ProductInstructions from './ProductInstructions/ProductInstructions';
import ProductSummary from './ProductSummary/ProductSummary';
import useRestaurantMenuFetch from '../RestaurantMenu/hooks/useRestaurantMenuFetch';
import useAddingProductToBasket from './hooks/useAddingProductToBasket';
import styles from './ProductDetailsModal.module.scss';
import Section from 'components/Section/Section';
import { InputField } from 'components/Input/Input';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import classNames from 'classnames';

const MAX_INSTRUCTIONS_LENGTH = 16;
const MAX_NAME_LENGTH = 32;

const ProductDetailsModal = ({ product = {}, edit = false, onClose, emptyModifiers }) => {
  const recipientRef = useRef();
  const contentRef = useRef();

  const [productDetails, setProductDetails] = useState(null);
  const [productError, setProductError] = useState(null);
  const [extraCost, setExtraCost] = useState(0);
  const [options, setOptions] = useState([]);
  const [isOptionsValid, setIsOptionsValid] = useState(true);
  const [showOptionsError, setShowOptionsError] = useState(false);
  const [scrollingToError, setScrollingToError] = useState(false);
  const [productQuantity, setProductQuantity] = useState(product.quantity || 1);
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [recipient, setRecipient] = useState('');
  const [showRecipientError, setShowRecipientError] = useState(false);

  const [{ data: menuData, error: menuError }] = useRestaurantMenuFetch();
  const { groupOrderId } = useQueryUrlParams();
  const [
    { loading: adding, data: basketData, error: addingError },
    { addProduct, updateProduct },
  ] = useAddingProductToBasket();

  const menuErrorMessage =
    (menuError &&
      (menuError.errorMessage ||
        'Something went wrong... Could not load details for selected product. Please try again later!')) ||
    '';

  const addingErrorMessage =
    (addingError &&
      (addingError.errorMessage ||
        'Something went wrong... Could not add product to basket. Please try again later!')) ||
    '';

  useEffect(() => {
    if (addingErrorMessage) {
      showToast('error', addingErrorMessage);
      if (addingError && addingError.num === 0 && onClose) {
        onClose();
      }
    }
  }, [addingErrorMessage, addingError, onClose]);

  useEffect(() => {
    if (basketData && onClose) {
      const msg = edit ? 'Item is updated!' : 'Item has been added to the bag';
      showToast('success', msg);
      onClose();
    }
  }, [basketData, onClose, edit]);

  useEffect(() => {
    if (product && edit && menuData) {
      let menuProduct = null;
      menuData.categories.forEach((category) => {
        category.products.forEach((menuDataProduct) => {
          if (menuDataProduct.id === product.productId) {
            menuProduct = menuDataProduct;
          }
        });
      });
      if (menuProduct) {
        setProductDetails({ ...menuProduct, basketProductId: product.id });
      } else {
        setProductDetails(null);
        setProductError('Could not find selected product in menu. Please refresh the page!');
      }
      setOptions(product.choices.map((option) => option.optionid));
    } else if (product && !edit) {
      setProductDetails(product);
    }
  }, [product, edit, menuData]);

  const handleContainerScroll = useCallback(() => {
    if (scrollingToError) {
      setScrollingToError(false);
    }
  }, [scrollingToError]);

  const handleAddProductClick = useCallback(() => {
    if (!isOptionsValid) {
      setScrollingToError(true);
      setShowOptionsError(true);
      showToast('error', 'Please choose options before continuing!');
    } else if (groupOrderId && recipient === '') {
      setShowRecipientError(true);
      if (recipientRef && recipientRef.current && contentRef && contentRef.current) {
        contentRef.current.scrollTo(0, recipientRef.current.offsetTop);
        recipientRef.current.focus();
      }
      showToast('error', 'Please enter name!');
    } else if (productDetails) {
      setScrollingToError(false);
      setShowOptionsError(false);
      const opts = options.length > 0 ? options.join(',') : '';
      if (!edit && addProduct) {
        addProduct(productDetails.id, opts, productQuantity, specialInstructions, recipient);
      }
      if (edit && updateProduct) {
        updateProduct(
          productDetails.basketProductId,
          productDetails.id,
          opts,
          productQuantity,
          specialInstructions,
          recipient
        );
      }
    }
  }, [
    productDetails,
    isOptionsValid,
    options,
    addProduct,
    productQuantity,
    recipient,
    specialInstructions,
    edit,
    updateProduct,
    groupOrderId,
    recipientRef,
    contentRef,
  ]);

  const handleModifiersChange = useCallback(
    (selected, cost, isValid) => {
      if (cost !== extraCost) {
        setExtraCost(cost);
      }
      if (JSON.stringify(selected) !== JSON.stringify(options)) {
        setOptions(selected);
      }
      setScrollingToError(false);
      setIsOptionsValid(isValid);
    },
    [extraCost, options]
  );

  const handleProductQuantity = useCallback((value) => {
    setProductQuantity(value);
  }, []);

  const handleSpecialInstructions = useCallback((e) => {
    if (e.target.value.length <= MAX_INSTRUCTIONS_LENGTH) {
      setSpecialInstructions(e.target.value);
    }
  }, []);

  const handleRecipient = useCallback((e) => {
    if (e.target.value.length <= MAX_NAME_LENGTH) {
      setRecipient(e.target.value);
      setShowRecipientError(false);
    }
  }, []);

  const theme = useTheme();
  const isTabletPortraitSize = useMediaQuery(theme.breakpoints.down(769));
  const additionalOffset = isTabletPortraitSize ? 65 : 10;

  return (
    <>
      {productDetails ? (
        <ModalWindow
          open={true}
          onChange={onClose}
          onScroll={handleContainerScroll}
          contentRef={contentRef}
          className={classNames(styles.product_details_modal, {
            [styles.empty_body]: emptyModifiers && !groupOrderId,
          })}
          contrast={false}
          modalActions={
            isTabletPortraitSize && (
              <PrimaryButton className={styles.back_to_menu} endIcon={<ArrowRight />} onClick={onClose}>
                Back to menu
              </PrimaryButton>
            )
          }
          headerChildren={
            <ProductDescription imagepath={menuData ? menuData.imagepath : ''} product={productDetails} />
          }
          footerChildren={
            <ProductSummary
              quantityincrement={productDetails.quantityincrement}
              minimumquantity={productDetails.minimumquantity}
              cost={productDetails.cost}
              handleAddProductClick={handleAddProductClick}
              adding={adding}
              extraCost={extraCost}
              productQuantity={productQuantity}
              handleProductQuantity={handleProductQuantity}
            />
          }
        >
          {addingErrorMessage && <Alert message={addingErrorMessage} />}
          <ProductModifiersView
            id={productDetails.id}
            showError={showOptionsError}
            defaultSelected={options}
            onChange={handleModifiersChange}
            contentRef={contentRef}
            scrollingToError={scrollingToError}
            additionalOffset={additionalOffset}
            emptyModifiers={emptyModifiers}
          />
          {/*!TODO Plug when customer add special instructions */}
          {false && (
            <ProductInstructions
              recipientRef={recipientRef}
              specialInstructions={specialInstructions}
              handleSpecialInstructions={handleSpecialInstructions}
              recipient={recipient}
              handleRecipient={handleRecipient}
              showRecipientError={showRecipientError}
              maxInstructionsLength={MAX_INSTRUCTIONS_LENGTH}
              maxNameLength={MAX_NAME_LENGTH}
            />
          )}
          {/*Plug without special instructions */}
          {groupOrderId && (
            <Section
              className={styles.name_section}
              titleElem={<PrimaryHeader title="YOUR NAME" brush="after" />}
              description="Required"
              headerHr
            >
              <div className={styles.name_wrapper}>
                <InputField
                  value={recipient}
                  onChange={handleRecipient}
                  label="First Name"
                  inputProps={{ maxLength: MAX_NAME_LENGTH }}
                  className={styles.input}
                  error={showRecipientError}
                  helperText={showRecipientError ? 'Please enter name' : ''}
                  ref={recipientRef}
                />
              </div>
            </Section>
          )}
        </ModalWindow>
      ) : (
        <div className={styles.error_container}>
          <Alert message={productError || menuErrorMessage} />
        </div>
      )}
    </>
  );
};

export default React.memo(ProductDetailsModal);
