import axios from './axiosInstances/axiosInstance';

export const createGroupOrder = (restaurantId, basketId, deadline, note) => {
  const body = {
    restaurantid: restaurantId,
    deadline: deadline,
    note: note,
  };
  if (basketId) {
    body.basketid = basketId;
  }
  return axios.post(`/baskets/grouporders`, body);
};
