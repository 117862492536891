import React, { useCallback, useEffect, useState } from 'react';
import styles from './ConfirmRestaurant.module.scss';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import OrderRestaurant from '../OrderRestaurant/OrderRestaurant';
import { useHistory } from 'react-router';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { createBasket } from '../../api/createBasket';
import Spin from '../../components/Spin/Spin';
import HandoffModeModal from '../HandoffModeModal/HandoffModeModal';

const ConfirmRestaurant = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [basket, setBasket] = useState(null);
  const appDispatch = useAppDispatchContext();
  const history = useHistory();
  const [isHandoffModeModalOpen, setIsHandoffModeModalOpen] = useState(false);
  const {
    autodetectedRestaurant: { data: restaurant },
  } = useAppStateContext();

  const onHandoffModeSubmit = () => {
    history.push(`/${restaurant.id}/menu`);
  };

  const onChangeRoute = useCallback(() => {
    history.push(`/?location=true`);
  }, [history]);

  const onStartOrder = useCallback(
    async (restaurant) => {
      appDispatch({ type: ACTION_TYPES.setRestaurant, payload: restaurant });
      setIsLoading(true);
      const basket = await createBasket(restaurant.id);
      setBasket(basket);
      setIsLoading(false);
      onHandoffModeSubmit();

      //Old method to open the Curbside or Delivery popup that doesn't get carried to checkout.
      //setIsHandoffModeModalOpen(true);
    },
    [appDispatch]
  );

  useEffect(() => {
    if (history && !restaurant) {
      history.push(`/`);
    }
  }, [restaurant, history]);

  return (
    <div className={styles.wrapper}>
      {isLoading && <div className={styles.backdrop} />}
      {isLoading && <Spin className={styles.spinner} spinning={isLoading} />}
      {isHandoffModeModalOpen && basket && restaurant && (
        <HandoffModeModal
          restaurant={restaurant}
          basket={basket}
          onSubmit={onHandoffModeSubmit}
          isOpen={isHandoffModeModalOpen}
          onClose={() => {
            setIsHandoffModeModalOpen(false);
          }}
        />
      )}
      <div className={styles.content_wrapper}>
        <div className={styles.order}>
          {restaurant ? (
            <OrderRestaurant
              onSelectDiffLocation={onChangeRoute}
              onStartOrder={() => onStartOrder(restaurant)}
              restaurant={restaurant}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
export default React.memo(ConfirmRestaurant);
