import React, { useCallback } from 'react';
import { useQueryUrlParamsDispatch } from 'hooks';

import { TextButton } from 'components/Button/Button';
import DrawerLayout from 'components/DrawerLayout/DrawerLayout';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import Link from 'components/Link/Link';

import { SignUpForm } from '../Forms';
import styles from './SignUpPanel.module.scss';

const SignUpPanel = ({ open, onClose }) => {
  const setQuery = useQueryUrlParamsDispatch();

  const handleSignIn = useCallback(() => {
    setQuery(
      {
        signin: true,
      },
      '',
      false,
      ['forgotPassword', 'signup']
    );
  }, [setQuery]);

  return (
    <DrawerLayout
      anchor={'right'}
      open={open}
      onChange={onClose}
      header={<PrimaryHeader title={'Sign Up'} size={'lg'} brush={'after'} />}
      footerChildren={<TextButton onClick={onClose}>cancel</TextButton>}
      transparentFooter
    >
      <div className={styles.form_container}>
        <SignUpForm close={onClose} />
        <div className={styles.form_footer}>
          <span>HAVE AN ACCOUNT?</span>
          <Link routing isGothic onClick={handleSignIn}>
            <TextButton>Sign in</TextButton>
          </Link>
        </div>
      </div>
    </DrawerLayout>
  );
};

export default SignUpPanel;
