import React from 'react';
import classNames from 'classnames/bind';
import './PrimaryHeader.scss';

// size = | sm | md | lg | xl | extra |
// brush = | before | after | both |

const PrimaryHeader = ({ title, titleClassName, size = 'md', revert = false, className, brush = 'both' }) => {
  const isBeforeBrush = ['before', 'both'].includes(brush);
  const isAfterBrush = ['after', 'both'].includes(brush);

  return (
    <div className={classNames('header_wrapper', className, { revert })}>
      <div
        className={classNames('title_wrapper', {
          before: isBeforeBrush,
          after: isAfterBrush,
        })}
      >
        <h3
          className={classNames('title', titleClassName, {
            [size]: size,
          })}
        >
          {title}
        </h3>
      </div>
    </div>
  );
};

export default React.memo(PrimaryHeader);
