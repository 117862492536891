import { getTransferBasketId } from 'api/getTransferBasketId';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useCallback, useReducer } from 'react';
import showToast from 'components/Toast/ShowToast';
import { HANDOFF_MODE_ENUM } from 'modules/HandoffModeModal/HandoffModeModal';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useTransferBasketId = () => {
  const appDispatch = useAppDispatchContext();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  return {
    ...state,
    setTransferBasketId: useCallback(
      (basketId, restaurant) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            const { basket, itemsnottransferred } = await getTransferBasketId(basketId, restaurant.id);
            const isCurbsideSupported = restaurant.supportscurbside;
            const isDispatchSupported = restaurant.supportsdispatch;
            const isPickup = basket.deliverymode === HANDOFF_MODE_ENUM.PICKUP;
            const isIncorrectDeliveryMode = isPickup && (isCurbsideSupported || isDispatchSupported);
            if (isIncorrectDeliveryMode) {
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: null } });
              return basket;
            }
            appDispatch({ type: ACTION_TYPES.setItemsNotTransferred, payload: itemsnottransferred });
            appDispatch({ type: ACTION_TYPES.setBasket, payload: { data: basket } });
            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: basket } });
            showToast('success', 'The basket was successfully transferred to the new location');
            if (itemsnottransferred.length > 0) {
              itemsnottransferred.map((item) => showToast('error', `${item} is not available at this location!`));
            }
          } catch (e) {
            const errorMsg =
              e.errorMessage || 'Something went wrong... Could not transfer current basket to selected restaurant!';
            if (errorMsg) {
              showToast('error', errorMsg);
            }
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
          }
        };
        if (basketId && restaurant) {
          return fetch();
        }
      },
      [appDispatch, dispatch]
    ),
  };
};

export default useTransferBasketId;
