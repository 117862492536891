import React, { useCallback, useEffect, useState } from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import classNames from 'classnames';
import { AppBar } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slider from 'components/Slider/Slider';

import styles from './RestaurantCategoriesNav.module.scss';

const RestaurantCategoriesNav = ({ categories: restaurantCategories, value, onChange, onDomUpdated }) => {
  const theme = useTheme();
  const isHandheldSize = useMediaQuery(theme.breakpoints.up(1025));
  const { globalNotificationIsShow } = useAppStateContext();
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    if (restaurantCategories) {
      const values = restaurantCategories.map((category, index) => {
        return { label: category.name, value: index };
      });
      setNavItems(values);
    }
  }, [restaurantCategories]);

  const handleClickItem = useCallback(
    (item) => {
      onChange(item.value);
    },
    [onChange]
  );

  const barRef = (node) => {
    if (node) {
      const { top, right, bottom, left, width, height, x, y } = node.getBoundingClientRect();
      onDomUpdated({ top, right, bottom, left, width, height, x, y });
    }
  };

  const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0;

  return (
    <div className={styles.container}>
      <AppBar
        classes={{ root: classNames(styles.nav_panel, { [styles.with_notification]: globalNotificationIsShow }) }}
        position="fixed"
        ref={barRef}
      >
        {isHandheldSize ? (
          <div className={styles.nav}>
            {navItems.map((item, index) => {
              return (
                <Chip
                  label={item.label}
                  clickable={true}
                  size={'small'}
                  key={item.value}
                  className={classNames(styles.nav_item, {
                    [styles.selected]: value === index,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    onChange(item.value);
                  }}
                />
              );
            })}
          </div>
        ) : (
          <div className={classNames(styles.slider_nav, { [styles.touch]: isTouch })}>
            <Slider
              items={navItems}
              itemComponentProps={{
                className: (item) => {
                  return classNames(styles.nav_item, {
                    [styles.selected]: value === item.value,
                  });
                },
                clickable: true,
                size: 'small',
              }}
              value={value}
              centered={false}
              hideNavigation={true}
              itemComponent={Chip}
              onClickItem={handleClickItem}
            />
          </div>
        )}
      </AppBar>
    </div>
  );
};

export default RestaurantCategoriesNav;
