import { useState, useEffect, useCallback } from 'react';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import isMobileBrowser from '../services/isMobileBrowser';

const TIMEOUT = 1000;

const defaultOptions = {
  enableHighAccuracy: false,
  timeout: TIMEOUT,
  maximumAge: 0,
};

const useGeoPosition = (disabledGeo = false, watch = false, positionOptions = defaultOptions) => {
  const appDispatch = useAppDispatchContext();
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  const [currentPositionTimeout, setCurrentPositionTimeout] = useState(null);

  const clearCurrentPositionTimeout = useCallback(() => {
    if (currentPositionTimeout) {
      clearTimeout(currentPositionTimeout);
      setCurrentPositionTimeout(null);
    }
  }, [currentPositionTimeout]);

  const onChange = useCallback(
    ({ coords, timestamp }) => {
      const location = {
        latitude: coords.latitude,
        longitude: coords.longitude,
        accuracy: coords.accuracy,
        timestamp,
      };
      setPosition(location);
      appDispatch({ type: ACTION_TYPES.setLocation, payload: location });
    },
    [appDispatch]
  );

  const onError = useCallback(
    (error) => {
      setError(error.message);
      appDispatch({ type: ACTION_TYPES.setErrorLocation, payload: error.message });
    },
    [appDispatch]
  );

  useEffect(() => {
    if (currentPositionTimeout !== null && ((position && position.timestamp) || error)) {
      clearCurrentPositionTimeout();
    }
  }, [position, error, clearCurrentPositionTimeout, currentPositionTimeout]);

  useEffect(() => {
    let timeout;

    if (disabledGeo) {
      setError(true);
      return;
    }
    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported');
      return;
    }
    let watcher = null;

    if (isMobileBrowser()) {
      timeout = setTimeout(() => {
        onError({ message: 'User denied Geolocation (mobile)' });
      }, TIMEOUT + 100);

      setCurrentPositionTimeout(timeout);
    }

    if (watch) {
      watcher = navigator.geolocation.watchPosition(onChange, onError, positionOptions);
    } else {
      navigator.geolocation.getCurrentPosition(onChange, onError, positionOptions);
    }
    return () => {
      if (watcher) {
        navigator.geolocation.clearWatch(watcher);
      }

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [positionOptions, watch, appDispatch, onChange, onError, disabledGeo]);

  return { ...position, error };
};
export default useGeoPosition;
