import React from 'react';

import SendEmails from 'components/SendEmails/SendEmails';
import Section from 'components/Section/Section';

import styles from './GroupOrderSendInvites.module.scss';

const GroupOrderSendInvites = ({ onChangeEmails, onChangeNote, error, onSend, errorMessage, loading }) => {
  return (
    <Section title={'Email your group'} sectionHr description={'Optional'} className={styles.container}>
      <SendEmails
        loading={loading}
        errorMessage={errorMessage}
        notePlaceholder={
          'Email Message - Example: We are having lunch at 1:30pm Conference Room A. Please submit your order no later than 10am. See you there!'
        }
        onSend={onSend}
        onChangeEmails={onChangeEmails}
        onChangeNote={onChangeNote}
        error={error}
      />
    </Section>
  );
};

export default GroupOrderSendInvites;
