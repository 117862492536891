// export const RECAPTCHA_V3_SITE_KEY = '6LfOETMfAAAAAJpk86uBeNYyTurWdDQf7FulujlG';
export const RECAPTCHA_V3_SITE_KEY = '6LeJxromAAAAAMP-Q8v8df0OVx0G4W4qyGeS5GEg';
export const RECAPTCHA_V2_SITE_KEY = '6LdxEzMfAAAAACjJVZff78NW5uzZRs4UuV2aRkDd';

const getV3Token = async () => {
  const token = await window.grecaptchaV3.execute(RECAPTCHA_V3_SITE_KEY, {
    action: 'submit',
  });

  return token;
};

const getV2Token = async (setCaptchaToken) => {
  const token = await new Promise((resolve) => {
    window.grecaptchaV2.render('v2-captcha', {
      sitekey: RECAPTCHA_V2_SITE_KEY,
      callback: (token) => {
        setCaptchaToken(token);
        resolve(token);
      },
    });
  });

  return token;
};

const findV2TokenInDOM = () => {
  let token;

  const recaptchaToken = document.getElementById('recaptcha-token');
  const reResponseField = document.getElementById('g-recaptcha-response');

  if (!!reResponseField) {
    token = reResponseField.value;
  }

  if (!!recaptchaToken) {
    token = recaptchaToken.value;
  }

  return token;
};

export const withReCaptchaRequest = async (action, params, setCaptchaToken = () => {}) => {
  let reCaptchaToken, reCaptchaVersion;

  if (window.grecaptcha) {
    await new Promise((resolve) => {
      window.grecaptchaV3.ready(() => {
        resolve();
      });
    });

    try {
      reCaptchaToken = await getV3Token();
      reCaptchaVersion = 3;

      const response = await action(...params, {
        headers: { 'X-CPTCH-RESULT': reCaptchaToken, 'X-CPTCH-V': reCaptchaVersion },
      });

      return response;
    } catch (error) {
      console.error(error);
      if (error.error_status === 429) {
        try {
          reCaptchaToken = await getV2Token(setCaptchaToken);
          setCaptchaToken(reCaptchaToken);
        } catch (error) {
          await new Promise((r) => setTimeout(r, 1000));

          const token = findV2TokenInDOM();
          if (token) {
            reCaptchaToken = token;
          }
        }
        reCaptchaVersion = 2;
        setTimeout(() => {
          window.grecaptchaV2.reset();
          setCaptchaToken(null);
        }, 1000);
      } else {
        reCaptchaToken = await getV3Token();
        reCaptchaVersion = 3;
      }

      const response = await action(...params, {
        headers: { 'X-CPTCH-RESULT': reCaptchaToken, 'X-CPTCH-V': reCaptchaVersion },
      });

      return response;
    }
  }
};
