import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useQueryUrlParamsDispatch, useQueryUrlParams } from 'hooks';

import FavoriteOrder from './FavoriteOrder/FavoriteOrder';
import ProductDetailsModal from '../ProductDetailsModal/ProductDetailsModal';

const FavoritesPanel = () => {
  const { restaurantId } = useParams();
  const { favoritesPanel, backToAccountPage, faveId } = useQueryUrlParams();
  const parsedFavoritesPanel = favoritesPanel ? !!JSON.parse(favoritesPanel) : false;
  const parsedBackToAccountPage = backToAccountPage ? !!JSON.parse(backToAccountPage) : false;
  const [favoritesPanelOpen, setFavoritesPanelOpen] = useState(parsedFavoritesPanel && faveId && restaurantId);
  const appDispatch = useAppDispatchContext();
  const setQuery = useQueryUrlParamsDispatch();

  const [openProductDetails, setOpenProductDetails] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);

  const setModifyInProgress = useCallback(
    (inModifyProgress) => {
      appDispatch({
        type: ACTION_TYPES.setModifyFavoriteIsInProgress,
        payload: { data: inModifyProgress },
      });
      window.localStorage.removeItem('rewardId');
    },
    [appDispatch]
  );

  useEffect(() => {
    if (parsedFavoritesPanel && faveId && restaurantId) {
      setFavoritesPanelOpen(true);
      setModifyInProgress(true);
    }
  }, [parsedFavoritesPanel, faveId, restaurantId, setModifyInProgress]);

  const onClose = (inModifyProgress, wasSaved = false) => {
    const paramsToExclude = inModifyProgress ? ['favoritesPanel'] : ['favoritesPanel', 'faveId', 'backToAccountPage'];

    if (parsedBackToAccountPage && wasSaved && !inModifyProgress) {
      setQuery({}, '/account', false, paramsToExclude);
    } else {
      setQuery({}, null, false, paramsToExclude);
    }

    setFavoritesPanelOpen(false);
  };

  const handleNameClick = (product) => {
    setEditedProduct(product);
    setOpenProductDetails(true);
  };

  const handleProductDetailsClose = () => {
    setOpenProductDetails(false);
    setEditedProduct(null);
  };

  return (
    <>
      <FavoriteOrder
        open={!!favoritesPanelOpen}
        onClose={onClose}
        setModifyInProgress={setModifyInProgress}
        handleNameClick={handleNameClick}
      />
      {openProductDetails && (
        <ProductDetailsModal edit={true} product={editedProduct} onClose={handleProductDetailsClose} />
      )}
    </>
  );
};

export default FavoritesPanel;
