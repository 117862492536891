import React, { Fragment, useMemo } from 'react';
import styles from './AvailableRewardsList.module.scss';
import { TextButton } from 'components/Button/Button';
import moment from 'moment';

const AvailableRewardsList = ({ data = [], handleDetailsReward }) => {
  const rewards = useMemo(
    () =>
      data.reduce((accum, reward) => {
        if (Array.isArray(reward.expirations)) {
          const duplicatedRewards = reward.expirations.map((expiration) => {
            return { ...reward, expirationdate: expiration.expirationDate };
          });

          return [...accum, ...duplicatedRewards];
        }

        return [...accum, reward];
      }, []),
    [data]
  );

  return (
    <div className={styles.available_rewards_list_container}>
      {rewards.map((rewardItem, index) => {
        const expiresLabel = rewardItem.expirationdate
          ? `Expires ${moment(rewardItem.expirationdate, 'YYYYMMDD HH:mm').format('MM/DD/YYYY')}`
          : 'No expiration date';

        return (
          <Fragment key={index}>
            <div className={styles.available_rewards_list_item}>
              <div className={styles.rewards_info}>
                <h3 className={styles.reward_name}>{rewardItem.name}</h3>
                <div className={styles.reward_expiration}>{expiresLabel}</div>
              </div>

              <TextButton
                className={styles.add_to_order_btn}
                onClick={() => {
                  handleDetailsReward(rewardItem);
                }}
              >
                DETAILS
              </TextButton>
            </div>
            {index !== data.length - 1 && <div className={styles.divider} />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(AvailableRewardsList);
