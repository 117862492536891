import React, { useCallback, useState } from 'react';
import styles from './SelectFavoriteLocationModal.module.scss';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import Section from 'components/Section/Section';
import ChooseFavoriteLocation from 'components/ChooseFavoriteLocation/ChooseFavoriteLocation';
import Spin from 'components/Spin/Spin';
import showToast from 'components/Toast/ShowToast';
import useSelectFavoriteLocation from './hooks/useSelectFavoriteLocation';
import CheckIcon from '@material-ui/icons/Check';

const DEFAULT_STATE = { searchValue: '', isTouched: false, locationCode: '' };

const SelectFavoriteLocationModal = ({ onClose, open }) => {
  const [favoriteState, setFavoriteState] = useState({ ...DEFAULT_STATE });
  const [{ loading }, { updateFavoriteLocation }] = useSelectFavoriteLocation();

  const closeModal = useCallback(() => {
    setFavoriteState({ ...DEFAULT_STATE });
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(() => {
    setFavoriteState({ ...favoriteState, isTouched: true });

    if (!favoriteState.locationCode) {
      showToast('warning', 'Please choose favorite location');
      return;
    }

    updateFavoriteLocation(favoriteState.locationCode).then(() => {
      showToast('success', 'Favorite location updated successfully');
      closeModal();
    });
  }, [closeModal, updateFavoriteLocation, favoriteState]);

  const handleFavoriteLocationChanged = (state) => {
    setFavoriteState(state);
  };

  return (
    <ModalWindow
      open={open}
      onChange={closeModal}
      maxWidth="sm"
      headerSize="lg"
      headerTitle="FAVORITE LOCATION"
      footerChildren={
        <div className={styles.modal_footer}>
          <PrimaryButton
            disabled={loading}
            className={styles.submit_btn}
            endIcon={<CheckIcon />}
            size="large"
            onClick={onSubmit}
          >
            <Spin spinning={loading} className={styles.spinner} />
            SUBMIT
          </PrimaryButton>
        </div>
      }
    >
      <div className={styles.modal_content}>
        <Section sectionHr title="Choose Your Favorite Location" className={styles.section}>
          <ChooseFavoriteLocation state={favoriteState} stateChanged={handleFavoriteLocationChanged} />
        </Section>
      </div>
    </ModalWindow>
  );
};

export default React.memo(SelectFavoriteLocationModal);
