import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ErrorIcon from '@material-ui/icons/Error';
import { toast } from 'react-toastify';
import Toast from './Toast';

const configToast = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeButton: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  className: 'Notify_container',
};

const showToast = (type, message) => {
  let toastProperties = {};
  if (message) {
    switch (type) {
      case 'success':
        toastProperties = {
          id: 1,
          description: message || 'This is a success toast component',
          icon: <CheckIcon />,
        };
        configToast.className = 'Notify_container success';
        break;
      case 'error':
        toastProperties = {
          id: 2,
          description: message || 'This is an error toast component',
          icon: <ErrorIcon />,
        };
        configToast.className = 'Notify_container error';
        break;
      case 'info':
        toastProperties = {
          id: 3,
          description: message || 'This is an info toast component',
          icon: <InfoIcon />,
        };
        configToast.className = 'Notify_container info';
        break;
      case 'warning':
        toastProperties = {
          id: 4,
          description: message || 'This is a warning toast component',
          icon: <WarningRoundedIcon />,
        };
        configToast.className = 'Notify_container warning';
        break;
      default:
        toastProperties = {};
    }

    toast(<Toast toastList={toastProperties} />, configToast);
  } else {
    console.error('Call notification component with empty message or undefined, call stack: ', new Error().stack);
  }
};

export default showToast;
