import React from 'react';

import CloseIcon from '@material-ui/icons/Close';

import { PrimaryIconButton } from 'components/Button/Button';
import Price from 'components/Price/Price';

import styles from './FavoriteOrderProducts.module.scss';

const FavoriteOrderProducts = ({ data, handleRemoveProduct, loading, handleNameClick }) => {
  const onRemoveProduct = (name, productId) => () => {
    handleRemoveProduct(name, productId);
  };

  const onNameClick = (product) => () => {
    handleNameClick(product);
  };

  return data.products.map((product) => {
    const { id, name, quantity, totalcost, choices, recipient } = product;

    return (
      <div className={styles.menu_item} key={id}>
        <div className={styles.item_header}>
          <div className={styles.title_price_wrapper} onClick={onNameClick(product)}>
            <h4 className={styles.title}>{`${quantity}x ${name}`}</h4>
            <span className={styles.price}>
              <Price value={totalcost} />
            </span>
          </div>
          <PrimaryIconButton disabled={loading} size={'small'} onClick={onRemoveProduct(name, id)}>
            <CloseIcon />
          </PrimaryIconButton>
        </div>
        {recipient && <div className={styles.recipient}>{recipient}</div>}
        {choices.length > 0 && (
          <div className={styles.description}>
            {choices.map(({ id, name }) => {
              return (
                <span key={id}>
                  {name}
                  <br />
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  });
};

export default FavoriteOrderProducts;
