import React, { useCallback, useMemo } from 'react';
import styles from './AccountDetails.module.scss';
import { Box } from '@material-ui/core';
import AccountInfo from './AccountInfo/AccountInfo';
import AccountRewards from './AccountRewards/AccountRewards';
import AccountVisits from './AccountVisits/AccountVisits';
import InviteFriends from './InviteFriends/InviteFriends';
import { TextButton } from 'components/Button/Button';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useQueryUrlParamsDispatch } from 'hooks';
import auth from 'services/auth';
import useLogOutUser from '../hooks/useLogOutUser';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import showToast from 'components/Toast/ShowToast';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

const AccountDetails = () => {
  const {
    user: { data: userData },
  } = useAppStateContext();
  const setRedirect = useQueryUrlParamsDispatch();
  const { loading: logoutLoading, fetchLogOutUser } = useLogOutUser();
  const appDispatch = useAppDispatchContext();
  const mappedUserData = userData
    ? {
        firstName: userData.firstName || '-',
        lastName: userData.lastName || '-',
        rewardsId:
          userData.primaryCardNumbers && userData.primaryCardNumbers.length ? userData.primaryCardNumbers[0] : '-',
        phoneNumber: userData.mobilePhone || '',
        birthday: userData.dateOfBirth || '-',
        email: userData.email || '-',
      }
    : null;
  const logoutCallback = useCallback(() => {
    setRedirect({ location: true }, '/', false, [
      'restaurantId',
      'basketId',
      'groupOrderId',
      'owner',
      'joinGroup',
      'invites',
    ]);
    appDispatch({ type: ACTION_TYPES.finishCleanUpBasket });
  }, [setRedirect, appDispatch]);
  const handleLogOutClick = useCallback(
    (showMessage) => {
      fetchLogOutUser().then(
        (resolve) => {
          if (resolve) {
            showToast('success', 'Success! Sign out successful.');
            auth.logout(logoutCallback);
          }
        },
        (reject) => {
          console.error(reject);
        }
      );
    },
    [fetchLogOutUser, logoutCallback]
  );

  const visitsBalance = useMemo(() => {
    const walletCode = 14;

    // for recent orders
    // if (isDevelopment || isStaging) {
    //   walletCode = 22;
    // } else {
    //   walletCode = 32;
    // }

    const pointBalances = userData.accountInfo.pointBalances;
    if (!pointBalances) {
      return;
    }

    return pointBalances.find((balance) => balance.walletCode === walletCode);
  }, [userData]);

  return (
    <div className={styles.account_details}>
      <div className={styles.card_details}>
        <div className={styles.account_header}>
          <PrimaryHeader
            titleClassName={styles.title}
            className={styles.header}
            size="extra"
            revert
            title="My Account"
          />
        </div>
        <AccountVisits visitsBalance={visitsBalance} />
        <AccountRewards />
        <div className={styles.card_info}>
          <AccountInfo accountData={mappedUserData} handleLogOutClick={handleLogOutClick} />
        </div>
        <Box mt={1}>
          <InviteFriends />
        </Box>
      </div>
      <div className={styles.footer}>
        <TextButton disabled={logoutLoading} size="large" className={styles.logout_btn} onClick={handleLogOutClick}>
          LOG OUT
        </TextButton>
      </div>
    </div>
  );
};

export default React.memo(AccountDetails);
