import React, { useState, useEffect, useCallback } from 'react';
import Accordion from '../../../components/Accordion/Accordion';
import OrderPlaceCard from '../../../components/OrderPlaceCard/OrderPlaceCard';
import styles from './RestaurantsList.module.scss';
import classNames from 'classnames';

export const ACCORDION_TRANSITION_TIMEOUT = 200;

const ACCORDION_HEADER_HEIGHT = 64;

const RestaurantsList = ({
  states,
  onChange,
  defaultSelectPlace = {},
  onSelectOrder,
  scrollOffset,
  cleanSelected,
  isHandheldSize = false,
}) => {
  const [openAccordion, setOpenAccordion] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(defaultSelectPlace);
  useEffect(() => {
    if (cleanSelected && openAccordion && Object.keys(selectedPlace).length) {
      setOpenAccordion('');
      setSelectedPlace({});
    }
  }, [cleanSelected, openAccordion, selectedPlace]);
  useEffect(() => {
    const stateKey = defaultSelectPlace['stateKey'];
    if ((stateKey && !selectedPlace['stateKey']) || (stateKey && selectedPlace['stateKey'] !== stateKey)) {
      setOpenAccordion({ [stateKey]: true });
    }

    if (!selectedPlace || !selectedPlace.id || (defaultSelectPlace && defaultSelectPlace.id !== selectedPlace.id)) {
      setSelectedPlace(defaultSelectPlace);
    }
  }, [defaultSelectPlace, selectedPlace, openAccordion]);
  const handlerPlace = (stateKey, restaurant) => (id) => {
    onChange({ stateKey, idPlace: id, restaurant: restaurant });
  };
  const onChangeAccordion = (stateKey) => (event, isExpanded) => {
    setOpenAccordion({ [stateKey]: isExpanded });
  };
  const onOffsetTopChange = useCallback(
    (offsetTop) => {
      if (scrollOffset) {
        scrollOffset(offsetTop);
      }
    },
    [scrollOffset]
  );

  return (
    <div className={classNames(styles.wrapper, { [styles.handheld_mode]: isHandheldSize })}>
      {states &&
        (states || []).map(({ stateName, stateKey, restaurants }, index) => (
          <Accordion
            id={stateKey}
            TransitionProps={{
              timeout: ACCORDION_TRANSITION_TIMEOUT,
            }}
            expanded={openAccordion[stateKey] || false}
            onChange={onChangeAccordion(stateKey)}
            header={stateName}
            key={stateKey}
          >
            <div className={styles.restaurants}>
              {restaurants &&
                restaurants.map((restaurant) => {
                  const {
                    id,
                    zip,
                    city,
                    state,
                    streetaddress,
                    telephone,
                    distance,
                    isavailable,
                    iscurrentlyopen,
                    latitude,
                    longitude,
                    calendars,
                    supportsdispatch,
                    supportscurbside,
                  } = restaurant;
                  const description = [
                    { label: `${streetaddress}` },
                    { label: `${city}, ${state} ${zip}` },
                    { ...(distance !== 0 && { label: `${distance} miles away` }) },
                  ];
                  return (
                    <div key={id} className={styles.restaurant}>
                      <OrderPlaceCard
                        id={id}
                        lat={latitude}
                        long={longitude}
                        phone={telephone}
                        calendars={calendars}
                        title={`${city}, ${state}`}
                        description={description}
                        btnLabel="Start Your Order"
                        disabled={!isavailable}
                        iscurrentlyopen={iscurrentlyopen}
                        supportsdispatch={supportsdispatch}
                        supportscurbside={supportscurbside}
                        disabledMsg="Not Accepting Online Orders"
                        defaultSelect={selectedPlace['idPlace'] === id}
                        onPick={() => onSelectOrder(restaurant)}
                        parentOffsetTop={index * ACCORDION_HEADER_HEIGHT}
                        offsetTopChange={onOffsetTopChange}
                        onSelect={handlerPlace(stateKey, restaurant)}
                      />
                    </div>
                  );
                })}
            </div>
          </Accordion>
        ))}
    </div>
  );
};
export default React.memo(RestaurantsList);
