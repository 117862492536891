import React from 'react';
import styles from './OrderingFrom.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import { useLocation } from 'react-router';
import useRestaurantByIdFetch from './hooks/useRestaurantByIdFetch';
import Alert from 'components/Alert/Alert';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import ChangeLocationLink from '../ChangeLocationLink/ChangeLocationLink';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';
import { useQueryUrlParams } from 'hooks';
import { useParams } from 'react-router';

/**
 * @function
 * @name useRestaurantInfo
 * @description Create function for reuse getting restaurant information
 * @param {boolean} fullData - return full restaurant info
 * @param {array} keys - return specific restaurant info fields
 * @return {object} object of restaurant info: dataLoaded, error, loading, address, fullCityName,isDefinedAddress
 */
export const useRestaurantInfo = (keys = [], fullData = false) => {
  const {
    restaurant: { data, loading, error },
  } = useAppStateContext();
  const dataIsLoaded = !error && !loading;
  const zip = data && data['zip'] && data['zip'];
  const state = data && data['state'] && data['state'];
  const city = data && data['city'] && data['city'];
  const streetaddress = data && data['streetaddress'] && data['streetaddress'];
  const isDefinedAddress = city && state && streetaddress && zip;
  const shortCityName = state && city ? `${city}, ${state}` : city && !state ? city : '';
  const fullCityName = state && city && zip ? `${city}, ${state} ${zip}` : city && !state ? city : '';
  const address = isDefinedAddress ? `${streetaddress && streetaddress} ${city}, ${state} ${zip}` : '';
  const restaurantInfo = fullData ? { ...data } : null;
  const returnKeys = {};
  if (data && !loading && !error && keys.length > 0) {
    keys.forEach((key) => {
      returnKeys[key] = data[key];
    });
  }
  return {
    ...restaurantInfo,
    ...returnKeys,
    isDefinedAddress,
    dataLoaded: dataIsLoaded,
    loading,
    error,
    shortCityName,
    fullCityName,
    fullAddress: address,
  };
};

const SkeletonLocation = () => (
  <div className={styles.loading}>
    <Skeleton animation="wave" />
  </div>
);

const OrderingFrom = () => {
  useRestaurantByIdFetch();
  const { dataLoaded, loading, error, shortCityName, isDefinedAddress, id } = useRestaurantInfo(['id']);
  const { pathname, search } = useLocation();
  const setHistoryPush = useQueryUrlParamsDispatch();
  const url = pathname + search;
  const { restaurantId: queryRestaurantId } = useQueryUrlParams();
  const { restaurantId, orderId } = useParams();
  const getRestaurantId = restaurantId || queryRestaurantId || id;

  const handlerChangeLocation = (e) => {
    e.preventDefault();
    const confirmOrderPage = pathname.includes(`order/${orderId}`);
    setHistoryPush(
      {
        location: true,
        prevRestaurant: getRestaurantId,
        returnUrl: !confirmOrderPage && encodeURIComponent(pathname + search),
      },
      '/',
      true
    );
  };

  const errorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not load restaurant location!')) || '';

  const ErrorMsg = () => (
    <span className={styles.error_link}>
      {errorMessage} Please try again later or{' '}
      <button className={'btn_link'} onClick={handlerChangeLocation}>
        change location
      </button>
      !
    </span>
  );

  if (url.includes('/?location=true')) {
    return <p className={styles.location_page}>Search or view nearby locations below</p>;
  }

  if (loading) {
    return <SkeletonLocation />;
  }

  if (!isDefinedAddress && !error) {
    return '';
  }

  return (
    <div className={styles.wrapper}>
      {error && (
        <div className={styles.error}>
          <Alert message={<ErrorMsg />} />
        </div>
      )}
      {dataLoaded && (
        <div className={styles.city}>
          <span>Ordering from:</span>
          <ChangeLocationLink label={shortCityName} className={styles.link} />
        </div>
      )}
    </div>
  );
};

export default React.memo(OrderingFrom);
