import React, { memo } from 'react';
import styles from './ReviewOrder.module.scss';
import Section from 'components/Section/Section';
import DrawerLayout from 'components/DrawerLayout/DrawerLayout';
import { PrimaryButton } from 'components/Button/Button';
import RestaurantInfo from './RestaurantInfo/RestaurantInfo';
import BasketItems from './BasketItems/BasketItems';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import Alert from 'components/Alert/Alert';
import { useUpdateUpsell } from '../OrderUpsell/hooks/useBasketUpsell';
import { useHistory } from 'react-router';
import { useQueryUrlParams } from 'hooks';
import { useParams } from 'react-router';
import Price from 'components/Price/Price';
import GroupOrderInfo from './GroupOrderInfo/GroupOrderInfo';
import HandoffModeInfo from './HandoffModeInfo/HandoffModeInfo';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import { Box, Divider } from '@material-ui/core';

const HeaderPanel = memo(({ onEmptyClose, onClose }) => {
  return (
    <div className={styles.header}>
      <Box mb={4}>
        <PrimaryHeader size="lg" brush="after" title="YOUR ORDER" />
      </Box>
      <RestaurantInfo onEmptyClose={onEmptyClose} onClose={onClose} />
      {/* <Divider classes={{ root: styles.divider }} />
      <HandoffModeInfo onClose={onEmptyClose} /> */}
    </div>
  );
});

HeaderPanel.displayName = 'HeaderPanel';

const ReviewOrder = ({ open, onSetStep, onClose, onEmptyClose, emptyBasket, handleNameClick }) => {
  const history = useHistory();
  const { restaurantId } = useParams();
  const { restaurantId: queryResId } = useQueryUrlParams();
  const { loading: upsellLoading, getUpsell } = useUpdateUpsell();
  const {
    basket: { loading, data, error },
    notTransferredBasketItems,
    authenticated,
  } = useAppStateContext();
  const subtotal = data && data['subtotal'];
  const isUpsellEnabled = data && data['isupsellenabled'];
  const onSubmitOrder = () => {
    if (isUpsellEnabled) {
      getUpsell().then(
        (resolve) => {
          if (resolve && resolve.length > 0) {
            onSetStep('upsell');
          } else {
            if (authenticated) {
              history.push(`/${restaurantId || queryResId}/checkout`);
            }
            onSetStep('checkoutAuth');
          }
        },
        (reject) => {
          if (authenticated) {
            history.push(`/${restaurantId || queryResId}/checkout`);
          }
          onSetStep('checkoutAuth');
        }
      );
    } else {
      onSetStep('checkoutAuth');
    }
  };
  return (
    <DrawerLayout
      open={open}
      onChange={onClose}
      disabledFooter={emptyBasket}
      header={<HeaderPanel onEmptyClose={onEmptyClose} onClose={onClose} />}
      footerChildren={
        <PrimaryButton
          variant="outlined"
          fullWidth
          className={styles.submit_btn}
          disabled={upsellLoading}
          onClick={onSubmitOrder}
        >
          CHECKOUT: SUBTOTAL&nbsp;
          {subtotal ? <Price value={subtotal} /> : `$${0}`}
        </PrimaryButton>
      }
    >
      <div className={styles.wrapper}>
        <GroupOrderInfo onClose={onClose} />
        {notTransferredBasketItems && notTransferredBasketItems.length > 0 && (
          <div className={styles.alert_wrapper}>
            {notTransferredBasketItems.map((name, key) => {
              return <Alert key={key} message={`${name} is not available at this location`} />;
            })}
          </div>
        )}
        <Section className={styles.section} sectionHr title={'SELECTED ITEMS'}>
          <BasketItems
            loading={loading}
            data={data}
            error={error}
            onClose={onClose}
            handleNameClick={handleNameClick}
          />
        </Section>
      </div>
    </DrawerLayout>
  );
};

export default React.memo(ReviewOrder);
