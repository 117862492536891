import React, { useState } from 'react';
import styles from './AccountInfo.module.scss';
import { TextButton } from 'components/Button/Button';
import { Box, Divider } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import EditProfileModal from './EditProfileModal/EditProfileModal';
import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal';
import DeleteAccountModal from './DeleteAccountModal/DeleteAccountModal';
import PrintRewardsCardModal from './PrintRewardsCardModal/PrintRewardsCardModal';
import CommunicationPrefModal from './CommunicationPrefModal/CommunicationPrefModal';
import moment from 'moment';
import FavoriteLocationButton from 'modules/Account/FavoriteLocations/FavoriteLocationButton/FavoriteLocationButton';

const AccountInfo = ({ accountData, handleLogOutClick }) => {
  const { birthday, firstName, lastName, rewardsId, phoneNumber, email } = accountData;
  const [modalsState, setModalsState] = useState({
    editProfile: false,
    changePassword: false,
    deleteAccount: false,
    printRewards: false,
    communicationPref: false,
  });
  const handleStateModal = (modals) => () => {
    const newState = { ...modalsState, ...modals };
    setModalsState(newState);
  };

  const onCloseModal = (modal) => {
    setModalsState({ ...modalsState, ...modal });
  };

  return (
    <div className={styles.container}>
      <div className={styles.account_info_row}>
        <h3 className={styles.account_item_name} title={`${firstName} ${lastName}`}>
          {firstName} {lastName}
        </h3>
        <div>
          <TextButton onClick={handleStateModal({ editProfile: true })} className={styles.edit_btn} size="small">
            Edit
          </TextButton>
        </div>
      </div>
      <Divider />
      <div className={styles.account_info_row}>
        <h4 className={styles.account_item_label}>Rewards ID</h4>
        <div className={styles.account_item_value} title={rewardsId}>
          {rewardsId}
        </div>
      </div>
      <Divider />
      <div className={styles.account_info_row}>
        <h4 className={styles.account_item_label}>Phone number</h4>
        <div className={styles.account_item_value}>
          <NumberFormat value={phoneNumber} displayType={'text'} format="###-###-####" />
        </div>
      </div>
      <Divider />
      <div className={styles.account_info_row}>
        <h4 className={styles.account_item_label}>Birthday</h4>
        <div className={styles.account_item_value}>
          {moment(birthday, 'YYYY-MM-DD').isValid() ? moment(birthday, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}
        </div>
      </div>
      <Divider />
      <div className={styles.account_info_row}>
        <h4 className={styles.account_item_label}>E-mail</h4>
        <div className={styles.account_item_value} title={email}>
          {email}
        </div>
      </div>
      <Divider />
      <div className={styles.account_info_row}>
        <h4 className={styles.account_item_label}>Favorite Location</h4>
        <div>
          <FavoriteLocationButton />
        </div>
      </div>
      <Divider />
      <div className={styles.account_info_row}>
        <h4 className={styles.account_item_label}>Password</h4>
        <div>
          <TextButton className={styles.change_btn} size="small" onClick={handleStateModal({ changePassword: true })}>
            Change
          </TextButton>
        </div>
      </div>
      <Divider />
      <div className={styles.account_info_row}>
        <div className={styles.center}>
          <TextButton size="large" className={styles.print_btn} onClick={handleStateModal({ printRewards: true })}>
            PRINT REWARDS CARD
          </TextButton>
        </div>
      </div>
      <Divider />
      <Box pt={1} pb={1}>
        <div className={styles.account_info_row}>
          <div className={styles.center}>
            <TextButton
              className={styles.communication_pref_btn}
              onClick={handleStateModal({ communicationPref: true })}
            >
              Communication Preferences
            </TextButton>
          </div>
        </div>
      </Box>

      <EditProfileModal isOpen={modalsState['editProfile']} accountData={accountData} onClose={onCloseModal} />
      <ChangePasswordModal isOpen={modalsState['changePassword']} onClose={onCloseModal} />
      <DeleteAccountModal
        isOpen={modalsState['deleteAccount']}
        onClose={onCloseModal}
        handleLogOutClick={handleLogOutClick}
      />
      <PrintRewardsCardModal data={accountData} isOpen={modalsState['printRewards']} onClose={onCloseModal} />
      <CommunicationPrefModal onClose={onCloseModal} isOpen={modalsState['communicationPref']} />
    </div>
  );
};

export default React.memo(AccountInfo);
