import React, { useState, useEffect } from 'react';
import { useQueryUrlParams } from 'hooks';

import FavoritesList from './FavoritesList/FavoritesList';

const MyFavoritesModal = () => {
  const { myFavoritesModal } = useQueryUrlParams();
  const parsedMyFavoritesModal = myFavoritesModal ? !!JSON.parse(myFavoritesModal) : false;
  const [myFavoritesModalOpen, setMyFavoritesModalOpen] = useState(parsedMyFavoritesModal);

  useEffect(() => {
    if (parsedMyFavoritesModal) {
      setMyFavoritesModalOpen(parsedMyFavoritesModal);
    } else {
      setMyFavoritesModalOpen(false);
    }
  }, [parsedMyFavoritesModal]);

  return <FavoritesList state={myFavoritesModalOpen} />;
};

export default MyFavoritesModal;
