import { useEffect, useReducer, useCallback } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { retrieveBasketQualifyingRewards } from 'api/retrieveBasketQualifyingRewards';
import { retrieveBasketAppliedRewards } from 'api/retrieveBasketAppliedRewards';
import { applyRewardToBasket } from 'api/applyRewardToBasket';
import { removeAppliedRewardFromBasket } from 'api/removeAppliedRewardFromBasket';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { validateBasketService } from 'services/validateBasket';
import useValidateBasket from '../../hooks/useValidateBasket';
import { getBasketInfo } from '../../../../../api/getBasketInfo';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useRetrieveBasketQualifyingRewardsFetch = (basketId, printedCardNumber, appliedrewardsIds, restaurant) => {
  const validateBasket = useValidateBasket();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppBasketState = useAppDispatchContext();

  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      if (basketId) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          let responseAll;
          let responseApplied;

          if (restaurant.supportsloyalty) {
            responseAll = await retrieveBasketQualifyingRewards(basketId, printedCardNumber);
            responseApplied = await retrieveBasketAppliedRewards(basketId, printedCardNumber);
          }

          const allRewards = responseAll ? responseAll.rewards : [];
          const appliedRewards = responseApplied ? responseApplied.rewards : [];

          appliedRewards.forEach((appliedReward) => {
            const foundReward = allRewards.find((reward) => reward.reference === appliedReward.reference);
            foundReward.applied = appliedReward.applied;
            foundReward.rewardid = appliedReward.rewardid;
          });

          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: allRewards } });
          }
        } catch (e) {
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
          }
        }
      }
    };

    fetch();

    return () => {
      ignore = true;
    };
  }, [basketId, printedCardNumber, appliedrewardsIds, restaurant]);

  const removeRewardFromBasket = useCallback(
    (basketId, rewardid) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true, refetch: true } });
          dispatchAppBasketState({ type: ACTION_TYPES.loadingBasket, payload: { loading: true } });
          try {
            const basket = await validateBasketService(removeAppliedRewardFromBasket, basketId, rewardid);
            const rewards = state.data.map((item) => {
              return { ...item, applied: false };
            });

            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: rewards } });
            dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: basket } });
            resolve(true);
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            reject(false);
          }
        };

        fetch();
      }),
    [dispatchAppBasketState, state]
  );

  const addRewardToBasket = useCallback(
    (basketId, reference) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true, refetch: true } });
          try {
            const basketFromReward = await applyRewardToBasket(basketId, printedCardNumber, [reference]);
            const isValid = await validateBasket();

            if (isValid) {
              const basket = await getBasketInfo(basketId);
              const rewards = state.data.map((item) => {
                const foundAppliedReward = basket.appliedrewards.find(
                  (appliedReward) => appliedReward.reference === item.reference
                );

                if (foundAppliedReward) {
                  return { ...item, ...foundAppliedReward };
                }

                return { ...item, applied: false };
              });

              window.localStorage.removeItem('rewardId');
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: rewards } });
              dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: basket } });
              resolve(true);
            } else {
              if (basketFromReward && basketFromReward.appliedrewards && basketFromReward.appliedrewards.length) {
                removeRewardFromBasket(basketFromReward.id, basketFromReward.appliedrewards[0].rewardid).then(() => {
                  dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false, refetch: true } });
                  return resolve(false);
                });
              } else {
                dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false, refetch: true } });
                resolve(false);
              }
            }
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            reject(false);
          }
        };

        fetch();
      }),
    [dispatchAppBasketState, printedCardNumber, state, validateBasket, removeRewardFromBasket]
  );

  return [state, { addRewardToBasket, removeRewardFromBasket }];
};

export default useRetrieveBasketQualifyingRewardsFetch;
