import { useEffect } from 'react';
import { getProductsMenu } from 'api/productsMenu';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';

const useRestaurantMenuFetch = () => {
  const { restaurantMenu, restaurant } = useAppStateContext();
  const { data, loading, error } = restaurantMenu;
  const dispatchSetMenu = useAppDispatchContext();
  const restaurantId = restaurant && restaurant.data ? restaurant.data.id : '';
  const restaurantError = restaurant && restaurant.error ? restaurant.error : null;
  useEffect(() => {
    const fetch = async () => {
      if (restaurantId && data === null && !loading && !error && dispatchSetMenu) {
        dispatchSetMenu({ type: ACTION_TYPES.setLoadingRestaurantMenu, payload: { loading: true } });
        try {
          const response = await getProductsMenu(restaurantId);
          dispatchSetMenu({ type: ACTION_TYPES.setRestaurantMenu, payload: { data: response } });
        } catch (e) {
          dispatchSetMenu({ type: ACTION_TYPES.setErrorRestaurantMenu, payload: { error: e } });
        }
      }
      if (restaurantError) {
        dispatchSetMenu({ type: ACTION_TYPES.setErrorRestaurantMenu, payload: { error: restaurantError } });
      }
    };
    fetch();
  }, [restaurantId, data, loading, error, dispatchSetMenu, restaurantError]);
  return [restaurantMenu];
};

export default useRestaurantMenuFetch;
