import React, { useEffect, useState, memo } from 'react';
import styles from './Summary.module.scss';
import classNames from 'classnames';
import Price from 'components/Price/Price';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';

const Summary = ({ basket = null }) => {
  const {
    giftCard: { data: giftCardData },
  } = useAppStateContext();
  const appDispatch = useAppDispatchContext();
  const { subtotal, coupon, total, tip, salestax, tax, fees, customerhandoffcharge, discounts, deliverymode } = basket;
  let { balance: giftCardBalance, cardNumber: giftCardNumber, success: giftCardSuccess } = giftCardData || {};
  const [totalAmount, setTotalAmount] = useState(null);
  const [gitCardAmount, setGiftCardAmount] = useState(null);
  useEffect(() => {
    if (giftCardData) {
      if (giftCardData[0]) {
        let accum = 0;
        for (var index in giftCardData) {
          accum += giftCardData[index].balance;
        }
        giftCardNumber = giftCardData[0].cardNumber;
        giftCardSuccess = giftCardData[0].success;
        giftCardBalance = accum;
      } else {
        if (!giftCardData.cardNumber) {
          setGiftCardAmount(null);
          setTotalAmount(total);
        }
        giftCardNumber = giftCardData.cardNumber;
        giftCardSuccess = giftCardData.success;
        giftCardBalance = giftCardData.balance;
      }

      if (giftCardBalance > 0 && giftCardSuccess) {
        try {
          const result = Number((total - giftCardBalance).toFixed(2));
          const amountDifference = Number((giftCardBalance - total).toFixed(2));
          const giftCardAmount = amountDifference > 0 ? giftCardBalance - amountDifference : giftCardBalance;
          setGiftCardAmount(giftCardAmount);
          setTotalAmount(result < 0 ? 0 : result);
        } catch (error) {
          console.error('[Summary]:', error);
        }
      }
    }
  }, [giftCardData, total, totalAmount]);
  useEffect(() => {
    if (!giftCardBalance && gitCardAmount) {
      setGiftCardAmount(null);
      setTotalAmount(total);
    }
  }, [giftCardBalance, gitCardAmount, total]);

  useEffect(() => {
    appDispatch({
      payload: giftCardSuccess && totalAmount <= 0,
      type: ACTION_TYPES.setIsGiftCardOnlyPayment,
    });
  }, [appDispatch, giftCardSuccess, totalAmount]);

  if (!basket) return '';

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.cell}>Subtotal</div>
        <div className={classNames(styles.cell, styles.bold)}>
          <Price value={subtotal} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>Tax</div>
        <div className={classNames(styles.cell, styles.bold)}>
          <Price value={tax || salestax} />
        </div>
      </div>
      {!!tip && (
        <div className={styles.row}>
          <div className={styles.cell}>Tip</div>
          <div className={classNames(styles.cell, styles.bold)}>
            <Price value={tip} />
          </div>
        </div>
      )}
      {deliverymode !== 'curbside' && (
        <div className={styles.row}>
          <div className={styles.cell}>Delivery</div>
          <div className={classNames(styles.cell, styles.bold)}>
            {customerhandoffcharge ? <Price value={customerhandoffcharge} /> : 'FREE'}
          </div>
        </div>
      )}
      {fees.map(({ description, amount }, index) => (
        <div className={styles.row} key={index}>
          <div className={styles.cell}>{description}</div>
          <div className={classNames(styles.cell, styles.bold)}>
            <Price value={amount} />
          </div>
        </div>
      ))}
      {giftCardData && gitCardAmount && (
        <div className={styles.row}>
          <div className={styles.cell}>Gift Card</div>
          <div className={classNames(styles.cell, styles.bold)}>
            -<Price value={gitCardAmount} />
          </div>
        </div>
      )}
      {discounts &&
        discounts.length > 0 &&
        discounts
          .filter(({ amount }) => amount > 0)
          .map(({ description, amount }, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.cell}>
                {coupon && coupon.description === description ? 'Coupon Code' : description}
              </div>
              <div className={classNames(styles.cell, styles.bold)}>
                -<Price value={amount} />
              </div>
            </div>
          ))}

      <div className={classNames(styles.row, styles.total)}>
        <div className={styles.cell}>Total:</div>
        <div className={classNames(styles.cell, styles.bold)}>
          <Price value={typeof totalAmount === 'number' ? totalAmount : total} />
        </div>
      </div>
    </div>
  );
};
const MemoizedSummary = memo(Summary);
export default MemoizedSummary;
