import { useEffect, useCallback } from 'react';

const useOutsideClick = (ref, callback) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    },
    [ref, callback]
  );
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, handleClickOutside]);
};
export default useOutsideClick;
