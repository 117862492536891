import React, { memo } from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import './Radiobox.scss';

const RadioBox = memo(({ checked, handleChange, name, ...otherProps }) => (
  <Radio classes={{ root: 'radiobox_root' }} checked={checked} onChange={handleChange} name={name} {...otherProps} />
));

const RadioboxWithLabel = memo(
  ({ checked = false, handleChange, name = '', label = '', className = '', ...otherProps }) => (
    <FormControlLabel
      label={label}
      classes={{ root: 'form_radiobox_root' }}
      className={className}
      control={<RadioBox checked={checked} onChange={handleChange} name={name} {...otherProps} />}
    />
  )
);
RadioBox.displayName = 'RadioBox';
RadioboxWithLabel.displayName = 'RadioboxWithLabel';
export { RadioboxWithLabel, RadioBox };
