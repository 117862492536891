import React, { memo } from 'react';
import styles from './RestaurantInfo.module.scss';
import { useRestaurantInfo } from 'components/OrderingFrom/OrderingFrom';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import { TextButton } from 'components/Button/Button';
import ChangeLocationLink from 'components/ChangeLocationLink/ChangeLocationLink';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useLocation } from 'react-router';

const RestaurantInfo = ({ onEmptyClose, onClose }) => {
  const {
    dataLoaded,
    isDefinedAddress,
    loading,
    error,
    fullCityName,
    streetaddress,
    telephone,
    distance,
  } = useRestaurantInfo(['telephone', 'distance', 'streetaddress']);
  const { groupOrder } = useAppStateContext();
  const groupOrderExist = !(groupOrder && groupOrder.data === null && !groupOrder.error);
  const { pathname } = useLocation();
  const showChangeLocation = dataLoaded && !loading && !groupOrderExist;
  const errorMessage =
    (error &&
      (error.errorMessage ||
        'Something went wrong... Could not load restaurant information. Please try again later!')) ||
    '';
  const notSelectedRestaurant = dataLoaded && !isDefinedAddress && (pathname === '/' || pathname === '/account');
  return (
    <>
      {loading && <Spin spinning={loading} />}
      {errorMessage && <Alert message={errorMessage} />}
      {notSelectedRestaurant && (
        <div className={styles.empty_location}>
          <div>Ordering from:</div>
          <TextButton className={styles.empty_btn} onClick={onEmptyClose}>
            Please select a location to start an order.
          </TextButton>
        </div>
      )}
      {dataLoaded && isDefinedAddress && (
        <div className={styles.wrapper}>
          <div className={styles.description}>
            <div>{streetaddress}</div>
            <div>{fullCityName}</div>
            <div>{telephone}</div>
            {distance !== 0 && <div className={styles.primary}>{`${distance} miles away`}</div>}
          </div>
          {showChangeLocation && (
            <ChangeLocationLink label={'Change location'} className={styles.link} alreadyOnChangeLocation={onClose} />
          )}
        </div>
      )}
    </>
  );
};
const MemoizedRestaurantInfo = memo(RestaurantInfo);
export default MemoizedRestaurantInfo;
