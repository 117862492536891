import React from 'react';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';

import CloseIcon from '@material-ui/icons/Close';

import { PrimaryIconButton } from 'components/Button/Button';
import Link from 'components/Link/Link';

import './GlobalNotification.scss';

const parseNotification = (notification = '') => {
  const urlRegex = /(\s((https?:\/\/((www\.)?[a-zA-Z0-9]*\.)?)|(www\.[a-zA-Z0-9]*\.))[^\s.]+)/g;
  const link = notification.match(urlRegex) && notification.match(urlRegex)[0].trim();

  return {
    parsedNotification: notification.replace(urlRegex, ''),
    link: link && link.startsWith('www.') ? `https://${link}` : link,
  };
};

const GlobalNotification = ({ notification }) => {
  const dispatch = useAppDispatchContext();

  const handleCloseNotification = () => {
    dispatch({ type: ACTION_TYPES.setGlobalNotificationState, payload: { isShow: false } });
  };

  const { parsedNotification, link } = parseNotification(notification);

  return (
    <div className="notification_wrapper">
      <p className="notification">{parsedNotification}</p>
      {link && (
        <Link className="link" color={'primary'} isGothic underline target="_blank" href={link}>
          Learn&nbsp;More
        </Link>
      )}
      <PrimaryIconButton className="close_btn" onClick={handleCloseNotification} size="medium">
        <CloseIcon />
      </PrimaryIconButton>
    </div>
  );
};

export default GlobalNotification;
