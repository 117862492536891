import { useCallback, useReducer } from 'react';
import { fetchReducer } from 'reducers/fetchReducer';
import { removeUserBillingAccount } from 'api/user/billingAccounts/removeUserBillingAccount';
import { FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  error: null,
  loading: false,
};

const useRemoveUserBillingAccount = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const appDispatch = useAppDispatchContext();
  const {
    userBillingAccounts: { billingAccounts },
  } = useAppStateContext();

  const removeBillingAccount = useCallback(
    (billingAccount) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          if (billingAccount) {
            try {
              dispatch({
                payload: { loading: true },
                type: FETCH_ACTION_TYPES.loading,
              });

              await removeUserBillingAccount(billingAccount);
              const filteredAccounts = billingAccounts.filter((account) => account.id !== billingAccount);

              if (!filteredAccounts || !filteredAccounts.length) {
                appDispatch({
                  payload: { billingAccounts: filteredAccounts },
                  type: ACTION_TYPES.cleanUpUserBillingAccountsData,
                });

                appDispatch({
                  payload: false,
                  type: ACTION_TYPES.setIsUseSavedCard,
                });
              } else {
                appDispatch({
                  payload: { billingAccounts: filteredAccounts },
                  type: ACTION_TYPES.setUserBillingAccountsData,
                });
              }

              dispatch({
                payload: { data: filteredAccounts },
                type: FETCH_ACTION_TYPES.setData,
              });
              showToast('success', 'The card was deleted successfully');
              resolve();
            } catch (error) {
              dispatch({
                type: FETCH_ACTION_TYPES.setError,
                payload: { error },
              });
              showToast('error', error.errorMessage || 'Something went wrong... Could not delete card!');
              reject();
            }
          }
        };

        fetch();
      }),
    [appDispatch, dispatch, billingAccounts]
  );

  return [state, removeBillingAccount];
};

export default useRemoveUserBillingAccount;
