import React, { useEffect } from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useQueryUrlParamsDispatch } from 'hooks';

import { PrimaryButton, TextButton } from 'components/Button/Button';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import DrawerLayout from 'components/DrawerLayout/DrawerLayout';
import showToast from 'components/Toast/ShowToast';
import Section from 'components/Section/Section';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';

import FavoriteOrderProducts from './FavoriteOrderProducts/FavoriteOrderProducts';
import FavoriteOrderInfo from './FavoriteOrderInfo/FavoriteOrderInfo';
import useFaveBasketOperations from './hooks/useFaveBasketOperations';
import useFavoriteOrderFetch from './hooks/useFavoriteOrderFetch';

import styles from './FavoriteOrder.module.scss';
import useQueryUrlParams from '../../../hooks/useQueryUrlParams';

const FavoriteOrder = ({ open, onClose, setModifyInProgress, handleNameClick }) => {
  const { faveId } = useQueryUrlParams();
  const parsedFaveId = faveId ? JSON.parse(faveId) : null;
  const setQuery = useQueryUrlParamsDispatch();
  const {
    modifyFavoriteIsInProgress,
    basket: { loading: loadingBasket, data: basket, error: errorBasket },
  } = useAppStateContext();
  const [{ loading: loadingOrder, data: order, error: errorOrder }] = useFavoriteOrderFetch(open);
  const [
    { loading: loadingFaveUpdate, error: errorFaveUpdate },
    { createBasket, removeProduct, updateFave },
  ] = useFaveBasketOperations();

  const hasOrder = !loadingOrder && !errorOrder && order;
  const hasBasket = !loadingBasket && !errorBasket && basket;

  const handleSaveFavoriteClick = () => {
    updateFave(order.id, basket.id, order.name).then(() => {
      const SuccessMsg = () => <span>Your favorite has been saved</span>;

      showToast('success', <SuccessMsg />);
      handleOnClose(true);
    });
  };

  const handleUpdateNameClick = (name) => {
    updateFave(order.id, basket.id, name).then((newFaveId) => {
      setQuery({ faveId: newFaveId }, null, false, []);
      showToast('success', 'Favorite name was updated');
    });
  };

  const handleAddMoreItems = () => {
    onClose(true);
  };

  const handleRemoveProduct = (name, productId) => {
    if (basket.products.length === 1) {
      showToast('warning', 'At least one product should be in bag');
      return;
    }

    removeProduct(basket.id, productId).then(() => {
      const SuccessMsg = () => (
        <span>
          Item <u>{name}</u> was deleted
        </span>
      );

      showToast('success', <SuccessMsg />);
    });
  };

  const handleOnClose = (wasSaved = false) => {
    onClose(false, wasSaved);
    setModifyInProgress(false);
  };

  useEffect(() => {
    if (modifyFavoriteIsInProgress && createBasket && parsedFaveId) {
      createBasket(parsedFaveId);
    }
  }, [modifyFavoriteIsInProgress, createBasket, parsedFaveId]);

  const loadFavErrorMessage =
    (errorOrder &&
      (errorOrder.errorMessage ||
        'Something went wrong... Could not load favorite order details. Please try again later!')) ||
    '';

  const loadBasketErrorMessage =
    (errorBasket &&
      (errorBasket.errorMessage ||
        'Something went wrong... Could not load favorite order details. Please try again later!')) ||
    '';

  const updateErrorMessage =
    (errorFaveUpdate &&
      (errorFaveUpdate.errorMessage ||
        'Something went wrong... Could not update favorite order. Please try again later!')) ||
    '';

  return (
    <DrawerLayout
      open={open}
      onChange={handleOnClose}
      header={
        <>
          <PrimaryHeader title={'Modify Favorite'} size={'xl'} brush={'after'} />
          {(loadFavErrorMessage || loadBasketErrorMessage) && !loadingOrder && !loadingBasket && (
            <Alert message={loadFavErrorMessage || loadBasketErrorMessage} />
          )}
          {updateErrorMessage && !loadingFaveUpdate && <Alert message={updateErrorMessage} />}
          {(loadingOrder || loadingBasket) && <Spin spinning={loadingOrder || loadingBasket} />}
          {hasOrder && hasBasket && (
            <FavoriteOrderInfo
              data={order}
              loading={loadingFaveUpdate}
              error={errorFaveUpdate}
              handleUpdateNameClick={handleUpdateNameClick}
            />
          )}
        </>
      }
      disabledFooter={!hasOrder || !hasBasket}
      footerChildren={
        <div className={styles.footer_wrapper}>
          <PrimaryButton
            variant="outlined"
            onClick={handleSaveFavoriteClick}
            className={styles.save_btn}
            disabled={loadingFaveUpdate}
          >
            {loadingFaveUpdate && <Spin spinning={loadingFaveUpdate} className={styles.btn_spinner} />}
            SAVE FAVORITE
          </PrimaryButton>
          <TextButton onClick={handleAddMoreItems} className={styles.add_more_items_btn}>
            ADD MORE ITEMS FROM MENU
          </TextButton>
        </div>
      }
    >
      <Section className={styles.section} title={'SELECTED ITEMS'} sectionHr>
        {(loadFavErrorMessage || loadBasketErrorMessage) && !loadingOrder && !loadingBasket && (
          <Alert message={loadFavErrorMessage || loadBasketErrorMessage} />
        )}
        {(loadingOrder || loadingBasket) && <Spin spinning={loadingOrder || loadingBasket} />}
        {hasOrder && hasBasket && (
          <FavoriteOrderProducts
            handleNameClick={handleNameClick}
            data={basket}
            loading={loadingFaveUpdate}
            handleRemoveProduct={handleRemoveProduct}
          />
        )}
      </Section>
    </DrawerLayout>
  );
};

export default FavoriteOrder;
