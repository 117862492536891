import React, { useState, useEffect } from 'react';
import styles from './CommunicationPrefModal.module.scss';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import Spin from 'components/Spin/Spin';
import showToast from 'components/Toast/ShowToast';
import useSubmitCommunicationPref from './hooks/useSubmitCommunicationPref';
import { CheckboxWithLabel } from 'components/Checkbox/Checkbox';
import Alert from 'components/Alert/Alert';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import CheckIcon from '@material-ui/icons/Check';

const CommunicationPrefModal = ({ isOpen, onClose }) => {
  const { loading, error, updatePreferences } = useSubmitCommunicationPref();
  const {
    user: { data, loading: loadingUserInfo, error: errorUserInfo },
  } = useAppStateContext();

  const [preferences, setPreferences] = useState(null);
  useEffect(() => {
    if (data && !loadingUserInfo && !errorUserInfo && !preferences && isOpen) {
      setPreferences(data);
    }
  }, [data, loadingUserInfo, errorUserInfo, preferences, isOpen]);
  const handleOnClose = () => {
    setPreferences(null);
    onClose({ communicationPref: false });
  };
  const handleOnSubmit = () => {
    updatePreferences(preferences).then(
      (resolve) => {
        if (resolve) {
          showToast('success', 'Successfully updated communication preferences');
          handleOnClose();
        }
      },
      () => {
        showToast('error', 'Error updated communication preferences!');
        handleOnClose();
      }
    );
  };
  const onChangeCheckbox = (e) => {
    const checkboxName = e.target.name;
    const checked = e.target.checked;
    setPreferences({ ...preferences, [checkboxName]: checked });
  };
  const errorMessage =
    ((error || errorUserInfo) &&
      (error.errorMessage ||
        errorUserInfo.errorMessage ||
        'Something went wrong... Could not load User Communication Preferences. Please try again later!')) ||
    '';
  return (
    <ModalWindow
      maxWidth="sm"
      open={isOpen}
      headerSize="lg"
      className={'modal'}
      onChange={handleOnClose}
      headerTitle={
        <>
          Communication <br /> Preferences
        </>
      }
      footerChildren={
        <div className={styles.modal_footer}>
          <PrimaryButton
            endIcon={<CheckIcon />}
            className={styles.btn}
            disabled={loading || !!error}
            size="large"
            onClick={handleOnSubmit}
          >
            {loading && <Spin spinning={loading} className={styles.spinner} />}
            SUBMIT
          </PrimaryButton>
        </div>
      }
    >
      <div className={styles.modal_content}>
        {errorMessage && <Alert message={errorMessage} />}
        {!data && loadingUserInfo && (
          <div className={styles.loading_wrapper}>
            <Spin spinning={loading} />
          </div>
        )}
        {preferences && (
          <>
            <div className={styles.checkbox_wrapper}>
              <CheckboxWithLabel
                name="optIn"
                checked={preferences.optIn}
                label={
                  <span className={styles.checkbox_label}>
                    Please send me Emails about specials offers and promotions from 99 Restaurants.
                  </span>
                }
                handleChange={onChangeCheckbox}
              />
            </div>
            <div className={styles.checkbox_wrapper}>
              <CheckboxWithLabel
                name="textCampaignOptIn"
                checked={preferences.textCampaignOptIn}
                label={
                  <span className={styles.checkbox_label}>
                    Please send me SMS Text Messages about specials offers and promotions from 99 Restaurants.
                  </span>
                }
                handleChange={onChangeCheckbox}
              />
            </div>
          </>
        )}
      </div>
    </ModalWindow>
  );
};

export default CommunicationPrefModal;
