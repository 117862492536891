import React from 'react';
import Stepper from 'components/Stepper/Stepper';
import useOrderDeliveryStatusFetch from './hooks/useOrderDeliveryStatusFetch';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import styles from './DeliveryTracking.module.scss';

const STATUS = { Pending: 0, DeliveryInProgress: 1, Delivered: 2 };

const DeliveryTracking = () => {
  const { data, loading, error } = useOrderDeliveryStatusFetch();
  const currStep = data && data.status && STATUS[data.status];
  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not load order details. Please try again later!')) ||
    '';
  const STEPS = [
    {
      label: 'Order Received',
      description: 'We have your order in our system',
    },
    {
      label: 'Out for Delivery',
      description: `Your order will be delivered by ${
        data && data.deliveryservice ? data.deliveryservice : 'Postmates'
      }. For order issues,  please contact the store directly.`,
    },
    {
      label: 'Delivered!',
      description: 'Your food has arrived. Enjoy! Please call us with any questions or concerns.',
    },
  ];
  return (
    <div className={styles.wrapper_stepper}>
      {loading && !data && <Spin spinning={loading} />}
      {errorMessage && <Alert message={errorMessage} />}
      {data && <Stepper steps={STEPS} currStep={currStep} />}
    </div>
  );
};

export default DeliveryTracking;
