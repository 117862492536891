import axios from './axiosInstances/axiosInstance';

const URI = '/restaurants';

export const getNearByRestaurants = ({ lat, long, radius, searchQuery }) => {
  let params = {
    ...(lat && { lat }),
    ...(long && { long }),
    ...(radius && { radius }),
    ...(searchQuery && { searchQuery }),
  };
  return axios.get(URI, {
    params: params,
  });
};
