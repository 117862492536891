import React, { useCallback, useState } from 'react';
import { InputField, MultilineInputField } from 'components/Input/Input';
import 'components/Input/Input.scss';
import styles from './SendEmails.module.scss';
import Alert from 'components/Alert/Alert';

const SendEmails = ({
  notePlaceholder,
  loading,
  errorMessage,
  error,
  onChangeEmails,
  onChangeNote,
  allowNotes = true,
}) => {
  const [emails, setEmails] = useState('');
  const [note, setNote] = useState('');

  const handleEmailsChange = useCallback(
    (e) => {
      setEmails(e.target.value);
      onChangeEmails(e.target.value);
    },
    [onChangeEmails]
  );

  const handleNoteChange = useCallback(
    (e) => {
      setNote(e.target.value);

      if (onChangeNote) {
        onChangeNote(e.target.value);
      }
    },
    [onChangeNote]
  );

  return (
    <div className={styles.container}>
      {errorMessage && <Alert message={errorMessage} className={styles.error_container} />}
      <p className={styles.description}>Add multiple email addresses by separating with a comma</p>
      <InputField
        name={'emails'}
        value={emails}
        onChange={handleEmailsChange}
        placeholder={'Email address(s)'}
        InputLabelProps={{
          shrink: true,
        }}
        variant={'outlined'}
        className={styles.emails}
        disabled={loading}
        error={error}
        helperText={error && 'Please enter valid email'}
      />
      {allowNotes && (
        <MultilineInputField
          name={'note'}
          value={note}
          onChange={handleNoteChange}
          placeholder={notePlaceholder}
          disabled={loading}
          InputLabelProps={{
            shrink: true,
          }}
          variant={'outlined'}
          InputProps={{
            maxLength: 100,
          }}
          className={styles.note}
        />
      )}
    </div>
  );
};
export default React.memo(SendEmails);
