import RestaurantLocation from './modules/RestaurantLocation/RestaurantLocation';
import RestaurantMenu from './modules/RestaurantMenu/RestaurantMenu';
import UIComponents from './modules/UIComponents/UIComponents';
import Account from './modules/Account/Account';
import Checkout from './modules/Checkout/Checkout';
import OrderConfirmation from './modules/OrderConfirmation/OrderConfirmation';
import ChangePassword from './modules/ChangePassword/ChangePassword';
import SignIn from './modules/SignIn/SignIn';

import routes from './routes.json';
import CreateFaveOrder from './modules/CreateFaveOrder/CreateFaveOrder';
import SignUp from './modules/SignUp/SignUp';
import ForgotPassword from './modules/ForgotPassowrd/ForgotPassword';
import ConfirmRestaurant from './modules/ConfirmRestaurant/ConfirmRestaurant';

export default [
  {
    path: routes.locations,
    component: RestaurantLocation,
    exact: true,
    protected: false,
    withoutFooter: true,
  },
  {
    path: routes.autoDetectLocation,
    component: ConfirmRestaurant,
    exact: true,
    protected: false,
    withoutFooter: false,
  },
  {
    path: routes.menu,
    component: RestaurantMenu,
    exact: true,
    protected: false,
  },
  {
    path: routes.account,
    exact: true,
    component: Account,
    protected: true,
  },
  {
    path: routes.checkout,
    exact: true,
    component: Checkout,
    protected: false,
  },
  {
    path: routes.orderConfirm,
    exact: true,
    component: OrderConfirmation,
    protected: false,
  },
  {
    path: routes.createFaveOrder,
    exact: true,
    component: CreateFaveOrder,
    protected: false,
  },
  {
    path: routes.signIn,
    exact: true,
    component: SignIn,
    protected: false,
  },
  {
    path: routes.signUp,
    exact: true,
    component: SignUp,
    protected: false,
  },
  {
    path: routes.forgotPassword,
    exact: true,
    component: ForgotPassword,
    protected: false,
  },
  {
    path: routes.UIComponents,
    exact: true,
    component: UIComponents,
  },
  {
    path: routes.resetPassword,
    exact: true,
    component: ChangePassword,
  },
];
