import React from 'react';
import PulseButton from 'components/PulseButton/PulseButton';
import styles from './ContinueToBag.module.scss';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';
import useQueryUrlParams from '../../../hooks/useQueryUrlParams';
import GoToBag from 'assets/go_to_bag.svg';

const ContinueToBag = () => {
  const { groupOrderId, owner } = useQueryUrlParams();
  const {
    basket: { data },
    restaurant: { data: restaurantData },
    modifyFavoriteIsInProgress,
  } = useAppStateContext();
  const setQuery = useQueryUrlParamsDispatch();
  const products = data && data.products && data.products.length > 0 && restaurantData;
  const handleToBag = () => {
    if (modifyFavoriteIsInProgress) {
      setQuery({ favoritesPanel: true }, null, false, ['groupOrderId', 'owner', 'joinGroup', 'groupOrderModal']);
      return;
    }
    if (groupOrderId && !owner) {
      setQuery({ groupOrderPanel: true }, null, false, ['favoritesPanel', 'faveId']);
      return;
    }
    setQuery({
      openBasket: true,
    });
  };
  return products ? (
    <div className={styles.wrapper}>
      <PulseButton onClick={handleToBag}>
        <img src={GoToBag} alt={'go to bag'} />
      </PulseButton>
    </div>
  ) : (
    ''
  );
};

export default React.memo(ContinueToBag);
