import React, { memo } from 'react';
import styles from './AddRewardToOrderModal.module.scss';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const MessageReward = memo(({ reward }) => {
  return reward && reward.categories ? (
    <>
      your <span className={styles.reward_title}>{reward.label}</span>
    </>
  ) : (
    'appropriate items'
  );
});

MessageReward.displayName = 'MessagePart';

const AddRewardToOrderModal = ({ reward, isModalOpen, handleIsModalOpen, handleModalSubmit }) => (
  <ModalWindow
    contrastBody={false}
    maxWidth="sm"
    headerSize="lg"
    open={isModalOpen}
    headerTitle="ADD REWARD TO ORDER"
    onChange={() => handleIsModalOpen(false)}
  >
    <div className={styles.wrapper}>
      <div className={styles.modal_content}>
        <div className={styles.text}>
          Please visit the menu and add the <MessageReward reward={reward} /> to your bag. At checkout select the reward
          you wish to apply.
        </div>
        <PrimaryButton
          endIcon={<ArrowForwardIcon />}
          size="large"
          className={styles.submit_btn}
          onClick={handleModalSubmit}
        >
          GO TO MENU
        </PrimaryButton>
      </div>
    </div>
  </ModalWindow>
);

export default React.memo(AddRewardToOrderModal);
