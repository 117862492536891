import React, { useCallback, useEffect, useState } from 'react';
import { InputField } from 'components/Input/Input';
import { EMAIL_VALIDATION_PATTERN } from 'services/email';

const emailErrors = {
  empty: 'Please provide an email address',
  patternEmail: 'Please enter a valid email address',
};

const validationEmailPattern = (value) => EMAIL_VALIDATION_PATTERN.test(value);
const isNotEmptyEmail = (value) => !!value;

const EmailInput = React.forwardRef(
  ({ defaultValue, required = true, error = false, errorMsg = '', onChange, onError }, ref) => {
    const [errorEmail, setEmailError] = useState(error);
    const [errorEmailMessage, setErrorEmailMessage] = useState(emailErrors.empty);
    const [value, setValue] = useState(defaultValue);
    const [isTouched, setIsTouched] = useState(false);

    useEffect(() => {
      if (defaultValue && !value && !isTouched) {
        setValue(defaultValue);
      }
    }, [defaultValue, value, isTouched]);
    useEffect(() => {
      if (typeof error === 'boolean') {
        if (errorMsg) {
          setErrorEmailMessage(errorMsg);
        }
        setEmailError(error);
      }
    }, [error, errorMsg]);

    const onChangeEmail = (e) => {
      const value = e.target.value.trim();
      setIsTouched(true);
      setEmailError(false);
      setErrorEmailMessage('');
      setValue(value);
    };
    const onBlurEmail = useCallback(() => {
      const isValid = validationEmailPattern(value);
      const isNotEmpty = isNotEmptyEmail(value);
      if (isValid) {
        setEmailError(false);
        onError && onError(false);
        setValue(value);
        onChange && onChange(value);
        return;
      } else {
        if (!isNotEmpty) {
          setEmailError(true);
          setErrorEmailMessage(emailErrors.empty);
          onError && onError(true);
          return;
        }
        if (!isValid) {
          setEmailError(true);
          setErrorEmailMessage(emailErrors.patternEmail);
          onError && onError(true);
          return;
        }
      }
    }, [value, onChange, onError]);
    return (
      <InputField
        required={required}
        label={'Email'}
        inputRef={ref}
        error={errorEmail}
        value={value}
        helperText={errorEmail && errorEmailMessage}
        onChange={onChangeEmail}
        onBlur={onBlurEmail}
        autoComplete={'username'}
        inputProps={{
          minLength: '6',
          maxLength: '60',
        }}
      />
    );
  }
);

EmailInput.displayName = 'EmailInput';

export default React.memo(EmailInput);
