import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';

import { InputField } from 'components/Input/Input';

const PhoneNumberFormat = ({ inputRef, onChange, name, ...other }) => {
  const onValueChange = ({ value }) => {
    onChange({
      target: {
        name: name,
        value: value,
      },
    });
  };

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={onValueChange}
      mask="_"
      format="(###) ###-####"
      isNumericString
      {...other}
    />
  );
};

const pattern = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;

const phoneNumberConfig = {
  label: 'Phone Number',
  isValid: (str) => (str.match(pattern) ? true : false),
  errorText: 'Please enter a valid US phone number so that we can call you if there are any issues with your order',
  errorPhoneIsEmptyText: 'Please provide a phone number',
};

const PhoneInput = React.forwardRef((props, ref) => {
  const { value: defValue = '', error = false, required, onChange, errorMsg, disabled, onError, ...otherProps } = props;
  const [isError, setError] = useState(error);
  const [value, setValue] = useState(defValue);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    setError(error);
  }, [error]);

  useEffect(() => {
    if (isError) {
      if (value.trim().length === 0) {
        setErrorText(phoneNumberConfig.errorPhoneIsEmptyText);
      } else {
        setErrorText(phoneNumberConfig.errorText);
      }
    }
  }, [value, isError]);

  const parseStringPhone = (str) => {
    let temp = '';
    for (let letter of str) {
      let parsed = parseInt(letter);
      if (!isNaN(parsed)) {
        temp += letter;
      }
    }
    return temp;
  };

  const onChangeInput = (e) => {
    const value = e.target.value;
    setError(false);
    setErrorText('');
    const phoneStr = parseStringPhone(value);
    setValue(phoneStr);
    onChange && onChange(phoneStr);
  };

  const onBlurInput = (e) => {
    const value = e.target.value;
    if (phoneNumberConfig.isValid(value) && value.charAt(1) !== '0' && value.charAt(1) !== '1') {
      setError(false);
      const phoneStr = parseStringPhone(value);
      setValue(phoneStr);
      onChange && onChange(phoneStr);
      onError && onError(false);
    } else {
      if (value.trim().length < 1) {
        setErrorText(phoneNumberConfig.errorPhoneIsEmptyText);
      } else {
        setErrorText(phoneNumberConfig.errorText);
      }
      setError(true);
      setValue(value);
      onChange && onChange('');
      onError && onError(true);
    }
  };

  const helpText = isError ? (errorMsg ? errorMsg : errorText) : '';

  return (
    <InputField
      label={'Phone Number'}
      required={required}
      name="phoneNumber"
      maxLength="8"
      ref={ref}
      error={isError}
      defaultValue={defValue}
      helperText={helpText}
      disabled={disabled}
      onBlur={onBlurInput}
      onChange={onChangeInput}
      type={'tel'}
      InputProps={{
        inputComponent: PhoneNumberFormat,
      }}
      autocompelete={'tel-national'}
      {...otherProps}
    />
  );
});

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
