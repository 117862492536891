import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import GoogleMapApp from 'components/GoogleMap/GoogleMap';
import HandheldMarkerInfo from 'components/GoogleMap/HandheldMarkerInfo/HandheldMarkerInfo';
import styles from './PlacesMap.module.scss';
import SearchLocation, { INITIAL_DISTANCE, ZIP_CODE_PATTERN } from './SearchLocation/SearchLocation';
import { TextButton } from 'components/Button/Button';
import { useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import useNearByRestaurants from './hooks/useNearByRestaurants';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';
import RestaurantsList from './RestaurantsList/RestaurantsList';
import NotFoundRestaurants from './NotFoundRestaurants/NotFountRestaurants';
import { useHistory, matchPath } from 'react-router';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useQueryUrlParams } from 'hooks';
import routes from 'routes.json';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';
import { deserializeQuery } from 'utils';
import useTransferBasketId from './hooks/useTransferBasketId';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import HandoffModeModal from '../HandoffModeModal/HandoffModeModal';
import { useCallback } from 'react';
import { createBasket } from 'api/createBasket';

const RestaurantLocation = ({ coords, defaultLocationSelect }) => {
  const theme = useTheme();
  const history = useHistory();
  const locationsRef = useRef();
  const { returnUrl, prevRestaurant } = useQueryUrlParams();
  const pushHistoryPath = useQueryUrlParamsDispatch();
  const appDispatch = useAppDispatchContext();
  const latitude = coords ? coords.latitude : '';
  const longitude = coords ? coords.longitude : '';
  const isActiveGeoPosition = coords;
  const {
    data: transferBasket,
    loading: loadingTransferBasket,
    error: errorTransferBasket,
    setTransferBasketId,
  } = useTransferBasketId();
  const isHandheldSize = useMediaQuery(theme.breakpoints.down(1025)); // support tablet layout (theme.breakpoints.down('md') returns @media (max-width:1023.95px))
  const [tab, setTab] = useState('map');
  const [selectedPlace, setSelectedPlace] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [radius, setRadiusLocation] = useState(INITIAL_DISTANCE.value);
  const [isHandoffModeModalOpen, setIsHandoffModeModalOpen] = useState(false);
  const [allRestaurantsLocation, setAllRestaurantsLocation] = useState(true);
  const [cleanSelectedPlace, setCleanSelectedPlace] = useState(false);
  const [restaurant, setRestaurant] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [basket, setBasket] = useState(null);
  const [ignoreSearchInputBlur, setIgnoreSearchInputBlur] = useState(false);
  const [{ loading, data, error }, getRestaurants] = useNearByRestaurants();
  const restaurantsByState = useMemo(() => (data && data['restaurantsByState']) || [], [data]);
  const statesIsLoaded = !loading && !error && restaurantsByState.length > 0;
  const emptyStatesIsLoaded = !loading && !error && (restaurantsByState.length === 0 || !restaurantsByState);

  useEffect(() => {
    const searchValueValid = searchValue && searchValue.match(ZIP_CODE_PATTERN);
    getRestaurants(latitude, longitude, radius, searchValue, allRestaurantsLocation, searchValueValid);
  }, [latitude, longitude, radius, searchValue, allRestaurantsLocation, getRestaurants]);

  useEffect(() => {
    if (defaultLocationSelect && allRestaurantsLocation && !cleanSelectedPlace && !loading && !error && data) {
      const selectLocation = data.restaurants.find((restaurant) => restaurant.id.toString() === defaultLocationSelect);
      if (selectLocation && Object.keys(selectLocation).length) {
        setSelectedPlace({
          restaurant: selectLocation,
          stateKey: selectLocation.state,
          idPlace: selectLocation.id,
        });
      }
    }
  }, [defaultLocationSelect, loading, error, data, allRestaurantsLocation, cleanSelectedPlace]);

  const getReturnPath = useCallback(() => {
    let returnRoutePath;
    let parseQuery;
    if (returnUrl) {
      const parseURI = decodeURIComponent(returnUrl);
      const returnPath = parseURI.split('?')[0];
      const returnQuerySearch = parseURI.split('?')[1];
      parseQuery = returnQuerySearch ? deserializeQuery(`?${returnQuerySearch}`) : {};
      Object.keys(routes).forEach((key) => {
        if (matchPath(returnPath, { path: routes[key], exact: true })) {
          returnRoutePath = routes[key];
        }
      });
    }
    return { query: parseQuery, path: returnRoutePath };
  }, [returnUrl]);

  const closeHandoffModeModal = () => setIsHandoffModeModalOpen(false);

  const handleRedirect = useCallback(() => {
    const { query, path } = getReturnPath();
    const patternRestaurantID = ':restaurantId';
    const restaurantId = restaurant && restaurant.id;
    const changedPath = path && path.replace(patternRestaurantID, restaurantId);
    pushHistoryPath(
      {
        ...query,
        basketId: transferBasket.id,
      },
      changedPath,
      true
    );
  }, [getReturnPath, pushHistoryPath, restaurant, transferBasket]);

  useEffect(() => {
    if (transferBasket && restaurant && restaurant.id) {
      handleRedirect();
    }
  }, [loadingTransferBasket, errorTransferBasket, transferBasket, restaurant, handleRedirect]);

  const selectTab = useCallback((type) => () => setTab(type), []);
  const handlerDistance = useCallback((value) => {
    setRadiusLocation(value);
  }, []);
  const onSelectPlace = useCallback((place) => {
    setIgnoreSearchInputBlur(true);
    setSelectedPlace(place);
    setCleanSelectedPlace(false);
    setTimeout(() => document.activeElement.blur(), 0);
  }, []);
  const onCleanSelectPlace = useCallback((bool) => {
    setSelectedPlace({});
    setCleanSelectedPlace(bool);
  }, []);
  const onCleanLocationSettings = useCallback(() => {
    setSearchValue('');
    setAllRestaurantsLocation(true);
    onCleanSelectPlace(true);
  }, [onCleanSelectPlace]);
  const onSelectCurrPositionLocations = useCallback(() => {
    setSearchValue('');
    setAllRestaurantsLocation(false);
  }, []);
  const handlerSearchValue = useCallback(
    (value) => {
      !ignoreSearchInputBlur && onCleanSelectPlace(true);
      setSearchValue(value);
      setAllRestaurantsLocation(true);
    },
    [onCleanSelectPlace, ignoreSearchInputBlur]
  );

  const onHandoffModeSubmit = () => {
    history.push(`/${restaurant.id}/menu`);
  };

  const onScrollOffset = useCallback((offsetTop) => {
    locationsRef.current.scrollTo(0, offsetTop);
  }, []);

  const openHandoffModal = useCallback(
    async (restaurantId) => {
      setIsLoading(true);
      const basket = await createBasket(restaurantId);
      setBasket(basket);
      setIsLoading(false);
      setIsHandoffModeModalOpen(true);
    },
    [setBasket, setIsLoading, setIsHandoffModeModalOpen]
  );

  const onSelectOrder = useCallback(
    async (restaurant) => {
      setRestaurant(restaurant);
      appDispatch({ type: ACTION_TYPES.setRestaurant, payload: restaurant });
      appDispatch({ type: ACTION_TYPES.setGlobalNotificationState, payload: { isShow: true } });
      const { path, query } = getReturnPath();
      if (returnUrl) {
        const parseURI = decodeURIComponent(returnUrl);
        const returnQuerySearch = parseURI.split('?')[1];
        const { basketId } = deserializeQuery(`?${returnQuerySearch}`);
        if (basketId && prevRestaurant && prevRestaurant !== restaurant.id.toString()) {
          setTransferBasketId(basketId, restaurant);
        } else {
          const patternRestaurantID = ':restaurantId';
          const replacePath = path && path.replace(patternRestaurantID, restaurant.id);
          pushHistoryPath({ ...query }, replacePath, true);
        }
      } else {
        history.push(`/${restaurant.id}/menu`);
      }
    },
    [appDispatch, getReturnPath, pushHistoryPath, prevRestaurant, returnUrl, setTransferBasketId, openHandoffModal]
  );

  const onSearchInputFocus = useCallback(() => {
    setIgnoreSearchInputBlur(false);
  }, []);

  const errorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not load locations. Please try again later!')) ||
    '';

  const selectedCity = selectedPlace && selectedPlace.restaurant && selectedPlace.restaurant.city;
  return (
    <>
      {selectedCity && (
        <Helmet>
          <title>
            Bar Restaurant in {selectedCity}, {selectedPlace['stateKey']} | 99 Restaurants
          </title>
          <meta
            name="description"
            content={`Head to your local 99 Restaurant in ${selectedCity}, ${selectedPlace['stateKey']} for dine-in lunch, dinner or Happy Hour specials. Or order online for Curbside Pickup or Delivery.`}
          />
        </Helmet>
      )}
      {isHandoffModeModalOpen && basket && restaurant && (
        <HandoffModeModal
          restaurant={restaurant}
          basket={basket}
          onSubmit={onHandoffModeSubmit}
          isOpen={isHandoffModeModalOpen}
          onClose={closeHandoffModeModal}
        />
      )}
      <div className={classNames(styles.wrapper, { [styles.handheld_mode]: isHandheldSize })}>
        <Backdrop open={loadingTransferBasket || isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <div
          className={classNames(styles.left_panel, {
            [styles.handheld_mode]: isHandheldSize,
            [styles.active_tab]: isHandheldSize && tab === 'list',
          })}
        >
          <SearchLocation
            value={searchValue}
            onSearch={handlerSearchValue}
            onChangeDistance={handlerDistance}
            onResetLocations={onCleanLocationSettings}
            geoActive={!allRestaurantsLocation}
            onSelectCurrLocation={onSelectCurrPositionLocations}
            isActiveGeoPosition={isActiveGeoPosition}
            onFocus={onSearchInputFocus}
          />
          <div
            ref={locationsRef}
            className={classNames(styles.locations, {
              [styles.active_tab]: isHandheldSize && tab === 'list',
              [styles.handheld_mode]: isHandheldSize,
            })}
          >
            {error && <Alert message={errorMessage} />}
            {loading && (
              <div className={styles.restaurants_list_spinner_container}>
                <Spin spinning={loading} />
              </div>
            )}
            {statesIsLoaded && (
              <RestaurantsList
                scrollOffset={onScrollOffset}
                cleanSelected={cleanSelectedPlace}
                defaultSelectPlace={selectedPlace}
                onChange={onSelectPlace}
                onSelectOrder={onSelectOrder}
                states={restaurantsByState}
                isHandheldSize={isHandheldSize}
              />
            )}
            {emptyStatesIsLoaded && <NotFoundRestaurants onClick={onCleanLocationSettings} />}
          </div>
        </div>
        <div
          className={classNames(styles.right_panel, {
            [styles.handheld_mode]: isHandheldSize,
            [styles.active_tab]: isHandheldSize && tab === 'map',
          })}
        >
          <GoogleMapApp
            onCleanSelectPlace={onCleanSelectPlace}
            onSelect={onSelectPlace}
            selectedPlace={selectedPlace['idPlace'] && selectedPlace['idPlace'].toString()}
            places={data && data.restaurants}
            onSelectOrder={onSelectOrder}
            resetState={cleanSelectedPlace}
          />
          {emptyStatesIsLoaded && isHandheldSize && (
            <NotFoundRestaurants view={'modal'} onClick={onCleanLocationSettings} />
          )}
        </div>
        {isHandheldSize && (
          <div className={styles.handheld_footer}>
            {tab === 'map' && selectedPlace['idPlace'] && (
              <div className={styles.handheld_marker}>
                <HandheldMarkerInfo
                  onCleanSelectPlace={onCleanSelectPlace}
                  onSelectOrder={onSelectOrder}
                  restaurant={selectedPlace['restaurant'] && selectedPlace['restaurant']}
                />
              </div>
            )}
            <div className={styles.tabs}>
              <div className={styles.tab}>
                <TextButton
                  aria-label={'View List'}
                  color={tab === 'list' ? 'primary' : 'secondary'}
                  onClick={selectTab('list')}
                >
                  LIST
                </TextButton>
              </div>
              <div className={styles.tab}>
                <TextButton
                  aria-label={'View Map'}
                  color={tab === 'map' ? 'primary' : 'secondary'}
                  onClick={selectTab('map')}
                >
                  MAP
                </TextButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(RestaurantLocation);
