import React, { memo } from 'react';
import { FormControlLabel, Checkbox as CheckboxMUI } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import './Checkbox.scss';

const Checkbox = memo(({ classes, ...props }) => (
  <CheckboxMUI
    classes={{ root: 'checkbox_root', disabled: 'checkbox_disabled', ...classes }}
    icon={<span className={'checkbox_icon'}></span>}
    color="primary"
    checkedIcon={
      <span className={'checkbox_icon checkbox_icon_checked'}>
        <CheckIcon />
      </span>
    }
    {...props}
  />
));

const CheckboxWithLabel = memo(({ checked = false, handleChange, name = '', label = '', ...otherProps }) => (
  <FormControlLabel
    label={label}
    classes={{ root: 'form_checkbox_root' }}
    control={<Checkbox checked={checked} onChange={handleChange} name={name} {...otherProps} />}
  />
));

CheckboxWithLabel.displayName = 'CheckboxWithLabel';
Checkbox.displayName = 'Checkbox';

export { CheckboxWithLabel, Checkbox };
