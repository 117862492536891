import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { setBasketHandoffMethod } from 'api/setBasketHandoffMethod';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useSetDeliveryMode = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const appDispatch = useAppDispatchContext();

  const setDeliveryMode = useCallback(
    (basketId, deliveryMode) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          if (basketId && deliveryMode) {
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
            try {
              const basketData = await setBasketHandoffMethod(basketId, deliveryMode);
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: deliveryMode } });
              appDispatch({ type: ACTION_TYPES.setBasket, payload: { data: basketData } });
              resolve();
            } catch (e) {
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
              showToast('error', e.errorMessage || 'Something went wrong... Could not set order type!');
              reject();
            }
          } else {
            reject();
          }
        };
        fetch();
      }),
    [appDispatch]
  );

  return { ...state, setDeliveryMode };
};

export default useSetDeliveryMode;
