import React, { Fragment, useState } from 'react';
import styles from './RecentOrders.module.scss';
import { Divider } from '@material-ui/core';
import useRecentOrdersFetch from './hooks/useRecentOrdersFetch';
import OrderSummaryModal from './OrderSummaryModal/OrderSummaryModal';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import moment from 'moment';
import { TextButton } from 'components/Button/Button';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

const ORDER_TYPE_CURBSIDE = 'curbside';
const ORDER_TYPE_DISPATCH = 'dispatch';
const ORDER_TYPE_PXORDER = 'pxorder';
const ORDER_TYPE_PICKUP = 'pickup';

const ORDER_TYPE_LABELS = {
  [ORDER_TYPE_CURBSIDE]: 'curbside',
  [ORDER_TYPE_DISPATCH]: 'delivery',
  [ORDER_TYPE_PXORDER]: 'in restaurant',
  [ORDER_TYPE_PICKUP]: 'pickup',
};

const List = React.memo(({ data = [] }) => {
  const [state, setState] = useState({
    order: null,
    orderSummaryModal: false,
  });

  const handleState = (stateToChange) => {
    setState({ ...state, ...stateToChange });
  };

  const onClickOrderDetails = (order) => () => handleState({ orderSummaryModal: true, order });

  const list = data.map((order, index) => {
    const divider = index !== data.length - 1 && <Divider />;
    const formatedDate = moment(order.timeplaced, 'YYYYMMDD HH:mm').format('ddd, MMMM D h:mm A');

    return (
      <Fragment key={order.id}>
        <div className={styles.row}>
          <div className={styles.cell}>
            <div className={styles.order_name}>
              <div className={styles.label}>{ORDER_TYPE_LABELS[order.deliverymode]}</div>
              <div className={styles.name}>Order at {order.vendorname}</div>
            </div>
            <div className={styles.date}>{formatedDate}</div>
          </div>
          {order.deliverymode !== ORDER_TYPE_PXORDER && (
            <div className={styles.cell_actions}>
              <TextButton className={styles.btn} onClick={onClickOrderDetails(order)}>
                View details
              </TextButton>
            </div>
          )}
        </div>
        {divider}
      </Fragment>
    );
  });

  return (
    <>
      {list}
      <OrderSummaryModal state={state} handleState={handleState}></OrderSummaryModal>
    </>
  );
});

const RecentOrders = ({ toMenu }) => {
  const [{ loading, data, error }] = useRecentOrdersFetch();
  const {
    basket: { data: basketData },
  } = useAppStateContext();
  const errorMessage =
    (error &&
      (error.errorMessage || 'Something went wrong... Could not load order history. Please try again later!')) ||
    '';
  const dataIsLoaded = !loading && !error && data;
  return (
    <div className={styles.container}>
      {errorMessage && <Alert message={errorMessage} />}
      {loading && <Spin spinning={loading} />}
      {dataIsLoaded &&
        (data.length > 0 ? (
          <List data={data} toMenu={toMenu} />
        ) : (
          <div className={styles.no_data}>
            You have no recent orders,&nbsp;
            <TextButton onClick={toMenu} className={styles.place_order_link}>
              {basketData && basketData.products && basketData.products.length ? 'continue your order!' : 'Order Now!'}
            </TextButton>
          </div>
        ))}
    </div>
  );
};

export default React.memo(RecentOrders);
