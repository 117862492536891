import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { sendReferrals } from 'api/user/sendReferrals';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useReferrals = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const send = useCallback((emails) => {
    let ignore = false;
    const fetch = async () => {
      dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
      try {
        const response = await sendReferrals(emails);
        if (!ignore) {
          showToast('success', 'Successfully Sent!');
          dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
        }
      } catch (e) {
        if (!ignore) {
          const msg = e.errorMessage || 'Something went wrong... Could not send emails.';
          showToast('error', msg);
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
        }
      }
    };

    if (emails) {
      fetch();
    }

    return () => {
      ignore = true;
    };
  }, []);

  const resetState = () => dispatch({ type: FETCH_ACTION_TYPES.cleanState });

  return [state, { sendReferrals: send, resetState }];
};

export default useReferrals;
