import React, { useCallback } from 'react';

import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton, TextButton } from 'components/Button/Button';
import Spin from 'components/Spin/Spin';
import showToast from 'components/Toast/ShowToast';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';

import useDeleteAccount from './hooks/useDeleteAccount';
import styles from './DeleteAccountModal.module.scss';

const DeleteAccountModal = ({ onClose, isOpen, handleLogOutClick }) => {
  const [{ loading }, { deleteUserAccount }] = useDeleteAccount();

  const handleDeleteAccount = useCallback(() => {
    deleteUserAccount().then(() => {
      onClose({ deleteAccount: false });
      showToast('success', 'Account deleted successfully');
      handleLogOutClick(false);
    });
  }, [deleteUserAccount, onClose, handleLogOutClick]);

  const onChangeModal = useCallback(() => onClose({ deleteAccount: false }), [onClose]);
  return (
    <ModalWindow
      maxWidth="sm"
      headerSize="lg"
      open={isOpen}
      footerChildren={
        <div className={styles.footer}>
          <TextButton size="large" className={styles.submit_btn} disabled={loading} onClick={handleDeleteAccount}>
            <Spin spinning={loading} className={styles.spinner} />
            YES DELETE MY ACCOUNT
          </TextButton>
          <PrimaryButton
            endIcon={<ArrowRight />}
            size="large"
            disabled={loading}
            className={styles.text_btn}
            onClick={onChangeModal}
          >
            GO BACK
          </PrimaryButton>
        </div>
      }
      onChange={onChangeModal}
      headerTitle={'DELETE YOUR ACCOUNT'}
    >
      <div className={styles.modal_content}>
        <p>Are you sure you wish to cancel your 99 Rewards account? We will miss you.</p>
        <p className={styles.warning}>This action can not be undone.</p>
      </div>
    </ModalWindow>
  );
};

export default React.memo(DeleteAccountModal);
