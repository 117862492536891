import { useCallback, useReducer } from 'react';
import { logOutUser } from 'api/logout';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import showToast from 'components/Toast/ShowToast';
import auth from 'services/auth';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useLogOutUser = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const appDispatch = useAppDispatchContext();
  const fetchLogOutUser = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          if (dispatch) {
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
            try {
              const response = await logOutUser();
              dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false } });
              if (response && response.status === 'success') {
                auth.removeLogged();
                appDispatch({ type: ACTION_TYPES.cleanupUserDetails });
                appDispatch({ type: ACTION_TYPES.cleanupBasket });
                appDispatch({ type: ACTION_TYPES.cleanupFavoriteOrders });
                appDispatch({ type: ACTION_TYPES.cleanUpUserBillingAccountsData });
                appDispatch({ type: ACTION_TYPES.cleanUpFavoriteOloRestaurant });
                appDispatch({ type: ACTION_TYPES.cleanUpPaymentData });
                appDispatch({ type: ACTION_TYPES.cleanUpGiftCard });
                resolve(true);
              }
            } catch (e) {
              const msg = e.errorMessage || 'Error logout user';
              showToast('error', msg);
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
              reject(true);
            }
          }
        };
        fetch();
      }),
    [appDispatch, dispatch]
  );
  return { ...state, fetchLogOutUser };
};

export default useLogOutUser;
