import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { setBasketDispatchAddress } from 'api/setBasketDispatchAddress';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useDispatchAddressFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const appDispatch = useAppDispatchContext();
  const setDispatchAddress = useCallback(
    (basketId, address) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          if (basketId && address) {
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
            try {
              const basketData = await setBasketDispatchAddress(basketId, address);
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: basketData.deliveryaddress } });
              appDispatch({
                type: ACTION_TYPES.setBasket,
                payload: { data: basketData },
              });
              resolve(basketData.deliveryaddress);
            } catch (e) {
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
              showToast('error', e.errorMessage || 'Something went wrong... Could not set dispatch address!');
              if (e.num && e.num === 200) {
                showToast('success', 'Please select Curbside Pickup to complete your order');
              }
              reject(false);
            }
          }
        };
        fetch();
      }),
    [appDispatch]
  );

  return {
    ...state,
    setDispatchAddress,
  };
};

export default useDispatchAddressFetch;
