import React, { useCallback } from 'react';
import classNames from 'classnames/bind';

import CloseIcon from '@material-ui/icons/Close';
import MuiDrawer from '@material-ui/core/Drawer';

import { PrimaryIconButton } from 'components/Button/Button';

import './DrawerLayout.scss';

const DrawerLayout = ({
  open = false,
  onChange,
  children,
  footerChildren,
  disabledFooter = false,
  header = false,
  headerTitle = '',
  transparentFooter = false,
  headerDescription = '',
  ...otherProps
}) => {
  const onClose = useCallback(
    (bool) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      onChange && onChange(bool);
    },
    [onChange]
  );

  return (
    <MuiDrawer
      classes={{ paper: 'drawer_paper' }}
      anchor={'right'}
      open={open}
      onClose={onClose(false)}
      {...otherProps}
    >
      <div className={'drawer_container'}>
        <div className={'drawer_close_icon'}>
          <PrimaryIconButton onClick={onClose(false)}>
            <CloseIcon />
          </PrimaryIconButton>
        </div>
        {(header || headerTitle) && (
          <div className={'drawer_header'}>
            {header && header}
            {headerTitle && <h2>{headerTitle}</h2>}
            {headerDescription && <p>{headerDescription}</p>}
          </div>
        )}
        <div className={'drawer_body'}>{children}</div>
      </div>
      {!disabledFooter && (
        <div
          className={classNames('drawer_footer', {
            transparent_footer: transparentFooter,
          })}
        >
          {footerChildren}
        </div>
      )}
    </MuiDrawer>
  );
};

export default React.memo(DrawerLayout);
