import React, { memo } from 'react';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';

import styles from './AddRewardToOrderModal.module.scss';

const AddRewardToOrderModal = ({ reward, isModalOpen, handleIsModalOpen, handleModalSubmit }) => {
  const MessagePart = ({ reward }) =>
    reward && reward.categories ? <span className="reward_title">{reward.label}</span> : 'appropriate items';

  const onChangeModal = () => {
    handleIsModalOpen(false);
  };

  return (
    <ModalWindow
      contrastBody={false}
      maxWidth="sm"
      headerSize="lg"
      open={isModalOpen}
      headerTitle="ADD REWARD TO ORDER"
      onChange={onChangeModal}
    >
      <div className={styles.wrapper}>
        <div className={styles.modal_content}>
          <div className={styles.text}>
            Please visit the menu and add the <MessagePart reward={reward} /> to your bag. At checkout select the reward
            you wish to apply.
          </div>
          <PrimaryButton
            endIcon={<ArrowForwardIcon />}
            size="large"
            className={styles.submit_btn}
            onClick={handleModalSubmit}
          >
            GO TO MENU
          </PrimaryButton>
        </div>
      </div>
    </ModalWindow>
  );
};

const MemoizedAddRewardToOrderModal = memo(AddRewardToOrderModal);

export default MemoizedAddRewardToOrderModal;
