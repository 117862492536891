import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames/bind';
import { PrimaryIconButton, PrimaryButton } from 'components/Button/Button';
import CloseIcon from '@material-ui/icons/Close';
import styles from './Search.module.scss';

const Search = () => {
  const inputRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { menuSearchValue } = useAppStateContext();
  const dispatch = useAppDispatchContext();
  useEffect(() => {
    if (menuSearchValue !== searchValue) {
      setSearchValue(menuSearchValue);
      if (menuSearchValue === '') {
        setIsOpen(false);
      }
    }
  }, [menuSearchValue, searchValue]);

  const handleSearchChange = useCallback(
    (value) => {
      if (value !== menuSearchValue) {
        setSearchValue(value);
        dispatch({ type: ACTION_TYPES.setMenuSearch, payload: { value: value } });
      }
    },
    [menuSearchValue, dispatch]
  );
  const handleSearchIconClick = () => {
    if (isOpen) {
      handleSearchChange('');
    } else {
      inputRef.current.focus();
    }
    setIsOpen(!isOpen);
  };

  const onCloseSearch = () => {
    handleSearchChange('');
    setIsOpen(false);
  };

  const onChangeSearch = (e) => handleSearchChange(e.target.value);

  return (
    <div className={styles.search_container}>
      <PrimaryButton
        className={classNames(styles.search_btn, { [styles.show]: isOpen })}
        onClick={handleSearchIconClick}
        variant="outlined"
      >
        <SearchIcon variant="outlined" />
      </PrimaryButton>
      <div className={classNames(styles.search_input_wrapper, { [styles.show]: isOpen })}>
        <SearchIcon />
        <input
          type="text"
          placeholder="Search the menu"
          ref={inputRef}
          className={styles.search_input}
          value={searchValue}
          onChange={onChangeSearch}
        />
        <PrimaryIconButton onClick={onCloseSearch} size="small">
          <CloseIcon />
        </PrimaryIconButton>
      </div>
    </div>
  );
};

export default React.memo(Search);
