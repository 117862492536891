import axios from 'axios';
import showToast from '../../components/Toast/ShowToast';

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
instance.defaults.headers.get['Content-Type'] = 'application/json';

export const setupInterceptors = ({ onUnauthorized }) => {
  instance.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      if (!error.response || (error.response && error.response.status && error.response.status === 522)) {
        showToast('error', 'An unexpected error has occurred!');
        if (onUnauthorized) {
          onUnauthorized();
        }
      }
      if (error.response && error.response.status && error.response.status === 401) {
        if (onUnauthorized) {
          onUnauthorized();
        }
      }
      return Promise.reject(error.response ? { ...error.response.data, error_status: error.response.status } : error);
    }
  );
};

export default instance;
