import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import React from 'react';
import styles from './SignUpSection.module.scss';

import { useAppStateContext } from 'stateProviders/useAppStateContext';
import CreateAccountSection from '../CreateAccountSection/CreateAccountSection';

const SignUpSection = () => {
  const { authenticated } = useAppStateContext();
  if (authenticated) return;
  return (
    <div className={styles.wrapper}>
      <div className={styles.header_wrapper}>
        <PrimaryHeader
          brush={'after'}
          className={styles.header}
          titleClassName={styles.title}
          title={<span>Sign up for 99 REWARDS</span>}
        />
      </div>
      <p>
        You’ll receive faster checkout, ability to save your favorite orders and locations, access to Group Orders, a
        special reward on your next visit just for signing up, a special birthday treat and lots of other surprises like
        exclusive deals and promotions for both in restaurant dining and online ordering.
      </p>
      <CreateAccountSection />
    </div>
  );
};
export default React.memo(SignUpSection);
