import { getGiftCardBalance as getGiftCardBalanceFetch } from 'api/getGiftCardBalance';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import showToast from 'components/Toast/ShowToast';
import { useCallback, useState } from 'react';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { validateBasketService } from 'services/validateBasket';
import { withReCaptchaRequest } from 'services/reCaptcha';

const useRetrieveGiftCardBalance = () => {
  const { basketId } = useQueryUrlParams();
  const appDispatch = useAppDispatchContext();
  const { giftCard } = useAppStateContext();

  const {
    giftCard: { data: giftCardData },
  } = useAppStateContext();
  let arrayGifts = [];
  const [captchaToken, setCaptchaToken] = useState(true);
  const getGiftCardBalance = useCallback(
    (billingSchemeId, giftCardNumber, pin, accountGifts, previosGifts) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          if (basketId) {
            appDispatch({ type: ACTION_TYPES.loadingGiftCard, payload: { loading: true } });
            try {
              const body = {
                cardnumber: giftCardNumber.trim(),
              };

              if (pin) {
                body.pin = pin;
              }

              const response = await withReCaptchaRequest(
                validateBasketService,
                [getGiftCardBalanceFetch, basketId, billingSchemeId, body],
                setCaptchaToken
              );
              if (response) {
                const { message, success, balance } = response;
                if (!success) {
                  const errorMsg = message || 'The gift card cannot be applied';
                  showToast('error', errorMsg);
                  if (accountGifts == 0) {
                    appDispatch({
                      type: ACTION_TYPES.setGiftCard,
                      payload: { data: null },
                    });
                  }
                  resolve(false);
                  appDispatch({ type: ACTION_TYPES.loadingGiftCard, payload: { loading: false } });
                } else if (balance == 0) {
                  const errorMsg = message || 'Gift card is empty, please try another gift card';
                  showToast('error', errorMsg);
                  if (accountGifts == 0) {
                    appDispatch({
                      type: ACTION_TYPES.setGiftCard,
                      payload: { data: null },
                    });
                  }
                  resolve(false);
                  appDispatch({ type: ACTION_TYPES.loadingGiftCard, payload: { loading: false } });
                } else {
                  showToast('success', 'Gift card was successfully applied to basket');
                  const data = {
                    ...response,
                    cardNumber: giftCardNumber,
                    billingSchemeId: billingSchemeId,
                    pin,
                  };
                  if (previosGifts) {
                    if (previosGifts[0]) {
                      arrayGifts = [];
                      for (var e in previosGifts) {
                        arrayGifts.push(previosGifts[e]);
                      }
                    } else {
                      arrayGifts = [];
                      if (previosGifts.success) {
                        arrayGifts.push(previosGifts);
                      }
                    }
                  }
                  data.itemBalance = data.balance;
                  data.balance = data.balance;
                  data.response = true;
                  if (arrayGifts) {
                    arrayGifts.push(data);
                  }
                  appDispatch({
                    type: ACTION_TYPES.setGiftCard,
                    payload: { data: arrayGifts },
                  });
                  resolve(data);
                  appDispatch({ type: ACTION_TYPES.loadingGiftCard, payload: { loading: false } });
                }
              }
            } catch (e) {
              const errorMessage =
                e.errorMessage || 'Something went wrong... Could not apply gift card to your basket!';
              showToast('error', errorMessage);
              appDispatch({ type: ACTION_TYPES.errorGiftCard, payload: { error: e } });
            }
          }
        };
        fetch();
      }),
    [basketId, appDispatch]
  );
  return { ...giftCard, getGiftCardBalance, captchaToken };
};

export default useRetrieveGiftCardBalance;
