import React from 'react';
import Image from '../Image/Image';

const ProductImage = ({ imagepath, images, alt, className }) => {
  let mainImage =
    images && images !== null
      ? images.find((image) => {
          return image.groupname === 'desktop-customize';
        }) || images[0]
      : null;
  const imageUrl = mainImage ? imagepath + mainImage.filename : '';
  return <Image url={imageUrl} alt={alt} className={className} />;
};

export default React.memo(ProductImage);
