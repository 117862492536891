import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Section from 'components/Section/Section';
import { TextButton } from 'components/Button/Button';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

import MenuItemCard from '../MenuItemCard/MenuItemCard';
import styles from './RestaurantCategoryDetailsView.module.scss';

function RestaurantCategoryDetailsView({ imagepath, category, onMenuView, onProductClick }) {
  const theme = useTheme();
  const isHandheldSize = useMediaQuery(theme.breakpoints.up(576));

  return (
    <div className={styles.container}>
      <TextButton className={styles.back_link} onClick={onMenuView}>
        <ChevronLeftIcon />
        Back to Menu
      </TextButton>
      <Section
        titleElem={category ? <PrimaryHeader title={category.name} size={'lg'} brush={'after'} /> : ''}
        description={category && category.description && <p className={styles.description}>{category.description}</p>}
        displayHeaderType={'column'}
      >
        <ul className={styles.products}>
          {category &&
            category.products.map((product, productIndex) => {
              return (
                <li key={productIndex} className={styles.product}>
                  <MenuItemCard
                    imagepath={imagepath}
                    {...product}
                    isHorizontalView={isHandheldSize}
                    onClick={onProductClick}
                    product={product}
                  />
                </li>
              );
            })}
        </ul>
      </Section>
    </div>
  );
}

export default React.memo(RestaurantCategoryDetailsView);
