import React, { memo } from 'react';
import styles from './SelectedPaymentCard.module.scss';
import { TextButton } from 'components/Button/Button';

const SelectedPaymentCard = ({ children, onClick, footerBtnLabel = 'Use a Different Card' }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>{children}</div>
      <div className={styles.footer}>
        <TextButton classes={{ label: styles.button_label }} onClick={onClick}>
          {footerBtnLabel}
        </TextButton>
      </div>
    </div>
  );
};

const MemoizedSelectedPaymentCard = memo(SelectedPaymentCard);
export default MemoizedSelectedPaymentCard;
