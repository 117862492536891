import { useEffect, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { getUser } from 'api/login';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useUserInfoFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  useEffect(() => {
    const fetch = async () => {
      dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
      try {
        const response = await getUser();
        if (response) {
          dispatch({
            type: FETCH_ACTION_TYPES.setData,
            payload: { data: response },
          });
        }
      } catch (e) {
        dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
      }
    };
    fetch();
  }, []);
  return state;
};

export default useUserInfoFetch;
