import React from 'react';
import classNames from 'classnames/bind';
import styles from './PasswordImprovementNotification.module.scss';
import { TextButton } from '../Button/Button';

const PasswordImprovementNotification = ({ className, onReset }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <h3>Welcome to Online Ordering!</h3>
      <p>Enjoy faster checkout, earn and redeem rewards, and so much more.</p>
    </div>
  );
};
export default React.memo(PasswordImprovementNotification);
