import React from 'react';
import classNames from 'classnames/bind';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import './Input.scss';

const SearchInput = React.memo(({ InputProps, variant = 'filled', InputAdornmentComponent, ...props }) => {
  const underline = variant === 'filled' ? { disableUnderline: true } : {};

  return (
    <TextField
      color="secondary"
      variant={variant}
      classes={{
        root: 'form_control_search',
      }}
      InputProps={{
        classes: {
          root: 'input_root input_search',
          disabled: 'input_root_disabled',
          error: 'input_error',
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: <InputAdornment position="end">{InputAdornmentComponent}</InputAdornment>,
        ...underline,
        ...InputProps,
      }}
      {...props}
    />
  );
});

const MultilineInputField = ({ InputProps, variant = 'filled', label, ...props }) => {
  const underline = variant === 'filled' ? { disableUnderline: true } : {};
  return (
    <TextField
      color="secondary"
      label={label}
      multiline
      id="standard-multiline-flexible"
      rowsMax={4}
      rows={4}
      variant={variant}
      inputProps={{
        classes: {
          root: classNames('input_root', { outlined: variant === 'outlined' }),
          disabled: 'input_root_disabled',
          error: 'input_error',
        },
        ...underline,
        ...InputProps,
      }}
      {...props}
    />
  );
};

const InputField = React.memo(
  React.forwardRef(({ InputProps, label, variant = 'filled', ...props }, ref) => {
    const inputProps = {
      classes: {
        root: classNames('input_root', { outlined: variant === 'outlined', readOnly: props.readOnly }),
        disabled: 'input_root_disabled',
        error: 'input_error',
      },
      ...InputProps,
    };
    if (variant === 'filled') {
      inputProps.disableUnderline = true;
    }
    return (
      <TextField color="secondary" label={label} variant={variant} InputProps={inputProps} inputRef={ref} {...props} />
    );
  })
);

InputField.displayName = 'InputField';

export { InputField, SearchInput, MultilineInputField };
