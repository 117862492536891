import React from 'react';
import classNames from 'classnames/bind';
import './Section.scss';

/*
  displayHeaderType - 'null' | 'row' | 'column'
  titleSize - 'md' | 'lg'
 */

const Section = React.forwardRef((props, ref) => {
  const {
    title,
    titleElem = '',
    titleSize = 'md',
    description = '',
    children,
    displayHeaderType = 'row',
    uppercase = true,
    className,
    classNameBody,
    sectionHr = false,
    headerHr = false,
    topped = false,
  } = props;

  return (
    <div className={classNames('section_wrapper', className, { topped })} ref={ref}>
      <div
        className={classNames('section_header', {
          row_direction: displayHeaderType === 'row',
          with_header_hr: headerHr,
          with_description: description,
        })}
      >
        {titleElem ? (
          <div
            className={classNames('section_title', {
              with_description: description,
            })}
          >
            {titleElem}
          </div>
        ) : (
          <div className="section_title_wrapper">
            <h2
              className={classNames('section_title', {
                uppercase_text: uppercase,
                [titleSize]: titleSize,
              })}
            >
              {typeof title === 'string' ? title.toUpperCase() : title}
            </h2>
          </div>
        )}
        {headerHr && <div className="section_header_hr" />}
        {description && <div className="section_description">{description}</div>}
      </div>
      {sectionHr && <div className="section_hr" />}
      <div className={classNames('section_body', classNameBody)}>{children}</div>
    </div>
  );
});
Section.displayName = 'Section';
export default React.memo(Section);
