import React from 'react';

import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

import { ChangePasswordForm } from 'components/Forms';
import styles from './ChangePassword.module.scss';

const ChangePassword = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.form_header}>
          <PrimaryHeader title={'Change Password'} size={'extra'} />
        </div>
        <ChangePasswordForm />
      </div>
    </div>
  );
};

export default React.memo(ChangePassword);
