import React, { useCallback, memo } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes.json';

import { TextButton } from 'components/Button/Button';
import Link from 'components/Link/Link';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

import { SignUpForm } from '../../components/Forms';
import styles from './SignUp.module.scss';

const SignUp = () => {
  const history = useHistory();

  const handleSubmit = useCallback(() => {
    history.push(routes.account);
  }, [history]);

  const handleSignIn = useCallback(() => {
    history.push(routes.signIn);
  }, [history]);

  const handleForgotPassword = useCallback(() => {
    history.push(routes.forgotPassword);
  }, [history]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.form_header}>
          <PrimaryHeader title={'Sign Up'} size={'extra'} />
        </div>
        <div className={styles.form_body}>
          <SignUpForm close={handleSubmit} onSignIn={handleSignIn} onForgotPassword={handleForgotPassword} />
        </div>
        <div className={styles.form_footer}>
          <span>HAVE AN ACCOUNT?</span>
          <Link routing isGothic onClick={handleSignIn}>
            <TextButton>Sign in</TextButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(SignUp);
