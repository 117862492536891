import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { PrimaryButton } from 'components/Button/Button';
import { InputField } from 'components/Input/Input';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';

import useAddUserInformation from './hooks/useAddUserInformation';
import './SignInForm.scss';

const errorMessages = {
  emailIsInvalid: 'Please enter a valid e-mail address',
  fieldIsEmpty: 'Email and password cannot be empty',
  fieldIsError: 'Invalid email or password. Please try again',
};

const SignInForm = ({ close }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [currUser, setCurrUser] = useState(null);
  const [visibility, setVisibilityPassword] = useState(true);
  const [validateEmail, setValidateEmail] = useState(true);
  const [validatePassword, setValidatePassword] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [{ loading, data, error }, { userInformation }] = useAddUserInformation();

  useEffect(() => {
    if (!loading && data && !currUser) {
      setCurrUser(data);
      close();
    }

    if (error && error.errorMessage && error.error_status !== 429) {
      setEmailErrorMessage(errorMessages.fieldIsError);
      setPasswordErrorMessage(errorMessages.fieldIsError);
      setValidatePassword(false);
      setValidateEmail(false);
    }
  }, [loading, data, currUser, close, error]);

  const signInHandler = (event) => {
    event.preventDefault();

    if (!email && !password) {
      setEmailErrorMessage(errorMessages.fieldIsEmpty);
      setPasswordErrorMessage(errorMessages.fieldIsEmpty);
      setValidateEmail(false);
      setValidatePassword(false);
      return;
    }

    if (!email) {
      setEmailErrorMessage(errorMessages.fieldIsEmpty);
      setValidateEmail(false);
      return;
    }

    if (!password) {
      setPasswordErrorMessage(errorMessages.fieldIsEmpty);
      setValidatePassword(false);
      return;
    }

    if (validateEmail && validatePassword) {
      userInformation(email, password);
    }
  };

  const onChangeEmail = (e) => {
    const value = e.target.value.trim();
    setValidateEmail(true);
    setEmailErrorMessage('');
    setEmail(value);
    if (password) {
      setPasswordErrorMessage('');
      setValidatePassword(true);
    }
  };

  const onChangePassword = (e) => {
    const value = e.target.value.trim();
    setValidatePassword(true);
    setPasswordErrorMessage('');
    setPassword(value);
    if (email) {
      setValidateEmail(true);
      setEmailErrorMessage('');
    }
  };

  const onBlurEmail = (e) => {
    const value = e.target.value.trim();
    setEmail(value);
  };

  const onBlurPassword = (e) => {
    const value = e.target.value.trim();
    setPassword(value);
  };

  const showPassword = () => {
    setVisibilityPassword(!visibility);
  };

  const errorMessage =
    error && !validateEmail && !validatePassword
      ? error.errorMessage || 'Something went wrong... Could not sign in. Please try again later!'
      : '';

  return (
    <div className={'signin_content'}>
      <form className={'content_form'} onSubmit={signInHandler}>
        {errorMessage && <Alert message={errorMessage} className={'form_error_container'} />}
        <div className={'form_input-email input_wrapper'}>
          <InputField
            required
            label={'Email'}
            error={!validateEmail}
            helperText={emailErrorMessage}
            onChange={onChangeEmail}
            onBlur={onBlurEmail}
            inputProps={{
              minLength: '6',
              maxLength: '60',
              autoComplete: 'username',
            }}
          />
        </div>

        <div className={'form_input-password input_wrapper'}>
          <InputField
            required
            label={'Password'}
            error={!validatePassword}
            helperText={passwordErrorMessage}
            type={visibility ? 'password' : 'text'}
            onChange={onChangePassword}
            onBlur={onBlurPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showPassword}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {!visibility ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              autoComplete: 'current-password',
            }}
          />
        </div>
        <div className={classNames(['captcha', 'g-recaptcha'])} id="v2-captcha" data-badge="inline" />
        <div className={'form_button-signin input_wrapper'}>
          <PrimaryButton onClick={signInHandler} disabled={loading} type="submit">
            SIGN IN
            <Spin spinning={loading} className={'button_spinner'} />
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default SignInForm;
