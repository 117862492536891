import { useCallback } from 'react';
import { validateBasket } from 'api/validateBasket';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import showToast from 'components/Toast/ShowToast';

const useValidateBasket = () => {
  const {
    basket: { data: basketData },
  } = useAppStateContext();
  const appDispatch = useAppDispatchContext();

  return useCallback(() => {
    const fetch = async () => {
      try {
        const validateData = await validateBasket(basketData.id);
        const data = { ...basketData, ...validateData };
        appDispatch({
          type: ACTION_TYPES.setBasket,
          payload: { data },
        });

        return true;
      } catch (error) {
        showToast('error', error.errorMessage || 'Validate fetch error!');
        appDispatch({
          type: ACTION_TYPES.errorBasket,
          payload: error,
        });

        return false;
      }
    };

    return fetch();
  }, [appDispatch, basketData]);
};

export default useValidateBasket;
