import React from 'react';
import { TextButton, PrimaryButton } from 'components/Button/Button';
import Alert from 'components/Alert/Alert';
import styles from './OrderRestaurant.module.scss';
import PrimaryHeader from '../../components/PrimaryHeader/PrimaryHeader';
import { useMediaQuery, useTheme } from '@material-ui/core';
import OrderPlaceCard from '../../components/OrderPlaceCard/OrderPlaceCard';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const OrderRestaurant = ({ error = false, onStartOrder, onSelectDiffLocation, restaurant = {} }) => {
  const theme = useTheme();
  const isTabletSize = useMediaQuery(theme.breakpoints.down('md'));
  const isPhoneSize = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    state = '',
    city = '',
    zip = '',
    streetaddress = '',
    distance = 0,
    telephone = '',
    isavailable = true,
    latitude,
    longitude,
    calendars,
    supportsdispatch,
    supportscurbside,
  } = restaurant;
  const errorMessage =
    (error &&
      (error.errorMessage ||
        'Something went wrong... Could not load near restaurant location. Please try again later!')) ||
    '';
  return (
    <>
      <div className={styles.header}>
        <PrimaryHeader
          titleClassName={'extra-line-height'}
          size={isTabletSize ? (isPhoneSize ? 'md' : 'xl') : 'extra'}
          title="Confirm your location"
        />
      </div>
      <div className={styles.body}>
        {errorMessage ? (
          <div className={styles.error}>
            <Alert message={errorMessage} />
          </div>
        ) : (
          <OrderPlaceCard
            lat={latitude}
            long={longitude}
            phone={telephone}
            title={`${city}, ${state}`}
            calendars={calendars}
            supportsdispatch={supportsdispatch}
            supportscurbside={supportscurbside}
            viewMode={true}
            description={[
              { label: streetaddress },
              { label: `${city}, ${state} ${zip}` },
              { ...(distance !== 0 && { label: `${distance} miles away` }) },
            ]}
          />
        )}
      </div>
      <div className={styles.footer}>
        <TextButton className={styles.link} onClick={onSelectDiffLocation}>
          CHANGE LOCATION
        </TextButton>

        <PrimaryButton
          className={styles.button}
          onClick={error ? onSelectDiffLocation : onStartOrder}
          disabled={!isavailable}
          size="large"
        >
          {isavailable ? (
            <>
              START YOUR ORDER <ChevronRightIcon className={styles.link} />{' '}
            </>
          ) : (
            'NOT ACCEPTING ONLINE ORDERS'
          )}
        </PrimaryButton>
      </div>
    </>
  );
};
export default OrderRestaurant;
