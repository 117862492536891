import React, { memo, useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { PrimaryIconButton } from 'components/Button/Button';
import classNames from 'classnames';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import './Modal.scss';

const DialogTitle = memo(({ headerTitle, headerActions, size, children }) => {
  const HeaderContent = children ? (
    children
  ) : headerTitle ? (
    <div className={'modal_header_title'}>
      {headerActions && <div className={'modal_header_actions'}>{headerActions}</div>}
      <PrimaryHeader title={headerTitle} size={size} />
    </div>
  ) : (
    ''
  );
  return HeaderContent ? (
    <MuiDialogTitle classes={{ root: 'dialog_header_root' }} disableTypography>
      <div className={'modal_header'}>{HeaderContent}</div>
    </MuiDialogTitle>
  ) : null;
});

DialogTitle.displayName = 'DialogTitle';

const ModalWindow = ({
  open = false,
  modalActions,
  headerChildren,
  headerTitle,
  headerActions,
  footerChildren,
  children,
  onChange,
  onScroll,
  className = '',
  contentRef = null,
  fullWidth = true,
  maxWidth = 'lg',
  contrast = true,
  secondaryContrast = false,
  headerSize = 'xl',
  fullScreen = false,
}) => {
  const handleClose = useCallback(() => {
    onChange && onChange(false);
  }, [onChange]);
  const shouldShowModalHeader = headerTitle || headerChildren;
  return (
    <Dialog
      classes={{ container: classNames('modal', className), paper: !fullScreen && 'modal_container' }}
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
    >
      {modalActions ? (
        <>{modalActions}</>
      ) : (
        <div className={'modal_icon_close'}>
          {onChange && (
            <PrimaryIconButton onClick={handleClose} variant="outlined" className={'modal_icon_button_close'}>
              <CloseIcon />
            </PrimaryIconButton>
          )}
        </div>
      )}
      <div className={'modal_scroll_wrapper'} ref={contentRef} onScroll={onScroll}>
        {shouldShowModalHeader && (
          <DialogTitle size={headerSize} headerActions={headerActions} headerTitle={headerTitle} onClose={handleClose}>
            {headerChildren}
          </DialogTitle>
        )}
        {children ? (
          <DialogContent classes={{ root: classNames('modal_body', { no_contrast: contrast }) }}>
            {children}
          </DialogContent>
        ) : (
          ''
        )}
      </div>
      {footerChildren && (
        <DialogActions
          classes={{
            root: classNames('modal_footer', { no_contrast: contrast, secondary_contrast: secondaryContrast }),
          }}
        >
          {footerChildren}
        </DialogActions>
      )}
    </Dialog>
  );
};
export default React.memo(ModalWindow);
