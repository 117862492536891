import axios from './axiosInstances/axiosInstance';

export const authUser = (username, password, basketId, config) => {
  const data = {
    username,
    password,
    ...(basketId && { basketid: basketId }),
  };
  return axios.post('/users/signIn', data, config);
};

export const getUser = () => {
  return axios.get(`/users/userInfo`);
};

export const authUserByMobile = (codeParam) => {
  return axios.get(`/users/signIn?code=${codeParam}`);
};
