import React, { useCallback, useState } from 'react';
import { SearchInput } from '../../../components/Input/Input';
import { TextButton } from '../../../components/Button/Button';
import Select from '../../../components/Select/Select';
import styles from './SearchLocation.module.scss';
import { useEffect } from 'react';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import Tooltip from '@material-ui/core/Tooltip';

export const ZIP_CODE_PATTERN = /^[0-9]{5}(?:-[0-9]{4})?$/;

const DISTANCE = [
  {
    label: '10 mi.',
    value: '10',
  },
  {
    label: '20 mi.',
    value: '20',
  },
  {
    label: '30 mi.',
    value: '30',
  },
  {
    label: '40 mi.',
    value: '40',
  },
  {
    label: '50 mi.',
    value: '50',
  },
];

export const INITIAL_DISTANCE = DISTANCE[0];

const SearchLocation = ({
  onChangeDistance,
  geoActive,
  isActiveGeoPosition,
  onResetLocations,
  onSelectCurrLocation,
  onSearch,
  value,
  onFocus,
}) => {
  const [distance, setDistance] = useState(INITIAL_DISTANCE.value);
  const [searchValue, setSearchValue] = useState(value);
  const [searchValueError, setSearchValueError] = useState(false);
  const [changingValue, setChangingValue] = useState(false);
  useEffect(() => {
    if (!value && !changingValue && searchValue.length > 0) {
      setSearchValue(value);
    }
  }, [value, searchValue, changingValue]);
  const onChangeSelect = useCallback(
    (value) => {
      setDistance(value);
      onChangeDistance && onChangeDistance(value);
    },
    [onChangeDistance]
  );
  const onChangeInput = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchValue(value);
      setSearchValueError(false);
      setChangingValue(true);
      if (value.length === 0) {
        onSearch(value);
        onChangeSelect(INITIAL_DISTANCE.value);
      }
    },
    [onSearch, onChangeSelect]
  );
  const onEnterSearch = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (searchValue.match(ZIP_CODE_PATTERN) || searchValue.length === 0) {
          setSearchValueError(false);
          onSearch(searchValue);
          setChangingValue(false);
        } else {
          setSearchValueError(true);
        }
      }
    },
    [searchValue, onSearch]
  );
  const onBlurInput = useCallback(
    (e) => {
      const value = e.target.value;
      if (value.match(ZIP_CODE_PATTERN) || value.length === 0) {
        setSearchValueError(false);
        onSearch(value);
        setChangingValue(false);
      } else {
        setSearchValueError(true);
      }
    },
    [onSearch]
  );
  const onClickLocations = useCallback(() => {
    setDistance(INITIAL_DISTANCE.value);
    onChangeDistance && onChangeDistance(INITIAL_DISTANCE.value);
    onResetLocations && onResetLocations();
  }, [onChangeDistance, onResetLocations]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.search_input}>
        <SearchInput
          pattern={ZIP_CODE_PATTERN}
          required
          inputMode="numeric"
          placeholder="Enter your Zip Code"
          onChange={onChangeInput}
          onBlur={onBlurInput}
          onFocus={onFocus}
          value={searchValue}
          error={searchValueError}
          InputAdornmentComponent={
            isActiveGeoPosition ? (
              <Tooltip title="Near me locations" arrow placement="top" aria-label="Near me locations">
                <span data-active={geoActive} className={styles.end_adornment}>
                  <MyLocationIcon onClick={onSelectCurrLocation} />
                </span>
              </Tooltip>
            ) : (
              false
            )
          }
          inputProps={{ 'aria-label': 'Search location' }}
          helperText={searchValueError ? 'Please enter a valid zip code' : ''}
          onKeyDown={onEnterSearch}
          autoComplete="no"
        />
      </div>
      <div className={styles.options}>
        <div className={styles.locations_btn}>
          <TextButton className={styles.location_button} onClick={onClickLocations}>
            ALL LOCATIONS
          </TextButton>
        </div>
        <div className={styles.distance}>
          <div className={styles.distance_label}>From your location</div>
          <div>
            <Select
              disabled={!searchValue}
              className={`${styles.distance_select} disable_white`}
              classesMenu={{
                root: styles.distance_select_item,
              }}
              value={distance}
              classes={{
                root: styles.distance_select,
              }}
              onChange={onChangeSelect}
              items={DISTANCE}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchLocation;
