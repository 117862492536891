import React, { useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PrimaryButton } from 'components/Button/Button';
import Price from 'components/Price/Price';
import Quantity from 'components/Quantity/Quantity';

import styles from './ProductSummary.module.scss';

const ProductSummary = ({
  quantityincrement,
  minimumquantity,
  cost,
  handleAddProductClick,
  adding,
  extraCost,
  productQuantity,
  handleProductQuantity,
}) => {
  const getTotalCost = useCallback(() => {
    return productQuantity * (cost + extraCost);
  }, [productQuantity, cost, extraCost]);

  return (
    <div className={styles.container}>
      <div className={styles.quantity_wrapper}>
        <span className={styles.quantity_label}>QTY.</span>
        <Quantity
          increment={quantityincrement}
          min={minimumquantity}
          revertColor
          defaultValue={productQuantity}
          onChange={handleProductQuantity}
        />
      </div>
      <PrimaryButton className={styles.order_button} disabled={adding} onClick={handleAddProductClick}>
        Add to Bag&nbsp;-&nbsp;
        <Price value={getTotalCost()} />
        {adding && <CircularProgress size={24} className={styles.circular_progress} />}
      </PrimaryButton>
    </div>
  );
};

export default React.memo(ProductSummary);
