import React, { Fragment, useEffect, useState } from 'react';
import ActionTable from 'components/ActionTable/ActionTable';
import styles from './MyFavorites.module.scss';
import { TextButton } from 'components/Button/Button';
import Alert from 'components/Alert/Alert';
import Spin from 'components/Spin/Spin';
import useMyFavoritesFetch from './hooks/useMyFavoritesFetch';
import { useQueryUrlParamsDispatch } from 'hooks';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import showToast from 'components/Toast/ShowToast';

function MyFavorites({ toMenu, dataLoaded }) {
  const [dataInitialized, setDataInitialized] = useState(false);
  const [{ loading, data, error }, { deleteFave }] = useMyFavoritesFetch();
  const {
    basket: { data: basketData },
  } = useAppStateContext();
  const setQuery = useQueryUrlParamsDispatch();
  const {
    restaurant: { data: restaurantData },
  } = useAppStateContext();

  const handleDeleteClick = (fave) => {
    deleteFave(fave.id).then(() => {
      showToast('success', `${fave.name} deleted successfully`);
    });
  };

  const handleModifyClick = (faveId) => {
    const queriesToClear = ['groupOrderId', 'owner', 'joinGroup', 'groupOrderModal', 'restaurantId'];

    const queriesToSet = { favoritesPanel: true, backToAccountPage: true, faveId };

    if (restaurantData && restaurantData.id) {
      setQuery({ ...queriesToSet }, `/${restaurantData.id}/menu`, false, queriesToClear);
    } else {
      setQuery(
        {
          selectLocationFirstModal: true,
          returnUrl: `/:restaurantId/menu?favoritesPanel=true&backToAccountPage=true&faveId=${faveId}`,
        },
        null,
        false,
        queriesToClear
      );
    }
  };

  useEffect(() => {
    if (!dataInitialized && !loading && (data || error)) {
      setDataInitialized(true);
      dataLoaded();
    }
  }, [loading, data, error, dataInitialized, dataLoaded]);

  const errorMessage =
    (error && (error.errorMessage || 'Something went wrong... Could not load my favorites. Please try again later!')) ||
    '';

  return (
    <div className={styles.container}>
      {errorMessage && <Alert message={errorMessage} />}
      {loading && <Spin spinning={loading} />}
      {!loading &&
        !error &&
        data &&
        (data.length > 0 ? (
          <ActionTable data={data}>
            {(row) => (
              <Fragment>
                <TextButton
                  onClick={() => handleModifyClick(row.id)}
                  className={`${styles.action_btn} ${styles.modify}`}
                >
                  Modify
                </TextButton>
                <TextButton onClick={() => handleDeleteClick(row)} className={`${styles.action_btn} ${styles.delete}`}>
                  Delete
                </TextButton>
              </Fragment>
            )}
          </ActionTable>
        ) : (
          <div className={styles.no_data}>
            You have no favorites.
            <br />
            <TextButton onClick={toMenu} className={styles.place_order_link}>
              {basketData && basketData.products && basketData.products.length ? 'Continue your order' : 'Order Now'}
            </TextButton>
            &nbsp;and save it as a favorite to speed up the ordering process next time.
          </div>
        ))}
    </div>
  );
}

export default MyFavorites;
