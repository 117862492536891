import { useEffect, useReducer } from 'react';
import { setTipBasketAmount } from 'api/setTipBasketAmount';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import showToast from 'components/Toast/ShowToast';
import { validateBasketService } from 'services/validateBasket';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useAddTipsFetch = (amount, submit) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const { basketId } = useQueryUrlParams();
  const appDispatch = useAppDispatchContext();
  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      if (basketId && !isNaN(amount) && submit) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        appDispatch({ type: ACTION_TYPES.loadingBasket, payload: { loading: true } });
        try {
          const response = await validateBasketService(setTipBasketAmount, basketId, Number(amount));
          if (!ignore && response) {
            dispatch({
              type: FETCH_ACTION_TYPES.setData,
              payload: { data: response },
            });
            appDispatch({
              type: ACTION_TYPES.setBasket,
              payload: { data: response },
            });
          }
        } catch (e) {
          if (!ignore) {
            const errorMessage = e.errorMessage || 'Something went wrong... Could not accept tips to your basket!';
            showToast('error', errorMessage);
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            appDispatch({ type: ACTION_TYPES.loadingBasket, payload: { loading: false } });
          }
        }
      }
    };
    fetch();
    return () => {
      ignore = true;
    };
  }, [basketId, amount, submit, appDispatch]);
  return state;
};

export default useAddTipsFetch;
