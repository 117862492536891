import { useEffect, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { getMyFavorites } from 'api/user/favorites/getUserFavorites';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useQueryUrlParams } from 'hooks';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useFavoriteOrderFetch = (isPanelOpen = false) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppState = useAppDispatchContext();
  const { faveId } = useQueryUrlParams();
  const parsedFaveId = faveId ? JSON.parse(faveId) : null;

  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });

      try {
        const response = await getMyFavorites();
        const faves = response.faves ? response.faves.reverse() : [];

        if (!ignore) {
          const favoriteOrder = faves.find((order) => order.id === parsedFaveId);

          if (!favoriteOrder) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: true } });
            return;
          }

          dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: favoriteOrder } });
          dispatchAppState({ type: ACTION_TYPES.setFavoriteOrders, payload: { data: faves } });
        }
      } catch (e) {
        if (!ignore) {
          dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
        }
      }
    };

    if (isPanelOpen) {
      fetch();
    }

    return () => {
      ignore = true;
    };
  }, [isPanelOpen, dispatchAppState, parsedFaveId]);

  return [state];
};

export default useFavoriteOrderFetch;
