import React from 'react';

import Section from 'components/Section/Section';
import RestaurantInfo from 'components/RestaurantInfo/RestaurantInfo';
import ChangeLocationLink from 'components/ChangeLocationLink/ChangeLocationLink';

import styles from './RestaurantDetails.module.scss';

const RestaurantDetails = ({ showEdit = true, onClose = () => {}, topped = false }) => {
  const changeLocation = <ChangeLocationLink className={styles.button} />;

  return (
    <Section
      title={'Ordering From'}
      topped={topped}
      sectionHr
      className={styles.container}
      description={showEdit ? changeLocation : null}
    >
      <RestaurantInfo onClose={onClose} showEdit={false} />
    </Section>
  );
};

export default RestaurantDetails;
