import { validateBasket } from '../api/validateBasket';
import showToast from 'components/Toast/ShowToast';

let caughtErrors = [];
export const validateBasketService = async (entity, basketId, ...params) => {
  if (entity) {
    return entity(basketId, ...params).then((res) => {
      const id = res['id'];
      if (caughtErrors.length) return res;
      return validateBasket(basketId)
        .then((valid) => {
          const data = id === basketId ? { ...res, ...valid } : res;
          caughtErrors = [];
          return data;
        })
        .catch((err) => {
          if (err.num && err.num === 218) {
            err.errorMessage && showToast('warning', err.errorMessage);
          } else {
            showToast('error', err.errorMessage || 'Something went wrong when trying to validate basket!');
          }
          caughtErrors.push(err);
          return res;
        });
    });
  }
  return;
};
