import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { createBasketFromFave } from 'api/createBasketFromFave';
import { removeProductFromBasket } from 'api/removeProductFromBasket';
import { updateUserFave } from 'api/user/favorites/updateUserFave';
// import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useFaveBasketOperations = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppBasketState = useAppDispatchContext();
  const { favoriteOrders } = useAppStateContext();

  const createBasket = useCallback(
    (faveId) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.cleanState });
          dispatchAppBasketState({ type: ACTION_TYPES.loadingBasket, payload: { loading: true } });
          try {
            const basket = await createBasketFromFave(faveId);
            dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: basket } });
            resolve(basket);
          } catch (e) {
            dispatchAppBasketState({ type: ACTION_TYPES.errorBasket, payload: { error: e } });
            reject(e);
          }
        };

        fetch();
      }),
    [dispatchAppBasketState]
  );

  const removeProduct = useCallback(
    (basketGuid = '', productId = '') =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatchAppBasketState({ type: ACTION_TYPES.loadingBasket, payload: { loading: true } });
          try {
            const basket = await removeProductFromBasket(basketGuid, productId);
            dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: basket } });
            resolve(true);
          } catch (e) {
            dispatchAppBasketState({ type: ACTION_TYPES.errorBasket, payload: { error: e } });
            reject(false);
          }
        };

        fetch();
      }),
    [dispatchAppBasketState]
  );

  const updateFave = useCallback(
    (faveId, basketGuid, description) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            const staticIds = favoriteOrders.data.map((fave) => fave.id).filter((id) => id !== faveId);
            const response = await updateUserFave(faveId, basketGuid, description);
            const faves = response.faves || [];
            const newFaveId = faves.find((fave) => staticIds.every((staticId) => staticId !== fave.id)).id;
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false } });
            resolve(newFaveId);
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            reject(false);
          }
        };

        fetch();
      }),
    [favoriteOrders]
  );

  return [state, { createBasket, removeProduct, updateFave }];
};

export default useFaveBasketOperations;
