import React, { useCallback, memo } from 'react';
import DrawerLayout from 'components/DrawerLayout/DrawerLayout';
import Section from 'components/Section/Section';
import useBasketUpsell from './hooks/useBasketUpsell';
import useAddUpsellItems from './hooks/useAddUpsellItems';
import { Alert } from '@material-ui/lab';
import Spin from 'components/Spin/Spin';
import ChooseButton from 'components/ChooseButton/ChooseButton';
import styles from './OrderUpsell.module.scss';
import { useState } from 'react';
import { PrimaryButton } from 'components/Button/Button';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useQueryUrlParams, useQueryUrlParamsDispatch } from 'hooks';
import { useParams } from 'react-router';
import Price from 'components/Price/Price';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';

const HeaderPanel = memo(() => {
  return (
    <div className={styles.header}>
      <PrimaryHeader size="lg" brush="after" title="MAY WE SUGGEST" />
    </div>
  );
});

HeaderPanel.displayName = 'HeaderPanel';

const OrderUpsell = ({ open, onSetStep, onClose }) => {
  const setPath = useQueryUrlParamsDispatch();
  const { data, loading, error } = useBasketUpsell(open);
  const { restaurantId } = useParams();
  const { restaurantId: queryResId } = useQueryUrlParams();
  const {
    basket: { data: basketData, loading: basketLoading, error: basketError },
    authenticated,
  } = useAppStateContext();
  const subTotal = !basketLoading && basketData && basketData.subtotal;
  const addUpsell = useAddUpsellItems();
  const [stateUpsell, setStateUpsell] = useState([]);
  const [checkedUpsell, setCheckedUpsell] = useState({});
  const [totalSum, setTotalSum] = useState(null);
  const dataIsLoaded = !loading && !error && data;

  const onSubmitStep = useCallback(() => {
    if (authenticated) {
      onClose();
      setPath({}, `/${restaurantId || queryResId}/checkout`, false, ['openBasket']);
    } else {
      onSetStep('checkoutAuth');
    }
  }, [authenticated, onClose, setPath, onSetStep, queryResId, restaurantId]);

  const handleChange = (name, elem) => (value, e) => {
    let currUpsell = [...stateUpsell];
    const upsellCost = elem && elem.cost && Number(elem.cost.replace(/[^0-9.-]+/g, ''));
    if (currUpsell.length === 0) {
      currUpsell.push({ id: elem.id, quantity: 1 });
      setTotalSum(subTotal + upsellCost);
    } else if (currUpsell.find((upsell) => upsell.id === elem.id) && !value) {
      currUpsell = currUpsell.filter((upsell) => upsell.id !== elem.id);
      setTotalSum((Math.ceil(totalSum * 100) - Math.ceil(upsellCost * 100)) / 100);
    } else if (currUpsell.find((upsell) => upsell.id !== elem.id) && value) {
      currUpsell.push({ id: elem.id, quantity: 1 });
      setTotalSum((Math.ceil(totalSum * 100) + Math.ceil(upsellCost * 100)) / 100);
    }
    setStateUpsell(currUpsell);
    setCheckedUpsell({ ...checkedUpsell, [name]: { ...checkedUpsell[name], [elem.id]: value } });
  };

  const handlerNextStep = useCallback(() => {
    if (stateUpsell.length > 0) {
      addUpsell(stateUpsell).then((resp) => {
        if (resp) {
          setStateUpsell([]);
          setCheckedUpsell({});
          setTotalSum(null);
          onSubmitStep();
        }
      });
    } else {
      onSubmitStep();
    }
  }, [addUpsell, onSubmitStep, stateUpsell]);

  const errorMessage =
    (error &&
      (error.errorMessage ||
        'Something went wrong... Could not load additional options for order. Please try again later!')) ||
    '';
  const basketErrorMessage =
    (basketError &&
      (basketError.errorMessage ||
        'Something went wrong... Could not add additional options for order. Please try again later!')) ||
    '';

  return (
    <DrawerLayout
      header={<HeaderPanel />}
      onChange={onClose}
      footerChildren={
        <PrimaryButton
          className={styles.footer_button}
          variant="outlined"
          disabled={basketLoading || loading}
          onClick={handlerNextStep}
        >
          {stateUpsell && totalSum && stateUpsell.length > 0 ? (
            <>
              CHECKOUT: SUBTOTAL &nbsp;
              <Price value={totalSum} />
            </>
          ) : (
            'NO THANKS'
          )}
        </PrimaryButton>
      }
      transitionDuration={0}
      open={open}
    >
      {errorMessage && <Alert message={errorMessage} />}
      {basketErrorMessage && <Alert message={basketErrorMessage} />}
      {loading && <Spin spinning={loading} />}
      {dataIsLoaded &&
        data.map(({ title, items }) => (
          <Section key={title} title={title} sectionHr className={styles.upsell_section}>
            {items.map((upsellItem) => {
              const transformTitle = title.toLowerCase().replace(/\s/g, '_');
              const price = Number(upsellItem.cost) ? <Price value={upsellItem.cost} /> : upsellItem.cost;
              const stateSection = checkedUpsell[transformTitle];
              const checkedElem = stateSection ? stateSection[upsellItem.id] : false;
              const imagePath = upsellItem.image && upsellItem.imagePath + upsellItem.image.filename;
              return (
                <div key={upsellItem.id} className={styles.btn_wrapper}>
                  <ChooseButton
                    checked={checkedElem}
                    type="image"
                    imagePath={imagePath}
                    value={upsellItem.id}
                    label={upsellItem.name}
                    handleChange={handleChange(transformTitle, upsellItem)}
                    name={upsellItem.id.toString()}
                    disabled={basketLoading}
                    price={<>+{price}</>}
                  />
                </div>
              );
            })}
          </Section>
        ))}
    </DrawerLayout>
  );
};
export default React.memo(OrderUpsell);
