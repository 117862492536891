import React, { useState, useEffect } from 'react';
import styles from './AddTip.module.scss';
import ChooseButton from 'components/ChooseButton/ChooseButton';
import { InputField } from 'components/Input/Input';
import { RadioGroup } from '@material-ui/core';
import useAddTipsFetch from './hooks/useAddTipsFetch';
import ConfirmTipsModal from './ConfirmTipsModal/ConfirmTipsModal';
import { memo } from 'react';

const TIPS_PERCENTAGE_COUNT = [15, 20, 25];

const TIPS_BUTTONS = [
  {
    label: '15%',
    value: '15',
  },
  {
    label: '20%',
    value: '20',
  },
  {
    label: '25%',
    value: '25',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const AddTip = ({ subtotal, defaultTips, currTips }) => {
  const [tipValue, setTipValue] = useState();
  const [inputValue, setInputValue] = useState('');
  const [radioValue, setRadioValue] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const { loading } = useAddTipsFetch(tipValue, submitModal);

  useEffect(() => {
    if (currTips !== 0 && radioValue && radioValue !== 'other' && tipValue) {
      setInputValue(currTips.toFixed(2));
    }
  }, [currTips, radioValue, tipValue]);

  useEffect(() => {
    const isEmptyState = isNaN(tipValue) && !radioValue;
    if (!isNaN(defaultTips) && isEmptyState && TIPS_PERCENTAGE_COUNT.includes(defaultTips)) {
      setRadioValue(defaultTips.toString());
      const calcTips = (subtotal * Number(defaultTips)) / 100;
      setSubmitModal(true);
      setTipValue(calcTips);
      setInputValue(calcTips.toFixed(2));
    } else if (isEmptyState) {
      setRadioValue('20');
      const calcTips = (subtotal * 20) / 100;
      setSubmitModal(true);
      setTipValue(calcTips);
      setInputValue(calcTips.toFixed(2));
    }
  }, [defaultTips, subtotal, tipValue, radioValue]);

  const onBlurInput = () => {
    let inputValueNumber = inputValue.includes(',') ? Number(inputValue.replace(',', '.')) : Number(inputValue);
    inputValueNumber = inputValueNumber.toFixed(2);
    setInputValue(inputValueNumber);
    const tipPercentage = (inputValueNumber / subtotal) * 100;
    if (inputValueNumber === tipValue) return;
    if (tipPercentage >= 50) {
      setModalOpen(true);
      setTipValue(inputValueNumber);
    } else {
      setSubmitModal(true);
      setTipValue(inputValueNumber);
    }
  };
  const onEnterInput = (e) => {
    if (e.key === 'Enter') {
      let inputValueNumber = inputValue.includes(',') ? Number(inputValue.replace(',', '.')) : Number(inputValue);
      inputValueNumber = inputValueNumber.toFixed(2);
      setInputValue(inputValueNumber);
      if (inputValueNumber === tipValue) return;
      const tipPercentage = (inputValueNumber / subtotal) * 100;
      if (tipPercentage >= 50) {
        setModalOpen(true);
        setTipValue(inputValueNumber);
      } else {
        setTipValue(inputValueNumber);
        setSubmitModal(true);
      }
    }
  };
  const onChangeInput = (e) => {
    setSubmitModal(false);
    const value = e.target.value;
    const pattern = /^\d+(\.\d{0,2})?$/;
    if (pattern.test(value) && Number(value) <= 999) {
      setInputValue(value);
    } else if (value.length === 0) {
      setInputValue('');
    }
  };
  const onChangeRadio = (e) => {
    const value = e.target.value;
    setRadioValue(value);
    let calcTips;
    if (value === '0') {
      calcTips = 0;
    } else {
      calcTips = (subtotal * Number(value)) / 100;
    }
    if (value !== 'other') {
      setInputValue(calcTips.toFixed(2));
      setSubmitModal(true);
      setTipValue(calcTips);
    } else {
      setTipValue();
    }
  };
  const handleCloseModal = () => {
    const calcTips = (subtotal * 20) / 100;
    setModalOpen(false);
    setInputValue(calcTips.toFixed(2));
    setTipValue(calcTips);
    setRadioValue('20');
    setSubmitModal(true);
  };
  const onSubmitModal = () => {
    setModalOpen(false);
    setSubmitModal(true);
  };
  const isDisabled = radioValue !== 'other';
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.radio}>
          <RadioGroup name="tips" value={radioValue} onChange={onChangeRadio}>
            {TIPS_BUTTONS.map(({ label, value }) => (
              <div className={styles.radio_item} key={value}>
                <ChooseButton
                  className={styles.radio_btn}
                  disabled={loading}
                  checked={radioValue === value}
                  value={value}
                  label={label}
                  type="radio"
                />
              </div>
            ))}
          </RadioGroup>
        </div>
        <div className={styles.amount}>
          <span className={styles.amount_type}>$</span>
          <InputField
            value={loading ? '' : inputValue}
            fullWidth
            disabled={loading || isDisabled}
            onBlur={onBlurInput}
            onChange={onChangeInput}
            onKeyDown={onEnterInput}
          />
        </div>
      </div>
      <ConfirmTipsModal handleOnClose={handleCloseModal} onSubmit={onSubmitModal} isOpen={modalOpen} />
    </>
  );
};
const MemoizedAddTip = memo(AddTip);
export default MemoizedAddTip;
