import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import styles from './App.module.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AppStateProvider } from 'stateProviders/appStateProvider';
import routerConfig from 'routerConfig';
import PageNotFound from './PageNotFound/PageNotFound';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import AuthGuard from '../components/AuthGuard/AuthGuard';
import Layout from 'modules/Layout/Layout';
import ChangePassword from './ChangePassword/ChangePassword';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as variables from '../_theme.scss';

//React custom notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router';
import AxiosRequestHandling from './AxiosRequestHandling/AxiosRequestHandling';

const getNumberFromPx = (str) => {
  const arrStr = str.split('px');
  return Number(arrStr[0]) && Number(arrStr[0]);
};

const tagManagerArgs = {
  gtmId: 'GTM-PLWRXT',
};

TagManager.initialize(tagManagerArgs);

const theme = createMuiTheme({
  typography: {
    fontFamily: `'Avenir', 'New Gothic', 'Helvetica Neue', sans-serif;`,
  },
  palette: {
    primary: {
      light: variables.color_primary_darker,
      main: variables.color_primary,
      dark: variables.color_primary,
      contrastText: variables.color_white,
    },
    secondary: {
      light: variables.color_gray1,
      main: variables.color_gray,
      dark: variables.color_black,
    },
    error: {
      main: variables.color_error,
    },
  },
  breakpoints: {
    values: {
      xs: getNumberFromPx(variables.breakPointXs),
      sm: getNumberFromPx(variables.breakPointSm),
      md: getNumberFromPx(variables.breakPointMd),
      lg: getNumberFromPx(variables.breakPointLg),
      xl: getNumberFromPx(variables.breakPointXl),
    },
  },
});

const ScrollTop = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
});

ScrollTop.displayName = 'ScrollTop';

const App = () => {
  return (
    <Router>
      <Helmet>
        <title>Ninety Nine - Online Ordering</title>
        <meta
          name="description"
          content="Order Ahead and Skip the Line at Ninety Nine Restaurant. Place Orders Online or on your Mobile Phone."
        />
      </Helmet>
      <ScrollTop />
      <MuiThemeProvider theme={theme}>
        <AppStateProvider>
          <AxiosRequestHandling>
            <AuthGuard>
              <div className={styles.wrapper}>
                <div className={styles.content_wrapper}>
                  <ToastContainer />
                  <Switch>
                    {routerConfig.map((route, ind) => {
                      return route.protected ? (
                        <ProtectedRoute key={ind} exact={route.exact} path={route.path}>
                          <Layout>
                            <route.component />
                          </Layout>
                        </ProtectedRoute>
                      ) : (
                        <Route key={ind} exact={route.exact} path={route.path}>
                          <Layout>
                            <route.component />
                          </Layout>
                        </Route>
                      );
                    })}
                    <Route key={'not-fount'} path={'*'}>
                      <Layout>
                        <PageNotFound />
                      </Layout>
                    </Route>
                    <Route key={'reset-password'} path={'/resetPassword'}>
                      <Layout>
                        <ChangePassword />
                      </Layout>
                    </Route>
                  </Switch>
                </div>
              </div>
            </AuthGuard>
          </AxiosRequestHandling>
        </AppStateProvider>
      </MuiThemeProvider>
    </Router>
  );
};

export default App;
