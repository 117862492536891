import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import { setupInterceptors } from 'api/axiosInstances/axiosInstance';
import routes from 'routes.json';
import ExpiredSessionModal from '../../modules/ExpiredSessionModal/ExpiredSessionModal';

let isSignIn = false;

const AxiosRequestHandling = ({ children }) => {
  const isSignInMenuActive = useRouteMatch({
    path: routes.signIn,
    exact: true,
  });
  const { signin, checkoutAs } = useQueryUrlParams();
  isSignIn = isSignInMenuActive !== null || signin || checkoutAs;
  const [showExpiredSessionModal, setShowExpiredSessionModal] = useState(false);
  const [init, setInit] = useState(false);

  const handleModalClose = useCallback(() => {
    setShowExpiredSessionModal(false);
  }, []);

  if (!init) {
    const showModal = () => {
      if (!isSignIn) {
        setShowExpiredSessionModal(true);
      }
    };
    setupInterceptors({ onUnauthorized: showModal });
    setInit(true);
  }

  return (
    <>
      {children}
      <ExpiredSessionModal open={showExpiredSessionModal} onClose={handleModalClose} />
    </>
  );
};

export default React.memo(AxiosRequestHandling);
