//   / .. /	          All regular expressions start and end with forward slashes.

//   ^	              Matches the beginning of the string or line.

//   \w	              Equivalent to [A-Za-z0-9_].

//   [\.-]	          \ Indicates that the next character is special and not to be interpreted literally.
//                    .- matches character . or -.

//   *              Matches the previous character 0 or more times.

//   {2,5}	          From 2 to 5 charachters

//   $	              Matches the end of the string or line.

export const EMAIL_VALIDATION_PATTERN = /^[A-Za-z0-9-][\\.-\w]*@([A-Za-z0-9-]+(\.))+([A-Za-z]{2,5})$/;

export const EMAIL_REGEXP = /^[a-zA-Z]+(?:[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*(?:\.[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,5}$/;
