import './AuthGuard.scss';

import React, { useEffect } from 'react';
import auth from '../../services/auth';
import { useLogin, useQueryUrlParams } from 'hooks';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import Spin from 'components/Spin/Spin';

const AuthGuard = ({ children }) => {
  const isLogged = auth.getLogged() === 'true';
  const { code } = useQueryUrlParams();
  const [, { relogin, loginByCode }] = useLogin();
  const {
    user: { loading, reloading },
    authenticated,
  } = useAppStateContext();
  const waitByGrandTokenCode = code && !authenticated;
  const waitByRefresh = (!authenticated && isLogged && !code) || reloading;

  useEffect(() => {
    if (relogin && !authenticated && !code && isLogged && !reloading && !loading) {
      relogin();
      auth.removeLogged();
    }
  }, [relogin, authenticated, code, isLogged, reloading, loading]);

  useEffect(() => {
    if (loginByCode && code && !authenticated && !reloading && !loading) {
      loginByCode(code);
    }
  }, [loginByCode, code, authenticated, reloading, loading]);

  return waitByRefresh || waitByGrandTokenCode ? (
    <div className="auth_spinner_container">
      <Spin spinning={true} className="auth_spinner" />
    </div>
  ) : (
    children
  );
};

export default React.memo(AuthGuard);
