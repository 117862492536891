import { useReducer, useCallback } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { updateAccount } from 'api/user/updateAccount';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useSelectFavoriteLocationFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppUserState = useAppDispatchContext();
  const {
    user: { data: userData },
  } = useAppStateContext();

  const updateFavoriteLocation = useCallback(
    (locationCode) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatchAppUserState({
            type: ACTION_TYPES.setLoadingUserDetails,
            payload: { loading: true },
          });
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            await updateAccount({ favoriteStore: { code: locationCode } });
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: false } });
            dispatchAppUserState({
              type: ACTION_TYPES.setUserDetails,
              payload: {
                data: {
                  ...userData,
                  accountInfo: {
                    ...userData.accountInfo,
                    fields: { ...userData.accountInfo.fields, favoriteStore: { code: locationCode } },
                  },
                },
              },
            });
            resolve(true);
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            dispatchAppUserState({
              type: ACTION_TYPES.setErrorUserDetails,
              payload: { error: e },
            });

            showToast('error', e.errorMessage || 'Something went wrong... Could not update favorite location!');

            reject(e);
          }
        };

        fetch();
      }),
    [dispatchAppUserState, userData]
  );

  return [state, { updateFavoriteLocation }];
};

export default useSelectFavoriteLocationFetch;
