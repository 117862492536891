import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { deserializeQuery } from 'utils';
import { serializeQuery } from 'utils';

/**
 * @name useQueryUrlParamsDispatch
 * @function
 * @param {Object} query - object of values to set for search url
 * @param {string} path - new url for redirect
 * @param {boolean} clean - clean up current query search
 * @param {string[]} deleteKeys - query params that need to delete from url
 * @return {void}
 * @description
 * Function for add/remove/update current query
 */
const useQueryUrlParamsDispatch = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const setQuery = useCallback(
    (query, path, clean, deleteKeys) => {
      const queryState = deserializeQuery(search);
      let currQuery = clean ? { ...query } : { ...queryState };
      if (query && Object.keys(query).length > 0 && !clean) {
        currQuery = { ...currQuery, ...query };
      }
      if (deleteKeys.length > 0 && !clean) {
        currQuery = Object.keys(currQuery).reduce((total, key) => {
          if (key && !deleteKeys.includes(key)) {
            total[key] = currQuery[key];
          }
          return total;
        }, {});
      }
      const params = serializeQuery(currQuery);
      const searchQuery = params && `?${params}`;
      const newPath = path || pathname;
      if (search !== searchQuery) {
        if (history.location.pathname === newPath) {
          history.replace({
            search: searchQuery,
          });
        } else {
          history.push({
            pathname: newPath,
            search: searchQuery,
          });
        }
      } else {
        if (history.location.pathname !== newPath) {
          history.push({
            pathname: newPath,
            search: search,
          });
        }
      }
    },
    [pathname, history, search]
  );
  return (query, path, clean = false, deleteKeys = []) => {
    setQuery(query, path, clean, deleteKeys);
  };
};

export const useQueryUrlCleanParamsDispatch = () => {
  const history = useHistory();
  const { search } = useLocation();
  const cleanQuery = useCallback(
    (deleteKeys, clean) => {
      const queryState = deserializeQuery(search);
      let currQuery = clean ? {} : { ...queryState };
      if (deleteKeys.length > 0 && !clean) {
        currQuery = Object.keys(currQuery).reduce((total, key) => {
          if (key && !deleteKeys.includes(key)) {
            total[key] = currQuery[key];
          }
          return total;
        }, {});
      }
      const params = serializeQuery(currQuery);
      const searchQuery = params && `?${params}`;
      history.replace({
        search: searchQuery,
      });
    },
    [history, search]
  );
  return (deleteKeys = [], clean = false) => {
    cleanQuery(deleteKeys, clean);
  };
};

export default useQueryUrlParamsDispatch;
