import React, { useCallback } from 'react';
import styles from './OrderSummaryModal.module.scss';
import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import Section from 'components/Section/Section';
import Spin from 'components/Spin/Spin';
import OrderSummaryList from 'components/OrderSummaryList/OrderSummaryList';
import useCreateBasketFromPreviousOrder from './hooks/useCreateBasketFromPreviousOrder';
import showToast from 'components/Toast/ShowToast';
import { useQueryUrlParamsDispatch } from 'hooks';
import moment from 'moment';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const OrderSummaryModal = ({ handleState, state }) => {
  const [{ loading }, { createBasket }] = useCreateBasketFromPreviousOrder();
  const setRedirect = useQueryUrlParamsDispatch();

  const handleOrderClick = useCallback(() => {
    setRedirect({}, null, false, ['restaurantId']);

    createBasket(state.order.id).then(() => {
      const SuccessMsg = () => (
        <span>
          Order <u>{state.order.vendorname}</u> at{' '}
          <u>{moment(state.order.timeplaced, 'YYYYMMDD HH:mm').format('h:mm A, dddd, MMMM D, YYYY')}</u> added to basket
        </span>
      );
      handleState({ orderSummaryModal: false });
      showToast('success', <SuccessMsg />);
      setRedirect({}, `/${state.order.vendorid}/menu`, false);
    });
  }, [setRedirect, handleState, createBasket, state]);

  return (
    <ModalWindow
      maxWidth="md"
      open={state.orderSummaryModal}
      onChange={() => handleState({ orderSummaryModal: false })}
      headerTitle="ORDER SUMMARY"
      headerSize="lg"
      footerChildren={
        <div className={styles.modal_footer}>
          <PrimaryButton
            disabled={loading}
            className={styles.order_btn}
            size="large"
            endIcon={<ArrowForwardIcon />}
            onClick={handleOrderClick}
          >
            <Spin spinning={loading} className={styles.spinner} />
            ORDER AGAIN
          </PrimaryButton>
        </div>
      }
    >
      <div className={styles.modal_content}>
        <Section sectionHr title="ORDER SUMMARY">
          <OrderSummaryList data={state.order} />
        </Section>
      </div>
    </ModalWindow>
  );
};

export default React.memo(OrderSummaryModal);
