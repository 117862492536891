import React, { useEffect, useState, memo, useCallback } from 'react';
import styles from './SavedCreditCard.module.scss';
import SelectedPaymentCard from 'components/SelectedPaymentCard/SelectedPaymentCard';
import SavedCreditCardModal from '../SavedCreditCardModal/SavedCreditCardModal';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import useUpdateUserDefaultCreditCard from '../SavedCreditCardModal/hooks/useUpdateUserDefaultCreditCard';

const SavedCreditCard = () => {
  const {
    userBillingAccounts: { billingAccounts, loading, defaultBillingAccount, selectedBillingAccount },
    paymentData: { isUseSavedCard },
  } = useAppStateContext();
  const [{ loading: isUpdating }, updateBillingAccount] = useUpdateUserDefaultCreditCard();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const appDispatch = useAppDispatchContext();

  useEffect(() => {
    if (Array.isArray(billingAccounts) && billingAccounts.length) {
      const defaultBillingAccount = billingAccounts.find((item) => item.isDefault);

      if (defaultBillingAccount) {
        appDispatch({
          payload: { defaultBillingAccount },
          type: ACTION_TYPES.setUserBillingAccountsData,
        });
      } else {
        updateBillingAccount(billingAccounts[0].id);
      }
    }
  }, [appDispatch, billingAccounts, defaultBillingAccount, updateBillingAccount]);

  const data = selectedBillingAccount.id ? selectedBillingAccount : defaultBillingAccount;
  const cardIsNotDefined = !loading && !isUpdating && !data.id;

  useEffect(() => {
    if (isUseSavedCard && cardIsNotDefined) {
      setIsModalOpen(true);
    }
  }, [isUseSavedCard, cardIsNotDefined]);

  const onClickButton = useCallback(() => {
    setSelectedCardId(data.id);
    setIsModalOpen(true);
  }, [data]);
  const handleClose = useCallback(
    (isClosedOnUseCreditCard) => {
      setIsModalOpen(false);
      if (!isClosedOnUseCreditCard && cardIsNotDefined) {
        appDispatch({
          payload: false,
          type: ACTION_TYPES.setIsUseSavedCard,
        });
      }
    },
    [appDispatch, cardIsNotDefined]
  );

  return (
    <>
      {!loading && !isUpdating && data.id && (
        <SelectedPaymentCard onClick={onClickButton}>
          <div className={styles.innerBlock}>
            <div className={styles.cardNumber}>{`XXXX-XXXX-XXXX-${data.cardNumber}`}</div>
            <div className={styles.nameOnCard}>{data.name}</div>
            <div className={styles.cardType}>{data.cardType}</div>
          </div>
        </SelectedPaymentCard>
      )}
      <SavedCreditCardModal handleClose={handleClose} defaultSelectedCard={selectedCardId} isOpen={isModalOpen} />
    </>
  );
};

const MemoizedSavedCreditCard = memo(SavedCreditCard);

export default MemoizedSavedCreditCard;
