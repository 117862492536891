import React from 'react';
import { Accordion as MUIAccordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Accordion.scss';

const Accordion = ({ header, children, id = '', ...otherProps }) => {
  return (
    <MUIAccordion {...otherProps} square classes={{ root: 'accordion_panel' }}>
      <AccordionSummary
        id={id}
        classes={{
          root: 'accordion_header',
          expandIcon: 'accordion_header_icon',
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <h2>{header}</h2>
      </AccordionSummary>
      <AccordionDetails classes={{ root: 'accordion_details' }}>{children}</AccordionDetails>
    </MUIAccordion>
  );
};

export default React.memo(Accordion);
