import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { updateCommunicationPreferences } from 'api/user/userCommunicationsPreferences';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useSubmitCommunicationPref = () => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppUserState = useAppDispatchContext();
  const {
    user: { data: currentUserDetails },
  } = useAppStateContext();

  const updatePreferences = useCallback(
    (preferences) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          if (preferences) {
            dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true, refetch: true } });
            try {
              const response = await updateCommunicationPreferences(preferences);
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
              dispatchAppUserState({
                type: ACTION_TYPES.setUserDetails,
                payload: { data: { ...currentUserDetails, ...preferences } },
              });
              resolve(true);
            } catch (e) {
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
              reject(false);
            }
          }
        };

        fetch();
      }),
    [currentUserDetails, dispatchAppUserState]
  );

  return { ...state, updatePreferences };
};

export default useSubmitCommunicationPref;
