import React, { useState, useEffect, useCallback } from 'react';
import { InputField } from 'components/Input/Input';
import moment from 'moment';
import './BirthdayInput.scss';
import { FormHelperText } from '@material-ui/core';

const errorsMessage = {
  age: 'You must be 18 years or older',
  emptyDate: 'Please enter a birthday date',
  invalidDate: 'Please enter a valid birthday date',
};

const BirthdayInput = ({ error = false, onChange, onError, defaultValue, disabled = false, required = true }) => {
  const [monthValue, setMonthValue] = useState('');
  const [dayValue, setDayValue] = useState('');
  const [yearValue, setYearValue] = useState('');
  const [errorField, setErrorField] = useState(error);
  const [isTouched, setIsTouched] = useState(false);
  const [messageError, setMessageError] = useState(errorsMessage.emptyDate);

  useEffect(() => {
    if (typeof error === 'boolean') {
      setErrorField(error);
    }
  }, [error]);

  useEffect(() => {
    const momentDate = moment(defaultValue);
    if (momentDate.isValid() && defaultValue && !monthValue && !dayValue && !yearValue && !isTouched) {
      const month = moment(defaultValue, 'YYYY-MM-DD').format('MM');
      const day = moment(defaultValue, 'YYYY-MM-DD').format('DD');
      const year = moment(defaultValue, 'YYYY-MM-DD').format('YYYY');
      setMonthValue(month);
      setDayValue(day);
      setYearValue(year);
    }
  }, [defaultValue, monthValue, dayValue, yearValue, isTouched]);

  const onChangeMonth = (event) => {
    setErrorField(false);
    setIsTouched(true);
    let value = event.target.value.trim();
    const pattern = /^\d+$/;

    if (pattern.test(value)) {
      if (value > 12) {
        const valueSlice = value.slice(0, value.length - 1);
        value = valueSlice;
      }
      setMonthValue(value);
    } else if (!value) {
      setMonthValue('');
    }
  };

  const onChangeDay = (event) => {
    setErrorField(false);
    setIsTouched(true);
    let value = event.target.value.trim();
    const pattern = /^\d+$/;

    if (pattern.test(value)) {
      if (value > 31) {
        const valueSlice = value.slice(0, value.length - 1);
        value = valueSlice;
      }
      setDayValue(value);
    } else if (!value) {
      setDayValue('');
    }
  };

  const onChangeYear = (event) => {
    setErrorField(false);
    setIsTouched(true);
    const value = event.target.value.trim();
    const pattern = /^\d+$/;

    if (pattern.test(value)) {
      setYearValue(value);
    } else if (!value) {
      setYearValue('');
    }
  };

  const onBlurInput = useCallback(
    (inputType) => () => {
      if (inputType === 'day' && dayValue.length === 1) {
        const valueSlice = ('0' + dayValue).slice(-2);
        setDayValue(valueSlice);
      }
      if (inputType === 'month' && monthValue.length === 1) {
        const valueSlice = ('0' + monthValue).slice(-2);
        setMonthValue(valueSlice);
      }

      if (monthValue && dayValue && yearValue) {
        const checkIsValidDate = checkDate({ monthValue, dayValue, yearValue });

        if (!checkIsValidDate) {
          setMessageError(errorsMessage.invalidDate);
          setErrorField(true);
          onError && onError(true);
          return;
        } else {
          const userAge = calculateAge(yearValue, monthValue - 1, dayValue);

          if (userAge < 18) {
            setErrorField(true);
            setMessageError(errorsMessage.age);
            onError && onError(true);
            return;
          } else {
            setErrorField(false);
            setMessageError('');
            onError && onError(false);
          }
        }

        let dateStr = '';
        if (dayValue.length < 2 && monthValue.length < 2) {
          dateStr = `${yearValue}-0${monthValue}-0${dayValue}`;
        } else if (dayValue.length < 2) {
          dateStr = `${yearValue}-${monthValue}-0${dayValue}`;
        } else if (monthValue.length < 2) {
          dateStr = `${yearValue}-0${monthValue}-${dayValue}`;
        } else {
          dateStr = `${yearValue}-${monthValue}-${dayValue}`;
        }
        let dateMoment = moment(dateStr);
        if (dateMoment.isValid()) {
          onChange && onChange(dateStr);
        } else {
          setErrorField(true);
          setMessageError(errorsMessage.invalidDate);
          onChange && onChange('');
        }
      } else {
        if (monthValue || dayValue || yearValue) {
          setMessageError(errorsMessage.invalidDate);
        } else {
          setMessageError(errorsMessage.emptyDate);
        }
        setErrorField(true);
        onChange && onChange('');
      }
    },
    [dayValue, monthValue, onChange, onError, yearValue]
  );

  const checkDate = (date) => {
    const currentDate = new Date();
    if (
      +date.yearValue < 1753 ||
      +date.dayValue < 1 ||
      +date.monthValue < 1 ||
      date.yearValue.length < 4 ||
      currentDate.getFullYear() < +date.yearValue
    ) {
      return false;
    }

    return true;
  };

  const calculateAge = (birthYear, birthMonth, birthDay) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let age = currentYear - birthYear;

    if (currentMonth > birthMonth || (currentMonth === birthMonth && currentDay >= birthDay)) {
      return age;
    }

    return --age;
  };

  return (
    <div className={'birthday_component'}>
      <div className={'birthday_content'}>
        <div className={'birthday_label'}> {required ? 'Birthday*:' : 'Birthday:'}</div>
        <InputField
          error={errorField}
          label={monthValue ? '' : 'MM'}
          className={'birthday_input-month'}
          onChange={onChangeMonth}
          onBlur={onBlurInput('month')}
          autoComplete={'bday-month'}
          value={monthValue}
          fullWidth
          disabled={disabled}
          inputProps={{
            maxLength: '2',
          }}
        />
        <span className={'splitter'}>/</span>
        <InputField
          error={errorField}
          value={dayValue}
          label={dayValue ? '' : 'DD'}
          className={'birthday_input-day'}
          onChange={onChangeDay}
          onBlur={onBlurInput('day')}
          autoComplete={'bday-day'}
          fullWidth
          disabled={disabled}
          inputProps={{
            maxLength: '2',
          }}
        />
        <span className={'splitter'}>/</span>
        <InputField
          error={errorField}
          value={yearValue}
          label={yearValue ? '' : 'YYYY'}
          className={'birthday_input-year'}
          onChange={onChangeYear}
          onBlur={onBlurInput('year')}
          autoComplete={'bday-year'}
          disabled={disabled}
          fullWidth
          inputProps={{
            maxLength: '4',
          }}
        />
      </div>
      {errorField && (
        <FormHelperText error={errorField} className={'birthday_input_helperText'}>
          {messageError}
        </FormHelperText>
      )}
    </div>
  );
};

export default React.memo(BirthdayInput);
