import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { PrimaryIconButton } from 'components/Button/Button';

import './Quantity.scss';

const Quantity = ({
  increment = 1,
  min = 1,
  max = 99,
  defaultValue = 1,
  onChange,
  size = 'medium',
  className,
  classNameValue,
  revertColor = false,
}) => {
  const [value, setValue] = useState(Number(defaultValue) || Number(min));

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleMinusClick = useCallback(() => {
    if (value) {
      const newValue = value - Number(increment);
      const minValue = Number(min);
      const currValue = newValue >= minValue ? newValue : minValue;
      setValue(currValue);
      onChange && onChange(currValue);
    }
  }, [value, increment, min, onChange]);

  const handlePlusClick = useCallback(() => {
    if (value) {
      const newValue = value + Number(increment);
      const maxValue = Number(max);
      const currValue = (maxValue && newValue <= maxValue) || !maxValue ? newValue : maxValue;
      setValue(currValue);
      onChange && onChange(currValue);
    }
  }, [value, increment, max, onChange]);

  return (
    <span className={classNames('quantity_container', className)}>
      <PrimaryIconButton
        className={classNames('left_btn', { revert: revertColor })}
        size={size}
        onClick={handleMinusClick}
      >
        <RemoveIcon />
      </PrimaryIconButton>
      <div className={'value_wrapper'}>
        <span className={classNames('value', classNameValue, size)}>{value}</span>
      </div>
      <PrimaryIconButton
        size={size}
        className={classNames('right_btn', { revert: revertColor })}
        onClick={handlePlusClick}
      >
        <AddIcon />
      </PrimaryIconButton>
    </span>
  );
};

export default React.memo(Quantity);
