import React, { useCallback } from 'react';
import Link from 'components/Link/Link';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';
import { useAppStateContext } from 'stateProviders/useAppStateContext';

const FavoritesLink = ({ className, children }) => {
  const {
    restaurant: { data },
  } = useAppStateContext();
  const setQuery = useQueryUrlParamsDispatch();

  const handleClick = useCallback(() => {
    let params = {
      myFavoritesModal: true,
    };
    if (!data) {
      params = { selectLocationFirstModal: true };
    }
    setQuery(params);
  }, [setQuery, data]);

  return (
    <Link className={className} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default React.memo(FavoritesLink);
