import React, { useCallback } from 'react';
import { useQueryUrlParamsDispatch } from 'hooks';
import { ACTION_TYPES } from '../../stateProviders/appStateProvider';
import { useAppDispatchContext } from '../../stateProviders/useAppStateContext';
import auth from '../../services/auth';
import routes from 'routes.json';

import { PrimaryButton, TextButton } from 'components/Button/Button';
import NotFoundLogo from 'components/NotFoundLogo/NotFoundLogo';
import ModalWindow from 'components/Modal/Modal';
import Link from 'components/Link/Link';

import styles from './ExpiredSessionModal.module.scss';

const ExpiredSessionModal = ({ open, onClose }) => {
  const setQuery = useQueryUrlParamsDispatch();
  const appDispatch = useAppDispatchContext();

  const logout = useCallback(
    (url, params) => {
      appDispatch({ type: ACTION_TYPES.cleanupUserDetails });
      appDispatch({ type: ACTION_TYPES.cleanupBasket });
      appDispatch({ type: ACTION_TYPES.cleanupFavoriteOrders });
      appDispatch({ type: ACTION_TYPES.cleanUpUserBillingAccountsData });
      appDispatch({ type: ACTION_TYPES.cleanUpFavoriteOloRestaurant });
      appDispatch({ type: ACTION_TYPES.cleanUpPaymentData });
      appDispatch({ type: ACTION_TYPES.cleanUpGiftCard });
      auth.logout(() => {
        setQuery(params, url, true);
        appDispatch({ type: ACTION_TYPES.finishCleanUpBasket });
      });
      if (onClose) {
        onClose();
      }
    },
    [appDispatch, onClose, setQuery]
  );

  const handleCloseModal = useCallback(() => {
    logout(routes.signIn, null);
  }, [logout]);

  const handleGoHomeClick = useCallback(() => {
    logout(`/`, { location: true });
  }, [logout]);

  return (
    <ModalWindow open={open} onChange={handleCloseModal} maxWidth={'sm'}>
      <div className={styles.modal_content}>
        <div className={styles.content}>
          <NotFoundLogo />
          <p>Your session has expired.</p>
          <div className={styles.buttons}>
            <PrimaryButton size="large" onClick={handleCloseModal}>
              Log back in
            </PrimaryButton>
            <Link className={styles.backToHome} routing isGothic onClick={handleGoHomeClick}>
              <TextButton>Back to home</TextButton>
            </Link>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default React.memo(ExpiredSessionModal);
