import React, { useState, memo, Fragment, useCallback } from 'react';
import { PASSWORD_VALIDATION_PATTERN } from 'services/password';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import ModalWindow from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import { InputField } from 'components/Input/Input';
import Spin from 'components/Spin/Spin';
import showToast from 'components/Toast/ShowToast';
import Alert from 'components/Alert/Alert';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';

import useChangePassword from './hooks/useChangePassword';
import styles from './ChangePasswordModal.module.scss';

const inputsConfig = [
  {
    label: 'Current Password',
    property: 'currentPassword',
    autocomplete: 'current-password',
    isValid: (state) => {
      const value = state.model.currentPassword.value;
      return value !== '';
    },
    getValidationMessage: (state) => {
      const value = state.model.currentPassword.value;
      if (!value.length) {
        return 'Please provide your current password';
      }

      return '';
    },
  },
  {
    label: 'New Password',
    property: 'newPassword',
    autocomplete: 'new-password',
    isValid: (state) => {
      const value = state.model.newPassword.value;
      return value.match(PASSWORD_VALIDATION_PATTERN);
    },
    getValidationMessage: (state) => {
      const value = state.model.newPassword.value;
      if (!value.length) {
        return 'Please provide a password';
      }

      if (!value.match(PASSWORD_VALIDATION_PATTERN)) {
        return 'Password must contain at least 8 characters, 1 special character !@#$%^&?*, and 1 number';
      }

      return '';
    },
    helpMessage: 'Password must contain at least 8 characters, 1 special character !@#$%^&?*, and 1 number',
  },
  {
    label: 'Confirm New Password',
    property: 'confirmNewPassword',
    autocomplete: 'new-password',
    isValid: (state) => {
      const value = state.model.confirmNewPassword.value;
      const shouldMatchValue = state.model.newPassword.value;
      return value && value === shouldMatchValue;
    },
    getValidationMessage: (state) => {
      const value = state.model.confirmNewPassword.value;
      const shouldMatchValue = state.model.newPassword.value;
      if (!value.length) {
        return 'Please confirm your password';
      }

      if (value !== shouldMatchValue) {
        return 'Passwords do not match';
      }

      return '';
    },
  },
];

const ChangePasswordForm = memo(({ state, handleState, loading, error }) => {
  const onChange = (config) => (e) => handleState(config.property, { value: e.target.value.trim() });
  const onBlur = (config) => (e) => handleState(config.property, { isTouched: true });
  const inputs = inputsConfig.map((config, index) => {
    let isServerError = false;
    let serverValidationMessage = '';
    if (error && error.errorByFields && error.errorByFields.length > 0) {
      isServerError = error.errorByFields.some((field) => field === config.property);
      serverValidationMessage = isServerError ? error.errorMessage : '';
    }
    return (
      <Fragment key={index}>
        <InputField
          key={index}
          disabled={loading}
          className={styles.input_container}
          value={state.model[config.property].value}
          onBlur={onBlur(config)}
          onChange={onChange(config)}
          error={
            ((state.isFormSubmitted || state.model[config.property].isTouched) && !config.isValid(state)) ||
            (!state.model[config.property].isTouched && isServerError)
          }
          helperText={
            ((state.isFormSubmitted || state.model[config.property].isTouched) && config.getValidationMessage(state)) ||
            (!state.model[config.property].isTouched && serverValidationMessage)
          }
          inputProps={{
            maxLength: 32,
          }}
          autoComplete={config.autocomplete}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    handleState(config.property, { showPassword: !state.model[config.property].showPassword });
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  {state.model[config.property].value.length ? (
                    state.model[config.property].showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )
                  ) : (
                    ''
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={config.label}
          type={state.model[config.property].showPassword ? 'text' : 'password'}
        />
        {config.helpMessage && <p className={styles.help_message}>{config.helpMessage}</p>}
      </Fragment>
    );
  });

  const errorMessage =
    (error &&
      (!error.errorByFields || error.errorByFields.length === 0) &&
      (error.errorMessage || 'Something went wrong... Could not change password. Please try again later!')) ||
    '';
  return (
    <form className={styles.change_password_form}>
      <>
        {errorMessage && <Alert message={errorMessage} />}
        {inputs}
      </>
    </form>
  );
});

ChangePasswordForm.displayName = 'ChangePasswordForm';

const ChangePasswordModal = ({ onClose, isOpen }) => {
  const [{ loading, error }, { updatePassword, resetState }] = useChangePassword();

  const defaultProperty = {
    value: '',
    isTouched: false,
    showPassword: false,
  };

  const defaultLocalState = {
    model: {
      currentPassword: { ...defaultProperty },
      newPassword: { ...defaultProperty },
      confirmNewPassword: { ...defaultProperty },
    },
    isFormSubmitted: false,
  };

  const [localState, setLocalState] = useState(defaultLocalState);

  const handleModelState = (property, propertyState) => {
    const newProperty = { ...localState.model[property], ...propertyState };
    const newModel = { ...localState.model, [property]: newProperty };
    const newState = { ...localState, model: newModel };
    setLocalState(newState);
  };

  const handleIsFormSubmittedState = (isSubmitted) => {
    const newState = { ...localState, isFormSubmitted: [isSubmitted] };
    setLocalState(newState);
  };

  const handleOnClose = useCallback(() => {
    resetState();
    setLocalState(defaultLocalState);
    onClose({ changePassword: false });
  }, [resetState, defaultLocalState, onClose]);

  const handleOnSubmit = () => {
    handleIsFormSubmittedState(true);

    const patternMatched = inputsConfig.every((config) => config.isValid(localState));

    if (!patternMatched) {
      return;
    }

    updatePassword({
      currentPassword: localState.model.currentPassword.value,
      newPassword: localState.model.newPassword.value,
    })
      .then(() => {
        handleOnClose();
        showToast('success', 'Password updated successfully');
      })
      .catch((error) => {
        if (error && error.errorByFields && error.errorByFields.length > 0) {
          inputsConfig.forEach((config) => {
            const isServerError = error.errorByFields.some((field) => field === config.property);
            if (isServerError) {
              handleModelState(config.property, { isTouched: false });
            }
          });
        }
      });
  };

  return (
    <ModalWindow
      open={isOpen}
      onChange={handleOnClose}
      headerTitle={'CHANGE PASSWORD'}
      maxWidth="sm"
      headerSize="lg"
      footerChildren={
        <div className={styles.modal_footer}>
          <PrimaryButton
            endIcon={<ArrowRight />}
            className={styles.btn}
            disabled={loading}
            size="large"
            onClick={handleOnSubmit}
          >
            {loading && <Spin spinning={loading} className={styles.spinner} />}
            CHANGE PASSWORD
          </PrimaryButton>
        </div>
      }
    >
      <div className={styles.modal_content}>
        <ChangePasswordForm state={localState} loading={loading} error={error} handleState={handleModelState} />
      </div>
    </ModalWindow>
  );
};

export default React.memo(ChangePasswordModal);
