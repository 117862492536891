import React, { memo, useCallback } from 'react';
import { TextButton } from 'components/Button/Button';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';
import { useLocation } from 'react-router';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { useParams } from 'react-router';
import { useQueryUrlParams } from 'hooks';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';

const ChangeLocationLink = ({ label = 'Change Location', size = 'medium', className, alreadyOnChangeLocation }) => {
  const {
    groupOrder,
    restaurant: { data },
  } = useAppStateContext();
  const setPush = useQueryUrlParamsDispatch();
  const { pathname, search } = useLocation();
  const { restaurantId: queryRestaurantId } = useQueryUrlParams();
  const { restaurantId, orderId } = useParams();
  const appDispatch = useAppDispatchContext();
  const getRestaurantId = restaurantId || queryRestaurantId || (data && data.id);
  const setCurrentLocation = useCallback(() => {
    if (alreadyOnChangeLocation && (pathname + search).includes('/?location=true')) {
      return alreadyOnChangeLocation && alreadyOnChangeLocation();
    }

    appDispatch({ type: ACTION_TYPES.cleanUpPaymentData });
    appDispatch({ type: ACTION_TYPES.cleanUpGiftCard });

    const confirmOrderPage = pathname.includes(`order/${orderId}`);
    setPush(
      {
        location: true,
        prevRestaurant: getRestaurantId,
        returnUrl: !confirmOrderPage && encodeURIComponent(pathname + search),
      },
      '/',
      true
    );
  }, [setPush, getRestaurantId, pathname, search, appDispatch, orderId, alreadyOnChangeLocation]);

  const groupOrderExist = !(groupOrder && groupOrder.data === null && !groupOrder.error);
  const confirmOrderPage = pathname.includes(`order/${orderId}`);
  const createFavoriteFromOrder = pathname.includes(`createfave/${orderId}`);
  const isDisabled = confirmOrderPage || createFavoriteFromOrder || groupOrderExist;

  return (
    <TextButton className={className} disabled={isDisabled} size={size} onClick={setCurrentLocation}>
      {label}
    </TextButton>
  );
};
const MemoizedChangeLocationLink = memo(ChangeLocationLink);
export default MemoizedChangeLocationLink;
