import { useCallback, useEffect, useReducer } from 'react';
import { getAddresses, deleteAddress } from 'api/user/deliveryAddresses';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import showToast from 'components/Toast/ShowToast';

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const useUserSavedAddressList = (authenticated) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      if (authenticated && !ignore) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const response = await getAddresses();
          const savedAddressList = response.deliveryaddresses || [];

          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: savedAddressList } });
          }
        } catch (e) {
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            showToast('error', e.errorMessage || 'Something went wrong... Could not get delivery addresses!');
          }
        }
      }
    };

    fetch();

    return () => {
      ignore = true;
    };
  }, [authenticated]);

  const loadAddressList = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            const { deliveryaddresses } = await getAddresses();
            dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: deliveryaddresses } });
            resolve(deliveryaddresses);
          } catch (e) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            showToast('error', e.errorMessage || 'Something went wrong... Could not get delivery addresses!');
            reject(false);
          }
        };
        if (authenticated) {
          fetch();
        }
      }),
    [dispatch, authenticated]
  );

  const removeAddress = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        const fetch = async () => {
          if (id) {
            try {
              await deleteAddress(id);
              resolve(true);
            } catch (e) {
              showToast('error', e.errorMessage || 'Something went wrong... Could not delete address!');
              reject(false);
            }
          }
        };
        fetch();
      }),
    []
  );

  return { ...state, loadAddressList, removeAddress };
};

export default useUserSavedAddressList;
