import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import { useParams } from 'react-router';
import { addProductToBasket } from 'api/addProductToBasket';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import { createBasket } from 'api/createBasket';
import { useQueryUrlParamsDispatch } from 'hooks';
import { updateBasketProduct } from 'api/updateBasketProduct';
import { getGroupOrder } from 'api/getGroupOrder';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const checkGroupOrder = async (groupOrderId, queryBasketId, dispatch, dispatchAppBasketState, errorMsg) => {
  const groupOrderResponse = await getGroupOrder(groupOrderId, queryBasketId);
  if (!groupOrderResponse.isopen) {
    dispatch({
      type: FETCH_ACTION_TYPES.setError,
      payload: {
        error: { num: 0, message: errorMsg },
      },
    });
    dispatchAppBasketState({ type: ACTION_TYPES.setGroupOrder, payload: { data: groupOrderResponse } });
    return false;
  } else {
    dispatchAppBasketState({ type: ACTION_TYPES.setGroupOrder, payload: { data: groupOrderResponse } });
    return true;
  }
};

const useAddingProductToBasket = () => {
  const { restaurantId } = useParams();
  let { basketId: queryBasketId, groupOrderId, owner } = useQueryUrlParams();
  const setQuery = useQueryUrlParamsDispatch();
  const { authenticated } = useAppStateContext();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppBasketState = useAppDispatchContext();
  const parsedOwner = owner ? !!JSON.parse(owner) : false;

  const addProduct = useCallback(
    (productId = '', options = '', quantity = 1, specialInstructions = '', recipient = '') => {
      let ignore = false;
      const fetch = async () => {
        if (productId) {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            if (!parsedOwner && groupOrderId && queryBasketId) {
              const resolved = await checkGroupOrder(
                groupOrderId,
                queryBasketId,
                dispatch,
                dispatchAppBasketState,
                'Your group order link is expired. Item can not be added.'
              );
              if (!resolved) {
                return;
              }
            }
            let basketId = queryBasketId || '';
            if (!basketId && restaurantId) {
              const basketResponse = await createBasket(restaurantId, authenticated);
              basketId = basketResponse.id;
              setQuery({ basketId: basketId });
            }
            const response = await addProductToBasket(
              basketId,
              productId,
              options,
              quantity,
              specialInstructions,
              recipient
            );
            if (!ignore) {
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
              dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: response } });
            }
          } catch (e) {
            if (!ignore) {
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            }
          }
        }
      };
      fetch();
      return () => {
        ignore = true;
      };
    },
    [queryBasketId, restaurantId, authenticated, dispatchAppBasketState, dispatch, setQuery, groupOrderId, parsedOwner]
  );
  const updateProduct = useCallback(
    (basketProductId = '', productId = '', options = '', quantity = 0, specialInstructions = '', recipient = '') => {
      let ignore = false;
      const fetch = async () => {
        if (queryBasketId && basketProductId && productId && quantity > 0) {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            if (!parsedOwner && groupOrderId) {
              const resolved = await checkGroupOrder(
                groupOrderId,
                queryBasketId,
                dispatch,
                dispatchAppBasketState,
                'Your group order link is expired. Item can not be updated.'
              );
              if (!resolved) {
                return;
              }
            }
            const response = await updateBasketProduct(
              queryBasketId,
              basketProductId,
              productId,
              options,
              quantity,
              specialInstructions,
              recipient
            );
            if (!ignore) {
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
              dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: response } });
            }
          } catch (e) {
            if (!ignore) {
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            }
          }
        }
      };
      fetch();
      return () => {
        ignore = true;
      };
    },
    [queryBasketId, dispatchAppBasketState, dispatch, groupOrderId, parsedOwner]
  );
  return [state, { addProduct: addProduct, updateProduct: updateProduct }];
};

export default useAddingProductToBasket;
