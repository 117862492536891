import React, { useCallback, useState, useEffect } from 'react';
import { PrimaryButton } from 'components/Button/Button';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import OrderingPanel from 'modules/OrderingPanel/OrderingPanel';
import useBasketInfo from './hooks/useBasketInfoFetch';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import useQueryUrlParamsDispatch, { useQueryUrlCleanParamsDispatch } from 'hooks/useQueryUrlParamsDispatch';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useLocation } from 'react-router';
import { isMacOs } from 'react-device-detect';
import classNames from 'classnames';
import './DrawerPanel.scss';

const DrawerPanel = () => {
  const location = useLocation();
  const { openBasket, groupOrderId, owner } = useQueryUrlParams();
  const cleanQuery = useQueryUrlCleanParamsDispatch();
  const setQuery = useQueryUrlParamsDispatch();
  const { modifyFavoriteIsInProgress } = useAppStateContext();
  const parseOpenBasket = openBasket && JSON.parse(openBasket);
  const { data } = useBasketInfo();
  const [openPanel, setOpenPanel] = useState(parseOpenBasket);
  const isCheckoutPage = location.pathname.includes('/checkout');
  const handleOpenPanel = useCallback(() => {
    if (modifyFavoriteIsInProgress) {
      setQuery({ favoritesPanel: true }, null, false, ['groupOrderId', 'owner', 'joinGroup', 'groupOrderModal']);
      return;
    }
    if (groupOrderId && !owner) {
      setQuery({ groupOrderPanel: true }, null, false, ['favoritesPanel', 'faveId']);
      return;
    }

    setOpenPanel(!openPanel);
    if (!openPanel) {
      setQuery({ openBasket: true });
    } else {
      cleanQuery(['openBasket']);
    }
  }, [modifyFavoriteIsInProgress, owner, groupOrderId, setQuery, cleanQuery, openPanel]);
  const handleClosePanel = useCallback(
    (bool) => {
      setOpenPanel(bool || false);
      cleanQuery(['openBasket', 'checkoutAs']);
    },
    [cleanQuery]
  );
  useEffect(() => {
    if (parseOpenBasket) {
      setOpenPanel(parseOpenBasket);
    }
  }, [parseOpenBasket]);
  let productsCount = 0;
  if (data) {
    productsCount = data['products'] && data['products'].length;
  }
  const RenderIconBag = useCallback(() => {
    if (!productsCount) {
      return <LocalMallIcon />;
    } else if (productsCount > 0) {
      return (
        <span className="counter_wrapper">
          <span className={classNames('bag_counter', { mac_platform: isMacOs })}>{productsCount}</span>
        </span>
      );
    }
  }, [productsCount]);
  return (
    <div className="drawer_panel_wrapper">
      {isCheckoutPage ? null : (
        <PrimaryButton
          variant="outlined"
          className="drawer_panel_btn_bag"
          onClick={handleOpenPanel}
          endIcon={<RenderIconBag />}
        >
          <span className={classNames('drawer_panel_btn_bag_text', { mac_platform: isMacOs })}>Bag</span>
        </PrimaryButton>
      )}
      <OrderingPanel
        emptyBasket={productsCount === 0}
        handleClose={handleClosePanel}
        defaultStep={openPanel ? 'order' : null}
      />
    </div>
  );
};
export default React.memo(DrawerPanel);
