import React, { useEffect, useState } from 'react';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import NewCreditCard from './NewCreditCard/NewCreditCard';
import SavedCreditCard from './SavedCreditCard/SavedCreditCard';
import { TextButton } from 'components/Button/Button';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import styles from './PaymentMethod.module.scss';
import useSecureInputs from './hooks/useSecureInputs';
import classNames from 'classnames/bind';

const getUserBillingCreditCard = (card) => !card.accounttype.toLowerCase().includes('gift card');

const PaymentMethod = ({ isTouched, onErrorNewCard, errorsNewPaymentCard }) => {
  const {
    authenticated,
    paymentData: { isUseSavedCard, isCash },
    basket: { loading: basketLoading, error: basketError },
    userBillingAccounts: { billingAccounts },
  } = useAppStateContext();
  const appDispatch = useAppDispatchContext();
  const [creditCardContainer, setCreditCardContainer] = useState({});
  const [cvvContainer, setCvvContainer] = useState({});
  const [basketId, setBasketId] = useState(null);

  const onTogglePaymentMethod = () => {
    appDispatch({
      payload: false,
      type: ACTION_TYPES.setIsUseCardTouched,
    });
    appDispatch({
      payload: !isUseSavedCard,
      type: ACTION_TYPES.setIsUseSavedCard,
    });
  };

  useSecureInputs({ creditCardContainer, cvvContainer, basketId });

  const cardInputContainerChangedHandler = (creditCardContainer, cvvContainer, basketId) => {
    setCreditCardContainer(creditCardContainer);
    setCvvContainer(cvvContainer);
    setBasketId(basketId);
  };

  useEffect(() => {
    return () => {
      appDispatch({
        payload: false,
        type: ACTION_TYPES.setIsUseSavedCard,
      });
    };
  }, [appDispatch]);
  const creditCardsData = billingAccounts && billingAccounts.filter(getUserBillingCreditCard);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.title}>Credit Card</h3>
        <div className={styles.header_btn}>
          {authenticated && Array.isArray(creditCardsData) && !!creditCardsData.length && !isCash ? (
            <TextButton className={styles.link} disabled={basketLoading || basketError} onClick={onTogglePaymentMethod}>
              {isUseSavedCard ? 'Use New Card' : 'Use Saved Card'}
            </TextButton>
          ) : null}
        </div>
      </div>
      <div className={classNames({ [styles.hidden]: !isUseSavedCard })}>
        <SavedCreditCard />
      </div>
      <div className={classNames({ [styles.hidden]: isUseSavedCard })}>
        <NewCreditCard
          onError={onErrorNewCard}
          errorsNewPaymentCard={errorsNewPaymentCard}
          isTouched={isTouched}
          cardInputContainerChanged={cardInputContainerChangedHandler}
        />
      </div>
    </div>
  );
};

export default React.memo(PaymentMethod);
