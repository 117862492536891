import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { PrimaryButton } from 'components/Button/Button';
import { InputField } from 'components/Input/Input';
import { ReactComponent as Book } from 'assets/icons/book.svg';

import styles from './ProductInstructions.module.scss';

const ProductInstructions = ({
  recipientRef,
  specialInstructions,
  handleSpecialInstructions,
  recipient,
  handleRecipient,
  showRecipientError,
  maxInstructionsLength,
  maxNameLength,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      recipientRef.current.scrollIntoView();
    }
  }, [isVisible, recipientRef]);

  useEffect(() => {
    if (showRecipientError) {
      setIsVisible(true);
    }
  }, [showRecipientError]);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={styles.container}>
      <PrimaryButton onClick={handleToggle} endIcon={<Book />}>
        SPECIAL INSTRUCTIONS
      </PrimaryButton>
      <div className={classNames(styles.wrapper, { [styles.visible]: isVisible })}>
        <InputField
          value={specialInstructions}
          onChange={handleSpecialInstructions}
          label={`Max ${maxInstructionsLength} Characters`}
          inputProps={{ maxLength: maxInstructionsLength }}
          className={styles.input}
        />
        <InputField
          value={recipient}
          onChange={handleRecipient}
          label={'Name'}
          inputProps={{ maxLength: maxNameLength }}
          className={styles.input}
          error={showRecipientError}
          helperText={showRecipientError ? 'Please enter name' : ''}
          ref={recipientRef}
        />
      </div>
    </div>
  );
};

export default React.memo(ProductInstructions);
