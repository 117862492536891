import React, { forwardRef, memo } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ErrorIcon from '@material-ui/icons/Error';
import './Notification.scss';

// type = "error" | "warning" | "info" | "success"
const Notification = memo(
  forwardRef((props, ref) => {
    const { type = 'error', message = '', title = '', id = '1', ...otherProps } = props;
    return (
      <Alert
        id={id}
        ref={ref}
        classes={{
          root: 'notification_root',
          standardSuccess: 'notification_success',
          standardInfo: 'notification_info',
          standardWarning: 'notification_warning',
          standardError: 'notification_error',
        }}
        iconMapping={{
          success: <CheckIcon fontSize="inherit" />,
          info: <InfoIcon fontSize="inherit" />,
          warning: <WarningRoundedIcon fontSize="inherit" />,
          error: <ErrorIcon fontSize="inherit" />,
        }}
        severity={type}
        {...otherProps}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    );
  })
);
Notification.displayName = 'Notification';
export default Notification;
