import React, { memo } from 'react';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import logo from 'assets/logo.svg';
import './LogoComponent.scss';
import { useQueryUrlParamsDispatch } from 'hooks';
import { useHistory } from 'react-router';

const LogoComponent = ({ image = logo }) => {
  const {
    restaurant: { data, loading, error },
  } = useAppStateContext();
  const { location } = useHistory();
  const setRedirect = useQueryUrlParamsDispatch();
  const handleRedirect = () => {
    window.location.replace('https://www.99restaurants.com/');
    /*     if (data && data.id) {
      if (location.pathname.includes(`/${data.id}/order/`)) {
        setRedirect(null, `/${data.id}/menu`, true);
      } else {
        setRedirect(null, `/${data.id}/menu`);
      }
    } else {
      setRedirect({ location: true }, '/');
    } */
  };
  return (
    <div className={'logo_link'} onClick={handleRedirect}>
      {logo && <img src={image} alt="99-restaurant" />}
    </div>
  );
};
const MemoizedLogoComponent = memo(LogoComponent);
export default MemoizedLogoComponent;
