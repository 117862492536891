import React, { memo, useEffect, useState } from 'react';
import styles from './SavedGiftCard.module.scss';
import SelectedPaymentCard from 'components/SelectedPaymentCard/SelectedPaymentCard';
import Price from 'components/Price/Price';
import { useAppDispatchContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';

const SavedGiftCard = ({ selectedGiftCard, onClick }) => {
  const appDispatch = useAppDispatchContext();
  const [currGiftCard, setCurrGiftCard] = useState(null);
  useEffect(() => {
    if (selectedGiftCard && !currGiftCard) {
      appDispatch({
        type: ACTION_TYPES.setGiftCard,
        payload: { data: selectedGiftCard },
      });
      setCurrGiftCard(selectedGiftCard);
    }
  }, [selectedGiftCard, currGiftCard, appDispatch]);
  return (
    selectedGiftCard && (
      <SelectedPaymentCard onClick={onClick}>
        <div className={styles.card_number}>{selectedGiftCard.name}</div>
        <div className={styles.balance}>Balance: {<Price value={selectedGiftCard.balance} />}</div>
      </SelectedPaymentCard>
    )
  );
};

const MemoizedSavedGiftCard = memo(SavedGiftCard);

export default MemoizedSavedGiftCard;
