import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import './Slider.scss';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Swipeable } from 'react-swipeable';

const THRESHOLD = 20;
const DELTA = 30;

const getSlidesAmount = (displayedItems, containerRef, currentX) => {
  let slides = 1;
  if (displayedItems && displayedItems.length > 0 && containerRef && containerRef.current) {
    const eX = -1 * currentX + containerRef.current.offsetWidth;
    const visibleItems = displayedItems.filter((item) => {
      const startX = item.ref && item.ref.current ? item.ref.current.offsetLeft : null;
      const endX = item.ref && item.ref.current && startX !== null ? startX + item.ref.current.offsetWidth : null;
      return startX !== null && endX !== null && startX >= -1 * currentX && endX <= eX;
    });
    slides = visibleItems.length > 1 ? visibleItems.length : 1;
  }
  return slides;
};

const Slider = (props) => {
  const {
    items,
    itemComponentProps,
    className,
    padding = 24,
    centered = true,
    size = 'large',
    hideNavigation = false,
    value,
    onChange,
    onClickItem,
  } = props;
  const containerRef = useRef();
  const scrollableRef = useRef();
  const listRef = useRef();
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState();
  const [currentX, setCurrentX] = useState();
  const total = displayedItems.length;

  const updateSlidesAmount = useCallback(() => {
    const slides = getSlidesAmount(displayedItems, containerRef, currentX);
    if (slidesToShow !== slides) {
      setSlidesToShow(slides);
    }
  }, [slidesToShow, displayedItems, containerRef, currentX]);

  useEffect(() => {
    if (items.length > 0) {
      const newItems = items.map((item) => {
        return { props: item, ref: React.createRef() };
      });
      setDisplayedItems(newItems);
    }
  }, [items]);

  useEffect(() => {
    if (value !== undefined) {
      setCurrentIndex(value);
    }
  }, [value]);

  useEffect(() => {
    if (
      currentIndex !== undefined &&
      currentIndex !== -1 &&
      scrollableRef &&
      scrollableRef.current &&
      displayedItems.length > 0
    ) {
      let x = 0;
      if (slidesToShow === 1 && centered && currentIndex !== 0 && currentIndex !== displayedItems.length - 1) {
        x =
          displayedItems[currentIndex] && displayedItems[currentIndex].ref && displayedItems[currentIndex].ref.current
            ? displayedItems[currentIndex].ref.current.offsetLeft -
              (containerRef.current.clientWidth / 2 - displayedItems[currentIndex].ref.current.clientWidth / 2)
            : 0;
      } else {
        if (currentIndex + slidesToShow < displayedItems.length) {
          x =
            displayedItems[currentIndex] && displayedItems[currentIndex].ref && displayedItems[currentIndex].ref.current
              ? displayedItems[currentIndex].ref.current.offsetLeft - padding
              : 0 - padding;
        } else if (slidesToShow === displayedItems.length) {
          x = -padding;
        } else {
          x = listRef.current.clientWidth - containerRef.current.clientWidth + padding;
        }
      }
      scrollableRef.current.style.transition = null;
      scrollableRef.current.style.transform = `translate3d(${-x}px, 0px, 0px)`;
      setCurrentX(-x);
    } else if (currentX === undefined && scrollableRef && scrollableRef.current) {
      scrollableRef.current.style.transition = 'none';
      scrollableRef.current.style.transform = `translate3d(${padding}px, 0px, 0px)`;
    }
  }, [currentIndex, currentX, displayedItems, scrollableRef, listRef, containerRef, padding, centered, slidesToShow]);

  useEffect(() => {
    if (currentX) {
      const slides = getSlidesAmount(displayedItems, containerRef, currentX);
      setSlidesToShow(slides);
    }
  }, [currentX, displayedItems, containerRef]);

  useEffect(() => {
    if (onChange && currentIndex !== undefined) {
      onChange(currentIndex, slidesToShow);
    }
  }, [currentIndex, slidesToShow, onChange]);

  useEffect(() => {
    if (displayedItems.length > 0 && currentIndex === undefined) {
      setCurrentIndex(0);
    }
  }, [currentIndex, displayedItems]);

  useEffect(() => {
    window.addEventListener('resize', updateSlidesAmount);
    return () => {
      window.removeEventListener('resize', updateSlidesAmount);
    };
  }, [updateSlidesAmount]);

  const handleSwiping = (eventData) => {
    const { dir, first, deltaX, event } = eventData;
    if (first && scrollableRef && scrollableRef.current) {
      scrollableRef.current.style.transition = 'none';
    }
    if (dir === 'Left' || dir === 'Right') {
      event.target.returnValue = false;
      let x = currentX - deltaX;
      x += dir === 'Left' ? DELTA : -DELTA;
      if (scrollableRef && scrollableRef.current) {
        scrollableRef.current.style.transform = `translate3d(${x}px, 0px, 0px)`;
      }
      return false;
    }
    return true;
  };

  const handleSwipe = (eventData) => {
    const { deltaX, absX, absY } = eventData;
    if (scrollableRef && scrollableRef.current) {
      scrollableRef.current.style.transition = null;
    }
    if (absX >= THRESHOLD && absY <= 50) {
      const dir = deltaX > 0 ? 1 : -1;
      let newIndex = currentIndex + dir * slidesToShow;
      newIndex = newIndex < 0 ? 0 : newIndex >= total - slidesToShow ? total - slidesToShow : newIndex;
      if (newIndex === currentIndex) {
        scrollableRef.current.style.transform = `translate3d(${currentX}px, 0px, 0px)`;
      } else {
        setCurrentIndex(newIndex);
      }
    } else {
      scrollableRef.current.style.transform = `translate3d(${currentX}px, 0px, 0px)`;
    }
  };

  const handlePrevClick = useCallback(() => {
    const newIndex = currentIndex - slidesToShow >= 0 ? currentIndex - slidesToShow : 0;
    setCurrentIndex(newIndex);
  }, [currentIndex, slidesToShow]);

  const handleNextClick = useCallback(() => {
    const newIndex =
      currentIndex + slidesToShow < total - slidesToShow ? currentIndex + slidesToShow : total - slidesToShow;
    setCurrentIndex(newIndex);
  }, [currentIndex, slidesToShow, total]);

  return (
    <div className={classNames('slider_wrapper', className)} style={{ margin: `0 -${padding}px` }}>
      {currentIndex !== 0 && !hideNavigation && (
        <div className={classNames('slider_prev_container', size)}>
          <div className={classNames('slider_prev', size)} onClick={handlePrevClick} style={{ left: `${padding}px` }}>
            <ChevronLeftIcon className={'slider_button_icon'} />
          </div>
        </div>
      )}
      <div className={'slider_container'} ref={containerRef}>
        <Swipeable
          onSwipedLeft={handleSwipe}
          onSwipedRight={handleSwipe}
          onSwiping={handleSwiping}
          preventDefaultTouchmoveEvent={true}
          delta={DELTA}
        >
          <div className={'slider_scrollable_container'} ref={scrollableRef}>
            <div className={'slider_list'} ref={listRef}>
              {displayedItems.map((item, displayedIndex) => {
                return (
                  <div key={displayedIndex} ref={item.ref || null} className={'slider_item'}>
                    <>
                      <props.itemComponent
                        {...item.props}
                        {...itemComponentProps}
                        tabIndex={-1}
                        className={
                          typeof itemComponentProps.className === 'function'
                            ? itemComponentProps.className(item.props)
                            : itemComponentProps.className
                        }
                        onClick={() => {
                          if (scrollableRef && scrollableRef.current) {
                            scrollableRef.current.style.transition = null;
                          }
                          onClickItem(item.props);
                        }}
                      />
                    </>
                  </div>
                );
              })}
            </div>
          </div>
        </Swipeable>
      </div>
      {currentIndex + slidesToShow !== displayedItems.length && !hideNavigation && (
        <div className={classNames('slider_next_container', size)}>
          <div className={classNames('slider_next', size)} onClick={handleNextClick} style={{ right: `${padding}px` }}>
            <ChevronRightIcon className={'slider_button_icon'} />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Slider);
