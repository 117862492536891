import { useEffect, useReducer } from 'react';
import { checkOrderStatus } from 'api/checkOrderStatus';
import { getRestaurantById } from 'api/restaurantById';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useOrderStatusFetch = (orderId) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  useEffect(() => {
    let ignore = false;
    const fetch = async () => {
      if (orderId) {
        dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
        try {
          const response = await checkOrderStatus(orderId);
          let restaurantResponse;
          if (response && response.vendorid) {
            restaurantResponse = await getRestaurantById(response.vendorid);
          }
          if (!ignore) {
            dispatch({
              type: FETCH_ACTION_TYPES.setData,
              payload: { data: { order: response, restaurant: restaurantResponse } },
            });
          }
        } catch (e) {
          if (!ignore) {
            dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
          }
        }
      }
    };
    fetch();
    return () => {
      ignore = true;
    };
  }, [orderId]);
  return state;
};

export default useOrderStatusFetch;
