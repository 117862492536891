import React, { memo } from 'react';
import classNames from 'classnames/bind';
import styles from './CardWrapper.module.scss';

const CardWrapper = ({ isSelected, onClick, children }) => (
  <div
    className={classNames(styles.wrapper, {
      [styles.selected]: isSelected,
    })}
    onClick={onClick}
  >
    {children}
  </div>
);

const MemoizedCreditCard = memo(CardWrapper);

export default MemoizedCreditCard;
