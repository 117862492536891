import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './Toast.scss';

const Toast = (props) => {
  const { toastList } = props;

  return (
    <div className={'notification_content'}>
      {toastList.icon}
      <p>{toastList.description}</p>
    </div>
  );
};

Toast.propTypes = {
  toastList: PropTypes.object.isRequired,
};

const MemoizedToast = memo(Toast);

export default MemoizedToast;
