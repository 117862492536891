import { useCallback, useReducer } from 'react';
import { fetchReducer, FETCH_ACTION_TYPES } from 'reducers/fetchReducer';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import { ACTION_TYPES } from 'stateProviders/appStateProvider';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import { useQueryUrlParamsDispatch } from 'hooks';
import { createGroupOrder } from 'api/createGroupOrder';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const useCreatingGroupOrder = () => {
  let { basketId } = useQueryUrlParams();
  const setQuery = useQueryUrlParamsDispatch();
  const { authenticated, restaurant } = useAppStateContext();
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const dispatchAppBasketState = useAppDispatchContext();
  const restaurantId = restaurant && restaurant.data ? restaurant.data.id : '';
  const create = useCallback(
    (deadline = '', note = '') => {
      let ignore = false;
      const fetch = async () => {
        if (restaurantId && authenticated) {
          dispatch({ type: FETCH_ACTION_TYPES.loading, payload: { loading: true } });
          try {
            let basket = basketId || '';
            const response = await createGroupOrder(restaurantId, basket, deadline, note);
            const groupOrderId = response.id;
            if (!ignore) {
              setQuery({ groupOrderId: groupOrderId, basketId: response.basket.id, owner: true, invites: true });
              dispatch({ type: FETCH_ACTION_TYPES.setData, payload: { data: response } });
              dispatchAppBasketState({ type: ACTION_TYPES.setGroupOrder, payload: { data: response } });
              dispatchAppBasketState({ type: ACTION_TYPES.setBasket, payload: { data: response.basket } });
            }
          } catch (e) {
            if (!ignore) {
              dispatch({ type: FETCH_ACTION_TYPES.setError, payload: { error: e } });
            }
          }
        }
      };
      fetch();
      return () => {
        ignore = true;
      };
    },
    [basketId, restaurantId, authenticated, dispatchAppBasketState, dispatch, setQuery]
  );

  return [state, { createGroupOrder: create }];
};

export default useCreatingGroupOrder;
