import React from 'react';

import './ProductCalories.scss';

const ProductCalories = ({ basecalories, caloriesseparator, maxcalories }) => {
  return (
    basecalories && (
      <span className={'calories'}>
        {basecalories}
        {caloriesseparator && caloriesseparator + maxcalories}
        &nbsp;CAL
      </span>
    )
  );
};

export default React.memo(ProductCalories);
